import { IOption } from '../Interfaces';
import { ADD_SOCIAL_POST } from '../shared/enums';

export class AddSocialPostRootState {
  started: boolean = false;
  activeStep: ADD_SOCIAL_POST= ADD_SOCIAL_POST.SOCIAL_POST_DESC;
  uploadedImages: string[];
  socialPostDesc: string = '';

  constructor(props?: Partial<AddSocialPostRootState>) {
    Object.assign(this, props);
  }

  // Mapping UI model to Backend Model
}

export interface ImageUpload {
    uploadImage: string;
  }