import React, { useEffect, useState } from "react";
import { Modal, ModalFooter } from "react-bootstrap";
import { ReactPhotoEditor } from "react-photo-editor";
import "react-photo-editor/dist/style.css";
import blackarrow from "../../../assets/images/blackarrow.svg";
import { uploadFileImg } from "../../../Posts/api";
import styles from "../styles/style.module.css";
interface ModalProps {
  modal3: boolean;
  closeModal3: any;
  title: string;
  img: string | undefined;
  editedImage?: any
}

const EditImageModal: React.FC<ModalProps> = ({
  title,
  modal3,
  closeModal3,
  img,
  editedImage
}) => {
  // if (!modal3) return null;
  const [imageFile, setImageFile] = useState<any>(null);
  const [showModal, setShowModal] = useState(true);
  const [imge, setimge] = useState();

  const [onImageEditing, setImageEditing] = useState(false)

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const file = await urlToFile(img as string, "aws-image.jpg", "image/jpeg");
        console.log(file, "file after fetchImage EDIT success");
        setImageFile(file);
      } catch (error) {
        console.error("file after fetchImage EDIT error", error);
      }
    };
    fetchImage();
  }, []);

  const showModalHandler = () => {
    if (imageFile) {
      setShowModal(true);
    }
  };

  const hideModal = () => {
    setShowModal(false);
    // closeModal3()
  };

  const urlToFile = async (url: string, filename: string, mimeType: string) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], filename, { type: mimeType });
  };

  const handleSaveImage = (editedFile: any) => {
    console.log(editedFile, "kjfsdjfjsbdf EDIT");
    setImageEditing(true)
    handleFileUpload(editedFile);
    // return;
    // setImageFile(editedFile);
  };

  const handleFileUpload = async (files: any) => {
    console.log(files, "filesfilesfilesfilesfiles EDIT");

    if (files) {
      const formData = new FormData();
      // Array.from(files).forEach((img) => {
      //   formData.append("files", img);
      // });
      // Object.keys(files).forEach((key: any) => {
      //   // console.log(`${key}: );
      //   formData.append("files", `${files[key]}`);
      // });
      formData.append("files", files);

      console.log(formData, "formData EDIT");
      try {
        const response = await uploadFileImg(formData);
        const newImageUrl = response?.data?.files[0];
        console.log(newImageUrl, "newImageUrl EDIT ", response);
        editedImage(newImageUrl)
        // setTimeout(() => {
        closeModal3()
        // }, 0);
        setTimeout(() => {
          setImageEditing(false)
        }, 1500);
      } catch (err) {
        setImageEditing(false)
        console.log("Error ocuring while uploading the image", err);
      }
    }
  };
  console.log(imageFile?.name, "image file");

  return (

    <>
      <Modal show={modal3} onHide={closeModal3} centered>
        {/*  */}
        <>
          {/* <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header> */}

          {
            onImageEditing ?
              <div style={{
                height: "100",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // backgroundColor: "pink",
                paddingBottom: "240px"
              }}>
                <div className={`text-center ${styles.spinner}`}>
                  <div className="spinner-border text-blue" role="status">
                  </div>
                </div>
              </div>
              :
              <form>
                <Modal.Body>
                  {/* <div className="row p-3">
                    <div className="col-12">
                      <div className="addModalPro"> */}
                  {/* <button onClick={showModalHandler}>Edit</button> */}
                  <ReactPhotoEditor
                    open={showModal}
                    onClose={hideModal}
                    file={imageFile}
                    onSaveImage={handleSaveImage}
                  // downloadOnSave={true}
                  />
                  {/* </div>
                    </div>
                  </div> */}
                </Modal.Body>
                {/* <ModalFooter>
                  <div className="btn-hover-effect">
                    <h6
                      onClick={closeModal3}
                      className="btn bg-white arrowyellow border border-1 rounded-3 position-relative ps-5 backbutton w-auto"
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
                        Back
                      </span>
                      <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3 p-4">
                        <img
                          src={blackarrow}
                          alt=""
                          width={15}
                          height={15}
                          style={{ transform: "rotate(-180deg)" }}
                        />
                      </i>
                    </h6>
                  </div>
                </ModalFooter> */}
              </form>
          }

        </>
      </Modal>
    </>
  );
};

export default EditImageModal;
