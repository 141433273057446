import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ConnectionPlan {
    connectionPrice: number;
    connectionPlanType: string;
    connectionId: string;
    connectionDiscount: number;
}

const initialState: ConnectionPlan = {
    connectionPrice: 0,
    connectionPlanType: '',
    connectionId: '',
    connectionDiscount: 0,
};

const connectionSlice = createSlice({
    name: 'connection',
    initialState,
    reducers: {
        setConnectionPrice(state, action: PayloadAction<number>) {
            state.connectionPrice = action.payload;
        },
        setConnectionPlanType(state, action: PayloadAction<string>) {
            state.connectionPlanType = action.payload;
        }, setConnectionId(state, action: PayloadAction<string>) {
            state.connectionId = action.payload;
        },
        setConnectionDiscount(state, action: PayloadAction<number>) {
            state.connectionDiscount = action.payload;
        },
    }
});

export const { setConnectionPrice, setConnectionPlanType, setConnectionId, setConnectionDiscount } = connectionSlice.actions
export default connectionSlice.reducer;