import { MouseEventHandler } from "react";
import { axios } from "../../../lib/axios";
import { AxiosResponse } from "../../../shared/models";


// Define the API route for the endpoint
const API_ROUTES = {
  CUSTOMER: {
    GET_CUSTOMERS: "/user/customers",
  },
};

// Define the interface for the response data if applicable
export interface CustomerData {
    customer: {
      _id: MouseEventHandler<HTMLButtonElement> | undefined
      userData: {
        country: any;
        profilePicture: string;
        name: string;
        _id:string
        location: {
          coordinates: number[];
          type: string;
        };
        address: string;
      };
    };
  }

// Define the function to get customer data
export const getCustomers = async (): Promise<AxiosResponse<CustomerData[]>> => {
  return axios.get(API_ROUTES.CUSTOMER.GET_CUSTOMERS);
};
