import React, { FC, useEffect, useState } from "react";
import styles from "../../work/contractSteps/styles/styles.module.css";
import logo from "../../../assets/images/logo.png";
import Occupations from "../../../assets/images/occupations.png";
import whitearrow from "../../../assets/images/whitearrow.svg";
import blackarrow from "../../../assets/images/blackarrow.svg";
import folder from "../../../assets/images/folder.png";
import add_img from "../../../assets/images/add_img.png";

import HeaderContent from "../../../shared/Components/HeaderContent/headerContent";
import { SaveAsDraftButton } from "../../work/contractSteps/Components";

const UploadImage = () => {
  return (
    <>
      <HeaderContent />
      <div className="py-4 heightautoscroll">
        <div className={styles.signuppage}>
        {/* <ProgressBar now={20} /> */}
          <div className="contractpages">          
            <form>
              <div
                className={styles.registerOuter}
                style={{ position: "relative" }}
              >
                <div className="container h-100">
                  <div className=" h-100">
                    <div className={styles.registerBord}>
                      <div className="row pt-5 h-100 align-items-center">
                        <div className="col-md-6">
                          <div
                            className={styles.registerLeft}
                          >
                            <div className=" col-lg-11 col-12">
                            <figure>
                              <img
                                src={Occupations}
                                alt=""
                                className="img-fluid"
                              />
                            </figure>
                            </div>
                            
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className={styles.registerBord}>
                            <div className={styles.signUPContainer}>
                              <div className={styles.registerLeft}>
                                <h6 className="text-black text-38 fw-semibold">
                                  Upload Image
                                </h6>
                              </div>
                              <div className={styles.registerRight}>
                                <div className={styles.uploadSection}>
                                  <div className={styles.uploadbox}>
                                    <input
                                      type="file"
                                      className={styles.filePos}
                                    />
                                    <div className={styles.uploadcontent}>
                                      <img src={folder} alt="" className="mx-auto" />
                                      <h6 className="text-white text-14 ">
                                        Drag & Drop or choose Image to upload
                                      </h6>
                                      <p className=" ">Select Image</p>
                                    </div>
                                  </div>
                                </div>

                                <ul className={`nav ${styles.uploadnav}`}>
                                  <li>
                                    <div className={styles.uploadImages}>
                                      <img
                                        src={add_img}
                                        alt=""
                                        className="img-fluid"
                                      />
                                      <i className="fas fa-times"></i>
                                    </div>
                                  </li>

                                  <li>
                                    <div className={styles.uploadImages}>
                                      <img
                                        src={add_img}
                                        alt=""
                                        className="img-fluid"
                                      />
                                      <i className="fas fa-times"></i>
                                    </div>
                                  </li>

                                  <li>
                                    <div className={styles.uploadImages}>
                                      <img
                                        src={add_img}
                                        alt=""
                                        className="img-fluid"
                                      />
                                      <i className="fas fa-times"></i>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              <div className="row align-items-center justify-content-between">
                                <div className="col-4 text-start my-3 btn-hover-effect">
                                  <h6 className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton">
                                    <span className="px-lg-3 px-2 text-12 fw-semibold d-inline-block">
                                      Back
                                    </span>
                                    <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                                      <img src={blackarrow} alt="" />
                                    </i>
                                  </h6>
                                </div>
                                <div className="col-8">
                                  <div
                                    className="d-flex align-items-center jusify-content-end"
                                    style={{ gap: "10px" }}
                                  >
                                    {/* <div className="col-6">
                                      <SaveAsDraftButton />
                                    </div> */}
                                    <div className="col-12 text-end my-3 btn-hover-effect">
                                      <h6 className="btn arrowblue border-grey border-1 position-relative pe-5">
                                        <span className="px-lg-3 px-2 text-12 fw-semibold d-inline-block">
                                          Next
                                        </span>
                                        <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                                          <img src={whitearrow} alt="" />
                                        </i>
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadImage;
