import React, { FC, useEffect, useState } from "react";
import Header from "../../../header/routes";
import styles from "../../expense/styles/style.module.css";
import tip from "../../../../assets/images/info_icon.svg"
import print from "../../../../assets/images/print.png"
import dnlod from "../../../../assets/images/dnlod.png"
import style from "../styles/style.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { WORK_STATUS_TYPE } from "../../../../utils/enums/status";
import moment from "moment";
import Swal from 'sweetalert2';
import { toast } from "react-toastify";
import {
  addTimesheet,
  getTimesheetById,
  timesheetSendforApproval,
  updateTimesheet,
} from "../api";
import { MESSAGES } from "../../../../utils/messages";
import { APP_ROUTES } from "../../../../utils/routes/AppRoutes";
import {
  getDaysOfWeek,
  getMondayOfCurrentWeek,
} from "../../../../utils/daysMap";
import { COUNT_TYPE } from "../../../../utils/enums/types";
import { APIResponseError, WorkCountResponse } from "../../Contracts/interface";
import { getWorkDetailWithCount } from "../../Contracts/api";
import Toltip from "../../toltip/page";
import { Congratulations } from "../Modals/Congratulation";

interface TimeSheetFields {
  date: Date;
  hours: number;
  overTimeHours?: number;
  doubleTime?: number;
  travelHours?: number;
  travelTime: number;
  statusReport: string;
  isHoliday: boolean,
  total: number
}
interface Props {
  isEditMode?: boolean;
}
const Addtimesheet: FC<Props> = ({ isEditMode }) => {
  const { id, timesheetId } = useParams();
  const navigate = useNavigate();
  const mondayDate = getMondayOfCurrentWeek();
  const [workDetails, setWorkDetails] = useState<WorkCountResponse>();
  const [showApprovalBtn, setShowApprovalBtn] = useState<boolean>(false);
  const [showAddBtn, setShowAddBtn] = useState<boolean>(true);
  const [timeSheetDays, setTimeSheetDays] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  
  useEffect(() => {
    if (isEditMode) {
      initWithEdit();
    } else {
      initWithAddMode();
    }
  }, []);
  const closeModal = () => {
    console.log("Modal closed");
    setShowModal(false);
    // Add any additional logic if needed
  };
  const days: TimeSheetFields[] = [];
  const daysOfWeek = getDaysOfWeek();

  daysOfWeek.forEach((day) => {
    days.push({
      date: day,
      hours: 0,
      doubleTime: 0,
      isHoliday: false,
      travelHours: 0,
      statusReport: "",
      travelTime: 0,
      total: 0,
    });
  });
  const initWithEdit = async () => {
    const response = await getTimesheetById(timesheetId);
    if (response?.data?.timesheetDetails?.length > 0) {
      const updatedA = days?.map(itemA => {
        const matchingItem: any = response.data.timesheetDetails.find(itemB => new Date(itemB.workedDate).toISOString() === itemA.date.toISOString());
        return {
          ...itemA,
          hours: matchingItem?.hourPerDay ? Number(matchingItem?.hourPerDay) : 0,
          total: matchingItem?.total ? Number(matchingItem?.total) : 0,
          doubleTime: matchingItem?.isHoliday ? matchingItem?.isHoliday : false,
          isHoliday: matchingItem?.isHoliday ? matchingItem?.isHoliday : false,
          travelTime: matchingItem?.travelTime ? matchingItem?.travelTime : 0,
          statusReport: matchingItem?.dailyStatus ? matchingItem?.dailyStatus : "",
          overTimeHours: 0,
          travelHours: 0,
        };
      });
      setTimeSheetDays(updatedA);
    }
  };

  const initWithAddMode = () => {
    setTimeSheetDays(days);
  };

  useEffect(() => {
    const getWorkDetials = async () => {
      const response: any = await getWorkDetailWithCount(
        id,
        COUNT_TYPE.REPORTS
      );
      setWorkDetails(response?.data);
    };
    getWorkDetials();
  }, []);

  const handleAddTimesheet = async () => {
    const payload: any = {
      workId: id,
      totalStraightTime: 40,
      overTime: totalOverTime,
      doubleTime: totalResuls.totalDoubleTime,
      totalTravelTime: totalResuls.totalTravel,
      totalHours: totalResuls.totalHours,
      ssaRates: {
        baseRate: 48.5,
        overTimeRate: 60.0,
        doubleTimeRate: 78,
        travelTimeAllowed: 0,
      },
      status: WORK_STATUS_TYPE.DRAFT,
      timesheetDetails: timeSheetDays
        .filter((x: any) => x.date < new Date())
        .map((x: any) => ({
          workedDate: x.date.toISOString(),
          hourPerDay: x.hours.toString(),
          dailyStatus: x.statusReport,
          isHoliday: x.isHoliday,
          travelTime: x.travelTime,
          total: (
            totalResuls.totalHours +
            totalResuls.totalDoubleTime +
            totalResuls.totalTravel
          ).toString(),
        })),
    };
  
    const loadingToastId = toast.loading(MESSAGES.POSTING_CONTRACTS.VERIFYING);
  
    try {
      if (isEditMode) {
        await updateTimesheet(timesheetId, payload);
        toast.dismiss(loadingToastId);
        setShowApprovalBtn(true);
        setShowAddBtn(false);
        toast.success("Timesheet updated successfully!");
      } else {
        await addTimesheet(payload);
        toast.dismiss(loadingToastId); 
        setShowAddBtn(false);
        navigate(`${APP_ROUTES.TIMESHEETDETAIL_TABLE}/${id}`);
        toast.success("Timesheet added successfully!");
      }
    } catch (error) {
      toast.dismiss(loadingToastId);
      const errorMessage = (error as any)?.data?.message;
      Swal.fire({
        title: 'Error!',
        text: errorMessage,
        icon: 'error',
        confirmButtonText: 'Try Again',
      });
    }
  };
  const handleStatusChange = async () => {
    const loadingToastId = toast.loading(MESSAGES.WORK.APPLY);
  
    try {
      await timesheetSendforApproval(timesheetId as string);
      toast.dismiss(loadingToastId); // Dismiss the loading toast
      setShowModal(true); // Open the modal on success
    } catch (error) {
      toast.dismiss(loadingToastId); // Dismiss the loading toast
      const errorMessage = (error as any)?.data?.message || "An error occurred!";
      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error",
        confirmButtonText: "Try Again",
      });
    }
  };
  const onTimesheetValueChange = (
    value: number | string | boolean,
    key: string,
    idx: number
  ) => {
    const updatedValues = [...timeSheetDays];
    const updatedDay = { ...updatedValues[idx], [key]: value };
    updatedValues[idx] = updatedDay;
    setTimeSheetDays(updatedValues);
  };

  const totalResuls = timeSheetDays.reduce(
    (prev: any, curr: any) => {
      prev.totalTravel += curr.travelTime ?? 0;

      if (curr.isHoliday) {
        prev.totalDoubleTime += curr.hours;
      }
      prev.totalHours += curr.hours + curr.travelTime;

      return prev;
    },
    { totalHours: 0, totalDoubleTime: 0, totalTravel: 0 }
  );

  const totalOverTime =
    (totalResuls.totalHours - totalResuls.totalDoubleTime - totalResuls.totalTravel) > 40 ? (totalResuls.totalHours - totalResuls.totalDoubleTime - totalResuls.totalTravel) - 40 : 0;

  const totalStraight =
    (totalResuls.totalHours - totalResuls.totalDoubleTime - totalResuls.totalTravel) <= 40 ? (totalResuls.totalHours - totalResuls.totalDoubleTime - totalResuls.totalTravel) : 40;
  const currentdate: any = new Date();

  return (
    <>
      <Header />
      <div className={styles.pageOuter}>
        <div className="container">
          <h3 className="fw-bold text-30 mb-4 text-capitalize">Add new Timesheet</h3>
          <div className={`rounded-4 p-0 border-0 ${styles.baseInfo}`}>
            <div className={`rounded-3 col-12 ${styles.timeSheetdetils} ${styles.noMarg}`}>
              <div className={`${styles.tabletimesheet}`}>
                <div className="row align-items-center mb-3">
                  <div className="col-4 topTipHover">
                    <div className="w-100 d-flex align-items-center">
                      <h4 className="form-check-label text-20 fw-bold mb-0 font-inter">
                        Timesheet details
                      </h4>
                      <i>
                        <img className="customeTolImg" src={tip} alt="" />
                      </i>
                      <Toltip />
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="d-flex align-items-center justify-content-end g-2">
                      <div className="px-1">
                        {" "}
                        <button className="border text-14 d-flex align-items-center py-2 px-3 rounded-pill font-inter fw-semibold">
                          <i className="me-2">
                            <img src={print} alt="" />
                          </i>{" "}
                          Print
                        </button>
                      </div>
                      <div className="px-1">
                        {" "}
                        <button className="border text-14 d-flex align-items-center py-2 px-3 rounded-pill font-inter fw-semibold">
                          <i className="me-2">
                            <img src={dnlod} alt="" />
                          </i>{" "}
                          Export Data
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive ${styles.tabletimesheet}">
                  <table className="table align-middle">
                    <tr>
                      <td>
                        <span className="fw-semibold">Professional : </span>
                      </td>
                      <td>
                        <span className="fw-normal">
                          {workDetails?.applyUser?.name}
                        </span>
                      </td>
                      <td colSpan={2} className=" text-center">
                        <span className="fw-semibold">Monday / Week Start Date</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="fw-semibold">Business Name :</span>
                      </td>
                      <td>
                        <span className="fw-normal">
                          {workDetails?.companyDetails?.name}
                        </span>
                      </td>
                      <td rowSpan={2} colSpan={2} className=" text-center">
                        <span className="fw-normal">{mondayDate}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="fw-semibold">Business Number :  </span>
                      </td>
                      <td>
                        <span className="fw-normal">47110</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="fw-semibold">Business Contact :</span>
                      </td>
                      <td>
                        <span className="fw-normal">
                          {workDetails?.userInfo?.name}
                        </span>
                      </td>
                      <td>
                        <span className="fw-semibold">Business Email : </span>
                      </td>
                      <td>
                        <span className="fw-normal">
                          {workDetails?.userInfo?.email}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span className="fw-semibold">Contract Number:</span>
                      </td>
                      <td colSpan={4}>
                        <span className="fw-normal">#{workDetails?.workNumber}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="fw-semibold">End Customer : </span>
                      </td>
                      <td colSpan={4}>
                        <span className="fw-normal">Jerry Vemia</span>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="table-responsive table-striped ${styles.tabletimesheet}">
                  <table className="table align-middle mb-0">
                    <thead>
                      <th>
                        <span className="fw-normal">Day</span>
                      </th>
                      <th>
                        <span className="fw-normal">Date</span>
                      </th>
                      <th>
                        <span className="fw-normal">Hours Worked Per Day</span>
                      </th>
                      <th>
                        <span className="fw-normal">Holiday</span>
                      </th>
                      <th>
                        <span className="fw-normal">Travel Time Hours</span>
                      </th>
                      <th>
                        <span className="fw-normal"> Total</span>
                      </th>
                    </thead>
                    <tbody>
                    {timeSheetDays?.map((x: any, idx: any) => {
                    const momentObj = moment(x.date);
                    const isFutureDate = x.date > new Date();

                    return (
                      <>
                      <tr>
                        <td className="text-center">
                          <span className="fw-semibold">{momentObj.format("dddd")}</span>
                        </td>
                        <td>
                          <span className="fw-semibold">{momentObj.format("YYYY-MM-DD")}</span>
                        </td>
                        <td>
                          <input
                                disabled={isFutureDate}
                                type="number"
                                value={x.hours}
                                onChange={({ target: { value } }) =>
                                  onTimesheetValueChange(
                                    Number(value),
                                    "hours",
                                    idx
                                  )
                                }
                              />
                        </td>
                        <td>
                          <div className="checkboxes__item">
                            <label className="checkbox style-c">
                            <input
                                type="checkbox"
                                checked={x.isHoliday}
                                onChange={({ target: { checked } }) =>
                                  onTimesheetValueChange(checked, "isHoliday", idx)
                                }
                              />
                              <div className="checkbox__checkmark"></div>
                            </label>
                          </div>
                        </td>
                        <td>
                        <input
                                disabled={isFutureDate}
                                type="number"
                                value={x.travelTime}
                                onChange={({ target: { value } }) =>
                                  onTimesheetValueChange(
                                    Number(value),
                                    "travelTime",
                                    idx
                                  )
                                }
                              />
                        </td>
                        <td> {(x?.hours ? x?.hours : 0) +
                                (x?.travelTime ? x?.travelTime : 0)}</td>
                      </tr>
                      <tr>
                        <td colSpan={6} className="p-0 border-0 ">
                        <textarea
                            disabled={isFutureDate}
                            className="form-control w-100 bg-transparent"
                            placeholder="1"
                            value={x.statusReport}
                            onChange={({ target: { value } }) =>
                              onTimesheetValueChange(value, "statusReport", idx)
                            }
                          ></textarea>
                        </td>
                      </tr>
                      </>
                      );
                    })}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className={`table-responsive ${styles.tabletimesheet}`}>
                      <table className="table align-middle">
                          <tr>
                              <td><span className="fw-medium">Total Straight Time</span></td>
                              <td className="text-end"><span className="fw-normal">{totalStraight}</span></td>                          
                          </tr>
                          <tr>
                              <td><span className="fw-medium">Total Over Time</span></td>
                              <td className="text-end"><span className="fw-normal">{totalOverTime}</span></td>
                          </tr>
                          <tr>
                              <td><span className="fw-medium">Sunday/Holiday</span></td>
                              <td className="text-end"><span className="fw-normal">{totalResuls.totalDoubleTime}</span></td>
                          </tr>
                          <tr>
                              <td><span className="fw-medium">Total Travel TIme</span></td>
                              <td className="text-end"><span className="fw-normal">{totalResuls.totalTravel}</span></td>
                          </tr>
                          <tr>
                              <td><span className="fw-medium">Total Hours</span></td>
                              <td className="text-end"><span className="fw-normal">{totalResuls.totalHours}</span></td>                        
                          </tr>
                      </table>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className={`table-responsive ${styles.tabletimesheet}`}>
                      <table className="table align-middle">
                          <tr>
                              <td><span className="fw-semibold">Bill Rates</span></td>
                              <td className="text-end"><span className="fw-normal">-----</span></td>                          
                          </tr>
                          <tr>
                              <td><span className="fw-medium">Base Rate (per hour)</span></td>
                              <td className="text-end"><span className="fw-normal">
                              ${" "}
                        {workDetails?.rates?.baseRate
                          ? workDetails?.rates?.baseRate
                          : "0.00"}</span></td>
                          </tr>
                          <tr>
                              <td><span className="fw-medium">OT Rate (per hour)</span></td>
                              <td className="text-end"><span className="fw-normal">
                              ${" "}
                        {workDetails?.rates?.overTimeRate
                          ? workDetails?.rates?.overTimeRate
                          : "0.00"}</span></td>
                          </tr>
                          <tr>
                              <td><span className="fw-medium">Sunday/Holiday</span></td>
                              <td className="text-end"><span className="fw-normal"> ${" "}
                        {workDetails?.rates?.doubleTimeRate
                          ? workDetails?.rates?.doubleTimeRate
                          : "0.00"}</span></td>
                          </tr>
                          <tr>
                              <td><span className="fw-medium">Travel Time Rate</span></td>
                              <td className="text-end"><span className="fw-normal">{workDetails?.travels?.travelTimeRate
                          ? workDetails?.travels?.travelTimeRate
                          : "0.00"}</span></td>                        
                          </tr>
                          <tr>
                              <td><span className="fw-medium">Travel Trips Allowed (per month)</span></td>
                              <td className="text-end"><span className="fw-normal">  {workDetails?.travels?.tipAllowed
                          ? workDetails?.travels?.tipAllowed
                          : "0.00"}</span>
                              </td>                        
                          </tr>
                          <tr>
                              <td><span className="fw-medium">Travel Trips Allowed (per month)</span></td>
                              <td className="text-end"><span className="fw-normal">-----</span></td>                        
                          </tr>
                      </table>
                    </div>
                  </div>
                </div>
                <div className={styles.finalDta}>
                  <p className="mb-3 text-14 fw-semibold">IP Address : 89 : 99 :88</p>
                  <p className="text-danger  text-14">Pending for Approval</p>
                  <p className="text-14 mb-3">Approval Signature</p>
                  <p className="text-14">-----</p>
                </div> 
              </div>
            </div>
          </div>
        
          <div className={style.timeshettrds}>
            <div className={style.addroutesouters}>
            </div>
          </div>
          <div className="mt-3 row g-2">
          {!isEditMode || showAddBtn ? (
                  <div className="col-3">
                    <button className="bg-blue text-white p-2 rounded-2 text-14 px-4 w-100" 
              onClick={() => handleAddTimesheet()}>
                    Save Timesheet
                    </button>
                  </div>
                    ) : (
                      ""
                    )}
                    {isEditMode || showApprovalBtn ? (
                  <div className="col-3">
                    <button className="bg-blue text-white p-2 rounded-2 text-14 px-4 w-100"  onClick={() => handleStatusChange()}>
                    Send for Approval
                    </button>
                  </div>
                ) : (
                  ""
                )}
                </div>
        </div>
      </div>
      <Congratulations
        show={showModal}
      closeModal={closeModal}/>
    </>
  );
};

export default Addtimesheet;
