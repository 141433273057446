import { configureStore } from '@reduxjs/toolkit';
import userDataReducer from '../reducers/auth';
import {
  dashboardReducer,
  signupDataReducer,
  contractReducer,
  addContractReducer,
  authenticatedReducer,
  paidAdsReducer,
  addDirectJobReducer,
  videoJoinConfigReducer,
  workTypeReducer,
  jobPaidAdReducer,
  companyPaidAdReducer,
  productPaidAdReducer,
  servicePaidAdReducer,
  contractPaidAdReducer,
  copyDataReducer,
  connectionPlanReducer,
  socialPostReducer,
} from '../reducers';
import storage from '../../utils/storage';
import businessDetails from '../reducers/businessDetails';
import BusinessEmployeeSlice from '../reducers/BusinessEmployeeSlice';
import businessGroupData from '../reducers/businessGroupData';

export const store = configureStore({
  reducer: {
    userData: userDataReducer,
    signupResponse: signupDataReducer,
    dashboard: dashboardReducer,
    contractPost: contractReducer,
    addContract: addContractReducer,
    authentication: authenticatedReducer,
    paiAds: paidAdsReducer,
    directJob: addDirectJobReducer,
    videoJoinConfig: videoJoinConfigReducer,
    workType: workTypeReducer,
    business:businessDetails,
    employee:BusinessEmployeeSlice,
    businessGroup:businessGroupData,
    jobAd:jobPaidAdReducer,
    contractAd:contractPaidAdReducer,
    productAd: productPaidAdReducer,
    serviceAd: servicePaidAdReducer,
    companyAd: companyPaidAdReducer,
    copyData: copyDataReducer,
    connectionsPlan: connectionPlanReducer,
    socialPost: socialPostReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

store.subscribe(() => {
  const state = store.getState().addContract;

  if (state.started) {
    storage.saveContract(state);
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
