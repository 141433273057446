import React, { FC, useEffect, useState } from "react";
import HeaderContent from "../../../../shared/Components/HeaderContent/headerContent";
import styles from "../../../contractBeforeLogin/styles/style.module.css";
import blackarrow from "../../../../assets/images/blackarrow.svg";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import Occupations from "../../../../assets/images/choosetype.png";
import tip from "../../../../assets/images/tip.png";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveStep,
  setPlanId,
  setPlanPrice,
  setCurrentPlan,
} from "../../../../redux/reducers/companyPaidAd";
import { ADD_PAID_ADS } from "../../../../shared/enums";
import { getAdPlans } from "../../api";
import { PLAN_RESPONSE } from "../../../bostprofile/interface";
import Toltip from "../../../common/toltip/page";
import { CONSTANT_MANAGER } from "../../../../utils/constans/constants";
import { Dropdown } from "react-bootstrap";
import { RootState } from "../../../../redux/store";

const CompanyAdChoosePlan: FC = () => {
  const dispatch = useDispatch();
  const { currentPlan } = useSelector((state: RootState) => state.companyAd);
  const [planData, setPlanData] = useState<PLAN_RESPONSE[]>([]);
  const [planID, setPlanID] = useState<string | null>("");
  const [currPlan, setCurrPlan] = useState<string>(currentPlan);

  const handleClickNext = () => {
    dispatch(setActiveStep(ADD_PAID_ADS.BOOST_ADD));
  };
  const handleClickBack = () => {
    dispatch(setActiveStep(ADD_PAID_ADS.UPLOAD_IMAGE));
  };

  const _handlePlanChange = (
    planID: string,
    planPrice: number,
    planText: string
  ) => {
    setPlanID(planID);
    setCurrPlan(planText); // Update the current plan value for the dropdown
    dispatch(setPlanId(planID));
    dispatch(setPlanPrice(planPrice));
    dispatch(setCurrentPlan(planText));
  };

  const fetchAdPlans = async () => {
    try {
      const data = await getAdPlans();
      setPlanData(data.data);
    } catch (err) {
      console.log(err, "Error while fetching plans");
    }
  };

  useEffect(() => {
    fetchAdPlans();
  }, []);

  return (
    <>
      <HeaderContent />
      <div className="heightautoscroll contractradio paidads">
        <form className="h-100">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
                <div className="col-lg-6">
                  <div className={styles.registerLeft}>
                  <div className="col-lg-11 col-12 text-center mx-auto">
                <figure>
                  <img
                    src={Occupations}
                    alt=""
                    className="img-fluid w-100"
                  />
                </figure>
              </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className={styles.registerBord}>
                    <div className={styles.signUPContainer}>
                      <div className="topTipHover">
                        <div className="d-flex align-items-center">
                          <h4 className="form-check-label text-20 fw-bold text-white font-inter">
                            Choose from the following boosting models:
                          </h4>
                          <i>
                            <img
                              className="customeTolImg"
                              src={tip}
                              alt=""
                            />
                          </i>
                          <Toltip
                            slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                            labelId={
                              CONSTANT_MANAGER.ADD_CONTRACT.PAID_AD.DATA
                                .CHOOSE_YOUR_PLAN_FOR_PROMOTION
                            }
                          />
                        </div>
                      </div>
                      <div
                        className={styles.promotePlan}
                        style={{ marginTop: "30px" }}
                      >
                          <div className="row">
                            <Dropdown>
                              <Dropdown.Toggle
                                id="dropdown-basic"
                                name="travelTip"
                              >
                                {currPlan || "Select your plan"}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <div
                                  style={{
                                    maxHeight: "300px",
                                    overflowY: "scroll",
                                  }}
                                >
                                  {planData?.map((plan, ind) => (
                                    <Dropdown.Item
                                      key={ind}
                                      onClick={() =>
                                        _handlePlanChange(
                                          plan._id,
                                          plan.price,
                                          `$${plan.price} ${plan.duration} ${plan.durationType}`
                                        )
                                      }
                                    >
                                      {plan.price} {plan.duration}{" "}
                                      {plan.durationType}
                                    </Dropdown.Item>
                                  ))}
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                            <p className="text-white text-14 mt-3 ps-3" >
                              Promote your ad for a full week to
                              maximize visibility
                            </p>
                            <ul className="ps-3">
                              <li className="text-14 text-white">
                                Pay-Per-Week: Keep your ad visible for a
                                fixed weekly fee.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="row align-items-center justify-content-between">
                        <div
                          className="col-4 text-start my-3 btn-hover-effect"
                          onClick={handleClickBack}
                        >
                          <h6 className="btn bg-grey arrowyellow border-grey border-1 rounded-3 position-relative ps-5 backbutton">
                            <i className="bg-yellow rounded-3 arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                              <img src={blackarrow} alt="" />
                            </i>
                            <span className="px-lg-4 px-2 text-12 fw-semibold">
                              Back
                            </span>
                          </h6>
                        </div>
                        <div className="col-8">
                          <div
                            className="d-flex align-items-center jusify-content-end"
                            style={{ gap: "10px" }}
                          >
                            <div
                              className="col-12 text-end my-3 btn-hover-effect"
                              onClick={handleClickNext}
                            >
                              <h6 className="btn arrowblue border-grey border-1 position-relative pe-5">
                                <span className="px-lg-4 px-2 text-12 fw-semibold">
                                  Continue
                                </span>
                                <i className="bg-blue rounded-3 arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                                  <img src={whitearrow} alt="" />
                                </i>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </form>
        </div>
    </>
  );
};

export default CompanyAdChoosePlan;
