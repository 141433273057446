import React, { useState } from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../../assets/images/penbl.png";
import tip from "../../../../assets/images/tip.png";
import security from "../../../../assets/images/security.svg";
import downloadbalck from "../../../../assets/images/download_black.svg";
import printblack from "../../../../assets/images/print_black.svg";
import check from "../../../../assets/images/check_green.svg";
import EditW9FormModal from "../modals/EditW9FormModal";

const FormsW9 = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const openModal = () => {
    setShowModal(true);
  }
  const closeModal = () => { setShowModal(false)};
  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-center">
        <div className="col-xl-7 col-12">
          <div className="topTipHover">
            <div className="d-flex align-items-center">
              <h4 className="text-20 fw-bold  font-inter mb-0">
                W9 Form, W-8BEN, or W-8BEN-E Form
              </h4>
              <i>
                <img className="customeTolImg" src={tip} alt="" />
              </i>
            </div>
          </div>
        </div>
        <div className="col-xl-5 col-12 text-end">
          <ul
            className="d-flex align-items-center justify-content-end mb-0"
            style={{ gap: "15px" }}
          >
            <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
              <i className="me-1">
                <img src={printblack} alt="" width={12} height={12} />
              </i>
              <span className="text-10">Print</span>
            </li>
            <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
              <i className="me-1">
                <img src={downloadbalck} alt="" width={12} height={12} />
              </i>
              <span className="text-10">Download</span>
            </li>
            <li>
              <div className="text-end">
                <i>
                  <img src={penbl} className="cursor-pointer" alt=""  onClick={openModal}/>
                </i>
              </div>
            </li>
          </ul>
        </div>
        <div className="col-12 text-start my-3">
          <h4 className="justify-content-start text-black fw-semibold text-14 mb-2">
            <i className="me-2">
              <img src={check} alt="" width={15} />
            </i>
            W9 Form.pdf
          </h4>
          <h4 className="justify-content-start text-black fw-semibold text-14 mb-2">
            <i className="me-2">
              <img src={check} alt="" width={15} />
            </i>
            W9 Form.pdf
          </h4>
          <h4 className="justify-content-start text-black fw-semibold text-14 mb-2">
            <i className="me-2">
              <img src={check} alt="" width={15} />
            </i>
            W9 Form.pdf
          </h4>
        </div>
        <div className="col-12">
          <div className={styles.note}>
            <div className="d-flex align-items-center bg-lightblue p-3 rounded-2">
              <div className="col-lg-1 col-2 text-center">
                <i>
                  <img src={security} alt="" />
                </i>
              </div>
              <div className="col-lg-11 col-10">
                <p className="text-14 ps-2 fw-medium mb-0">
                  Please provide your financial information securely to
                  facilitate payments. Your security is extremely important to
                  us, and only Automate America Financial Administration will
                  have access to this information.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditW9FormModal showModal={showModal} closeModal={closeModal} title="W9 Form, W-8BEN, or W-8BEN-E Form"/>
    </div>
  );
};

export default FormsW9;
