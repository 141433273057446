import React from 'react';
import whats_new from "../../../assets/images/whats_new.png";
import des from "../../../assets/images/des.svg";
import videocam from "../../../assets/images/videocam.svg";
import person from "../../../assets/images/person.svg";
import check from "../../../assets/images/check_green.svg";
import { useParams } from 'react-router-dom';

const EventPage = () => {
    const { title, dateTime, desc } = useParams();

    // Decode the URL parameters
    const decodedTitle = decodeURIComponent(title || "");
    const decodedDateTime = decodeURIComponent(dateTime || "");
    const decodedDesc = decodeURIComponent(desc || "");

    return (
        <>
        <div className='col-xl-5 col-lg-7 col-12 mx-auto bg-lightbluewhite'>
            <div className='card-head'>
                <div className='bg-white py-2 px-4'>
                    <h5 className='text-24 py-2 fw-semibold'>Event Page</h5>
                </div>
                <div className='col-10 mx-auto py-3'>
                    <figure>
                        <img src={whats_new} alt='' className='img-fluid w-100' />
                    </figure>
                </div>
            </div>
            <div className='card-body p-4 bg-white'>
                <div className='heading'>
                    <h4 className='text-24 fw-semibold text-capitalize'>{decodedTitle}</h4>
                    <p className='text-14 text-muted'>Date and Time: {decodedDateTime}</p>
                </div>
                
                <div className='headingdetails py-4'>
                    <div className='d-flex align-items-start mb-4'>
                        <div className='col px-0'><i><img src={des} alt="" className='img-fluid' width={35} height={35}/></i></div>
                        <div className='col-11'>
                            <p className='text-14'><span className='fw-semibold text-14'>Description : </span>
                            {decodedDesc}
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio itaque eius quia delectus sunt rem error qui eveniet possimus voluptate enim officiis, ex ipsam repellendus rerum, impedit animi, esse facilis.
                            </p>
                        </div>
                    </div>
                    <div className='d-flex align-items-start mb-4'>
                        <div className='col px-0'><i><img src={videocam} alt="" className='img-fluid' width={35} height={35}/></i></div>
                        <div className='col-11'>
                            <p className='text-14'>
                                <span className='fw-semibold d-block'>Join video link : </span>
                            </p>
                              <a href='' className='text-muted text-14'> Lorem ipsum dolor</a> 
                            
                        </div>
                    </div>
                    {/* <div className='d-flex align-items-start mb-4'>
                        <div className='col px-0'><i><img src={person} alt="" className='img-fluid' width={35} height={35}/></i></div>
                        <div className='col-11'>
                            <p className='text-14'>
                                <span className='fw-semibold d-block'>4 guest : </span>
                            </p>
                              <a href='' className='text-muted text-14'>1 Yes</a> 
                            
                        </div>
                    </div> */}
                    <div className='d-flex align-items-start mb-0'>
                        <div className='col ps-4'>
                            <div className='d-flex align-items-center'>
                                <div className='d-inline-block position-relative'>
                                <i className='bg-lightblue rounded-circle d-inline-block p-3'><img src={person} alt="" className='img-fluid' width={35} height={35}/></i> 
                                <span className='position-absolute' style={{bottom: "10px", right: "0px"}}><img src={check} alt='' className='' width={20} height={20} /></span>
                                </div>
                            
                                <div className='d-inline-block ps-3'>
                                    <p className='text-14'>
                                        <span className='fw-semibold d-block'>Shubhagini</span>
                                    </p>
                                    <a href='' className='text-muted text-14'>Organizer</a> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-footer text-center border-top py-4'>
                    <p className='text-muted text-14 mb-3'>Powered by Automate America</p>
                    <p className='text-muted text-14'>Use is subject to the Automate america <a href='#' className='text-blue text-underline'>Privacy policy</a> and <a href='#' className='text-blue text-underline'>Terms and conditions</a></p>
                </div>
               
            </div>
        </div>
        </>
    )
}

export default EventPage