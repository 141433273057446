import React, { useEffect, useState } from "react";
import { Dropdown, FormControl, Modal, ModalFooter, Spinner } from "react-bootstrap";
import penbl from "../../../../assets/images/penbl.png";
import upload from "../../../../../assets/images/upload.svg";
import styles from "../styles/style.module.css";
import thumb from "../../../../assets/images/profile_picture.png";
import blackarrow from "../../../../assets/images/blackarrow.svg";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import { getMasterTypes } from "../../../../feature/auth/signup/api";
import { MASTER_TYPE } from "../../../../utils/enums/masterTypes.enum";
import { IOption } from "../../../../Interfaces";
import Select, { ActionMeta, MultiValue } from "react-select";
import { updateUserProfile } from "../../../home/api";
import { OccupationDetails, ProfessInfo } from "../../interface";
import { toast } from "react-toastify";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  primaryOccuDetails: OccupationDetails[] | undefined;
  secondaryOccuDetails: OccupationDetails[] | undefined;
  refresh: () => void;
}

const ProfessionalInfoModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  primaryOccuDetails = [],
  secondaryOccuDetails = [],
  refresh,
}) => {
  if (!isOpen) return null;

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filterValue, setFilterValue] = useState<string>("");
  const [primary, setPrimary] = useState("");
  const [primaryId, setPrimaryId] = useState("");
  const [primaryData, setPrimaryData] = useState<IOption[]>([]);
  const [secondaryId, setSecondaryId] = useState("");
  const [secondaryData, setSecondaryData] = useState<IOption[]>([]);
  const [value, setValue] = useState<IOption[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const [errors, setErrors] = useState({
    primary: "",
    secondary: "",
  });

  useEffect(() => {
    if (primaryOccuDetails && primaryOccuDetails.length > 0) {
      setPrimary(primaryOccuDetails[0].name);
      setPrimaryId(primaryOccuDetails[0]._id);
    } else {
      setPrimary("");
      setPrimaryId("");
    }
    if (secondaryOccuDetails && secondaryOccuDetails.length > 0) {
      const initialValue = secondaryOccuDetails.map(detail => ({
        label: detail.name,
        value: detail._id,
      }));
      setValue(initialValue);
    }
  }, [primaryOccuDetails, secondaryOccuDetails]);

  const loadDropDowns = async () => {
    try {
      const occupationDataRes = await getMasterTypes(MASTER_TYPE.OCCUPATIONS);
      if (occupationDataRes?.data) {
        setPrimaryData(
          occupationDataRes?.data.map((x: any) => ({
            label: x.name,
            value: x._id,
          }))
        );
        setSecondaryData(
          occupationDataRes?.data.map((x: any) => ({
            label: x.name,
            value: x._id,
          }))
        );
      }
    } catch (error) {
      console.error("Failed to load occupation data", error);
    }
  };

  useEffect(() => {
    loadDropDowns();
  }, []);

  const handleSubmitCustomOccupation = async () => {
    // Handle custom occupation submission
  };

  const handleOccupationChange = (label: string, value: string) => {
    setPrimary(label);
    setPrimaryId(value);
    setErrors((prev) => ({ ...prev, primary: "" }));
  };

  const updateOccupation = async () => {
    
    // Simple validation
    if (!primaryId) {
      setErrors((prev) => ({ ...prev, primary: "Primary occupation is required." }));
      return;
    }

    if (value.length > 5) {
      setErrors((prev) => ({ ...prev, secondary: "You can only select up to 5 occupations." }));
      return;
    }

    const selectedSecondaryIds = value.map(option => option.value);
    setIsSubmitting(true);
    const payload = {
      occupationIds: [primaryId],
      secondaryOccupationIds: selectedSecondaryIds,
    };

    try {
      await updateUserProfile(payload);
      refresh()
      setIsSubmitting(false);
      toast.success("Updated Successfully")
      onClose();
    } catch (error) {
      console.log("Error occurred while updating the occupations", error);
    }
  };

  const handleSelectChange = (
    newValue: MultiValue<IOption>,
    actionMeta: ActionMeta<IOption>
  ) => {
    const selectedOptions = Array.isArray(newValue) ? newValue : [];
    if (selectedOptions.length <= 5) {
      setValue(selectedOptions);
      setErrors((prev) => ({ ...prev, secondary: "" }));
    } else {
      setErrors((prev) => ({ ...prev, secondary: "You can only select up to 5 occupations." }));
    }
  };

  const primaryOccOptions =
    filterValue && filterValue !== ""
      ? primaryData.filter((x) =>
          x.label.toLowerCase().includes(filterValue.toLowerCase())
        )
      : primaryData;

  return (
    <>
      <Modal show={isOpen} onHide={onClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            <div className="addModalPro">
              <form>
                <div className="formGroup">
                  <label>Primary Occupation*</label>
                  <div style={{ marginBottom: "30px" }}>
                    <Dropdown>
                      <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        {primary || "Please select an option"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div className="">
                          <FormControl
                            autoFocus
                            className="mx-3 my-2 w-auto"
                            placeholder="Search..."
                            onChange={(e) => setFilterValue(e.target.value)}
                            value={filterValue}
                          />
                        </div>

                        <div
                          style={{
                            maxHeight: "300px",
                          }}
                        >
                          {primaryOccOptions.length === 0 ? (
                            <Dropdown.Item disabled>
                              No options found
                            </Dropdown.Item>
                          ) : (
                            primaryOccOptions.map((option, index: number) => (
                              <Dropdown.Item
                                key={index}
                                onClick={() =>
                                  handleOccupationChange(
                                    option.label,
                                    option.value
                                  )
                                }
                              >
                                {option.label}
                              </Dropdown.Item>
                            ))
                          )}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                    {errors.primary && (
                      <div className="error" style={{ color: "red" }}>
                        {errors.primary}
                      </div>
                    )}
                  </div>
                </div>

                <div className="formGroup">
                  <label>Other Occupations</label>
                  <div>
                    <Select
                      isMulti
                      name="occupations"
                      options={secondaryData}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={handleSelectChange}
                      value={value}
                    />
                    {errors.secondary && (
                      <div className="error" style={{ color: "red" }}>
                        {errors.secondary}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-12" style={{ marginBottom: "30px" }}>
                  <p className="text-blue text-14">
                    Note: You can add up to 5 additional occupations.
                  </p>
                </div>
              </form>
            </div>
          </Modal.Body>
          <ModalFooter>
            <div className="btn-hover-effect">
              <h6
                onClick={onClose}
                className="btn bg-white arrowyellow border border-1 rounded-3 position-relative ps-5 backbutton w-auto"
              >
                <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
                  Back
                </span>
                <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                  <img
                    src={blackarrow}
                    alt=""
                    width={15}
                    height={15}
                    style={{ transform: "rotate(-180deg)" }}
                  />
                </i>
              </h6>
            </div>
            <div className="btn-hover-effect">
              <h6
                onClick={updateOccupation}
                className="btn bg-white arrowblue border border-1 rounded-3 position-relative pe-5 w-auto"
              >
                 <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
            {isSubmitting ? (
              <Spinner animation="border" size="sm" className="me-2" />
            ) : (
              "Save"
            )}
          </span>
                <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                  <img src={whitearrow} alt="" width={15} height={15} />
                </i>
              </h6>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default ProfessionalInfoModal;
