import { axios } from "../../../../lib/axios";
import { AxiosResponse } from "../../../../shared/models";
import { API_ROUTES } from "../../../../utils/routes/apiRoutes";
import { ContractResponse, WorkCountResponse } from "../interface";
import { DirectJobResponse } from "../interface/directjobInterface";

export const getContractList = async (workType: string,type:number,workStatus:string ): Promise<ContractResponse[]> => {
    return axios.get(`${API_ROUTES.HOURLY_CONTRACTS.GET_CONTRACTS}?workType=${workType}&type=${type}&workStatus=${workStatus}`);
};
export const getOpenContractList = async (workType: string,type:number,workTypeState:string): Promise<ContractResponse[]> => {
    return axios.get(`${API_ROUTES.HOURLY_CONTRACTS.GET_CONTRACTS}?workType=${workType}&type=${type}&workTypeState=${workTypeState}`);
};
export const getWorkDetailWithCount = async (id: any, type: string ): Promise<AxiosResponse<WorkCountResponse>> => {
    return axios.get(`${API_ROUTES.WORK.GET_WORKDETAILS_COUNTS}/${id}?type=${type}`);
  };
  export const getContractDetail = async (id: any): Promise<ContractResponse[]> => {
    return axios.get(`${API_ROUTES.CONTRACTS.GET_WORKDETAILS}/${id}`);
};
export const getDirectJob = async (workType: string,type:number): Promise<DirectJobResponse[]> => {
    return axios.get(`${API_ROUTES.HOURLY_CONTRACTS.GET_CONTRACTS}?workType=${workType}&type=${type}`);
};