import React, { useEffect, useState } from "react";
import Header from "../header/routes";
import dummy from "../../assets/images/dummy.jpeg";
import styles from "../../../src/components/Feeds/styles/style.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import blsearch from "../../assets/images/blsearch.png";
import top_skill from "../../assets/images/top_skill.svg";
import shareprofile from "../../assets/images/share_profile.svg";
import loc from "../../assets/images/icon_location_filled.svg";
import star from "../../assets/images/star.svg";
import location from "../../assets/images/icon_location.svg";
import gprs from "../../assets/images/gprs.png";
import aeroplane from "../../assets/images/airoplane.svg";
import { UseSelector, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { ConfirmationComp } from "./modals/ConfirmationComp";
import { listingTeam } from "./api";
import Editprofile from "../Profile/routes/page";

const BusinessGroup = () => {
  const location = useLocation()
  const navigate = useNavigate();
  const newbusinessID = location.state?.businessID;
  const GroupId = location.state._id// Access businessID from state

  console.log("Received businessID:", newbusinessID);

  const [professional, setProfessional] = useState<[]>([]);
  const groupName = useSelector((state: RootState) => state);
  const [loading, setLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [businessId, setBusinessId] = useState<string>("");
  const [businessGroupId, setBusinessGroupId] = useState<string>("");
  const [ratingSum, setRatingSum] = useState<number>(0);
  const [averageRating, setAverageRating] = useState<string>("0.0");

  const closeModal = () => {
    setShowModal(false);
  };
  const [isListening, setIsListening] = useState(false);
  const GroupData = useSelector(
    (state: RootState) => state.businessGroup.businessGroupData
  );
  console.log(GroupData, "data of the group")

  useEffect(() => {
    businessData();
  }, []);

  const businessData = async () => {
    // if (Array.isArray(GroupData) && GroupData.length > 0) {
    //   const group = GroupData.find((item) => item._id && item.businessId);
    //   if (group) {
    setBusinessId(newbusinessID);
    setBusinessGroupId(GroupId);
    const res = await listingTeam(
      "672f4215e7dd482409c028f2",
      "6730f0c610c071b1e01db073"
    );
    setData(res?.data);
    let sum: number = 0;
    res?.data?.map((item: any) => {
      item?.reviewDetails?.map((review: any) => {
        sum += review?.rating || 0;
      });
    });
    const average = sum / res?.data?.length;
    setRatingSum(sum);
    setAverageRating(average.toFixed(1));
    //   }
    // }
  };

  const addNewEmployee = () => {
    navigate("/addNewEmployee", {
      state: {
        businessID: newbusinessID,
        groupID: GroupId,
      },
    });
  };

  const Addemp = () => {
    setShowModal(true);
  };

  return (
    <>
      <Header />
      <div className={styles.FondOpportuniy}>
        <div>
          <div className="container">
          <div className={styles.SearchData}>
            <div className={`${styles.FormGroup}`}>
              <span className="SeachIc me-2" style={{width: '20px'}}>
                <img src={blsearch} alt="" />
              </span>
              <input
                type="search"
                className="w-100"
                placeholder="Search by: Job title, Position, Keyword or People..."
           
              />
              <i className="btn bg-lightblue">
              {/* <FontAwesomeIcon
                icon={isListening ? faMicrophoneLines : faMicrophone}
                color={isListening ? "green" : "text-primary"}
                size="1x"
                onClick={toggleMic}
                style={{ cursor: "pointer" }}
              /> */}
              </i>
              <span>{isListening ? "Listening..." : ""}</span>
            </div>
            <div></div>
            {/* <div>
              <div>
                <button onClick={startListening} disabled={listening}>
                  🎤 Start
                </button>
                <button onClick={stopListening} disabled={!listening}>
                  🎤 Stop
                </button>
                {listening && <p>Listening...</p>}
              </div>
            </div> */}

            <div className={`${styles.FormGroup}`}>
              <span className="SeachIc me-2" style={{width: '20px'}}>
                <img src={loc} alt="" />
              </span>
              <input 
              type=" text"  
              // value={locationSearch} 
              // onChange={(event) => setLocationSearch(event.target.value)} 
              placeholder="City, state or zip code" 
              />
              <span className={styles.SeachIcgps}>
                <img src={gprs} alt="" />
              </span>
            </div>
            <div className={`w-25 ${styles.FormGroup}`}>
              <button
                className="btn btn-primary text-white">
                  Search
              </button>
              {/* <div className={styles.textwhite}>
              <input
                type="search"
               
                placeholder="Search"
               
                className="btn btn-primary text-white"
              />
              </div> */}
            </div>
          </div>
            {/* <div className={styles.SearchData}>
              <div className={`${styles.FormGroup}`}>
                <span className="SeachIc me-2" style={{ width: "20px" }}>
                  <img src={blsearch} alt="" />
                </span>
                <input
                  type="search"
                  className="w-100"
                  placeholder="Search by: Job tittle, Position, Keyword or People..."
                />
              </div>
              <div></div>

              <div className={`${styles.FormGroup}`}>
                <span className="SeachIc me-2" style={{ width: "20px" }}>
                  <img src={loc} alt="" />
                </span>
                <input type=" text" placeholder="City, state or zip code" />
                <span className={styles.SeachIcgps}>
                  <img src={gprs} alt="" />
                </span>
              </div>
              <div className={`${styles.FormGroup}`}>
                <div className={styles.textwhite}>
                  <input
                    type="search"
                    placeholder="Search"
                    className="btn btn-primary text-white"
                  />
                </div>
              </div>
            </div> */}
            <div className="d-flex align-items-center justify-content-between m-0 pt-4 pb-2 mb-0">
              <h4 className="text-20 mb-0 fw-medium">
                Add Your Employees to Business Group
              </h4>
              <button
                onClick={addNewEmployee}
                className="btn bg-blue px-3 py-2 rounded-2 text-14 text-white"
                style={{
                  cursor: "pointer",
                }}
              >
                + Add New Employee
              </button>
            </div>
          </div>
        </div>

        <div className="recommendedjobs">
          <div className="container">
            <div className="row align-items-center justify-content-start">
              {data?.length > 0
                ? data?.map((data: any, ind: any) => (
                  <div key={ind} className="col-xl-3">
                    <div className="col border rounded-3 mb-3">
                      <div className="row flex-column align-items-center justify-content-between mb-2 bg-lightblue pt-3 mx-0">
                        <div className="row align-items-start justify-content-center gx-2">
                          <div className="col-3 profile_icon">
                            <figure className="profileCircle">
                              <img
                                src={dummy}
                                alt=""
                                className="img-fluid rounded-circle border-2 border-blue"
                              />
                              <figcaption>
                                <p className="text-9 mt-2 text-center rounded-pill pb-0 bg-lightblue text-blue">
                                  20 Connections
                                </p>
                              </figcaption>
                            </figure>
                          </div>
                          <div className="col-9 ps-2">
                            <div className="d-flex align-items-start justify-content-between">
                              <div className="col-10">
                                <div className="newheadingstyle">
                                  <h4 className="mb-0 text-16 fw-semibold d-flex align-items-center">
                                    {data?.name}{" "}
                                    <span
                                      className={`text-9 text-white fw-normal ms-2 ${styles.boosted}`}
                                    >
                                      boosted
                                    </span>
                                  </h4>
                                  {data?.occupationDetails?.map(
                                    (item: any, ind: any) => (
                                      <p
                                        key={ind}
                                        className="text-10 my-0 clamp clamp1 "
                                      >
                                        {item?.name}
                                      </p>
                                    )
                                  )}{" "}
                                  <p className="text-10 my-0 d-flex align-items-center">
                                    Reviews : {data?.reviewDetails?.length}{" "}
                                    <span className="px-2">|</span>
                                    {data?.averageRating === null ? '0' : data?.averageRating}
                                    <i className="ms-2">
                                      <img src={star} alt="" />
                                    </i>
                                  </p>
                                  <p className="text-10 my-0  d-flex align-items-center">
                                    <i className="me-2">
                                      {/* <img
                                          src={location}
                                          alt=""
                                          width={10}
                                          height={10}
                                        /> */}
                                    </i>{" "}
                                    {data?.countryDetails?.name}
                                  </p>
                                  {/* <p className="text-10 my-0  d-flex align-items-center">
                                      <i className="me-2">
                                        <img
                                          src={flag}
                                          alt=""
                                          width={10}
                                          height={10}
                                        />
                                      </i>{" "}
                                      {data?.countryDetails?.name}
                                    </p> */}
                                  <p className="mb-0 bg-lightblue text-blue rounded-pill text-center text-9 d-inline-block px-2 py-0">
                                    Available
                                  </p>
                                </div>
                              </div>
                              <div className="col-2 text-end">
                                <i className="bg-white rounded-circle w-auto px-1 py-2 mb-0 text-center d-flex align-items-center justify-content-center">
                                  <img src={shareprofile} alt="" />
                                </i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="row align-items-center gx-2 my-2 pt-2"
                          style={{ borderTop: "1px solid #3375D7 " }}
                        >
                          <div className="col-xl-12 col-12">
                            <ul className="d-flex mb-0 align-items-center justify-content-center ps-0 gap=2">
                              <li className="col-6">
                                <p className="w-100 mb-0 bg-blue text-white rounded-pill text-center text-11 text-center px-2 py-1">
                                  Endorsements : 30
                                </p>
                              </li>
                              <li className="col-6">
                                <p className="w-100 mb-0 bg-lightgreen rounded-pill text-center text-11 d-inline-flex align-items-center px-2 py-1">
                                  <i className="me-1">
                                    <img src={aeroplane} alt="" />
                                  </i>
                                  Willing totravel : Yes
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="p-2">
                        <div className="row align-items-center">
                          <div className="col-6">
                            <div
                              className={`row align-items-center mb-3 ${styles.PenigSkilRate}`}
                            >
                              <div className="col-4 text-center">
                                <i>
                                  <img
                                    src={top_skill}
                                    alt=""
                                    className="img-fluid"
                                    width="35px"
                                      height="35px"
                                  />
                                </i>
                              </div>
                              <div className="col-8 ps-0">
                                <h5 className="mb-0 text-12 fw-semibold text-uppercase">
                                  Top Skill
                                </h5>
                                <p className="text-12 mb-0">Autocad</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div
                              className={`row align-items-center mb-3 ${styles.PenigSkilRate}`}
                            >
                              <div className="col-4 text-center">
                                <i>
                                  <img
                                    src={top_skill}
                                    alt=""
                                    className="img-fluid"
                                    width="35px"
                                      height="35px"
                                  />
                                </i>
                              </div>
                              <div className="col-8 ps-0">
                                <h5 className="mb-0 text-12 fw-semibold text-uppercase">
                                  Second Skill
                                </h5>
                                <p className="text-12 mb-0">Programming</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.PenigSkilRate}>
                          <h5>Hourly Rates</h5>
                          <ul>
                            <li>
                              <span>Base</span>
                              <label>$12</label>
                            </li>
                            <li>
                              <span>Overtime</span>
                              <label>$7</label>
                            </li>
                            <li>
                              <span>Sun/Holidays</span>
                              <label>$25</label>
                            </li>
                          </ul>
                        </div>

                        <div className="mt-3">
                          <div className="row align-items-center g-2">
                            <div className="col">
                              <button
                                type="button"
                                className="btn btn-primary w-100 text-10 px-1 text-center "
                              >
                                View Profile
                              </button>
                            </div>
                            <div className="col">
                              <button
                                onClick={Addemp}
                                type="button"
                                className="btn btn-white w-100 text-10 px-1 border text-center "
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
                : null}
            </div>
          </div>
        </div>
        <Editprofile />
      </div>
      <ConfirmationComp showModal={showModal} closeModal={closeModal} />
    </>
  );
};
export default BusinessGroup;
