import { ISaveContractApiRequestBody } from '../../../Interfaces/SaveContractApiRequestBody';
import { ISaveDirectJobApiRequestBody } from '../../../Interfaces/saveDirectJobApiRequestBody';
import { axios } from '../../../lib/axios';
import {
  AxiosResponse,
  MasterTypeResponse,
  PostMasterType,
} from '../../../shared/models';
import { API_ROUTES } from '../../../utils/routes/apiRoutes';
import { ContractResponse } from '../../common/Contracts/interface';
import { JobResponseDirect } from '../../common/Contracts/interface/directjobInterface';
import {
  ManufacturersResponse,
  PostContractPayload,
  PostContractResponse,
} from '../interface';

export const postContract = (
  payload: PostContractPayload
): Promise<PostContractResponse> => {
  return axios.post(API_ROUTES.CONTRACTS.CREATE_CONTRACT, payload);
};

export const getManufacturerList = async (
  type: string
): Promise<ManufacturersResponse> => {
  return axios.get(`${API_ROUTES.MASTER.GET_MASTERBYTYPE}?type=${type}`);
};
export const getManufacturerModelList = async (
  type: string,
  manufacturerId: string
): Promise<AxiosResponse<MasterTypeResponse[]>> => {
  return axios.get(
    `${API_ROUTES.MASTER.GET_MASTERBYTYPE}?type=${type}&manufacturerId=${manufacturerId}`
  );
};

export const postMasterType = (
  payload: PostMasterType
): Promise<AxiosResponse<MasterTypeResponse>> => {
  return axios.post(API_ROUTES.MASTER.POST_MASTERBYTYPE, payload);
};

export const saveContract = (
  body: ISaveContractApiRequestBody
): Promise<AxiosResponse<ContractResponse>> => {
  return axios.post(API_ROUTES.CONTRACTS.CREATE_CONTRACT, body);
};

export const saveDirectjob = (
  body: ISaveDirectJobApiRequestBody
): Promise<AxiosResponse<any>> => {
  return axios.post(API_ROUTES.CONTRACTS.CREATE_CONTRACT, body);
};

export const updateContract = async (
  body: ISaveContractApiRequestBody,
  id: string
): Promise<AxiosResponse<any>> => {
  return axios.put(`${API_ROUTES.CONTRACTS.CREATE_CONTRACT}/${id}`, body);
};
export const sendForApproval = async (
  body: any,
  id: string
): Promise<AxiosResponse<any>> => {
  return axios.put(`${API_ROUTES.SEND_FOR_APPROVAL.SEND_APPROVAL}/${id}`, body);
};

export const downloadHourlyContract = (): Promise<AxiosResponse<any>> => {
  return axios.get(`${API_ROUTES.CONTRACTS.DOWNLOAD}`);
};