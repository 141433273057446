import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ADD_PAID_ADS } from "../../../../shared/enums";
import { APP_ROUTES } from "../../../../utils/routes/AppRoutes";
import {
  setActiveStep,
  setAdDescription,
  setOccupation,
  setContactInfo,
  setOccupationsList,
  setServiceName,
  setHourlyCost,
} from "../../../../redux/reducers/servicePaidAd";
import HeaderContent from "../../../../shared/Components/HeaderContent/headerContent";
import styles from "../../../contractBeforeLogin/styles/style.module.css";
import Occupations from "../../../../assets/images/occupations.png";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../assets/images/blackarrow.svg";
import tip from "../../../../assets/images/tip.png";
import { RootState } from "../../../../redux/store";
import Toltip from "../../../common/toltip/page";
import { CONSTANT_MANAGER } from "../../../../utils/constans/constants";
import { toast } from "react-toastify";
import TextEditorCustomComp from "../../../contractBeforeLogin/HourlyContractType/HourlyContract/StepsComonents/TextEditorCustomComp/TextEditorCustomComp";
import { getMasterTypes } from "../../../../feature/auth/signup/api";
import { MASTER_TYPE } from "../../../../utils/enums/masterTypes.enum";
import { IOption } from "../../../../Interfaces";
import { postMasterType } from "../../../work/api";
import { MESSAGES } from "../../../../utils/messages";
import { APIResponseError } from "../../../work/interface";
import CreatableSelect from "react-select/creatable";

const ServiceAdDescription = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    adDescription,
    occupation,
    occupationsList,
    serviceName,
    hourlyCost,
    contactInfo,
  } = useSelector((state: RootState) => state.serviceAd);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleClickNext = () => {
    if (adDescription === "") {
      toast.error("Please Add Description");
      return;
    } else {
      dispatch(setActiveStep(ADD_PAID_ADS.UPLOAD_IMAGE));
    }
  };

  const handleClickBack = () => {
    navigate(APP_ROUTES.ADD_PAID_ADS.PAID_AD_ROOT);
  };

  const onDescriptionChange = (value: string): void => {
    dispatch(setAdDescription(value));
  };

  const getOccupationData = async () => {
    const response = await getMasterTypes(MASTER_TYPE.OCCUPATIONS);
    const mappedOccupations = response.data.map<IOption>((x) => ({
      label: x.name,
      value: x._id,
    }));
    dispatch(setOccupationsList(mappedOccupations));
  };

  useEffect(() => {
    getOccupationData();
  }, []);

  const handleSubmitCustomOccupation = async (
    newValue?: any,
    type?: string
  ) => {
    const payload = {
      type: MASTER_TYPE.OCCUPATIONS,
      name: newValue || searchTerm,
      alias: newValue || searchTerm,
    };
    toast.promise(postMasterType(payload), {
      pending: {
        render() {
          return MESSAGES.POSTING_CONTRACTS.VERIFYING;
        },
      },
      success: {
        render(newOccupation) {
          const newId = newOccupation?.data?.data?._id || newValue;
          const _obj: any = {
            label: newOccupation?.data?.data?.name || newValue,
            value: newId,
          };
          const newArr = [...occupation.occupation, _obj];
          dispatch(setOccupation({ ...occupation, occupation: newArr }));
          return MESSAGES.POSTING_CONTRACTS.ADDING;
        },
      },
      error: {
        render(error) {
          let errorMesage = error as APIResponseError;
          return errorMesage?.data?.data?.message;
        },
      },
    });
  };

  const handleCreate = (inputValue: string, type?: string) => {
    handleSubmitCustomOccupation(inputValue, type);
  };

  const handleOccupationChange = (value: any) => {
    if (value.length <= 3) {
      dispatch(setOccupation({ occupation: value }));
    } else {
      toast.error("You can select a maximum of 3 occupations.");
    }
  };

  const _onChange = (key: string, val: any) => {
    if (key === "serviceName") {
      dispatch(setServiceName(val));
    }
    if (key === "hourlyCost") {
      dispatch(setHourlyCost(val));
    }
    if (key === "contactInfo") {
      dispatch(setContactInfo(val));
    }
  };

  return (
    <>
      <HeaderContent />
      <div className="py-4 heightautoscroll">
        <div className={styles.signuppage}>
          <div className="contractpages">
            <form className="h-100">
              <div
                className={styles.registerOuter}
                style={{ position: "relative" }}
              >
                <div className="container h-100">
                  <div className=" h-100">
                    <div className={styles.registerBord}>
                      <div className="row pt-5 h-100 align-items-start">
                        <div className="col-md-6">
                          <div className={styles.registerLeft}>
                            <h4 className="text-white text-32 fw-semibold">
                              Ad Description
                            </h4>
                            <div className="col-lg-11 col-12 mx-auto text-center">
                              <figure>
                                <img
                                  src={Occupations}
                                  alt=""
                                  className="img-fluid w-100"
                                />
                              </figure>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className={styles.registerBord}>
                            <div className={styles.signUPContainer}>
                            <div className={styles.ScrollDiv}>
                              <div className={styles.registerRight}>
                                <div className={styles.fromGroup}>
                                  <div className="topTipHover">
                                    <div className="d-flex align-items-center">
                                      <label  className={styles.starlabel}>
                                        Service Name
                                      </label>
                                      <i>
                                        <img
                                          className="customeTolImg"
                                          src={tip}
                                          alt=""
                                        />
                                      </i>
                                      <Toltip
                                        slug={
                                          CONSTANT_MANAGER.SLUG.ADD_CONTRACT
                                        }
                                        labelId={
                                          CONSTANT_MANAGER.ADD_CONTRACT.PAID_AD
                                            .DATA.DESCRIPTION_OF_YOUR_ADD
                                        }
                                      />
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    placeholder="Eg: Artisans"
                                    value={serviceName}
                                    onChange={(e) =>
                                      _onChange("serviceName", e.target.value)
                                    }
                                  />
                                </div>

                                <div className={styles.fromGroup}>
                                  <div className="topTipHover">
                                    <div className="d-flex align-items-center">
                                    <label  className={styles.starlabel}>
                                        Description of your ad
                                      </label>
                                      <i>
                                        <img
                                          className="customeTolImg"
                                          src={tip}
                                          alt=""
                                        />
                                      </i>
                                      <Toltip
                                        slug={
                                          CONSTANT_MANAGER.SLUG.ADD_CONTRACT
                                        }
                                        labelId={
                                          CONSTANT_MANAGER.ADD_CONTRACT.PAID_AD
                                            .DATA.DESCRIPTION_OF_YOUR_ADD
                                        }
                                      />
                                    </div>
                                  </div>
                                  <TextEditorCustomComp
                                    data={adDescription}
                                    setDesc={(value: string) =>
                                      onDescriptionChange(value)
                                    }
                                  />
                                </div>

                                <div className={styles.fromGroup}>
                                  <div className="topTipHover">
                                    <div className="d-flex align-items-center">
                                      <label  className={styles.starlabel}>
                                        Occupations to Target (Choose up to 3)
                                      </label>
                                      <i>
                                        <img
                                          className="customeTolImg"
                                          src={tip}
                                          alt=""
                                        />
                                      </i>
                                      <Toltip
                                        slug={
                                          CONSTANT_MANAGER.SLUG.ADD_CONTRACT
                                        }
                                        labelId={
                                          CONSTANT_MANAGER.ADD_CONTRACT.PAID_AD
                                            .DATA.DESCRIPTION_OF_YOUR_ADD
                                        }
                                      />
                                    </div>
                                  </div>
                                  <CreatableSelect
                                    isMulti
                                    onCreateOption={(_cb) =>
                                      handleCreate(_cb, "occupation")
                                    }
                                    name="colors"
                                    options={occupationsList as any}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(val) =>
                                      handleOccupationChange(val)
                                    }
                                    value={occupation?.occupation}
                                  />
                                </div>

                                <div className={styles.fromGroup}>
                                  <div className="topTipHover">
                                    <div className="d-flex align-items-center">
                                      <label  className={styles.starlabel}>
                                        Hourly Cost
                                      </label>
                                      <i>
                                        <img
                                          className="customeTolImg"
                                          src={tip}
                                          alt=""
                                        />
                                      </i>
                                      <Toltip
                                        slug={
                                          CONSTANT_MANAGER.SLUG.ADD_CONTRACT
                                        }
                                        labelId={
                                          CONSTANT_MANAGER.ADD_CONTRACT.PAID_AD
                                            .DATA.DESCRIPTION_OF_YOUR_ADD
                                        }
                                      />
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    placeholder="Eg: $200"
                                    value={hourlyCost}
                                    onChange={(e) =>
                                      _onChange("hourlyCost", Number(e.target.value))
                                    }
                                  />
                                </div>

                                <div className={styles.fromGroup}>
                                  <div className="topTipHover">
                                    <div className="d-flex align-items-center">
                                      <label  className={styles.starlabel}>
                                        Contact Info
                                      </label>
                                      <i>
                                        <img
                                          className="customeTolImg"
                                          src={tip}
                                          alt=""
                                        />
                                      </i>
                                      <Toltip
                                        slug={
                                          CONSTANT_MANAGER.SLUG.ADD_CONTRACT
                                        }
                                        labelId={
                                          CONSTANT_MANAGER.ADD_CONTRACT.PAID_AD
                                            .DATA.DESCRIPTION_OF_YOUR_ADD
                                        }
                                      />
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    placeholder="Eg: Email, Website, Phone"
                                    value={contactInfo}
                                    onChange={(e) =>
                                      _onChange("contactInfo", e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              </div>
                              <div className="row align-items-center justify-content-between">
                                <div
                                  className="col-4 text-start my-3 btn-hover-effect"
                                  onClick={handleClickBack}
                                >
                                  <h6 className="btn bg-grey arrowyellow border-grey border-1 rounded-3 position-relative ps-5 backbutton">
                                    <span className="px-lg-4 px-2 text-12 fw-semibold d-inline-block">
                                      Back
                                    </span>
                                    <i className="bg-yellow rounded-3 arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                                      <img src={blackarrow} alt="" />
                                    </i>
                                  </h6>
                                </div>
                                <div className="col-8">
                                  <div className="d-flex align-items-center jusify-content-end">
                                    <div
                                      className="col-12 text-end my-3 btn-hover-effect"
                                      onClick={handleClickNext}
                                    >
                                      <h6 className="btn arrowblue border-grey border-1 position-relative pe-5">
                                        <span className="px-lg-4 px-2 text-12 fw-semibold d-inline-block">
                                          Continue
                                        </span>
                                        <i className="bg-blue rounded-3 arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                                          <img src={whitearrow} alt="" />
                                        </i>
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceAdDescription;
