import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalTitle,
} from "react-bootstrap";
import facebook from "../../assets/images/facebook.png";
import insta from "../../assets/images/insta.png";
import xtree from "../../assets/images/xtree.png";
import linked from "../../assets/images/linked.png";
import googlee from "../../assets/images/googlee.png";
import pro from "../../assets/images/pro.png";
import checkt from "../../assets/images/check.png";
import uncheck from "../../assets/images/uncheck.png";
import whatsapp from "../../assets/images/whatsapp.png";
import styles from "../../components/dashboard/styles/contractnew.module.css";
import { GetMyFriends } from "../Myfriends/api";
import { MyFriendsResponse } from "../Myfriends/interface";
import { toast } from "react-toastify";

interface ModalProps {
  modal2: boolean;
  closeModal2: () => void;
  text: string;
  url: string;
}

const AddUser: React.FC<ModalProps> = ({
  modal2,
  closeModal2,
  text,
  url,
}) => {
  const [myFriends, setMyFriends] = useState<MyFriendsResponse[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedFriends, setSelectedFriends] = useState<{
    [key: string]: boolean;
  }>({});
  const message = encodeURIComponent(`${text}`);
  const [copied, setCopied] = useState(false);

  const getConnectedFriends = async () => {
    const response = await GetMyFriends();
    setMyFriends(response?.data);
    setLoading(false);
  };

  useEffect(() => {
    getConnectedFriends();
  }, []);

  const handleToggleCheck = (friendId: string) => {
    setSelectedFriends((prevState) => ({
      ...prevState,
      [friendId]: !prevState[friendId],
    }));
  };

  const handleWhatsAppShare = () => {
    window.open(`https://wa.me/?text=${message}`, "_blank");
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(url).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 1500);
    });
  };

  const handleLinkedInShare = () => {
    const message = "Your message here..."; // Define the message you want to share
    const urlToShare = url; // URL that you want to share (if applicable)

    // Copy the message to the clipboard
    navigator.clipboard.writeText(message).then(() => {
        toast.info("Message copied to clipboard! You can now paste it into your LinkedIn post.");
    }).catch(err => {
        console.error('Could not copy text: ', err);
        toast.error("Failed to copy message. Please try again.");
    });

    // Open LinkedIn share dialog (not direct messaging)
    const shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(urlToShare)}`;
    window.open(shareUrl, "_blank");
};


  const handleTwitterShare = () => {
    window.open(`https://twitter.com/intent/tweet?text=${url}`, "_blank");
  };

  const handleFacebookShare = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      "_blank"
    );
  };

  // const handleInstagramShare = () => {
  //   const instagramShareUrl = `https://www.instagram.com/direct/inbox?text=${message}`;
  //   window.open(instagramShareUrl, "_blank");
  // };

  // const handleInstagramShare = () => {
  //   const decodedMessage = decodeURIComponent(message); // Decode the content
  //   navigator.clipboard.writeText(decodedMessage).then(() => {
  //     alert(
  //       "The content has been copied to your clipboard. Open Instagram, go to a chat, and paste the content to share."
  //     );
  //     window.open(`https://www.instagram.com/direct/inbox?text=${decodedMessage}`, "_blank");
  //   });
  // };

  const handleInstagramShare = () => {
    const decodedMessage = decodeURIComponent(message);
    navigator.clipboard.writeText(url).then(() => {
      toast.info(
        "The content has been copied to your clipboard. Paste the content in chats."
      );
      setTimeout(() => {
        window.open("https://www.instagram.com/direct/inbox/", "_blank");
      }, 3000);
    });
  };

  const handleGmailShare = () => {
    const subject = "Automate America";
    const body = `${message}`;
    window.open(`mailto:?subject=${encodeURIComponent(subject)}&body=${body}`, "_blank");
  };
console.log(url);

  return (
    <Modal show={modal2} onHide={closeModal2} centered size="lg">
      <Modal.Header closeButton>
        <ModalTitle>Add/Invite Users For Event</ModalTitle>
      </Modal.Header>
      <ModalBody>
        <div className={styles.ShareModal}>
          <div className={`pt-0 ${styles.shareLink}`}>
            <label>Share</label>
            <ul>
              <li onClick={handleWhatsAppShare}>
                <span>
                  <img src={whatsapp} alt="" width={27} height={27} />
                  whatsapp
                </span>
              </li>
              <li onClick={handleGmailShare}>
                <span>
                  <img src={googlee} alt="" />
                  Gmail
                </span>
              </li>
              <li onClick={handleFacebookShare}>
                <span>
                  <img src={facebook} alt="" />
                  Facebook
                </span>
              </li>
              <li onClick={handleInstagramShare}>
                <span>
                  <img src={insta} alt="" />
                  Instagram
                </span>
              </li>
              <li onClick={handleTwitterShare}>
                <span>
                  <img src={xtree} alt="" />
                  Twitter
                </span>
              </li>
              <li onClick={handleLinkedInShare}>
                <span>
                  <img src={linked} alt="" />
                  Linkedin
                </span>
              </li>
            </ul>
          </div>
          {loading ? (
            <div
              className="spinner-grow text-primary mx-auto"
              role="status"
              style={{ width: "25px", height: "25px" }}
            >
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <div className={styles.myfriendSearch}>
              <label>My Friends</label>
              <div className={`mb-0 ${styles.formGrop}`}>
                <input type="text" placeholder="Search for Connects" />
                <button type="submit">Search</button>
                <ul>
                  {myFriends?.length > 0
                    ? myFriends?.map((data, ind) => (
                      <>
                        <li
                          key={ind}
                          onClick={() => handleToggleCheck(data?.user?._id)}
                        >
                          <span className="d-flex align-items-center">
                            <img
                              src={data?.user?.profilePicture || pro}
                              alt=""
                            />
                            {data?.user?.name}
                          </span>
                          <label>
                            <img
                              src={
                                selectedFriends[data?.user?._id]
                                  ? checkt
                                  : uncheck
                              }
                              alt="Check/Uncheck"
                            />
                          </label>
                        </li>
                      </>
                    ))
                    : "You Have No Friends Yet!"}
                </ul>
              </div>
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter className="btn-hover-effect">
        <Button type="button" className="btn bg-blue text-14 fw-semibold py-2">
          Add
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddUser;
