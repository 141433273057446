import React, { useEffect, useState } from "react";
import Header from "../../../header/routes";
import exportt from "../../../../assets/images/exportt.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "../../../common/timesheets/styles/styles/style.module.css";
import { APP_ROUTES } from "../../../../utils/routes/AppRoutes";
import { TIMESHEET_OR_EXPENSE_TYPE } from "../../../../utils/enums/timesheetOrExpenseStatusTypes";
import moment from "moment";
import { getExpense } from "../api";
import AddExpense from "../addExpense";
import { COUNT_TYPE } from "../../../../utils/enums/types";
import { WorkCountResponse } from "../../Contracts/interface";
import { getWorkDetailWithCount } from "../../Contracts/api";
import UpdateExpense from "../updateExpense";

const ExpenseStatusTable = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loader, setLoader] = useState<boolean>(true);
  const [workDetails, setWorkDetails] = useState<WorkCountResponse>();
  const [expenseData, setExpenseData] = useState<any>();
  const [activeStatus, setActiveStatus] = useState<string>(TIMESHEET_OR_EXPENSE_TYPE.CURRENT);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [setExpemseId, setShowExpenseId] = useState<string>("");

  useEffect(() => {
    getWorkDetials();
  }, []);

  const getWorkDetials = async () => {
    const response: any = await getWorkDetailWithCount(id, COUNT_TYPE.REPORTS);
    setWorkDetails(response?.data);
  };

  const refreshdata = (id: any, type: string) => {
    const getTimesheetdata = async () => {
      const response = await getExpense(id, type);
      setExpenseData(response?.data);
      setLoader(false);
    };
    getTimesheetdata();
  };

  const handleShowExpenseStatus = (value: string) => {
    setActiveStatus(value);
    refreshdata(id, value);
    setLoader(false);
  };
  useEffect(() => {
    refreshdata(id, TIMESHEET_OR_EXPENSE_TYPE.CURRENT);
    setLoader(false);
  }, []);

  const handleViewStatusDetails = (timesheetId: string) => {
    navigate(`${APP_ROUTES.EXPENSE_REPORT}/${timesheetId}`, {
      state: { activeStatus },
    });
  };
  const handleShowAddExpense = () => {
    setShowModal(true);

  };
  const handleEditExpense = (expenseId: string) => {
    debugger
    setShowEditModal(true)
    setShowExpenseId(expenseId)
  }
  return (
    <>
      <Header />
      {showModal && <AddExpense onClose={() => setShowModal(false)} workId={id} />}
      {showEditModal && <UpdateExpense onClose={() => setShowEditModal(false)} workId={id as string} expenseId={setExpemseId as string}/>}

      <div className={styles.TimeShettTable}>
        <div className="container">
          <ul className={styles.timeSheetFilter}>
            <li
              className={activeStatus === "current" ? styles.active : ""}
              onClick={() =>
                handleShowExpenseStatus(TIMESHEET_OR_EXPENSE_TYPE.CURRENT)
              }
            >
              <span>All Expenses</span>
            </li>
            <li
              className={activeStatus === "due" ? styles.active : ""}
              onClick={() =>
                handleShowExpenseStatus(TIMESHEET_OR_EXPENSE_TYPE.DUE)
              }
            >
              <span>Due Expenses</span>
            </li>
            <li
              className={activeStatus === "pending" ? styles.active : ""}
              onClick={() =>
                handleShowExpenseStatus(TIMESHEET_OR_EXPENSE_TYPE.PENDING)
              }
            >
              <span>Pending Expenses</span>
            </li>
            <li
              className={activeStatus === "approved" ? styles.active : ""}
              onClick={() =>
                handleShowExpenseStatus(TIMESHEET_OR_EXPENSE_TYPE.APPROVED)
              }
            >
              <span>Approved Expenses</span>
            </li>
          </ul>
          <div className={styles.yimesheetBrd}>
            <div className="container">
              <div className={styles.TimeShettTableOuter}>
                <div className={styles.TimeShettTableHead}>
                  <h3>Expense Details</h3>
                  <div className="d-flex align-items-center">
                    <button >
                      {" "}
                     <div className="d-flex gap-1">
                     <img src={exportt} alt="" />
                     <span> Export Data</span>
                     </div>
                    </button>
                    <button onClick={handleShowAddExpense}>
                      Add New Expense
                    </button>
                  </div>
                </div>
                {loader ? (
                  <div className="spinner-grow text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <table>
                    <tr>
                      <th>WEEK START DATE</th>
                      <th>WEEK END DATE</th>
                      <th>TOTAL</th>
                      <th>CREATEDDATE</th>
                      <th>CREATED BY</th>
                      <th>APPROVED BY</th>
                      <th>APPROVED DATE</th>
                      <th>STATUS</th>
                      <th>ACTIONS</th>
                    </tr>
                    {expenseData?.length > 0 ? (
                      expenseData?.map((data: any, idx: number) => {
                        return (
                          <>
                            <tr>
                              <td key={idx}>
                                {moment(data.startDate).format("MMM DD, YYYY")}
                              </td>
                              <td>
                                {moment(data.endDate).format("MMM DD, YYYY")}
                              </td>
                              <td>{data?.total}</td>

                              <td>
                                {moment(data.createdAt).format("MMM DD, YYYY")}
                              </td>
                              <td>{data?.user?.name}</td>
                              <td>{data?.approvedBy?.name}</td>
                              <td>
                                {moment(data.approvedDate).format(
                                  "MMM DD, YYYY"
                                )}
                              </td>
                              <td>
                                {data?.status === "draft" ? (
                                  <span>Not Filled</span>
                                ) : data?.status === "approved" ? (
                                  <span className={styles.approvedf}>
                                    Approved
                                  </span>
                                ) : data?.status === "rejected" ? (
                                  <span className={styles.Decloine}>
                                    Declined
                                  </span>
                                ) : (
                                  data?.status === "pending" ?
                                    <span className={styles.pending}>
                                      Pending
                                    </span>
                                    : ""
                                )}
                              </td>
                              <td>
                                {data?.status === "approved" ? (
                                  <span
                                    onClick={() =>
                                      handleViewStatusDetails(data?._id)
                                    }
                                  >
                                    View Details
                                  </span>
                                ) : data?.status === "pending" ? (
                                  <span
                                    onClick={() =>
                                      handleViewStatusDetails(data?._id)
                                    }
                                  >
                                    View Details
                                  </span>
                                ) : data?.isDue === true ? (
                                  <span
                                    onClick={() =>
                                      handleViewStatusDetails(data?._id)
                                    }
                                  >
                                    View Details
                                  </span>
                                ) : (
                                  data?.status === "draft" ?
                                    <span
                                      onClick={() =>
                                        handleEditExpense(data)
                                      }
                                    >
                                      Edit
                                    </span>
                                    : ""
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <p style={{ textAlign: "center" }}>
                        No Expense Data Available!
                      </p>
                    )}
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpenseStatusTable;
