import React from "react";
import styles from "../../components/contractBeforeLogin/styles/style.module.css";
import { useDispatch, useSelector } from "react-redux";
import group from "../../assets/images/Group1686558045.png";
import whitearrow from "../../assets/images/whitearrow.svg";
import blackarrow from "../../assets/images/blackarrow.svg";
import { RootState } from "../../redux/store";
import { setLegalDetails } from "../../redux/reducers/addContract";

const Post = (props: any) => {
  const dispatch = useDispatch();
  const { legalDetails } = useSelector((state: RootState) => state.addContract);
  const { isAutomateAmerica, isAnonymous } = legalDetails;

  const onInputChange = (isAutomateAmerica: boolean, isAnonymous: boolean) => {
    dispatch(
      setLegalDetails({ ...legalDetails, isAutomateAmerica, isAnonymous })
    );
  };

  const submitContract = () => {
    props.publishContract();
  };

  return (
    <div className="py-3">
      <div className="col-12">
        <img src={group} alt="" className="img-fluid mx-auto" />
      </div>

      <div className="row justify-content-center mt-4 mx-0 selectpaymentterms">
        <div className={styles.ratedRadio}>
          <div className="p-3 bg-lightbluewhite mb-4 rounded-3">
            <input
              className="form-check-input"
              type="radio"
              name="postingOption"
              checked={isAutomateAmerica && !isAnonymous}
              onChange={() => onInputChange(true, false)}
            />
            <label className="text-16 fw-medium text-center text-black mb-0 ps-3">
              Show My Company Name and Logo on Contract
            </label>
          </div>
          <div className={styles.ratedRadio}>
            <div className="p-3 bg-lightbluewhite rounded-3">
              <input
                className="form-check-input"
                type="radio"
                name="postingOption"
                checked={!isAutomateAmerica && isAnonymous}
                onChange={() => onInputChange(false, true)}
              />
              <label className="text-16 fw-medium text-center text-black mb-0 ps-3">
                Post Anonymously
              </label>
            </div>
          </div>
        </div>

        <div className="col-12 my-3 btn-hover-effect text-end">
          <div className="row mx-0 my-3  justify-content-end">
            <div className="col-5 btn-hover-effect">
              <h6 className="btn bg-grey arrowyellow border-grey border-1 rounded-3 position-relative ps-5 backbutton">
                <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                  <img src={blackarrow} alt="" />
                </i>
                <span className="px-lg-4 px-2 text-12 fw-semibold">
                  Not Now
                </span>
              </h6>
            </div>
            <div className="col-5 btn-hover-effect">
              <button
                type="button"
                onClick={() => submitContract()}
                className="btn bg-grey arrowblue border-grey border-1 rounded-3 position-relative pe-5"
              >
                <span className="px-lg-4 px-2 text-12 fw-semibold">Submit</span>
                <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                  <img src={whitearrow} alt="" />
                </i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Post;
