import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import congratscheck from "../../../../assets/images/congra_check.png";

interface CongratsModalProps {
    closeModal: () => void;
    show: boolean;
}

export const Congratulations: React.FC<CongratsModalProps> = ({
    closeModal,show
}) => {
    const navigate = useNavigate();

    const handleAddMore = () => {
        closeModal();
        console.log("Add More button clicked");
    };

    const gotoDashboard = () => {
        navigate("/home");
    };

    return (
        <Modal show={show}  onHide={closeModal} centered size="lg">
            <ModalBody>
                <div className="modal_catch d-flex flex-column justify-content-center text-center rounded-0 pt-4">
                    <div className="text-center">
                        <figure>
                            <img
                                src={congratscheck}
                                alt=""
                                className="img-fluid mx-auto my-4"
                            />
                        </figure>
                    </div>
                    <h1 className="text-24 fw-bold">Congratulations!</h1>
                    <p className="text-16 mb-0 p-6" style={{padding:"20px"}}>
                        The Timesheet has been sent to the Business for approval, which wil be approve soon. Please wait a bit.
                    </p>
                </div>
            </ModalBody>

            <ModalFooter className="btn-hover-effect justify-content-center border-top-0 mb-3">
                <Button
                    type="button"
                    variant="secondary"
                    onClick={gotoDashboard}
                    className="btn bg-white arrowyellow border border-1 rounded-3 position-relative py-2 backbutton w-auto mb-3"
                >
                    <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
                        Back to Dashboard
                    </span>
                </Button>
                <Button
                    type="button"
                    variant="secondary"
                    onClick={handleAddMore}
                    className="btn bg-blue arrowblue border border-1 rounded-3 position-relative py-2 w-auto mb-3"
                >
                    <span className="px-lg-4 px-2 text-12 fw-semibold text-white">
                        Ok
                    </span>
                </Button>
            </ModalFooter>
        </Modal>
    );
};