import React, { useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../../assets/images/penbl.png";
import tip from "../../../../assets/images/tip.png";
import dummy from "../../../../assets/images/dummy.jpeg";
import star from "../../../../assets/images/icon_star.svg";
import flag from "../../../../assets/images/flag.svg";
import shareprofile from "../../../../assets/images/share_profile.svg";
import aeroplane from "../../../../assets/images/airoplane.svg";
import selfrating from "../../../../assets/images/self_rating.svg";
import EditBusinessGroupModal from "../modals/EditBusinessGroupModal";
import { useNavigate } from "react-router-dom";
import { getBusinessMembers } from "../../../NewBusiness/api";

const BusinessGroup = () => {
  const [showModal, setShowModal] = useState(false);
  const [businessMembers, setBusinessMembers] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBusinessMembers = async () => {
      try {
        setLoading(true);
        const response = await getBusinessMembers();
        console.log(response.data, "member API response");
        setBusinessMembers(response.data);
      } catch (err) {
        setError("Failed to load business members");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchBusinessMembers();
  }, []);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const createBusiness = () => navigate("/newBusiness");

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="text-danger">{error}</p>;

  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-between mb-3">
        <div className="col-8">
          <div className="topTipHover">
            <div className="d-flex align-items-center">
              <h4 className="text-20 fw-bold font-inter mb-0">Business Group</h4>
              <i>
                <img className="customeTolImg" src={tip} alt="Tip" />
              </i>
            </div>
          </div>
        </div>
      </div>

      {businessMembers.map((group) => (
        <div key={group.groupId} className={styles.personalinfo}>
          <ul className="row align-items-center">
            <li className="col-xl-4 col-6">
              <span>Group Name</span>
              <label className="clamp clamp1">{group.groupName}</label>
            </li>
          </ul>
          <div>
            <h4 className="text-16 fw-semibold">Group Members</h4>
            <div className="row align-items-center g-3">
              {group.members.map((member: any, index: number) => (
                <div className="col-xl-6 col-md-6 col-12 mb-4" key={`${group.groupId}-${index}`}>
                  <div className="col border rounded-3">
                    <div className="row flex-column align-items-center justify-content-between mb-2 bg-lightblue py-3 mx-0">
                      <div className="row align-items-center justify-content-center gx-2">
                        <div className="col-3 profile_icon">
                          <figure className="profileCircle">
                            <img
                              src={dummy}
                              alt="Profile"
                              className="img-fluid rounded-circle"
                            />
                          </figure>
                        </div>
                        <div className="col-9 ps-2">
                          <div className="d-flex align-items-start justify-content-between">
                            <div className="col-8">
                              <div className="newheadingstyle">
                                <h4 className="mb-0 text-16 clamp clamp1 fw-semibold">
                                  {member.name || `${member.firstName} ${member.lastName}`}
                                </h4>
                                <p className="text-10 my-0 clamp clamp1">
                                  {member.userType === "manager" ? "Manager" : "Employee"}
                                </p>
                                <p className="text-10 my-0 ">
                                  Email: {member.email || "Not Provided"}
                                </p>
                                <p className="text-10 my-0 ">
                                  Phone: {member.phoneNumber || "Not Provided"}
                                </p>
                              </div>
                            </div>
                            <div className="col-2">
                              <i>
                                <img src={flag} alt="Flag" />
                              </i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="p-2">
                      <div className="d-flex">
                        <div className="skills">
                          <h4 className="fw-semibold text-14 mb-0">Primary Occupation</h4>
                          <ul className="ps-0 d-flex flex-wrap align-items-center justify-content-start gap-2 mx-0">
                            {member.occupationDetails?.map((occupation: any) => (
                              <li key={occupation._id} className="d-inline-block pe-2">
                                <p className="mb-0 bg-lightblue text-blue rounded-pill text-center text-11 d-inline-block px-2 py-1 fw-medium">
                                  {occupation.name}
                                </p>
                              </li>
                            )) || (
                                <li>
                                  <p className="text-muted text-11">No Primary Occupation</p>
                                </li>
                              )}
                          </ul>
                        </div>

                        <div className="skills ms-3">
                          <h4 className="fw-semibold text-14 mb-0">Secondary Occupation</h4>
                          <ul className="ps-0 d-flex flex-wrap align-items-center justify-content-start gap-2 mx-0">
                            {member.secondaryOccupation?.map((secOccupation: any) => (
                              <li key={secOccupation._id} className="d-inline-block pe-2">
                                <p className="mb-0 bg-lightblue text-blue rounded-pill text-center text-11 d-inline-block px-2 py-1 fw-medium">
                                  {secOccupation.name}
                                </p>
                              </li>
                            )) || (
                                <li>
                                  <p className="text-muted text-11">No Secondary Occupation</p>
                                </li>
                              )}
                          </ul>
                        </div>
                      </div>
                      <div>
                      </div>
                      <div className="mt-3">
                        <div className="row align-items-center g-2">
                          <div className="col">
                            <button
                              type="button"
                              className="btn btn-primary w-100 text-10 px-1 text-center"
                            >
                              View Profile
                            </button>
                          </div>
                          <div className="col">
                            <button
                              type="button"
                              className="btn btn-primary w-100 text-10 px-1 text-center"
                            >
                              Shared Data
                            </button>
                          </div>
                          <div className="col">
                            <button
                              type="button"
                              className="btn btn-white w-100 text-center border text-10 px-1"
                            >
                              Message
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}

      <EditBusinessGroupModal
        showModal={showModal}
        closeModal={closeModal}
        title="Edit Business Group"
      />
    </div>
  );
};

export default BusinessGroup;
