import React, { useState } from "react";
import { Dropdown, FormControl, Modal, ModalFooter, Spinner } from "react-bootstrap";
import penbl from "../../../../assets/images/penbl.png";
import upload from "../../../../assets/images/upload.svg";
import styles from "../styles/style.module.css";
import blackarrow from "../../../../assets/images/blackarrow.svg";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import thumb from "../../../../assets/images/profile_picture.png";
import { IOption } from "../../../../Interfaces";
import { EmergencyCont } from "../../interface";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import { updateUserProfile } from "../../../home/api";
import { toast } from "react-toastify";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  contact: any;
  refresh: () => void;
  //   indexNumber: number | null;
}

const EmergencyContModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  contact,
  refresh
}) => {
  if (!isOpen) return null;
  const [name, setName] = useState(contact?.name);
  const [phoneNumber, setPhoneNumber] = useState(contact?.phoneNumber);
  const [rel, setRel] = useState(contact?.relationship);
  const [countryCode, setCountryCode] = useState<string>("");
  const [savedFullNumber, setSavedFullNumber] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<{ name?: string; phone?: string; relationship?: string }>({});

  const handlePhoneChange = (value: string | undefined | null) => {
    if (value) {
      setPhoneNumber(value);
      const countryCodeMatch = value.match(/^\+\d+/);
      const extractedCountryCode = countryCodeMatch ? countryCodeMatch[0] : "";
      const parsedNumber = parsePhoneNumber(value);
      const fullPhoneNumber = `+${parsedNumber?.countryCallingCode}${parsedNumber?.nationalNumber}`;
      setSavedFullNumber(fullPhoneNumber);

      if (parsedNumber) {
        setCountryCode(parsedNumber?.countryCallingCode);
      }
    } else {
      setCountryCode("");
      setPhoneNumber("");
    }
  };

  const handleUpdateDetails = async () => {
    setErrors({});
    let validationErrors = {};
    if (!name) {
      validationErrors = { ...validationErrors, name: "Name is required." };
    }
    if (!phoneNumber) {
      validationErrors = { ...validationErrors, phone: "Phone number is required." };
    }
    if (!rel) {
      validationErrors = { ...validationErrors, relationship: "Relationship is required." };
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors as any);
      return; 
    }
    if (name || phoneNumber || rel) {
      setIsSubmitting(true)
      const payload = {
        emergencyContact: {
          name: name,
          phoneNumber: phoneNumber,
          relationship: rel,
        },
      };
      try {
        await updateUserProfile(payload);
        refresh();
        setIsSubmitting(false)
        toast.success("Updated Succesfully")
        onClose();
      } catch (error) {
        console.log(
          "Occuring error while updating emergency contact information",
          error
        );
      }
    }
  };

  return (

    <>
    <Modal show={isOpen} onHide={onClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <form>
        <Modal.Body>       
        <div className="addModalPro">
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <div className="formGroup">
                      <label>Name*</label>
                      <div>
                        <input
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          className={`form-control ${errors.name ? 'is-invalid' : ''}`}

                          placeholder="Enter Name"
                        />
                        {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="formGroup">
                      <label>Phone Number*</label>
                      <div>
                        {/* <input
                          type="tel" 

                          className="form-control"
                          placeholder="+1 6253405561"
                        /> */}
                        <PhoneInput
                          placeholder="Eg. +1 254 2546 333"
                          value={phoneNumber} // Bind phone number here
                          onChange={handlePhoneChange}
                          defaultCountry="US"
                          international
                          countrySelectProps={{ unicodeFlags: true }}
                          className={`form-control ${errors.phone ? 'is-invalid' : ''}`}

                        />
                      </div>
                      {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}

                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="formGroup">
                      <label>Relationship*</label>
                      <div style={{ marginBottom: "30px" }}>
                        <input
                          type="text"
                          value={rel}
                          onChange={(e) => setRel(e.target.value)}
                          className={`form-control ${errors.relationship ? 'is-invalid' : ''}`}

                          placeholder="Enter Relationship"
                        />
                         {errors.relationship && <div className="invalid-feedback">{errors.relationship}</div>}
                      </div>
                    </div>
                  </div>
                </div>
            </div>    
        </Modal.Body>
        <ModalFooter>
          <div className="btn-hover-effect">
            <h6 onClick={onClose} className="btn bg-white arrowyellow border border-1 rounded-3 position-relative ps-5 backbutton w-auto"
                              >
              <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
                Back
              </span>
              <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                <img src={blackarrow} alt="" width={15} height={15} style={{transform: "rotate(-180deg)"}}/>
              </i>
            </h6>
          </div>
          <div className="btn-hover-effect">
            <h6 onClick={handleUpdateDetails} className="btn bg-white arrowblue border border-1 rounded-3 position-relative pe-5 w-auto">
            <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
            {isSubmitting ? (
              <Spinner animation="border" size="sm" className="me-2" />
            ) : (
              "Save"
            )}
          </span>
              <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                <img src={whitearrow} alt="" width={15} height={15}/>
              </i>
            </h6>
          </div>
          
        </ModalFooter>
      </form>
      
      </Modal>
    </>
  );
};

export default EmergencyContModal;
