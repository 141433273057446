import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ADD_PAID_ADS } from '../../shared/enums';
import { ServicePaidAdsRootState } from '../../models/ServicePaidAdRootState';
import { IOption } from '../../Interfaces';

const initialState = {
  ...new ServicePaidAdsRootState({
    started: false,
    activeStep: ADD_PAID_ADS.AD_DESCRIPTION,
    contractType: 'paid_ads',
    adDescription: '',
    uploadedImages: [],
    planId: '',
    planPrice: null,
    socialPostDesc: '',
    videoLink: '',
    currentPlan: '',
    serviceName: '',
    occupation: { occupation: [] },
    hourlyCost: 0,
    contactInfo: '',
    occupationsList: [],
  }),
};

const servicePaidAdsSlice = createSlice({
  name: 'servicePaidAds',
  initialState,
  reducers: {
    initPaidAds: (state, action: PayloadAction<ServicePaidAdsRootState>) =>
      action.payload,
    setActiveStep: (state, action: PayloadAction<ADD_PAID_ADS>) => {
      state.activeStep = action.payload;
    },
    setStarted: (state, action: PayloadAction<boolean>) => {
      state.started = action.payload;
    },
    setAdDescription: (state, action: PayloadAction<string>) => {
      state.adDescription = action.payload;
    },
    setUploadedFiles: (state, action: PayloadAction<string[]>) => {
      state.uploadedImages = action.payload;
    },
    setPlanId: (state, action: PayloadAction<string>) => {
      state.planId = action.payload;
    },
    setPlanPrice: (state, action: PayloadAction<number>) => {
      state.planPrice = action.payload;
    },
    setSocialPostDesc: (state, action: PayloadAction<string>) => {
      state.socialPostDesc = action.payload;
    },
    setVideoLink: (state, action: PayloadAction<string>) => {
      state.videoLink = action.payload;
    },
    setCurrentPlan: (state, action: PayloadAction<string>) => {
      state.currentPlan = action.payload;
    },
    setServiceName: (state, action: PayloadAction<string>) => {
      state.serviceName = action.payload;
    },
    setOccupation: (state, action: PayloadAction<{ occupation: IOption[] }>) => {
      state.occupation = { occupation: action.payload.occupation }; // Store occupation as an array of objects
    },
    setHourlyCost: (state, action: PayloadAction<number>) => {
      state.hourlyCost = action.payload;
    },
    setContactInfo: (state, action: PayloadAction<string>) => {
      state.contactInfo = action.payload;
    },
    setOccupationsList: (state, action: PayloadAction<IOption[]>) => {
      state.occupationsList = [...action.payload];
    },
  },
});

export const {
  setActiveStep,
  initPaidAds,
  setStarted,
  setAdDescription,
  setUploadedFiles,
  setPlanId,
  setPlanPrice,
  setSocialPostDesc,
  setVideoLink,
  setCurrentPlan,
  setServiceName,
  setOccupation,
  setHourlyCost,
  setContactInfo,
  setOccupationsList,
} = servicePaidAdsSlice.actions;

export default servicePaidAdsSlice.reducer;
