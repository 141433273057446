import React, { useState } from "react";
import styles from "../../../styles/style.module.css";
import choosetype from "../../../../../assets/images/choosetype.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import storage from "../../../../../utils/storage";
import { RootState } from "../../../../../redux/store";
import { keyInfoValidationSchema } from "../../../../work/validations";
import {
  setActiveStep,
  setKeyInformation,
} from "../../../../../redux/reducers/addContract";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import { SaveAsDraftButton } from "../../../../work/contractSteps/Components";
import Toltip from "../../../../common/toltip/page";
import tip from "../../../../../assets/images/tip.png";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";
import { Dropdown, FormControl } from "react-bootstrap";

const AddKeyInformation: React.FC = () => {
  const dispatch = useDispatch();
  const token = storage.getToken();
  const { keyInformation } = useSelector(
    (state: RootState) => state.addContract
  );
  const shifts = ["days", "afternoons", "nightshift", "variable"];
  const hours = ['6', '7', '8', '9', '10', '11', '12'];
  const days = ['1', '2', '3', '4', '5', '6', '7'];
  
  const formik: any = useFormik({
    initialValues: {
      noOfPeople: keyInformation.noOfPeople || "",
      shift: keyInformation.shift || "",
      plannedHoursPerDay: keyInformation.plannedHoursPerDay || "",
      plannedDaysPerWeek: keyInformation.plannedDaysPerWeek || "",
    },
    validationSchema: keyInfoValidationSchema,
    onSubmit: (value) => {
      console.log(value);
      dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_LOCATION));
    },
  });

  const onChange = (value: string | number, key: string) => {
    formik.setFieldValue(key, value);

    dispatch(
      setKeyInformation({
        ...keyInformation,
        [key]: value,
      })
    );
  };

  const handleGoBack = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_TIMINGS));
  };
  // const handleGoToNextStep = () => {
  //   dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_LOCATION));
  // };

  return (
    <>

      <HeaderContent />
      <div className="heightautoscroll contractradio">
        <form onSubmit={formik.handleSubmit} className="h-100">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-lg-6">
                <div className={styles.registerLeft}>
                  
                    <div className="col-lg-11 col-12 mx-auto text-center"
                    > 
                      <figure>
                        <img
                          src={choosetype}
                          alt=""
                          className="img-fluid w-100"
                        />
                      </figure>
                    </div>
                      </div>
              </div>
              <div className="col-lg-6 col-12">
              
                <h4 className="text-white text-32 fw-semibold">
                {" "}
                How Many People Are Needed And <br/>How Much Will
                They Work?
                </h4>
                <p className="text-16 text-white opacity-75">
                  How many Professionals do you need?
                </p>
                <div className={styles.ScrollDiv}>
                <div className={styles.signUPContainer}>
                  <div className={styles.registerRight}>
                    
                    <div >
                      <div className={styles.fromGroup} style={{ marginBottom: "30px" }}>
                        {" "}
                        <div className="topTipHover">
                          <label className={styles.starlabel}>
                            # Of People Needed{" "}
                          </label>{" "}
                          <i>
                            <img
                              className="customeTolImg"
                              src={tip}
                              alt=""
                            />
                          </i>
                          <Toltip
                            slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                            labelId={
                              CONSTANT_MANAGER.ADD_CONTRACT
                                .PEOPLE_NEEDED
                            }
                          />
                        </div>
                        <input
                          type="text"
                          placeholder="Enter the number"
                          name="noOfPeople"
                          className="form-control rounded-3 h-48"
                          value={formik.values.noOfPeople}
                          onChange={(e) =>
                            onChange(
                              Number(e.target.value),
                              "noOfPeople"
                            )
                          }
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.noOfPeople &&
                          formik.errors.noOfPeople ? (
                          <div className={styles.error}>
                            {formik.errors.noOfPeople}
                          </div>
                        ) : null}
                      </div>
                      <div className={styles.fromGroup} style={{ marginBottom: "30px" }}>
                        {" "}
                        <div className="topTipHover">
                          <label className={styles.starlabel}>
                            Shift
                          </label>{" "}
                          <i>
                            <img
                              className="customeTolImg"
                              src={tip}
                              alt=""
                            />
                          </i>
                          <Toltip
                            slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                            labelId={
                              CONSTANT_MANAGER.ADD_CONTRACT.SHIFT
                            }
                          />
                        </div>
                        <Dropdown>
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              name="shift"
                            >
                              {formik.values.shift || "Select shift"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <div
                                style={{
                                  maxHeight: "300px",
                                  overflowY: "scroll",
                                }}
                              >
                                {shifts.map((shiftData) => (
                                  <Dropdown.Item
                                    key={shiftData}
                                    onClick={() =>
                                      onChange(shiftData, "shift")
                                    }
                                  >
                                    {shiftData}
                                  </Dropdown.Item>
                                ))}
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        {formik.touched.shift && formik.errors.shift ? (
                          <div className={styles.error}>
                            {formik.errors.shift}
                          </div>
                        ) : null}
                        {/* <span>
                              <img src={down} alt="" />
                            </span> */}
                      </div>
                      <div className={styles.fromGroup} style={{ marginBottom: "30px" }}>
                        {" "}
                        <div className="topTipHover">
                          <label className={styles.starlabel}>
                            Planned Hours Per Day
                          </label>{" "}
                          <i>
                            <img
                              className="customeTolImg"
                              src={tip}
                              alt=""
                            />
                          </i>
                          <Toltip
                            slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                            labelId={
                              CONSTANT_MANAGER.ADD_CONTRACT
                                .PLANNED_HOURS_PER_DAY
                            }
                          />
                        </div>
                        <Dropdown>
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              name="shift"
                            >
                              {formik.values.plannedHoursPerDay || "Select planned Hours per day"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <div
                                style={{
                                  maxHeight: "300px",
                                  overflowY: "scroll",
                                }}
                              >
                                {hours.map((hourData) => (
                                  <Dropdown.Item
                                    key={hourData}
                                    onClick={() =>
                                      onChange(hourData, "plannedHoursPerDay")
                                    }
                                  >
                                    {hourData}
                                  </Dropdown.Item>
                                ))}
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        {formik.touched.plannedHoursPerDay &&
                          formik.errors.plannedHoursPerDay ? (
                          <div className={styles.error}>
                            {formik.errors.plannedHoursPerDay}
                          </div>
                        ) : null}
                      </div>

                      <div className={styles.fromGroup} style={{ marginBottom: "30px" }}>
                        {" "}
                        <div className="topTipHover">
                          <label className={styles.starlabel}>
                            Planned Days Per Week
                          </label>{" "}
                          <i>
                            <img
                              className="customeTolImg"
                              src={tip}
                              alt=""
                            />
                          </i>
                          <Toltip
                            slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                            labelId={
                              CONSTANT_MANAGER.ADD_CONTRACT
                                .PLANNED_DAYS_PER_WEEK
                            }
                          />
                        </div>
                        
                          <Dropdown>
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              name="shift"
                            >
                              {formik.values.plannedDaysPerWeek || "Select planned Hours per day"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <div
                                style={{
                                  maxHeight: "300px",
                                  overflowY: "scroll",
                                }}
                              >
                                {days.map((dayData) => (
                                  <Dropdown.Item
                                    key={dayData}
                                    onClick={() =>
                                      onChange(dayData, "plannedDaysPerWeek")
                                    }
                                  >
                                    {dayData}
                                  </Dropdown.Item>
                                ))}
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        {formik.touched.plannedDaysPerWeek &&
                          formik.errors.plannedDaysPerWeek ? (
                          <div className={styles.error}>
                            {formik.errors.plannedDaysPerWeek}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                <div className="row align-items-center justify-content-between">
                  <div className="col-md-4 col-6 text-start my-3 btn-hover-effect">
                    <h6
                      onClick={() => handleGoBack()}
                      className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton"
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold d-inline-block">
                        Back
                      </span>
                      <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                        <img src={blackarrow} alt="" />
                      </i>
                    </h6>
                  </div>

                  <div className=" col-md-4 col-6">
                    <SaveAsDraftButton />
                  </div>

                  <div className="col-md-4 col-12 text-end my-3 btn-hover-effect">
                    <button
                      type="submit"
                      className="btn arrowblue border-grey border-1 position-relative pe-5"
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold d-inline-block">
                        Next
                      </span>
                      <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                        <img src={whitearrow} alt="" />
                      </i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default AddKeyInformation;
