import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../../../../../components/common/css/styles/style.module.css";
import info from "../../../../../../assets/images/info_icon.svg";
import bag from "../../../../../../assets/images/grbag.png";
import loc from "../../../../../../assets/images/icon_location_filled.svg";
import Slider from "react-slick";
import { APP_ROUTES } from "../../../../../../utils/routes/AppRoutes";
import { CONTRACT_Type, PLAN_TYPE } from "../../../../../../utils/enums/types";
import moment from "moment";
import { TYPE, WORK_STATUS_TYPE } from "../../../../../../utils/enums/status";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";
import { WORK_TABS } from "../../../../../../utils/enums/workTabs.enum";
import { getContractList } from "../../../api";
import { ContractResponse } from "../../../interface";
import Toltip from "../../../../toltip/page";
import { getOngoingContractListProfesional } from "../../api";
import OngGoingStandardProfessional from "../OngoingStandardProfessional/OnGoingStandardProfessional";
const OnGoingContractsProfessional = () => {
  const navigate = useNavigate();
  const dashboardData = useSelector((state: RootState) => state.dashboard);
  const { activeTab } = dashboardData;
  const [onGoingContracts, setOngoingContracts] = useState<ContractResponse[]>(
    []
  );

  const [loading, setLoading] = useState<boolean>(true);

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 2,
    centerPadding: "60px",
    slidesToScroll: 2,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1.4,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.02,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchOngoingContracts = async () => {
      setLoading(true);
      try {
        let response: ContractResponse | any;
        if (activeTab === WORK_TABS.DONEFORME) {
          response = await getContractList(
            CONTRACT_Type.HOURLY_CONTRACT,
            TYPE.DONEFORME,
            WORK_STATUS_TYPE.ONGOING
          );
        } else if (activeTab === WORK_TABS.DONEBYME) {
          response = await getOngoingContractListProfesional(
            CONTRACT_Type.HOURLY_CONTRACT,
            WORK_STATUS_TYPE.ONGOING,
            PLAN_TYPE.WHITEGLOVE
          );
        } else {
          response = { data: [] };
        }
        setOngoingContracts(response.data);
      } catch (error) {
        console.error("Error fetching ongoing contracts:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchOngoingContracts();
  }, [activeTab]);

  const handleViewTimesheet = (workdata: ContractResponse) => {
    if (workdata.ownWork) {
      navigate(`${APP_ROUTES.TIMESHEETDETAIL_TABLE}/${workdata?._id}`, {
        state: { workdata },
      });
    } else {
      navigate(`${APP_ROUTES.TIMESHEET_TABLE}/${workdata?._id}`, {
        state: { workdata },
      });
    }
  };

  const handleViewExpense = (workdata: ContractResponse) => {
    if (workdata.ownWork) {
      navigate(`${APP_ROUTES.EXPENSE_STATUS_TABLE}/${workdata?._id}`);
    } else {
      navigate(`${APP_ROUTES.EXPENSE_TABLE}/${workdata?._id}`);
    }
  };

  const handleViewInvoice = (workdata: ContractResponse) => {
    if (workdata.ownWork) {
      navigate(
        `${APP_ROUTES.INVOICE_TABLE_PROFESSIONAL}/${
          workdata?._id
        }?isBusiness=${true}`
      );
    } else {
      navigate(`${APP_ROUTES.INVOICE_TABLE_PROFESSIONAL}/${workdata?._id}`);
    }
  };
  return (
    <div className={`bg-white p-lg-4 p-2 shadow rounded-3 mt-3`}>
      {onGoingContracts?.length > 0 && (
        <div className="Contracts">
          <div className={styles.ContractTabs}>
            <div className={`justify-content-start ${styles.AllContract}`}>
              <div className="col-12">
                <div className="topTipHover w-100">
                  <div className="w-100 d-flex align-items-center">
                    <h4 className="form-check-label text-20 fw-bold mb-0 font-inter">
                      Ongoing Contracts
                    </h4>
                    <i>
                      <img className="customeTolImg" src={info} alt="" />
                    </i>
                    <Toltip />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3">
            {loading ? (
              <div className="spinner-grow text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <div className={`${styles.tabingnav}`}>
                <nav className="bg-lightblue navtabing">
                  <div
                    className="nav nav-tabs px-lg-3 px-1"
                    id="nav-tab"
                    role="tablist"
                  >
                    <button
                      className={`nav-link active px-0`}
                      id="nav-home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-home"
                      type="button"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      White Glove Contracts
                    </button>
                    <button
                      className={`nav-link px-0 ms-3`}
                      id="nav-profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-profile"
                      type="button"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                    >
                      Standard Contracts
                    </button>
                  </div>
                </nav>
                <div
                  className="tab-content py-3 px-2 bg-light"
                  id="nav-tabContent"
                >
                  <div
                    className={`tab-pane fade show active`}
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    {" "}
                    <div className={styles.OuterSlides}>
                      {onGoingContracts?.length > 0 ? (
                        <Slider {...settings}>
                          {onGoingContracts?.length > 0
                            ? onGoingContracts?.map(
                                (data: ContractResponse, idx) => {
                                  return (
                                    <div
                                      key={idx}
                                      className={`mt-0 ${styles.ContarctOuter}`}
                                    >
                                      <div className={styles.ContarctHead}>
                                        <div className="row align-item-center justify-content-between mx-0 w-100 position-relative">
                                          <div className="col-xxl-7 col-8 mb-2">
                                            <div
                                              className={styles.leftContract}
                                            >
                                              <h5>
                                                {data?.occupation?.name
                                                  ? data?.occupation?.name
                                                  : ""}
                                              </h5>
                                              <span>
                                                Start Date:{" "}
                                                {moment(
                                                  data.times.startDate
                                                ).format("MM-DD-YYYY")}{" "}
                                                | End Date: MM-DD-YYYY
                                              </span>
                                            </div>
                                          </div>
                                          <div className="col-xxl-5 col-4 mb-2">
                                            <div
                                              className={`${styles.leftContract}`}
                                              style={{ textAlign: "end" }}
                                            >
                                              <button
                                                className={`position-absolute top-0 ${styles.statusdetails}`}
                                              >
                                                Ongoing
                                              </button>
                                            </div>
                                          </div>
                                          <div className="col-xxl-7 col-lg-12 col-12">
                                            <div
                                              className={styles.leftContract}
                                            >
                                              <div className={styles.Local}>
                                                <div
                                                  className={`justify-content-start ${styles.adrssLoc}`}
                                                >
                                                  <span
                                                    className={`py-2 mb-0 rounded-2 me-2  border-lightgreen bg-lightgreen lh-base`}
                                                  >
                                                    <img
                                                      src={bag}
                                                      alt="White Glove Contract"
                                                    />
                                                    {data.planType ===
                                                    "whiteglove"
                                                      ? "White Glove Contract"
                                                      : data.planType ===
                                                        "standard"
                                                      ? "Standard Contract"
                                                      : ""}
                                                  </span>
                                                  {data?.workLocation ? (
                                                    <span
                                                      className={`py-2 mb-0 rounded-2 bg-white text-blue border-blue lh-base`}
                                                    >
                                                      {data?.workLocation ===
                                                      "Remote"
                                                        ? "Remote Contract"
                                                        : data?.workLocation ===
                                                          "Onsite"
                                                        ? "Onsite Contract"
                                                        : data?.workLocation ===
                                                          "Hybrid"
                                                        ? "Hybrid Contract"
                                                        : "Unknown Location"}
                                                    </span>
                                                  ) : (
                                                    <span className="py-2 mb-0 rounded-2 bg-white text-blue border-blue lh-base">
                                                      No Work Location
                                                    </span>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-xxl-5 col-lg-12 col-12 mt-2 mt-lg-0">
                                            <div
                                              className={styles.leftContract}
                                              style={{ textAlign: "end" }}
                                            >
                                              <button
                                                className={`border-blue bg-blue text-white`}
                                              >
                                                <i className="far fa-copy"></i>{" "}
                                                Copy
                                              </button>
                                              <button
                                                className={`bg-white ${styles.endcontracts}`}
                                              >
                                                End Contract
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className={styles.outerNameDetail}>
                                        <div className="row align-items-center">
                                          <div className="col-md-12">
                                            <div
                                              className={styles.outerNDetail}
                                            >
                                              <div className="row align-items-center">
                                                <div className="col-6">
                                                  <div
                                                    className={styles.PrileImgs}
                                                  >
                                                    <h4 className="fw-medium text-18">
                                                      {" "}
                                                      {data?.ownWork
                                                        ? "Contractor Details"
                                                        : "Professional Details"}
                                                    </h4>
                                                  </div>
                                                </div>
                                                <div className="col-6"></div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-sm-2 col-12 pe-lg-0">
                                            <div className={styles.leftName}>
                                              <div className={styles.PrileImg}>
                                                <span className="text-center w-100">
                                                  <i>
                                                    <img
                                                      src={
                                                        data?.contractor
                                                          ?.profilePicture
                                                          ? data?.contractor
                                                              ?.profilePicture
                                                          : data?.userInfo
                                                              ?.profilePicture
                                                      }
                                                      alt=""
                                                      height={60}
                                                      width={60}
                                                    />
                                                  </i>
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-sm-5 col-6">
                                            <div className={styles.leftName}>
                                              <div className={styles.PrileImg}>
                                                <div className="">
                                                  {data?.ownWork ? (
                                                    <h5 className="clamp clamp1 d-initial">
                                                      {data?.contractor?.name}
                                                    </h5>
                                                  ) : (
                                                    <h5 className="clamp clamp1 d-initial">
                                                      {data?.userInfo?.name}
                                                    </h5>
                                                  )}

                                                  {data?.ownWork ? (
                                                    <h6 className="clamp clamp1 d-initial">
                                                      {
                                                        data?.contractor
                                                          ?.occupation?.name
                                                      }
                                                    </h6>
                                                  ) : (
                                                    <h6 className="clamp clamp1 d-initial">
                                                      {
                                                        data?.userInfo
                                                          ?.occupation?.name
                                                      }
                                                    </h6>
                                                  )}
                                                  {data?.ownWork ? (
                                                    <p className="clamp clamp1 d-initial">
                                                      <i>
                                                        <img src={loc} alt="" />{" "}
                                                      </i>
                                                      {data?.address}
                                                    </p>
                                                  ) : (
                                                    <p className="clamp clamp1">
                                                      {" "}
                                                      <i className="">
                                                        <img src={loc} alt="" />{" "}
                                                      </i>
                                                      {data?.userInfo?.address}{" "}
                                                    </p>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-sm-5 col-6">
                                            <div
                                              className={`ps-2 ${styles.rightName}`}
                                            >
                                              <p>
                                                Top Skill :{" "}
                                                {data?.technicalSkills?.map(
                                                  (item, ind) => (
                                                    <p key={ind}>
                                                      {" "}
                                                      Second Skill :
                                                      {
                                                        item
                                                          ?.manufacturerDetails
                                                          ?.name
                                                      }
                                                    </p>
                                                  )
                                                )}
                                              </p>
                                              <p>Exp : 2.5 Years</p>
                                              <p>Member Since : 2018</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      {data.planType === "whiteglove" && (
                                        <div className={styles.timeSheets}>
                                          <div className="row g-4">
                                            <div className="col-lg-6 col-12">
                                              <div
                                                className={styles.timesheetData}
                                                onClick={() => {
                                                  handleViewTimesheet(data);
                                                }}
                                              >
                                                <h4 className="fw-bold">
                                                  Timesheet
                                                </h4>
                                                <ul>
                                                  <li>
                                                    <span>
                                                      Current Timesheet
                                                    </span>{" "}
                                                    <label>
                                                      {data?.timesheet?.current}
                                                    </label>
                                                  </li>
                                                  <li className={styles.red}>
                                                    <span>Due Timesheet</span>{" "}
                                                    <label>
                                                      {data?.timesheet?.due}
                                                    </label>
                                                  </li>
                                                  <li className={styles.yeloo}>
                                                    <span>
                                                      Pending My Approval
                                                    </span>{" "}
                                                    <label>
                                                      {data?.timesheet?.pending}
                                                    </label>
                                                  </li>
                                                  <li className={styles.gren}>
                                                    <span>Approved</span>{" "}
                                                    <label>
                                                      {
                                                        data?.timesheet
                                                          ?.approved
                                                      }
                                                    </label>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                              <div
                                                className={styles.timesheetData}
                                                onClick={() => {
                                                  handleViewExpense(data);
                                                }}
                                              >
                                                <h4 className="fw-bold">
                                                  Expense Report
                                                </h4>
                                                <ul>
                                                  <li>
                                                    <span>
                                                      Current Expense Report
                                                    </span>{" "}
                                                    <label>
                                                      {data?.expense?.current}
                                                    </label>
                                                  </li>
                                                  <li className={styles.red}>
                                                    <span>
                                                      Due Expense Report
                                                    </span>{" "}
                                                    <label>
                                                      {data?.expense?.due}
                                                    </label>
                                                  </li>
                                                  <li className={styles.yeloo}>
                                                    <span>
                                                      Pending My Approval
                                                    </span>{" "}
                                                    <label>
                                                      {data?.expense?.pending}
                                                    </label>
                                                  </li>
                                                  <li className={styles.gren}>
                                                    <span>
                                                      Approved Expense Reports
                                                    </span>{" "}
                                                    <label>
                                                      {data?.expense?.approved}
                                                    </label>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                              <div
                                                className={styles.timesheetData}
                                                onClick={() => {
                                                  handleViewInvoice(data);
                                                }}
                                              >
                                                <h4 className="fw-bold">
                                                  Invoices
                                                </h4>
                                                <ul>
                                                  {" "}
                                                  <li>
                                                    <span>
                                                      Current Timesheet
                                                    </span>{" "}
                                                    <label>{}</label>
                                                  </li>
                                                  <li className={styles.red}>
                                                    <span>Due </span>{" "}
                                                    <label>
                                                      {data?.invoice?.due}
                                                    </label>
                                                  </li>
                                                  <li className={styles.yeloo}>
                                                    <span>Past Due</span>{" "}
                                                    <label>
                                                      {data?.invoice?.pastDue}
                                                    </label>
                                                  </li>
                                                  <li className={styles.gren}>
                                                    <span>Paid</span>{" "}
                                                    <label>
                                                      {data?.invoice?.isPaid}
                                                    </label>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-12"></div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  );
                                }
                              )
                            : ""}
                        </Slider>
                      ) : (
                        <div
                          style={{
                            background: "#2880DA1A",
                            // background: "#0053CD",
                            height: 40,
                            width: 40,
                            borderRadius: 10,
                            fontSize: 23,
                            fontWeight: "bold",
                          }}
                          className="d-flex justify-content-center align-items-center"
                        >
                          0
                        </div>
                      )}
                    </div>
                  </div>
                  {/* ongoing standar here */}
                  <OngGoingStandardProfessional />
                </div>
              </div>
            )}
          </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OnGoingContractsProfessional;
