import React, { FC } from 'react';
import { useAppSelector } from '../../redux/hooks'; 
import { RootState } from '../../redux/store';
import AddDescription from './components/AddDescription';
import UploadImages from './components/UploadImages';
import { ADD_SOCIAL_POST } from '../../shared/enums'; 

const AddSocialPost: FC = () => {
  const addSocialPost = useAppSelector((state: RootState) => state.socialPost);
  const { activeStep } = addSocialPost;

  const renderActiveStep = () => {
    switch (activeStep) {
      case ADD_SOCIAL_POST.SOCIAL_POST_DESC:
        return <AddDescription />;
      case ADD_SOCIAL_POST.UPLOAD_IMAGE:
        return <UploadImages />;
      default:
        return <AddDescription />;
    }
  };

  return renderActiveStep();
};

export default AddSocialPost;
