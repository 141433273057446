import React, { useState } from "react";
import styles from "../../common/css/styles/style.module.css";
import loc from "../../../assets/images/icon_location_filled.svg";
import bags2 from "../../../assets/images/bags.png";

import blackarrow from "../../../assets/images/blackarrow.svg";
import share from "../../../assets/images/share_profile.svg";
import manufacture from "../../../assets/images/manufacture.svg";
import modal from "../../../assets/images/modal.svg";
import admin from "../../../assets/images/admin_icon.svg";
import calls from "../../../assets/images/calls.svg";
import email from "../../../assets/images/email.svg";
import startdate from "../../../assets/images/startdate.svg";
import peopleneeded from "../../../assets/images/peopleneeded.svg";
import duration from "../../../assets/images/duration.svg";
import fullcontractimg from "../../../assets/images/fullcontractimg_1.jpg";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../utils/routes/AppRoutes";
import back from "../../../assets/images/left.png";
import { JobResponse } from "../interface";
import EligibiltyModal from "../../common/modals/eligibilty/page";
import { setWorkType } from "../../../redux/reducers/workType";
import { useDispatch, UseDispatch } from "react-redux";
interface FullContractProps {
  onBack: () => void; // onBack is a function that returns void
  workDetails: JobResponse | null;
  user: JobResponse | null;
}

const FullContract: React.FC<FullContractProps> = ({
  onBack,
  workDetails,
  user,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [workId, setWorkId] = useState("");
  const [workTypeVal, setWorkTypeVal] = useState<any>("");

  console.log("work details getting", workDetails);
  console.log("user", user);
  

  const handleShowModal = (value: any) => {
    setWorkTypeVal(user?.workType);
    if (
      value.eligibilityQuestions?.length > 0 ||
      value.interviewQuestions?.length > 0
    ) {
      setShowModal(true);
      setWorkId(value._id);
    } else {
      navigate(`${APP_ROUTES.JOBDETAIL}/${value._id}`);
    }
    dispatch(setWorkType(workDetails?.workType));
  };

  return (
    <>
      {showModal && (
        <EligibiltyModal onClose={() => setShowModal(false)} workId={workId} />
      )}
      <div>
        {/* <img src={back} alt="backButton" onClick={onBack}/> */}
        <h1>Contract</h1>
        <button
          className={`btn d-flex align-items-center w-auto px-3 ${styles.commonbtn} ${styles.sharebtn}`}
          onClick={() => onBack()}
        >
          <i className="me-2">
            <img
              src={blackarrow}
              alt=""
              style={{ transform: "rotate(180deg)" }}
            />
          </i>{" "}
          Back to User List
        </button>
      </div>
      <div className="col-lg-12">
        <div className={styles.RightOpportunity}>
          <div className={styles.ContarctOuter}>
            <div className={styles.opprBox}>
              <div
                className={`row align-items-start ${styles.PendingCOnatctList}`}
              >
                <div className="col-8">
                  <h5 className="fw-400 text-14">
                    Reference No. : #{workDetails?.workNumber}
                  </h5>
                  <h6 className={`text-capitalize ${styles.name_robo}`}>
                    {workDetails?.occupation?.name}
                    <span className={styles.boosted}>boosted</span>
                  </h6>
                  {/* <span className={styles.Hrrate}>planType</span> */}
                  <div className="row align-items-center gap-3 mt-3 mx-0">
                    <span className={`w-auto mb-3 ${styles.Hrrate}`}>
                      {" "}
                      <i className="me-2">
                        <img src={bags2} alt="" />
                      </i>
                      {workDetails?.planType === "whiteglove"
                        ? "White Glove Contract"
                        : ""}
                    </span>
                    {workDetails?.workEnvironment?.length ? (
                      <span
                        className={`btn mb-3 w-auto h-auto px-3 ${styles.commonbtn} ${styles.sharebtn}`}
                      >
                        {workDetails?.workEnvironment}
                      </span>
                    ) : (
                      ""
                    )}
                    <span
                      className={`btn mb-3 w-auto h-auto px-3 ${styles.commonbtn} ${styles.applybtn}`}
                    >
                      {" "}
                      {workDetails?.workType}
                    </span>

                    {workDetails?.workLocation ? (
                      <span
                        className={`btn mb-3 w-auto h-auto px-3 ${styles.commonbtn}`}
                      >
                        {workDetails?.workLocation === "Onsite"
                          ? "Onsite Contract"
                          : workDetails?.workLocation === "Remote"
                          ? "Remote Contract"
                          : workDetails?.workLocation === "Hybrid"
                          ? "Hybrid Contract"
                          : ""}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-4">
                  <div className="row align-items-center">
                    <div className="col-6">
                      <button
                        type="button"
                        className={`btn w-100 px-3 ${styles.commonbtn} ${styles.applybtn}`}
                        onClick={() => handleShowModal(workDetails)}
                      >
                        Apply now ›
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        type="button"
                        className={`btn d-flex align-items-center w-100 px-3 ${styles.commonbtn} ${styles.sharebtn}`}
                      >
                        <i className="d-inline-flex w-auto ps-0">
                          <img src={share} alt="" />
                        </i>
                        Share
                      </button>
                    </div>
                  </div>
                </div>

                <div className={styles.Loca}>
                  <p className={styles.Locate}>
                    <img src={loc} alt="" /> {workDetails?.address}
                  </p>
                </div>
                <div
                  className={`${styles.PenigSkilRate} ${styles.fullcontractSkilRate}`}
                >
                  <ul className="row align-items-start justify-content-start gap-0">
                    {/* <li className="col-4 me-0">
                      <div className="row align-items-start mb-3">
                        <div className="col-2 text-center ps-0">
                          <i>
                            <img
                              src={skill}
                              alt=""
                              className="img-fluid w-100"
                              width={15}
                              height={15}
                            />
                          </i>
                        </div>
                        <div className="col-10 ps-0">
                          <h5 className="mb-0 fw-bold text-14">Top Skill</h5>
                          {workDetails?.technicalSkills?.map((item, ind) => (
                            <p key={ind} className="text-14 mb-0">{item.manufacturerIds}</p>
                          ))}
                        </div>
                      </div>
                    </li> */}
                    <li className="col-4 me-0">
                      <div className="row align-items-start mb-3">
                        <div className="col-2 text-center ps-0">
                          <i>
                            <img
                              src={manufacture}
                              alt=""
                              className="img-fluid w-75"
                              width={15}
                              height={15}
                            />
                          </i>
                        </div>
                        <div className="col-10 ps-0">
                          <h5 className="mb-0">MANUFACTURER</h5>
                          {workDetails?.technicalSkills?.map((item, ind) => (
                            <p key={ind} className="text-14 mb-0">
                              {item?.manufacturerDetails?.name}
                            </p>
                          ))}
                        </div>
                      </div>
                    </li>
                    <li className="col-4 me-0">
                      <div className="row align-items-start mb-3">
                        <div className="col-2 text-center ps-0">
                          <i>
                            <img
                              src={modal}
                              alt=""
                              className="img-fluid w-75"
                              width={15}
                              height={15}
                            />
                          </i>
                        </div>
                        <div className="col-10 ps-0">
                          <h5 className="mb-0">Model/Version/ Controller</h5>
                          {workDetails?.technicalSkills?.map((item, ind) => (
                            <p key={ind} className="text-14 mb-0">
                              {item?.manufacturerModelDetails?.name}
                            </p>
                          ))}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* <div className={`${styles.PenigSkilRats}`}>
                  <ul className="row align-items-start justify-conetnt-start gap-0 mt-2">
                    <li className="col-2 me-0">
                      <div className="row align-items-start mb-3">
                        <div className="col-10 text-center ps-0">
                          <i>
                            <img
                              src={fullcontractimg}
                              alt=""
                              className="img-fluid w-100"
                            />
                          </i>
                        </div>
                      </div>
                    </li>
                    <li className="col-2 me-0">
                      <div className="row align-items-start mb-3">
                        <div className="col-10 text-center ps-0">
                          <i>
                            <img
                              src={fullcontractimg}
                              alt=""
                              className="img-fluid w-100"
                            />
                          </i>
                        </div>
                      </div>
                    </li>
                    <li className="col-2 me-0">
                      <div className="row align-items-start mb-3">
                        <div className="col-10 text-center ps-0">
                          <i>
                            <img
                              src={fullcontractimg}
                              alt=""
                              className="img-fluid w-100"
                            />
                          </i>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div> */}
                <div className={styles.PenigSkilRate}>
                  <h5 className="mb-2">Pay Rates</h5>
                  <ul>
                    <li>
                      <span>Base</span>
                      <label>${workDetails?.rates?.baseRate}</label>
                    </li>
                    <li>
                      <span>Overtime</span>
                      <label>${workDetails?.rates?.overTimeRate}</label>
                    </li>
                    <li>
                      <span>Sun/Holidays</span>
                      <label>${workDetails?.rates?.doubleTimeRate}</label>
                    </li>
                  </ul>
                </div>

                <div className="col-md-12">
                  <div className={styles.bordeerBottom}></div>
                  <h5 className={styles.payrate}>Travel & Expenses </h5>
                  <div className={` ${styles.trevelexpense}`}>
                    <div className="col-12">
                      <div className="row border-bottom pb-3 mb-3">
                        {/* <div className="col-lg-2 col-sm-6">
                          <div
                            className={styles.datesFlex2}
                            style={{ borderRight: "1px solid #eee" }}
                          >
                            <h6>Daily Per Diam </h6>
                            <p className={styles.dollar}>
                              ${workDetails?.expense?.dailyPerDiem}
                            </p>
                          </div>
                        </div> */}

                        <div className="col-lg-3 col-sm-6">
                          <div
                            className={styles.datesFlex2}
                            style={{ borderRight: "1px solid #eee" }}
                          >
                            <h6>Number of Allowed Trips (Per Month)</h6>
                            <p className={`mb-0 ${styles.dollar}`}>
                              {workDetails?.travels?.tipAllowed}
                            </p>
                            <h6>Receipt are required</h6>
                          </div>
                        </div>

                        <div className="col-lg-3 col-sm-6">
                          <div
                            className={styles.datesFlex2}
                            style={{ borderRight: "1px solid #eee" }}
                          >
                            <h6>Travel Hours Per Round Trip</h6>
                            <p className={`mb-0 ${styles.dollar}`}>
                              {workDetails?.travels?.perRoundTrip}
                            </p>
                            <h6>Receipt are required</h6>
                          </div>
                        </div>
                        <div className="col-lg-2 col-sm-6">
                          <div
                            className={styles.datesFlex2}
                            style={{ borderRight: "1px solid #eee" }}
                          >
                            <h6>Travel Time Rate</h6>
                            <p className={`mb-0 ${styles.dollar}`}>7</p>
                            <h6>Receipt are required</h6>
                          </div>
                        </div>
                        {/* <div className="col-lg-2 col-sm-6">
                          <div className={styles.datesFlex2}>
                            <h6>Travel Day Per Diem</h6>
                            <p className={`mb-0 ${styles.dollar}`}>7</p>
                            <h6>Receipt are required</h6>
                          </div>
                        </div> */}
                      </div>
                      {/* <div className="row pb-3 mb-3">
                        <div className="col-lg-2 col-sm-6">
                          <div
                            className={styles.datesFlex2}
                            style={{ borderRight: "1px solid #eee" }}
                          >
                            <h6>Hotel Allowance Per Day</h6>
                            <p className={styles.dollar}>$20</p>
                          </div>
                        </div>

                        <div className="col-lg-3 col-sm-6">
                          <div
                            className={styles.datesFlex2}
                            style={{ borderRight: "1px solid #eee" }}
                          >
                            <h6>Rental Car Allowance</h6>
                            <p className={`mb-0 ${styles.dollar}`}>7</p>
                            <h6>Receipt are required</h6>
                          </div>
                        </div>

                        <div className="col-lg-3 col-sm-6">
                          <div
                            className={styles.datesFlex2}
                            style={{ borderRight: "1px solid #eee" }}
                          >
                            <h6>Airfare Allowance</h6>
                            <p className={`mb-0 ${styles.dollar}`}>7</p>
                            <h6>Receipt are required</h6>
                          </div>
                        </div>
                        <div className="col-lg-2 col-sm-6">
                          <div
                            className={styles.datesFlex2}
                            style={{ borderRight: "1px solid #eee" }}
                          >
                            <h6>Mileage Allowance</h6>
                            <p className={`mb-0 ${styles.dollar}`}>7</p>
                            <h6>Receipt are required</h6>
                          </div>
                        </div>
                        <div className="col-lg-2 col-sm-6">
                          <div className={styles.datesFlex2}>
                            <h6>Luggage Fees Allowance</h6>
                            <p className={`mb-0 ${styles.dollar}`}>7</p>
                            <h6>Receipt are required</h6>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>

                {/* <div className="col-md-12">
                  <div className={styles.bordeerBottom}></div>
                  <h5 className={styles.payrate}>
                    Tools, Machines and equipment
                  </h5>
                  <div className={styles.PenigSkil}>
                    <ul>
                      <li>Lorem Ipsum</li>
                      <li>Figma</li>
                      <li>Adobe</li>
                    </ul>
                  </div>
                </div> */}

                <div className={styles.bordeerBottom}></div>
                <h5 className={styles.payrate}>SCHEDULE</h5>
                <div className="row">
                  <div className="col-lg-7 col-sm-12">
                    <div className="row">
                      <div className="col-lg-6 col-sm-6">
                        <div className={styles.datesFlex}>
                          <h6>Start date </h6>
                          <p className="mb-0">
                            {workDetails?.times?.startDate}
                          </p>
                          <i className="me-2">
                            {" "}
                            <img src={startdate} alt="" width={15} />
                          </i>
                        </div>
                      </div>

                      <div className="col-lg-6 col-sm-6">
                        <div className={styles.datesFlex}>
                          <h6>Duration</h6>
                          <p className="mb-0">{workDetails?.times?.duration}</p>
                          <i className="me-2">
                            {" "}
                            <img src={startdate} alt="" width={15} />
                          </i>
                        </div>
                      </div>

                      <div className="col-lg-6 col-sm-6">
                        <div className={styles.datesFlex}>
                          <h6>People Needed </h6>
                          <p className="mb-0">
                            {workDetails?.times?.noOfPepole}
                          </p>
                          <i className="me-2">
                            {" "}
                            <img src={peopleneeded} alt="" width={15} />
                          </i>
                        </div>
                      </div>

                      <div className="col-lg-6 col-sm-6">
                        <div className={styles.datesFlex}>
                          <h6>Day Shift </h6>
                          <p className="mb-0">{workDetails?.times?.shift}</p>
                          <i className="me-2">
                            {" "}
                            <img src={duration} alt="" width={15} />
                          </i>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-6">
                        <div className={styles.datesFlex}>
                          <h6>Planned Hrs Per Day </h6>
                          <p className="mb-0">plannedHoursPerDay</p>
                          <i className="me-2">
                            {" "}
                            <img src={startdate} alt="" width={15} />
                          </i>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-6">
                        <div className={styles.datesFlex}>
                          <h6>Planned Hrs Per Week </h6>
                          <p>{workDetails?.times?.plannedDaysPerWeek}</p>
                          <i className="me-2">
                            {" "}
                            <img src={duration} alt="" width={15} />
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.bordeerBottom}></div>
                  <div
                    className={styles.PenigSkil}
                    style={{ marginTop: "15px" }}
                  >
                    <h5>Description</h5>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: workDetails?.description
                          ? workDetails?.description
                          : "",
                      }}
                    />
                  </div>
                  <div className={styles.bordeerBottom}></div>
                  <div className="col-md-12">
                    <h5 className={styles.payrate}>Eligilibility Questions</h5>
                    <div>
                      {workDetails?.eligibilityQuestions?.length ? (
                        <ul>
                          {workDetails.eligibilityQuestions?.map(
                            (item, ind) => (
                              <li key={ind}>
                                <div>
                                  <h6>
                                    Question {ind + 1}: {item.question}
                                  </h6>{" "}
                                  <p className="text-14">Note: Yes/No Ans</p>
                                </div>
                              </li>
                            )
                          )}
                        </ul>
                      ) : (
                        <p>No eligibility questions available.</p>
                      )}
                    </div>
                  </div>
                  <div className={styles.bordeerBottom}></div>

                  <div className="col-md-12">
                    <h5 className={styles.payrate}>Interview Questions</h5>
                    <div>
                      {workDetails?.interviewQuestions?.length ? (
                        <ul>
                          {workDetails?.interviewQuestions?.map((item, ind) => (
                            <li key={ind}>
                              <div>
                                <h6>
                                  Question {ind + 1}: {item.question}
                                </h6>{" "}
                                <p className="text-14">Note: Yes/No Ans</p>
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No interview questions available.</p>
                      )}
                    </div>
                  </div>
                  {/* <div className={styles.bordeerBottom}></div>

                  <div className="col-md-12 ">
                    <h5 className={styles.payrate}>on site customer contact</h5>
                    <div>
                      <h6 className="mb-0 mt-3">Primary Contact</h6>
                      <ul className="row algign-items-center">
                        <li className="col-2">
                          <p className="text-14 d-flex align-items-center">
                            <i className="me-2">
                              <img src={admin} alt="" />
                            </i>
                            {workDetails?.primaryContact?.name}
                          </p>
                        </li>
                        <li className="col-2">
                          <p className="text-14 d-flex align-items-center">
                            <i className="me-2">
                              <img src={calls} alt="" />
                            </i>
                            {workDetails?.primaryContact?.mobileNumber}
                          </p>
                        </li>
                        <li className="col-2">
                          <p className="text-14 d-flex align-items-center">
                            <i className="me-2">
                              <img src={email} alt="" />
                            </i>
                            {workDetails?.primaryContact?.email}
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>

                  {workDetails?.secondaryContact?.name === "undefined" &&
                  workDetails?.secondaryContact?.email === "undefined" &&
                  workDetails?.secondaryContact?.mobileNumber ===
                    "undefined" ? null : (
                    <div className="col-md-12">
                      <div>
                        <h6 className="mb-0">Secondary Contact</h6>
                        {workDetails?.secondaryContact ? (
                          <ul className="row align-items-center">
                            {workDetails?.secondaryContact?.name ===
                            "undefined" ? null : (
                              <li className="col-2">
                                <p className="text-14 d-flex align-items-center">
                                  <i className="me-2">
                                    <img src={admin} alt="Admin Icon" />
                                  </i>
                                  {workDetails?.secondaryContact?.name}
                                </p>
                              </li>
                            )}

                            {workDetails?.secondaryContact?.mobileNumber ===
                            "undefined" ? null : (
                              <li className="col-2">
                                <p className="text-14 d-flex align-items-center">
                                  <i className="me-2">
                                    <img src={calls} alt="Phone Icon" />
                                  </i>
                                  {workDetails?.secondaryContact?.mobileNumber}
                                </p>
                              </li>
                            )}

                            {workDetails?.secondaryContact?.email ===
                            "undefined" ? null : (
                              <li className="col-2">
                                <p className="text-14 d-flex align-items-center">
                                  <i className="me-2">
                                    <img src={email} alt="Email Icon" />
                                  </i>
                                  {workDetails?.secondaryContact?.email}
                                </p>
                              </li>
                            )}
                          </ul>
                        ) : null}{" "}
                      </div>
                    </div>
                  )} */}

                  <div className="col-md-12">
                    <div className={styles.bordeerBottom}></div>
                    <div className={styles.flexbtns}>
                      <button
                        type="button"
                        className={`btn ${styles.commonbtn} ${styles.applybtn}`}
                        onClick={() => handleShowModal(workDetails)}
                      >
                        Apply now ›
                      </button>
                      <button
                        type="button"
                        className={`btn ${styles.commonbtn} ${styles.sharebtn}`}
                      >
                        Share{" "}
                      </button>
                    </div>
                  </div>
                </div>

              </div>
               
            </div>
          </div>
        </div>
     
      </div>
    </>
  );
};

export default FullContract;
