import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";

const Room = () => {
    const { roomID } = useParams();
    const [meetingLink, setMeetingLink] = useState<string | null>(null);

    const generateMeetingLink = () => {
        if (!roomID) {
            alert("Invalid Room ID");
            return;
        }
        const appID = 479881264;
        const serverSecret = 'a26fce860e81b199eba205e801fb7a32';
        const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
            appID,
            serverSecret,
            roomID,
            Date.now().toString(), // You can use a unique user ID here
            "abhi" // Your username or any identifier
        );

        const link = `https://dev-user.automateamerica.com/room/${roomID}`;
        setMeetingLink(link);

        alert(`Meeting link generated: ${link}`);
    };

    const myMeeting = async (element: any) => {
        if (!roomID) {
            alert("Invalid Room ID");
            return;
        }

        const appID = 479881264;
        const serverSecret = 'a26fce860e81b199eba205e801fb7a32';
        const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
            appID,
            serverSecret,
            roomID,
            Date.now().toString(),
            "abhi"
        );

        const zc = ZegoUIKitPrebuilt.create(kitToken);
        zc.joinRoom({
            container: element,
            sharedLinks: [
                {
                    name: 'Copy Link',
                    url: meetingLink || ` https://dev-user.automateamerica.com/room/${roomID}`,
                }
            ],
            scenario: {
                mode: ZegoUIKitPrebuilt.OneONoneCall,
            },
            showScreenSharingButton: true,
        });
    };

    return (
        <div>
            <button onClick={generateMeetingLink}>Generate Meeting Link.</button>
            {meetingLink && (
                <div>
                    <p>Meeting Link: <a href={meetingLink} target="_blank" rel="noopener noreferrer">{meetingLink}</a></p>
                </div>
            )}
            <div ref={myMeeting} />
        </div>
    );
};

export default Room;
