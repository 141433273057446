import React, { useEffect, useState } from "react";
import styles from "./styles/style.module.css";
import Header from "../../../header/routes";
import loc from "../../../../assets/images/icon_location_filled.svg";
import gprs from "../../../../assets/images/gprs.png";
import blsearch from "../../../../assets/images/blsearch.png";
import view from "../../../../assets/images/view.png";
import { getSpecificWork } from "../../api";
import { useParams } from "react-router";

import ContractData from "../../../common/Contracts/contractDetails/fullDetailsContract";
import { ContractResponse } from "../../../common/Contracts/interface";
import { APP_ROUTES } from "../../../../utils/routes/AppRoutes";
import { useLocation, useNavigate } from "react-router-dom";
import EligibiltyModal from "../../../common/modals/eligibilty/page";


const JobDetails = () => {
    const [isListening, setIsListening] = useState(false);
  const location = useLocation();
  const { isApplied } = location.state || {};
  const { id } = useParams();
  const navigate = useNavigate();
  const [contractDetails, setContractDetails] = useState<any>()
  const [showModal, setShowModal] = useState<boolean>(false);
  const [workId, setWorkId] = useState("");
  const [loading, setLoading] = useState(true)

  const getContractDetails = async () => {
    const response: any = await getSpecificWork(id as string);
    setContractDetails(response?.data);
    setLoading(false)
  }

  useEffect(() => {
    getContractDetails()
  }, []);

  const handleApply = async (value: ContractResponse) => {
    if (value.eligibilityQuestions.length > 0 || value.interviewQuestions.length > 0) {
      setShowModal(true);
      setWorkId(value._id);
    }
    else {
      navigate(`${APP_ROUTES.JOBDETAIL}/${value._id}`);
    }
  }

  return (
    <>
      <Header />
      {showModal && (
        <EligibiltyModal onClose={() => setShowModal(false)} workId={workId} />
      )}
      <div className={styles.FondOpportuniy}>
        <div className="container">
        <div className={styles.SearchData}>
            <div className={`${styles.FormGroup}`}>
              <span className="SeachIc me-2" style={{width: '20px'}}>
                <img src={blsearch} alt="" />
              </span>
              <input
                type="search"
                className="w-100"
                placeholder="Search by: Job title, Position, Keyword or People..."
           
              />
              <i className="btn bg-lightblue">
              {/* <FontAwesomeIcon
                icon={isListening ? faMicrophoneLines : faMicrophone}
                color={isListening ? "green" : "text-primary"}
                size="1x"
                onClick={toggleMic}
                style={{ cursor: "pointer" }}
              /> */}
              </i>
              <span>{isListening ? "Listening..." : ""}</span>
            </div>
            <div></div>
            {/* <div>
              <div>
                <button onClick={startListening} disabled={listening}>
                  🎤 Start
                </button>
                <button onClick={stopListening} disabled={!listening}>
                  🎤 Stop
                </button>
                {listening && <p>Listening...</p>}
              </div>
            </div> */}

            <div className={`${styles.FormGroup}`}>
              <span className="SeachIc me-2" style={{width: '20px'}}>
                <img src={loc} alt="" />
              </span>
              <input 
              type=" text"  
              // value={locationSearch} 
              // onChange={(event) => setLocationSearch(event.target.value)} 
              placeholder="City, state or zip code" 
              />
              <span className={styles.SeachIcgps}>
                <img src={gprs} alt="" />
              </span>
            </div>
            <div className={`${styles.FormGroup}`} style={{width: "10%"}}>
              <button
                className="btn btn-primary text-white">
                  Search
              </button>
              {/* <div className={styles.textwhite}>
              <input
                type="search"
               
                placeholder="Search"
               
                className="btn btn-primary text-white"
              />
              </div> */}
            </div>
          </div>
          {/* <div className={styles.SearchData}>
            <div className={styles.FormGroup}>
              <span className="SeachIc" style={{width: '20px'}}>
                <img src={blsearch} alt="" />
              </span>
              <input
                type=" text"
                placeholder="Search by: Job tittle, Position, Keyword or People..."
              />
            </div>
            <div className={styles.FormGroup}>
              <span className="SeachIc" style={{width: '20px'}}>
                <img src={loc} alt="" />
              </span>
              <input type=" text" placeholder="City, state or zip code" />
              <span className={styles.SeachIcgps}>
                <img src={gprs} alt="" />
              </span>
            </div>
            <div className={styles.FormGroup}>
              <input type="submit" value="Search" />
            </div>
          </div> */}
          <div className={styles.FilerData}>
            <ul>
              <li>
                <span>People</span>
              </li>
              <li>
                <span>Hourly Contracts</span>
              </li>
              <li>
                <span>Direct Jobs</span>
              </li>
              <li>
                <span>RFQ&rsquo;s</span>
              </li>
              <li>
                <button>
                  <i className="me-2"><img src={view} alt="" /></i>
                  <span className="bg-transparent p-0 border-0 h-auto me-0 text-14">List View</span>
                </button>
              </li>
            </ul>
          </div>


        </div>
      </div>

      {!loading ?
        <div className="col-12 mx-auto">
          <div className='container'>
            <div className={`bg-white py-3 ${styles.ContarctOuter}`}>
              <div className={styles.opprBox}>
                <div className={styles.jobDetails}>
                  <ContractData />
                  <div className={styles.ApplyBtn}>
                    {isApplied ?
                      <button className="cmbtn" disabled >Applied</button>
                      :
                      <button className="cmbtn" onClick={() => handleApply(contractDetails)} >Apply Now</button>
                    }
                    <button className="cmbtntwo">Share</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> :
        <>
          <div className={`text-center ${styles.spinner}`}>
            <div className="spinner-grow text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </>
      }
    </>
  );
}

export default JobDetails;
