import React, { useState } from "react";
import { ModalBody, ModalFooter, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import security from "../../../../assets/images/security.svg";
import blackarrow from "../../../../assets/images/blackarrow.svg";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import styles from "../styles/style.module.css";
import { PayRateInterface } from "../../interface";
import { updateUserProfile } from "../../../home/api";
import { UpdateProfileParams } from "../../../home/interface";
import { toast } from "react-toastify";

const EditFinancialInfoModal: React.FC<PayRateInterface> = ({
  showModal,
  closeModal,
  title,
  rates,
  refresh,
}) => {
  const [baseRate, setBaseRate] = useState<number | undefined>(rates?.baseRate);
  const [overtimeRate, setOvertimeRate] = useState<number | undefined>(rates?.overTimeRate);
  const [doubleTimeRate, setDoubletimeRate] = useState<number | undefined>(rates?.doubleTimeRate);
  const [flatRate, setFlatRate] = useState<number | undefined>(rates?.flatRate);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleUpdateRates = async () => {
    // Reset errors
    setErrors({});
    setIsSubmitting(true);

    // Validation checks
    const newErrors: { baseRate?: string; overtimeRate?: string; doubleTimeRate?: string; flatRate?: string } = {};
    
    if (baseRate === undefined || isNaN(baseRate)) {
      newErrors.baseRate = "Please enter a valid Base Rate.";
    }
    if (overtimeRate === undefined || isNaN(overtimeRate)) {
      newErrors.overtimeRate = "Please enter a valid Overtime Rate.";
    }
    if (doubleTimeRate === undefined || isNaN(doubleTimeRate)) {
      newErrors.doubleTimeRate = "Please enter a valid Sunday and Holiday Rate.";
    }
    if (flatRate === undefined || isNaN(flatRate)) {
      newErrors.flatRate = "Please enter a valid Flat Rate.";
    }

    // If there are errors, set them and return
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const payload: UpdateProfileParams = {
      rates: {
        baseRate: baseRate ?? 0,  // Defaulting to 0 if undefined
        overTimeRate: overtimeRate ?? 0,
        doubleTimeRate: doubleTimeRate ?? 0,
        flatRate: flatRate ?? 0,
      },
    };

    try {
      await updateUserProfile(payload);
      refresh();
      setIsSubmitting(false);
      toast.success("Updated Successfully")
    } catch (error) {
      console.log("Error occurring while updating the rates", error);
    }
  };

  return (
    <Modal show={showModal} onHide={closeModal} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <ModalBody>
        <div className="addModalPro">
          <form>
            <div className="row">
              <div className="col-12 mb-3">
                <div className="bg-lightblue row align-items-center justify-content-center p-3 mx-0 rounded-2">
                  <div className="col-1 text-center">
                    <i>
                      <img src={security} alt="" className="img-fluid" />
                    </i>
                  </div>
                  <div className="col-11">
                    <p className="text-14 fw-medium text-black mb-0">
                      Please provide your financial information securely to
                      facilitate payments. Your security is extremely important
                      to us, and only Automate America Financial Administration
                      will have access to this information.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Base Rate (0-40 hours)</label>
                  <div>
                    <input
                      type="text"
                      value={baseRate}
                      onChange={(e) => setBaseRate(Number(e.target.value))}
                      className="form-control"
                      placeholder="$"
                    />
                    {errors.baseRate && <div className="text-danger">{errors.baseRate}</div>}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Overtime Rate (41+ hours)</label>
                  <div>
                    <input
                      type="text"
                      value={overtimeRate}
                      onChange={(e) => setOvertimeRate(Number(e.target.value))}
                      className="form-control"
                      placeholder="$"
                    />
                    {errors.overtimeRate && <div className="text-danger">{errors.overtimeRate}</div>}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Sunday and Holiday Rate</label>
                  <div>
                    <input
                      type="text"
                      value={doubleTimeRate}
                      onChange={(e) => setDoubletimeRate(Number(e.target.value))}
                      className="form-control"
                      placeholder="$"
                    />
                    {errors.doubleTimeRate && <div className="text-danger">{errors.doubleTimeRate}</div>}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Flat Rate (All-inclusive, no overtime)</label>
                  <div>
                    <input
                      type="text"
                      value={flatRate}
                      onChange={(e) => setFlatRate(Number(e.target.value))}
                      className="form-control"
                      placeholder="$"
                    />
                    {errors.flatRate && <div className="text-danger">{errors.flatRate}</div>}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </ModalBody>
      <ModalFooter className="btn-hover-effect">
        <Button
          type="button"
          variant="secondary"
          onClick={closeModal}
          className="btn bg-white arrowyellow border border-1 rounded-3 position-relative ps-5 backbutton w-auto"
        >
          <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
            Back
          </span>
          <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
            <img
              src={blackarrow}
              alt=""
              width={15}
              height={15}
              style={{ transform: "rotate(-180deg)" }}
            />
          </i>
        </Button>
        <Button
          type="button"
          variant="secondary"
          onClick={handleUpdateRates}
          className="btn bg-white arrowblue border border-1 rounded-3 position-relative pe-5 w-auto"
        >
      <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
            {isSubmitting ? (
              <Spinner animation="border" size="sm" className="me-2" />
            ) : (
              "Save"
            )}
          </span>
          <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
            <img src={whitearrow} alt="" width={15} height={15} />
          </i>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditFinancialInfoModal;
