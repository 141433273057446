import React, { useEffect, useState } from 'react'
import top_skill from "../../../assets/images/top_skill.svg";
import profilepic from "../../../assets/images/profile_picture.png";
import location from "../../../assets/images/location_icon.svg";
import shareprofile from "../../../assets/images/share_profile.svg";
import aeroplane from "../../../assets/images/airoplane.svg";
import flag from "../../../assets/images/flag.svg";
import star from "../../../assets/images/star.svg";
import styles from "../../Feeds/styles/style.module.css";
import { userProfileResponse } from '../interface';
import { getBoostedProfessional } from '../api';
import dummy from '../../../assets/images/dummy.jpeg'

const BoostedProfessional = () => {

    const [boostedProfessionals, setBoostedProfessionals] = useState<userProfileResponse[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    useEffect(() => {
        const fetchProfessionals = async () => {
            try {
                setLoading(true);
                const response = await getBoostedProfessional()
                setBoostedProfessionals(Array.isArray(response.data) ? response.data : []);
                console.log(response, "the responseeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"); // Assuming the response data is an array of professionals
            } catch (err) {
                setError("Failed to fetch professionals.");
            } finally {
                setLoading(false);
            }
        };

        fetchProfessionals();
    }, []);


    return (
        <div className="col-12">
            <div className="mb-3">
                <h4 className="mb-0 text-20 fw-semibold clamp clamp1">Boosted Professional</h4>
            </div>
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p className="text-danger">{error}</p>
            ) : boostedProfessionals.length === 0 ? (
                <p>No professional available</p>
            ) : (
            <div className="row align-items-center justify-content-start">
                {boostedProfessionals?.map((professional, index) => (
                    <div className="col-xxl-3 col-lg-4 col-6" key={index}>
                        <div className=" overflow-hidden border rounded-3 mb-3">
                            <div className="row flex-column align-items-center justify-content-between mb-2 bg-lightbluewhite pt-3 mx-0">
                                <div className="row align-items-start justify-content-center gx-2">
                                    <div className="col-3 profile_icon">
                                        <figure className="profileCircle">
                                            <img
                                                src={professional.profilePicture || dummy}
                                                alt={`${professional?.firstName} ${professional?.lastName}`}
                                                className="img-fluid rounded-circle"
                                            />
                                            <figcaption>
                                                <p className="text-8 mt-2 text-center rounded-pill pb-0 bg-lightblue text-blue">
                                                    20 Connections
                                                </p>
                                            </figcaption>
                                        </figure>
                                    </div>
                                    <div className="col-9 ps-2">
                                        <div className="d-flex align-items-start justify-content-between">
                                            <div className="col-10">
                                                <div className="">
                                                    <div className="d-flex align-items-center">
                                                        <div className="col-7">
                                                            <h4 className="mb-0 text-14 fw-semibold clamp clamp1">
                                                            {`${professional?.firstName} ${professional?.lastName}`}
                                                            </h4>
                                                        </div>
                                                        <div className="col-5">
                                                            <span className={`text-9 text-white fw-normal ms-1 ${styles.boosted}`}>
                                                                Boosted
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <p className="text-10 my-0 clamp clamp1 ">
                                                    {professional.occupationDetails?.[0]?.name || "N/A"}
                                                    </p>
                                                    <p className="text-10 my-0 d-flex align-items-center">
                                                        Reviews : 20
                                                        <span className="px-2">|</span>
                                                        4.5
                                                        <i className="ms-2">
                                                            <img src={star} alt="" style={{ objectFit: "contain" }} />
                                                        </i>
                                                    </p>

                                                    <p className="text-10 my-0 d-flex align-items-center">
                                                        <i className="me-2">
                                                            <img
                                                                src={location}
                                                                alt=""
                                                                width={10}
                                                                height={10}
                                                            />
                                                        </i>{" "}
                                                        Address
                                                    </p>
                                                    <p className="text-10 my-0 d-flex align-items-center">
                                                        <i className="me-2">
                                                            <img
                                                                src={flag}
                                                                alt=""
                                                                width={10}
                                                                height={10}
                                                            />
                                                        </i>{" "}
                                                        country
                                                    </p>
                                                    <p className="mb-0 bg-lightgreen text-blue rounded-pill text-center text-9 d-inline-block px-2 py-0">
                                                    {professional.isAvailable ? "Available" : "Unavailable"}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-2 text-end">
                                                <i className="bg-white rounded-circle w-auto px-2 py-2 mb-0 text-center d-inline-flex align-items-center justify-content-center">
                                                    <img src={shareprofile} alt="" />
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="row align-items-center gx-2 my-2 pt-2"
                                    style={{
                                        borderTop: "1px solid #3375D7 ",
                                    }}
                                >
                                    <div className="col-xl-12 col-12 px-0">
                                        <ul className="d-flex mb-0 align-items-center justify-content-center ps-0 gap=2">
                                            <li className="col-6">
                                                <p className="w-100 mb-0 bg-blue text-white rounded-pill text-center text-11 text-center px-2 py-1">
                                                    Endorsements : 30
                                                </p>
                                            </li>
                                            <li className="col-6">
                                                <p className="w-100 mb-0 bg-lightgreen rounded-pill text-center text-11 d-inline-flex align-items-center px-2 py-1">
                                                    <i className="me-1">
                                                        <img src={aeroplane} alt="" />
                                                    </i>
                                                    Willing totravel : Yes
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="p-2">
                                <div className="row align-items-center">
                                    <div className="col-5">
                                        <div
                                            className={`row align-items-start mb-3 g-0 ${styles.PenigSkilRate}`}
                                        >
                                            <div className="col-3 text-center">
                                                <i>
                                                    <img
                                                        src={top_skill}
                                                        alt=""
                                                        className="img-fluid"
                                                        width="35px"
                                                        height="35px"
                                                    />
                                                </i>
                                            </div>
                                            <div className="col-9">
                                                <h5 className="mb-0 text-12 fw-semibold text-uppercase">
                                                    Top Skill
                                                </h5>
                                                <p className="text-12 mb-0">
                                                    Autocad
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div
                                            className={`row align-items-start mb-3 g-0 ${styles.PenigSkilRate}`}
                                        >
                                            <div className="col-3 text-center">
                                                <i>
                                                    <img
                                                        src={top_skill}
                                                        alt=""
                                                        className="img-fluid"
                                                        width="27px"
                                                        height="21px"
                                                    />
                                                </i>
                                            </div>
                                            <div className="col-9">
                                                <h5 className="mb-0 text-12 fw-semibold text-uppercase">
                                                    Second Skill
                                                </h5>
                                                <p className="text-12 mb-0">
                                                    Programming
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.PenigSkilRate}>
                                    <h5>Hourly Rates</h5>
                                    <ul>
                                        <li>
                                            <span>Base</span>
                                            <label>
                                                $3
                                            </label>
                                        </li>
                                        <li>
                                            <span>Overtime</span>
                                            <label>
                                                $3
                                            </label>
                                        </li>
                                        <li>
                                            <span>Sun/Holidays</span>
                                            <label>
                                                $3
                                            </label>
                                        </li>
                                    </ul>
                                </div>

                                <div className="mt-3">
                                    <div className="row align-items-center g-2">
                                        <div className="col">
                                            <button
                                                type="button"
                                                className="btn btn-primary w-100 text-10 px-1 text-center "
                                            >
                                                View Profile
                                            </button>
                                        </div>
                                        <div className="col">

                                            <button
                                                type="button"
                                                className={`btn btn-white w-100 text-10 px-1 border text-center`}
                                            >
                                                Connect
                                            </button>
                                        </div>
                                        <div className="col-12">
                                            <button
                                                type="button"
                                                className="btn btn-white w-100 text-10 px-1 border text-center "
                                            >
                                                Request This Professional
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
                )}
        </div>
    )
}

export default BoostedProfessional