import React, { FC } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { ServiceAdDescription, ServiceAdImage, ServiceAdChoosePlan, ServiceBoostAd } from './interface';
import { ADD_PAID_ADS } from '../../../shared/enums/AddPaidAdsSteps';

const ServiceAdFlow: FC = () => {
  const servicePaidAds = useAppSelector((state: RootState) => state.serviceAd);
  const { activeStep } = servicePaidAds;

  const renderActiveStep = () => {
    switch (activeStep) {
      case ADD_PAID_ADS.AD_DESCRIPTION:
        return <ServiceAdDescription/>;
      case ADD_PAID_ADS.UPLOAD_IMAGE:
        return <ServiceAdImage />;
      case ADD_PAID_ADS.BOOST_ADD:
        return <ServiceBoostAd />;
      case ADD_PAID_ADS.ADD_PROMOTION:
        return <ServiceAdChoosePlan />;
      default:
        return <ServiceAdDescription/>;
    }
  };
  return renderActiveStep();
};

export default ServiceAdFlow;
