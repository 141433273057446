import React, { useEffect, useState } from "react";
import Header from "../../../header/routes";
import info from "../../../../assets/images/info_icon.svg";
import exportt from "../../../../assets/images/exportt.png";
import { Link, useLocation, useParams } from "react-router-dom";
import styles from "../../css/styles/style.module.css";
import sty from '../../../../feature/auth/login/styles/login.module.css';
import moment from "moment";
import { getInvoice } from "../../expense/api";
import { INVOICE_UNPAID_TYPE } from "../../../../utils/enums/timesheetOrExpenseStatusTypes";
import Toltip from "../../toltip/page";
import loc from "../../../../assets/images/loc.png";
import style from "../../../../components/bostprofile/styles/style.module.css";
import visacard from "../../../../assets/images/visacard.png";
import applepy from "../../../../assets/images/applepy.png";
import paypal from "../../../../assets/images/paypal.png";

const Pay = () => {
  return (
    <>
      <Header />
      <div className="Contracts OngoingContractDetails paymentmenthods">
        <div className="container my-5 ">
        <div className="bg-white px-3 rounded-4">
          <div className="px-0">
            <div className={styles.OngoingContractDetails}>
              <div className={styles.ContractTabs}>
                <div className={styles.OuterSlides}>
                  <div className="">
                    <div className={`px-0 ${styles.outerNameDetail}`}>
                      <div className="">
                        <div className="">
                          <h2 className="text-22 fw-bold">Payment Details</h2>
                        </div>
                        <div className="col-lg-12">
                          <div className="bg-blue rounded-top-3 py-3 px-3">
                            <h5 className="text-white text-22 fw-semibold">Primary Occupation</h5>
                            <p className="text-white mb-0 text-13">Start Date : 11-23-2024</p>
                          </div>
                          <div
                            className={`py-3 rounded-bottom-3 rounded-top-0 ${styles.leftName}`}
                            style={{ minHeight: "170px" }}
                          >
                            <h2 className="text-20 text-bold fw-semibold mb-3">Paying To Automate America</h2>
                            <div className={styles.PrileImg}>
                            
                              <span className="text-center">
                                {/* <img src={expenseDetails?.user?.profilePicture} alt="" /> */}
                                <img
                                  src="https://automateamerica-assets.s3.us-east-1.amazonaws.com/uploads/1733396056925-emp2.jpeg"
                                  alt=""
                                />

                                <h5 className="text-14 py-2 justify-content-center fw-medium">
                                  #2223233
                                </h5>
                                <button className="text-12 fw-semibold btn-info text-blue rounded-pill px-2 py-1 border-0 bg-lightblue">
                                  {"Hourly Contract"}
                                </button>
                              </span>
                              <div className="ms-3">
                              
                                <h5>{"Daniel "}</h5>
                                <h5 className="fw-medium text-14 font-poppins lh-base">
                                  Pia Automation . inc
                                </h5>
                                <h5 className="fw-medium text-14 font-poppins lh-base">
                                  PLC Programer
                                </h5>
                                <p className="fw-medium text-14 font-poppins lh-base ps-0 d-flex align-items-center justify-content-start">
                                  <i className="">
                                    <img
                                      src={loc}
                                      alt=""
                                      className="position-relative"
                                    />
                                  </i>
                                  {"Chattanooga,Tennessee,37402"}
                                </p>
                                <h6 className="fw-medium text-blue text-14 font-poppins lh-base d-flex align-items-center justify-content-start">
                                  Rate PerHour : ${"0"} (Net 90)
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`row my-3 mx-0  ${styles.leftName}`}>
                        <div className="col-4">
                          <h5 className="mb-0 fw-semibold text-18 font-inter">Week Start Date</h5>
                          <span className="text-22 fw-bold font-inter">11-23-2024</span>
                        </div>
                        <div className="col-4">
                        <h5 className="mb-0 fw-semibold text-18 font-inter">Week End Date</h5>
                        <span className="text-22 fw-bold font-inter">11-23-2024</span>
                        </div>
                      </div>
                      <div>
                        <div className="mt-4">
                            <div className="w-100 d-flex align-items-center">
                              <h4 className="form-check-label text-20 fw-bold mb-0 font-inter">
                              Invoive : #747876
                              </h4>
                              <i>
                                  <img className="customeTolImg" src={info} alt="" />
                              </i>
                              <Toltip
                              />
                            </div>
                          </div>
                        <div className={`row mt-2 mb-3 mx-0  ${styles.leftName}`}>
                          <div className="col-4">
                            <h5 className="mb-0 fw-semibold text-18 font-inter">Week Start Date</h5>
                            <span className="text-22 fw-bold font-inter">11-23-2024</span>
                          </div>
                          <div className="col-4">
                          <h5 className="mb-0 fw-semibold text-18 font-inter">Week End Date</h5>
                          <span className="text-22 fw-bold font-inter">11-23-2024</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="px-0">
            <div className={`row mt-4 ${styles.loginRight}`}>
              <div className="col-lg-6">
                <div className={styles.formGroup}>
                  <h5 className="text-black text-22 fw-bold">Payment Methods</h5>
                  <div className={`${styles.disabled} ${style.selctCard}`}>
                    <h6>
                      <img src={applepy} alt="" />
                      Apple Pay
                    </h6>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="exampleRadios1"
                        value="option1"
                        disabled
                      />
                    </div>
                  </div>
                  <div className={`${styles.active} ${style.selctCard}`}>
                    <h6>
                      <img src={visacard} alt="" />
                      Credit / Debit Card{" "}
                      <label>Visa, Mastercard, AMEX, CUP, JCB</label>
                    </h6>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="exampleRadios1"
                        value="option1"
                        defaultChecked
                      />
                    </div>
                  </div>
                  <div className={style.selctCard}>
                    <h6>
                      <img src={paypal} alt="" />
                      Paypal
                    </h6>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="exampleRadios1"
                        value="option1"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="my-5">
                  <h5 className="text-black text-22 fw-bold mb-4">Payment Methods</h5>
                  <form>
                    <div className={`mb-3 ${sty.formGroup}`}>
                      <label>Card holder name</label>
                      <input className="form-control" type="text" placeholder="Ex. Jane Cooper"/>
                    </div>
                    <div className={`mb-3 ${sty.formGroup}`}>
                      <label>Billing address</label>
                      <select className="form-select rounded-3 bg-white">
                        <option>
                          United States
                        </option>
                        <option>
                          United States
                        </option>
                      </select>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-6">
                        <div className={`mb-3 ${sty.formGroup}`}>
                          <label>Zip code</label>
                          <input className="form-control" type="text" placeholder="Ex. 73923"/>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className={`mb-3 ${sty.formGroup}`}>
                          <label>City</label>
                          <input className="form-control" type="text" placeholder="Ex. New York"/>
                        </div>
                      </div>
                  
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-6 mb-5">
                <div className={style.paymentForm}>
                  <h5 className="text-black">Payment Summery</h5>
                  <div className={style.paymentBox}>
                    <div className="row align-items-center">
                      <div className="col-4">
                        <h6 className="opacity-75 font-poppins text-16">Invoice</h6>
                        <label className=" text-black font-poppins text-16">#548y9585</label>
                      </div>
                      <div className="col-4">
                        <h6 className="opacity-75 font-poppins text-16">Duration</h6>
                        <label className=" text-black font-poppins text-16">One Week</label>
                      </div>
                    </div>
                    <ul>
                      <li>
                        Subtotal <span>$484848</span>
                      </li>
                      {/* <li>
                        Discounts <span>-$3.00</span>
                      </li> */}
                      <li>
                        Total <span>$48458</span>
                      </li>
                    </ul>
                  </div>
                  <button type="button" className={style.pay_now}>
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default Pay;
