import React, { useState } from "react";
import { JobResponse } from "../interface";
import styles from "../../common/css/styles/style.module.css";
import loc from "../../../assets/images/icon_location.svg";

// import map from "../../../assets/images/map.jpg";
import startdate from "../../../assets/images/startdate.svg";
import image from "../../../assets/images/fullcontractimg_1.jpg";
import skill from "../../../assets/images/top_skill.svg";
import manufacture from "../../../assets/images/manufacture.svg";
import modal from "../../../assets/images/modal.svg";
import peopleneeded from "../../../assets/images/peopleneeded.svg";
import duration from "../../../assets/images/duration.svg";
import moment from "moment";
import { APP_ROUTES } from "../../../utils/routes/AppRoutes";
import { useNavigate } from "react-router-dom";
import EligibiltyModal from "../../common/modals/eligibilty/page";
import bags2 from "../../../assets/images/bags.png";
import share from "../../../assets/images/share_profile.svg";
interface UserDetailsProps {
  user: JobResponse | null;
  workDetails: JobResponse | null;
  onViewFullContract: () => void;
}
const UserDetails: React.FC<UserDetailsProps> = ({
  user,
  workDetails,
  onViewFullContract,
}) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [workId, setWorkId] = useState("");
  console.log("workdetails", workDetails);

  const handleShowModal = (value: any) => {
    if (
      value.eligibilityQuestions.length > 0 ||
      value.interviewQuestions.length > 0
    ) {
      setShowModal(true);
      setWorkId(value._id);
    } else {
      navigate(`${APP_ROUTES.JOBDETAIL}/${value._id}`);
    }
  };
  if (!user) {
    return <div className="col-md-8">loading.....</div>;
  }

  function formatText(text: string | undefined) {
    if (!text) return "No procedure policies available";
    // Replace newline characters with <br> tags
    return text.replace(/\n/g, "<br/>");
  }

  return (
    <>
      {showModal && (
        <EligibiltyModal onClose={() => setShowModal(false)} workId={workId} />
      )}
      <div className="col-lg-8">
        <div className={styles.RightOpportunity}>
          <div className={styles.ContarctOuter}>
            <div className={styles.opprBox}>
              <div className={styles.PendingCOnatctList}>
                <h5 className="fw-400 text-14">
                  Reference No.: #{workDetails?.workNumber}
                </h5>
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <h6 className={styles.name_robo}>
                      {workDetails?.occupation?.name}
                    </h6>
                  </div>
                  <div className="d-flex gap-2">
                    <button
                      type="button"
                      className={`btn w-100 px-3 py-2 bg-blue text-14 ${styles.applybtn}`}
                      // onClick={() => handleShowModal(user)}
                      onClick={onViewFullContract}
                      disabled={user?.isApplied === true}
                    >
                      {user?.isApplied === true ? "Applied" : "View and Apply"}
                    </button>

                    <button
                      type="button"
                      className={`btn d-flex align-items-center px-3 py-2 bg-blue text-14 border-blue ${styles.sharebtn}`}
                    >
                      <i className="pe-1">
                        <img src={share} alt="" width={15} height={15} />
                      </i>
                      Share
                    </button>
                  </div>
                </div>

                <span
                  className={` w-auto h-auto px-3 text-12 text-green py-2 rounded-1 text-capitalize ${styles.Hrrate}`}
                >
                  <img src={bags2} alt="" />
                  {workDetails?.planType === "whiteglove"
                    ? "White Glove Contract"
                    : ""}
                </span>

                {workDetails?.workLocation ? (
                  <span
                    className={`bg-white border-blue w-auto h-auto px-3 text-12 text-blue py-2 rounded-1 ms-3`}
                  >
                    {workDetails?.workLocation === "Onsite"
                      ? "Onsite Contract"
                      : workDetails?.workLocation === "Remote"
                      ? "Remote Contract"
                      : workDetails?.workLocation === "Hybrid"
                      ? "Hybrid Contract"
                      : ""}
                  </span>
                ) : (
                  ""
                )}
                <span
                  className={`bg-blue w-auto h-auto px-3 text-12 text-white py-2 rounded-1 ms-3`}
                >
                  {workDetails?.workType}
                </span>
                <div className={styles.Loca}>
                  <p className={styles.Locate}>
                    <img src={loc} alt="" /> {workDetails?.address}
                  </p>
                </div>
                <div className={`pt-1 ${styles.PenigSkilRate}`}>
                  <ul>
                    <li className="d-flex align-items-start">
                      <i className="me-2">
                        <img src={skill} alt="" className="img-fluid" />
                      </i>
                      <div>
                        <span className="text-12 fw-semibold text-uppercase">
                          TOP SKILL
                        </span>
                        {workDetails?.technicalSkills?.map((data, ind) => (
                          <label
                            className="text-14 text-muted fw-normal"
                            key={ind}
                          >
                            {data?.manufacturerDetails?.name}
                          </label>
                        ))}
                      </div>
                    </li>
                    <li className="d-flex align-items-start">
                      <i className="me-2">
                        <img src={manufacture} alt="" className="img-fluid" />
                      </i>
                      <div>
                        <span className="text-12 fw-semibold text-uppercase">
                          MANUFACTURER
                        </span>
                        {workDetails?.technicalSkills?.map((data, ind) => (
                          <label
                            className="text-14 text-muted fw-normal"
                            key={ind}
                          >
                            {data?.manufacturerDetails?.name}
                          </label>
                        ))}
                      </div>
                    </li>
                    <li className="d-flex align-items-start">
                      <i className="me-2">
                        <img src={modal} alt="" className="img-fluid" />
                      </i>
                      <div>
                        <span className="text-12 fw-semibold text-uppercase">
                          Model/Version/Controller
                        </span>
                        {workDetails?.technicalSkills?.map((data, ind) => (
                          <label
                            className="text-14 text-muted fw-normal"
                            key={ind}
                          >
                            {data?.manufacturerModelDetails?.name}
                          </label>
                        ))}
                      </div>
                    </li>
                  </ul>
                </div>
                <div className={`${styles.PenigSkilRate}`}>
                  <h5>Image</h5>
                  <ul className="gap-2">
                    <li>
                      <i>
                        <img src={image} alt="" />
                      </i>
                    </li>
                    <li>
                      <i>
                        <img src={image} alt="" />
                      </i>
                    </li>
                    <li>
                      <i>
                        <img src={image} alt="" />
                      </i>
                    </li>
                  </ul>
                </div>
                <div className={styles.PenigSkilRate}>
                  <h5 className="mb-2">Pay Rates</h5>
                  <ul>
                    <li>
                      <span className="text-12 fw-semibold text-muted">
                        Base
                      </span>
                      <label className="text-14 fw-bold">
                        ${workDetails?.rates?.baseRate}
                      </label>
                    </li>
                    <li>
                      <span className="text-12 fw-semibold text-muted">
                        Overtime
                      </span>
                      <label className="text-14 fw-bold">
                        ${workDetails?.rates?.overTimeRate}
                      </label>
                    </li>
                    <li>
                      <span className="text-12 fw-semibold text-muted">
                        Sun/Holidays
                      </span>
                      <label className="text-14 fw-bold">
                        ${workDetails?.rates?.doubleTimeRate}
                      </label>
                    </li>
                  </ul>
                </div>

                {/* <div className="col-md-12">
                  <div className={styles.bordeerBottom}></div>
                  <h5 className={styles.payrate}>Expenses</h5>
                  <div className="row">
                    <div className="col-lg-7 col-sm-12">
                      <div className="row">
                        <div className="col-lg-6 col-sm-6">
                          <div className={styles.datesFlex2}>
                            <h6 className="text-12 fw-semibold text-muted">
                              Daily Per Diam{" "}
                            </h6>
                            <p className="text-14 fw-bold text-blue">
                              {" "}
                              ${workDetails?.expense?.dailyPerDiem}
                            </p>
                          </div>
                        </div>

                        <div className="col-lg-6 col-sm-6">
                          <div className={styles.datesFlex2}>
                            <h6 className="text-12 fw-semibold text-muted">
                              Airfare Allowance
                            </h6>
                            <p className="text-14 fw-bold text-blue">
                              {" "}
                              ${workDetails?.expense?.airfareAllowance}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div className="col-md-12">
                  <div className={styles.bordeerBottom}></div>
                  <h5 className={styles.payrate}>
                    Tools, Machines and equipment
                  </h5>
                  <div className={styles.PenigSkil}>
                    <ul>
                      <li>Lorem Ipsum</li>
                      <li>Figma</li>
                      <li>Adobe</li>
                    </ul>
                  </div>
                </div> */}

                <div className={styles.bordeerBottom}></div>
                <h5 className={styles.payrate}>SCHEDULE</h5>
                <div className="row">
                  <div className="col-lg-7 col-sm-12">
                    <div className="row">
                      <div className="col-lg-6 col-sm-6">
                        <div className={styles.datesFlex}>
                          <h6 className="text-12 text-capitalize">
                            Start date{" "}
                          </h6>
                          <p className="text-10 fw-bold text-muted">
                            {" "}
                            {moment(workDetails?.times?.startDate).format(
                              "MMM DD, YYYY"
                            )}
                          </p>
                          <i className="me-2">
                            {" "}
                            <img src={startdate} alt="" width={15} />
                          </i>
                        </div>
                      </div>

                      <div className="col-lg-6 col-sm-6">
                        <div className={styles.datesFlex}>
                          <h6 className="text-12 text-capitalize">Duration</h6>
                          <p className="text-10 fw-bold text-muted mb-0">
                            {workDetails?.times?.duration}
                          </p>
                          <i className="me-2">
                            {" "}
                            <img src={startdate} alt="" width={15} />
                          </i>
                        </div>
                      </div>

                      <div className="col-lg-6 col-sm-6">
                        <div className={styles.datesFlex}>
                          <h6 className="text-12 text-capitalize">
                            People Needed{" "}
                          </h6>
                          <p className="text-10 fw-bold text-muted mb-0">
                            {" "}
                            {workDetails?.times?.noOfPepole}
                          </p>
                          <i className="me-2">
                            {" "}
                            <img src={peopleneeded} alt="" width={15} />
                          </i>
                        </div>
                      </div>

                      <div className="col-lg-6 col-sm-6">
                        <div className={styles.datesFlex}>
                          <h6 className="text-12 text-capitalize">
                            {workDetails?.times?.shift} shift
                          </h6>
                          <p className="text-10 fw-bold text-muted mb-0"></p>
                          <i className="me-2">
                            {" "}
                            <img src={duration} alt="" width={15} />
                          </i>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-6">
                        <div className={styles.datesFlex}>
                          <h6 className="text-12 text-capitalize">
                            Planned Hrs Per Day{" "}
                          </h6>
                          <p className="text-10 fw-bold text-muted mb-0">
                            {" "}
                            {workDetails?.times?.plannedHoursPerDay}
                          </p>
                          <i className="me-2">
                            {" "}
                            <img src={duration} alt="" width={15} />
                          </i>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-6">
                        <div className={styles.datesFlex}>
                          <h6 className="text-12 text-capitalize">
                            Planned Hrs Per Week{" "}
                          </h6>
                          <p className="text-10 fw-bold text-muted mb-0">
                            {" "}
                            {workDetails?.times?.plannedDaysPerWeek}
                          </p>
                          <i className="me-2">
                            {" "}
                            <img src={duration} alt="" width={15} />
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.bordeerBottom}></div>
                  <div
                    className={styles.PenigSkil}
                    style={{ marginTop: "15px" }}
                  >
                    <h5>Job Description</h5>
                    <div
                      className={`${styles.Peras}`}
                      dangerouslySetInnerHTML={{
                        __html:
                          workDetails?.description ||
                          "No description available",
                      }}
                    />
                  </div>
                  <div className={styles.bordeerBottom}></div>
                  <div className={styles.PenigSkilRate22}>
                    <h5>Software Licenses</h5>
                    <div className={styles.SoftWareLicenece}>
                      <div className="row align-items-center w-100">
                        <div className="col-lg-6">
                          <ul className={`nav ${styles.TaglinesButton}`}>
                            {workDetails?.softwareLicence?.map(
                              (license: any) => (
                                <li key={license?.softwareLicenceId}>
                                  <span
                                    className={
                                      license.isMandatory
                                        ? styles.blueButtons
                                        : license.isGoodToHave
                                        ? styles.greenButtons
                                        : styles.orangeButtons
                                    }
                                  >
                                    {license?.softwareLicenceDetails?.name}
                                  </span>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                        <div className="col-lg-6">
                          <ul className={`nav ${styles.Taglines}`}>
                            <li>
                              <span className={styles.greenBOx}></span>Good to
                              have
                            </li>
                            <li>
                              <span className={styles.blueBOx}> </span>Mandatory
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className={styles.bordeerBottom}></div>
                    <h5 className={styles.payrate}>Procedure and Policies</h5>
                    {/* <p className={styles.Peras}>
                      {workDetails?.procedurePolicies}
                    </p> */}
                    <div
                      className={`${styles.Peras}`}
                      dangerouslySetInnerHTML={{
                        __html: formatText(
                          workDetails?.proceduresAndPolicies?.description
                        ),
                      }}
                    />
                  </div>

                  <div className="col-md-12">
                    <div className={styles.bordeerBottom}></div>
                    <div className={styles.flexbtns}>
                      <button
                        type="button"
                        className={`btn w-auto ${styles.commonbtn} ${styles.applybtn}`}
                        // onClick={() => handleShowModal(user)}
                        onClick={onViewFullContract}
                        disabled={user?.isApplied === true}
                      >
                        {user?.isApplied === true
                          ? "Applied"
                          : "View and Apply"}
                      </button>
                      <button
                        type="button"
                        className={`btn ${styles.commonbtn} ${styles.sharebtn}`}
                      >
                        Share{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.bordeerBottom}></div>
          {/* <div className={styles.mapView}>
            <img src={map} alt="" className="img-fluid" />
          </div> */}
        </div>
        
      </div>
    </>
  );
};

export default UserDetails;

// <div>
//             <strong>Software Licenses</strong>
//             {workDetails.softwareLicence.map((license) => (
//               <span
//                 key={license.name}
//                 className={`badge mx-1 ${
//                   license.type === 'Good to have' ? 'bg-success' : 'bg-primary'
//                 }`}
//               >
//                 {license.name}
//               </span>
//             ))}
//           </div>
