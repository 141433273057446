import React, { useEffect, useState } from "react";
import { Dropdown, FormControl, ModalBody, ModalFooter, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import security from "../../../../assets/images/security.svg";
import blackarrow from "../../../../assets/images/blackarrow.svg";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import styles from "../styles/style.module.css";
import { updateUserProfile } from "../../../home/api";
import { bankDetailsProps } from "../../interface";
import { toast } from "react-toastify";

const EditBankingInfoModal: React.FC<bankDetailsProps> = ({
  showModal,
  closeModal,
  title,
  refresh,
  bankDetails
}) => {
  const [bankName, setBankName] = useState<string>("");
  const [routing, setRouting] = useState<number>();
  const [accNum, setAccNum] = useState<number>();
  const [accName, setAccName] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  // Error states
  const [errors, setErrors] = useState<{ 
    bankName?: string; 
    routing?: string; 
    accNum?: string; 
    accName?: string 
  }>({});

  useEffect(() => {
    if (bankDetails) {
      setBankName(bankDetails.bankName || "");
      setRouting(typeof bankDetails.routing === 'number' ? bankDetails.routing : undefined);
      setAccNum(typeof bankDetails.account === 'number' ? bankDetails.account : undefined);
      setAccName(bankDetails.accountName || "");
    }
  }, [bankDetails]);

  const handleUpdateInfo = async () => {
    // Reset errors
    setErrors({});

    // Validation checks
    const newErrors: { bankName?: string; routing?: string; accNum?: string; accName?: string } = {};

    if (!bankName) {
      newErrors.bankName = "Please enter a Bank Name.";
    }
    if (!routing || !/^\d{9}$/.test(String(routing))) {
      newErrors.routing = "Please enter a valid 9-digit Routing Number.";
    }
    if (!accNum || accNum.toString().length < 6) {
      newErrors.accNum = "Please enter a valid Account Number with at least 6 digits.";
    }
    if (!accName) {
      newErrors.accName = "Please enter an Account Name.";
    }

    // If there are errors, set them and return
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setIsSubmitting(true);
    const payload = {
      bankDetails: {
        bankName: bankName,
        routing: routing ?? 0, // Provide a default value if undefined
        account: accNum ?? 0,   // Provide a default value if undefined
        accountName: accName,
      },
    };
    
    try {
      await updateUserProfile(payload);
      refresh();
      toast.success("Updated Successfully");
    } catch (error) {
      console.log("Error while updating the bank details", error);
      toast.error("Failed to update bank details. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal show={showModal} onHide={closeModal} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <ModalBody>
        <div className="addModalPro">
          <form>
            <div className="row">
              <div className="col-12 mb-3">
                <div className="bg-lightblue row align-items-center justify-content-center p-3 mx-0 rounded-2">
                  <div className="col-1 text-center">
                    <i>
                      <img src={security} alt="" className="img-fluid" />
                    </i>
                  </div>
                  <div className="col-11">
                    <p className="text-14 fw-medium text-black mb-0">
                      Please provide your financial information securely to
                      facilitate payments. Your security is extremely important
                      to us, and only Automate America Financial Administration
                      will have access to this information.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Bank Name</label>
                  <div>
                    <input
                      type="text"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                      className="form-control"
                      placeholder="$"
                    />
                    {errors.bankName && <small className="text-danger">{errors.bankName}</small>}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Routing</label>
                  <div>
                    <input
                      type="text"
                      value={routing}
                      onChange={(e) => setRouting(Number(e.target.value))}
                      className="form-control"
                      placeholder="$"
                    />
                    {errors.routing && <small className="text-danger">{errors.routing}</small>}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Account Number</label>
                  <div>
                    <input
                      type="text"
                      value={accNum}
                      onChange={(e) => setAccNum(Number(e.target.value))}
                      className="form-control"
                      placeholder="$"
                    />
                    {errors.accNum && <small className="text-danger">{errors.accNum}</small>}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Account Name</label>
                  <div>
                    <input
                      type="text"
                      value={accName}
                      onChange={(e) => setAccName(e.target.value)}
                      className="form-control"
                      placeholder="$"
                    />
                    {errors.accName && <small className="text-danger">{errors.accName}</small>}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </ModalBody>
      <ModalFooter className="btn-hover-effect">
        <Button
          type="button"
          variant="secondary"
          onClick={closeModal}
          className="btn bg-white arrowyellow border border-1 rounded-3 position-relative ps-5 backbutton w-auto"
        >
          <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
            Back
          </span>
          <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
            <img
              src={blackarrow}
              alt=""
              width={15}
              height={15}
              style={{ transform: "rotate(-180deg)" }}
            />
          </i>
        </Button>
        <Button
          type="button"
          variant="secondary"
          onClick={handleUpdateInfo}
          className="btn bg-white arrowblue border border-1 rounded-3 position-relative pe-5 w-auto"
        >
          <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
            {isSubmitting ? (
              <Spinner animation="border" size="sm" className="me-2" />
            ) : (
              "Save"
            )}
          </span>
          <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
            <img src={whitearrow} alt="" width={15} height={15} />
          </i>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditBankingInfoModal;
