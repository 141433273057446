import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setPrivacyPolicies } from "../../../../redux/reducers/auth";
import { getPrivacyPolicy, signup, signupBusiness } from "../api";
import { APIResponseError, Location, PrivacyPolicyResponse, SignupUserSuccessResponse } from "../interface";
import { RootState } from "../../../../redux/store";
import { toast } from "react-toastify";
import { MESSAGES } from "../../../../utils/messages";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import HeaderContent from "../../../../shared/Components/HeaderContent/headerContent";
import { AUTH_ROUTES } from "../../../../utils/routes/AuthRoutes";
import { APP_ROUTES } from "../../../../utils/routes/AppRoutes";
import { API_ROUTES } from "../../../../utils/routes/apiRoutes";

const PrivacyPolicy: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [privacyPolicyData, setPrivacyPolicy] = useState<string>("");
    const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState<boolean>(false);
    const userDetail = useSelector((state: RootState) => state.userData);
    const emailId: string = userDetail?.emailData?.email;
    const password: string = userDetail?.password?.password;
    const otp: string = userDetail?.verificationCode.verificationCode.toString();
    const firstName: string = userDetail?.data?.firstName ?? "";
    const lastName: string = userDetail?.data?.lastName ?? "";
    const joinData=userDetail.joinData
    const fullName: string = `${firstName} ${lastName}`;
    const occupation: string[] = userDetail?.occupationData?.occupation?.length
        ? [userDetail.occupationData.occupation[0]]
        : [];
    const citizenshipArray = userDetail?.citizenshipData?.citizenship || [];
    const citizenship: string = Array.isArray(citizenshipArray) ? citizenshipArray[0] : "";
    const phNumber: string = userDetail?.contact?.phoneNum;
    const phoneNumVerifyId: string = userDetail?.contact?.phoneNumVerifyId;
    const hearAbout: string = userDetail?.aboutData.About;
    const languages: string[] = ["english"];
    const location: Location = { coordinates: [0, 0] };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        setIsPrivacyPolicyAccepted(isChecked);
        const privacyPolicyData = {
            isPrivacyPolicy: isChecked,
        };
        dispatch(setPrivacyPolicies(privacyPolicyData));
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); // Prevent default form submission

        const payload = {
            email: emailId,
            name: fullName,
            firstName: firstName,
            lastName: lastName,
            password: password,
            countryCode: userDetail.contact.countryCode,
            phoneNumber: phNumber,
            isVerified: true,
            occupationIds: occupation,
            countryId: citizenship,
            hearAbout: hearAbout,
            phoneNoVerifyId: phoneNumVerifyId,
            languages: languages,
            location: location,
            isPrivacyPolicy: isPrivacyPolicyAccepted,
            businessId:joinData.businessId,
            userType:joinData.userType,
            businessGroupId:joinData.businessGroupId
            
        };
        try {
            await toast.promise(joinData.userType===""?signup(payload):signupBusiness(payload), {
                pending: MESSAGES.LOGIN.VERIFY,
                success: {
                    render({ data }) {
                        let response = data as SignupUserSuccessResponse;
                        if (response) {
                            // navigate(APP_ROUTES.FEEDS)
                            navigate(AUTH_ROUTES.LOGIN);
                            return MESSAGES.SIGNUP.SUCCESS;
                        }
                    },
                },
                error: {
                    render(error) {
                        let errorMesage = error as APIResponseError;
                        return errorMesage?.data?.data?.message;
                    },
                },
            });
        } catch (error) {
            console.error("Signup failed:", error);
        }
    };

    const getPrivacyPolicyData = async () => {
        const response: PrivacyPolicyResponse = await getPrivacyPolicy();
        setPrivacyPolicy(response?.data?.htmlText);
    };

    useEffect(() => {
        getPrivacyPolicyData();
    }, []);

    return (
        <>
            <HeaderContent />

            <div className="py-4 heightautoscroll" >
                <div
                    className="privacy-policy-container"
                    dangerouslySetInnerHTML={{ __html: privacyPolicyData }}
                ></div>

                <div className="checkbox-container mx-auto">
                    <div className="checkboxwrapper text-center">
                        <input
                            type="checkbox"
                            id="privacyPolicyCheckbox"
                            checked={isPrivacyPolicyAccepted}
                            onChange={handleCheckboxChange}
                            className=""
                        />
                        <label htmlFor="privacyPolicyCheckbox" className="ms-3">I agree to the Privacy Policy</label>
                    </div>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="col-sm-3 col-6 mx-auto">
                        <div className="text-center my-3 btn-hover-effect border rounded-3">
                            <button
                                type="submit"
                                className="btn bg-white arrowblue border-white border-1 position-relative w-100 pe-5 text-center"
                            >
                                <span className="px-lg-4 px-2 text-12 fw-semibold w-100">Next</span>
                                <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                                    <img src={whitearrow} alt="Next" />
                                </i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default PrivacyPolicy;
