import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../css/styles/style.module.css";
import prpbag from "../../../../assets/images/bag_orange.svg";
import timeling from "../../../../assets/images/timeiing.png";
import skill from "../../../../assets/images/top_skill.svg";
// import prpbag from "../../../../assets/images/prpbag.png";
import loc from "../../../../assets/images/icon_location.svg";
import rejectedapp from "../../../../assets/images/rejectedapp.svg";
import offeraccepted from "../../../../assets/images/offeraccepted.svg";
import checkmark from "../../../../assets/images/checkmark.svg";
import Slider from "react-slick";
import { APP_ROUTES } from "../../../../utils/routes/AppRoutes";
import { CONTRACT_Type } from "../../../../utils/enums/types";
import { TYPE, WORK_STATUS_TYPE } from "../../../../utils/enums/status";
import moment from "moment";
import { ContractResponse } from "../../Contracts/interface";
import { getOpenContractList } from "../../Contracts/api";
import BoostContract from "../../../../shared/CustomModal/BoostContractModal";
import CustomModal from "../../../../shared/CustomModal/customModal";
import { CONSTANT_MANAGER } from "../../../../utils/constans/constants";
import Toltip from "../../toltip/page";
import tip from "../../../../../src/assets/images/info_icon.svg";
import SortFilter from "../../../Sortfilter/routes";
import DateTime from "../../../Datetimefilter/routes";
import Swal from "sweetalert2";
import { endContractJob } from "../../../home/api";
import { APIResponseErrors } from "../../../work/interface";

const OpenDirectJob = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [openContracts, setOpenContract] = useState<ContractResponse[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [workId, setWorkId] = useState("");

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
  };
  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 2.4,
    centerPadding: "60px",
    slidesToScroll: 2,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.02,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handleShowContractDetails = (id: string) => {
    navigate(`${APP_ROUTES.OPENCONTRACT_DETAILS}/${id}`);
  };

  useEffect(() => {
    getOpenContractsForMe();
  }, []);

  const getOpenContractsForMe = async () => {
    const response: any = await getOpenContractList(
      CONTRACT_Type.DIRECT_JOB,
      TYPE.DONEFORME,
      WORK_STATUS_TYPE.OPEN
    );
    setOpenContract(response?.data);
    setLoading(false);
  };

  const handleBoostContract = (id: string) => {
    setWorkId(id);
    setModalDetail({
      show: true,
      title: "",
      flag: "boostContract",
    });
  };

  const _handleEndContractJob = async (id: string) => {
    try {
      const res = await endContractJob(id);
      Swal.fire({
        title: "Your request has been sent to Admin for Deleting this Job!",
        // text: res.message as string,
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (error) {
      console.log("Occuring error while ending the contract/job", error);
      const apiError = error as APIResponseErrors;
      const errorMessage = apiError?.data?.message as string;
      if (apiError.data.status == 400) {
        Swal.fire({
          html: `<span style="color: red;">${errorMessage}</span>`,
          icon: "error",
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "confirmBtn",
          },
          buttonsStyling: false,
        });
      } else {
        Swal.fire({
          title: "Unexpected Error",
          text: errorMessage,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  return (
    <div className={`bg-white p-lg-4 p-2 shadow rounded-3 mt-3`}>
      <div className={`${styles.AllContracta}`}>
        <div className="row align-items-center justify-content-between mx-0 g-0">
          <div className="col-12">
            <div className="topTipHover">
              <div className="d-flex align-items-center">
                <h4 className="form-check-label text-20 fw-bold mb-0 font-inter">
                  Open Direct In-House Jobs
                </h4>
                <i>
                  <img className="customeTolImg" src={tip} alt="" />
                </i>
                {openContracts?.length > 0 ? '' : <span className="badge rounded-3 bg-lightblue text-black text-16 fw-semibold px-3 py-2 ms-2">0</span>}
                <Toltip
                  slug={CONSTANT_MANAGER.SLUG_HOME.HOME}
                  labelId={CONSTANT_MANAGER.HOME_DATA.OPEN_CONTRACTS}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {openContracts?.length > 0 && openContracts ? (
        <>
          {loading ? (
            <div className="mt-3">
            <div className="spinner-grow text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            </div>
          ) : (
            <div className={`mt-3 ${styles.OuterSlides}`}>
              <Slider {...settings}>
                {openContracts?.length > 0
                  ? openContracts?.map(
                      (data: ContractResponse, idx: number) => {
                        return (
                          <div key={idx} className={styles.ContarctOuter}>
                            <div className={styles.ContarctHead}>
                              <div className="row align-item-center justify-content-between mx-0 w-100 position-relative">
                                <div className="col-7 mb-2">
                                  <div className={styles.leftContract}>
                                    <h5>{data?.occupation?.name}</h5>
                                    <span>
                                      Start Date:{" "}
                                      {moment(data.times.startDate).format(
                                        "MM-DD-YYYY"
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-xl-5 col-12 mb-2">
                                  <div
                                    className={styles.leftContract}
                                    style={{ textAlign: "end" }}
                                  >
                                    {/* <button>{data?.workStatus === WORK_STATUS_TYPE.ONGOING ? "Ongoing" : data.workStatus === WORK_STATUS_TYPE.APPROVED ? "Approved" : data.workStatus === WORK_STATUS_TYPE.OPEN ? "Open" : ""}</button> */}
                                    <button
                                      className={`position-absolute top-0 ${styles.statusdetails}`}
                                    >
                                      Open
                                    </button>
                                  </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-12">
                                  <div className={styles.leftContract}>
                                    <div className={styles.Local}>
                                      <div
                                        className={`justify-content-start ${styles.adrssLoc}`}
                                      >
                                        <span
                                          className={`py-2 rounded-2 me-2 mb-0 border-lightorange text-lightorange bg-lightorange ${styles.standardContact}`}
                                        >
                                          <img src={prpbag} alt="" />
                                          {data?.workType === "direct_job"
                                            ? "Standard Job"
                                            : ""}
                                        </span>
                                        <span
                                          className={`py-2 mb-0 rounded-2 bg-white text-blue border-blue lh-base`}
                                        >
                                          {data?.workLocation === "Remote"
                                            ? "Remote Job"
                                            : data?.workLocation === "Onsite"
                                            ? "Onsite Job"
                                            : data?.workLocation === "Hybrid"
                                            ? "Hybrid Job"
                                            : "Unknown Location"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-12 mt-2">
                                  <div
                                    className={styles.leftContract}
                                    style={{ textAlign: "end" }}
                                  >
                                    <button
                                      className={`border-blue bg-blue text-white`}
                                    >
                                      <i className="far fa-copy"></i> Copy
                                    </button>
                                    <button
                                      className={`border-blue bg-blue text-white`}
                                    >
                                      <i className="far fa-copy"></i> Unpublish
                                    </button>

                                    <button
                                      className={`bg-white text-danger border-danger ${styles.deletecontracts}`}
                                      onClick={() =>
                                        _handleEndContractJob(data?._id)
                                      }
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                                <div className="col-xl-7 col-lg-12 col-md-7 col-12">
                                  <div
                                    className={`${styles.RightContract} ${styles.leftContract}`}
                                    style={{ justifyContent: "flex-end" }}
                                  >
                                    {/* <span className={styles.switchBtn}>
                                            <div className="form-check form-switch">
                                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Unpublish</label>
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />

                                            </div>
                                        </span> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={styles.outerNameDetail}>
                              <div className="row">
                                <div className={styles.OpenContout}>
                                  <div
                                    className={`pb-4 ${styles.OpenContactData}`}
                                  >
                                    <div className={styles.adrssLoc}>
                                      <p className="mb-1">
                                        <img src={loc} alt="" /> {data?.address}
                                      </p>
                                    </div>
                                    <p>
                                      {" "}
                                      <img src={timeling} alt="" />
                                      Sent For Approval:{" "}
                                      {moment(data?.times?.startDate).format(
                                        "MM-DD-YYYY"
                                      )}
                                    </p>
                                    {/* <h6>{data?.description}</h6> */}
                                    <div className="fw-semibold clamp clamp1 mb-2 mt-2">
                                      <div
                                        className={styles.desblock}
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            isExpanded && data.description
                                              ? data?.description
                                              : data?.description?.slice(
                                                  0,
                                                  100
                                                ),
                                        }}
                                      />
                                      {/* <span
                                        onClick={toggleReadMore}
                                        className={`fw-medium ${styles.readMore}`}
                                      >
                                        {isExpanded ? "Show Less" : "Read More"}
                                      </span> */}
                                    </div>
                                    <div
                                      className={`mt-3 ${styles.RecevideContact}`}
                                    >
                                      {data?.technicalSkills?.length && (
                                        <div className={styles.PenigSkils}>
                                          <ul className="row align-items-start justify-content-start gap-0">
                                            <li className="col-4 pe-0">
                                              <div className="row align-items-start mb-3">
                                                <div className="col-3 text-center pe-0">
                                                  <i>
                                                    <img
                                                      src={skill}
                                                      alt=""
                                                      className="img-fluid"
                                                      width="35px"
                                                      height="35px"
                                                    />
                                                  </i>
                                                </div>
                                                <div className="col-9 ps-0">
                                                  <h5 className="mb-0 fw-bold text-14 text-uppercase">
                                                    Top Skill
                                                  </h5>
                                                  <p className="text-14 mb-0">
                                                    {
                                                      data?.technicalSkills?.[0]
                                                        ?.manufacturerDetails
                                                        ?.name
                                                    }
                                                  </p>
                                                </div>
                                              </div>
                                            </li>
                                            <li className="col-4 pe-0">
                                              <div className="row align-items-start mb-3">
                                                {data?.technicalSkills?.[1]
                                                  ?.manufacturerDetails
                                                  ?.name ? (
                                                  <>
                                                    <div className="col-3 text-center pe-0">
                                                      <i>
                                                        <img
                                                          src={skill}
                                                          alt=""
                                                          className="img-fluid"
                                                          width="35px"
                                                          height="35px"
                                                        />
                                                      </i>
                                                    </div>
                                                    <div className="col-9 ps-0">
                                                      <h5 className="mb-0 fw-bold text-14 text-uppercase">
                                                        Second skill
                                                      </h5>
                                                      <p className="text-14 mb-0">
                                                        {
                                                          data
                                                            ?.technicalSkills?.[1]
                                                            ?.manufacturerDetails
                                                            ?.name
                                                        }
                                                      </p>
                                                    </div>
                                                  </>
                                                ) : null}
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      )}

                                      <div
                                        className={`pt-0 mb-4 ${styles.PenigSkilRate}`}
                                      >
                                        <h5 className="mb-2">Pay Rates</h5>
                                        <ul>
                                          <li>
                                            <span>Base</span>
                                            <label>
                                              ${data?.rates?.baseRate}
                                            </label>
                                          </li>
                                          <li>
                                            <span>OverTime</span>
                                            <label>
                                              ${data?.rates?.overTimeRate}
                                            </label>
                                          </li>
                                          <li>
                                            <span>Sunday/Holidays</span>
                                            <label>
                                              ${data?.rates?.doubleTimeRate}
                                            </label>
                                          </li>
                                        </ul>
                                      </div>
                                      {/* <div className="row mb-3">
                                      <div className="col-xl-3 col-md-6 col-12">
                                        <div
                                          className={styles.InnrRecveContarct}
                                        >
                                          <h5>Shortlisted Applicants</h5>
                                          <span>
                                            {data?.shortlistedApplication
                                              ? data?.shortlistedApplication
                                              : 0}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="col-xl-3 col-md-6 col-12">
                                        <div
                                          className={styles.InnrRecveContarct}
                                        >
                                          <h5>Applications Received</h5>
                                          <span>
                                            {data?.receivedApplication
                                              ? data?.receivedApplication
                                              : 0}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col-xl-3 col-md-6 col-12">
                                        <div
                                          className={styles.InnrRecveContarct}
                                        >
                                          <h5>Offer <br/>Pending</h5>
                                          <span>
                                            {data?.pendingApplication
                                              ? data?.pendingApplication
                                              : 0}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col-xl-3 col-md-6 col-12">
                                        <div
                                          className={`${styles.InnrRecveContarct} ${styles.RedBox}`}
                                        >
                                          <h5>Rejected Applicants</h5>
                                          <span>
                                            {data?.rejectedApplication
                                              ? data?.rejectedApplication
                                              : 0}
                                          </span>
                                        </div>
                                      </div>
                                    </div> */}
                                      {data?.boosts ? (
                                        <div
                                          className={`row align-items-center justify-content-between mt-3 ${styles.chatAdmin}`}
                                        >
                                          <div className="col-12">
                                            <button className="rounded-3 bg-blue text-white w-100 text-center fw-semibold p-2 text-14">
                                              Boosted On{" "}
                                              {moment(
                                                data.boosts?.startDate
                                              ).format("DD-MM-YYYY")}{" "}
                                              {" | "}
                                              Renewal{" "}
                                              {moment(
                                                data.boosts?.endDate
                                              ).format("DD-MM-YYYY")}
                                            </button>
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          className={`row align-items-center justify-content-between mt-3 ${styles.chatAdmin}`}
                                        >
                                          <div className="col-12">
                                            <button
                                              onClick={() =>
                                                handleBoostContract(data._id)
                                              }
                                              className="rounded-3 text-14 py-2 p-2 bg-blue text-white w-100 text-center fw-semibold"
                                            >
                                              Boost This Job
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row pt-4 g-3">
                                    <div className="col-6">
                                      <div
                                        className={`d-flex align-items-start bg-lightblue border-0 ${styles.timesheetData}`}
                                      >
                                        <div className="col-2 mt-1">
                                          <i>
                                            <img
                                              src={checkmark}
                                              alt=""
                                              className="img-fluid"
                                            />
                                          </i>
                                        </div>
                                        <div className="col-10">
                                          <h6 className="fw-medium mb-1">
                                            Applications Received
                                          </h6>
                                          <h3 className="mb-0">
                                            {data?.receivedApplication}
                                          </h3>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div
                                        className={`d-flex align-items-start bg-lightblue border-0 ${styles.timesheetData}`}
                                      >
                                        <div className="col-2 mt-1">
                                          <i>
                                            <img
                                              src={checkmark}
                                              alt=""
                                              className="img-fluid"
                                            />
                                          </i>
                                        </div>
                                        <div className="col-10">
                                          <h6 className="fw-medium mb-1">
                                            Shortlisted Applicants
                                          </h6>
                                          <h3 className="mb-0">
                                            {data?.shortlistedApplication}
                                          </h3>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div
                                        className={`d-flex align-items-start bg-lightblue border-0 ${styles.timesheetData}`}
                                      >
                                        <div className="col-2 mt-1">
                                          <i>
                                            <img
                                              src={checkmark}
                                              alt=""
                                              className="img-fluid"
                                            />
                                          </i>
                                        </div>
                                        <div className="col-10">
                                          <h6 className="fw-medium mb-1">
                                            Offers Pending
                                          </h6>
                                          <h3 className="mb-0">
                                            {data?.pendingApplication}
                                          </h3>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-6">
                                      <div
                                        className={`d-flex align-items-start bg-lightblue border-0 ${styles.timesheetData}`}
                                      >
                                        <div className="col-2 mt-1">
                                          <i>
                                            <img
                                              src={offeraccepted}
                                              alt=""
                                              className="img-fluid"
                                            />
                                          </i>
                                        </div>
                                        <div className="col-10">
                                          <h6 className="fw-medium mb-1">
                                            Offers Accepted
                                          </h6>
                                          <h3 className="mb-0">20</h3>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div
                                        className={`d-flex align-items-start bg-lightred border-0 ${styles.timesheetData}`}
                                      >
                                        <div className="col-2 mt-1">
                                          <i>
                                            <img
                                              src={rejectedapp}
                                              alt=""
                                              className="img-fluid"
                                            />
                                          </i>
                                        </div>
                                        <div className="col-10">
                                          <h6 className="fw-medium mb-1">
                                            Rejected Applicants
                                          </h6>
                                          <h3 className="mb-0">
                                            {data?.rejectedApplication}
                                          </h3>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* {data?.planType === "standard" &&
                                    (!data?.boosts ? (
                                      <div
                                        className={styles.ApplyBTN}
                                        onClick={() =>
                                          handleBoostContract(data._id)
                                        }
                                      >
                                        <button>Boost This Contract</button>
                                      </div>
                                    ) : (
                                      <div className={styles.ApplyBTN}>
                                        <button>
                                          Boosted On{" "}
                                          {moment(
                                            data.boosts?.startDate
                                          ).format("MM-DD-YYYY")}
                                          <p>
                                            Renewal{" "}
                                            {moment(
                                              data.boosts?.endDate
                                            ).format("MM-DD-YYYY")}
                                          </p>
                                        </button>
                                      </div>
                                    ))} */}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )
                  : ""}
              </Slider>
            </div>
          )}
        </>
      ) : ''}
      {modalDetail.show && (
        <CustomModal
          show={modalDetail.show}
          backdrop="static"
          showCloseBtn={true}
          isRightSideModal={false}
          mediumWidth={false}
          className={
            modalDetail.flag === "boostContract" ? "boostContract" : ""
          }
          child={
            modalDetail.flag === "boostContract" ? (
              <BoostContract
                onCloseModal={() => handleOnCloseModal()}
                workId={workId}
              />
            ) : null
          }
          onCloseModal={() => handleOnCloseModal()}
        />
      )}
    </div>
  );
};

export default OpenDirectJob;
