import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ADD_PAID_ADS } from '../../shared/enums';
import { ContractPaidAdsRootState } from '../../models/ContractPaidAdRootState';

const initialState = {
 data:null
};

const copyDataSlice = createSlice({
  name: 'copy',
  initialState,
  reducers: {
    setData: (state, action:any)=> {
        state.data=action.payload
    }
   
  },
});

export const {
 setData
} = copyDataSlice.actions;

export default copyDataSlice.reducer;