import React, { useEffect, useState } from "react";
import styles from "../../../../../components/contractBeforeLogin/styles/style.module.css";
import choosetype from "../../../../../assets/images/choosetype.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import { Dropdown, FormControl } from "react-bootstrap";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { getCitizenshipList } from "../../../../../feature/auth/signup/api";
import {
  setActiveStep,
  setAdditionalFields,
  setLocation,
} from "../../../../../redux/reducers/addDirectJob";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import { IOption } from "../../../../../Interfaces";
import tip from "../../../../../assets/images/tip.png";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";
import { toast } from "react-toastify";
import { locationSchema } from "../../../../work/validations";
import { DIRECT_JOB_STEPS } from "../Enums";
import Toltip from "../../../../common/toltip/page";
import {
  cities,
  states,
  streets,
} from "../../../../work/contractSteps/Constants/locationData";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";

const libraries: any = ["places"];

const AddLocationForDirectJob: React.FC = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIKEY as string,
    libraries,
  });
  const dispatch = useDispatch();
  const { location } = useSelector((state: RootState) => state.directJob);
  const {
    selectedCity,
    selectedCountry,
    selectedState,
    selectedStreet,
    selectedZipcode,
    isFilled,
  } = location;
  const { additionfiels } = useSelector((state: RootState) => state.directJob);
  const { workLocation } = additionfiels;
  const options = ["Remote", "Hybrid", "Onsite"];
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const [addressDetails, setAddressDetails] = useState({
    city: "",
    state: "",
    country: "",
    zipcode: "",
    streets: "",
  });

  const formik = useFormik({
    initialValues: {
      selectedCountry:selectedCountry.label,
      selectedStreet:selectedStreet.label,
      selectedState:selectedState.label,
      selectedCity:selectedCity.value,
      selectedZipcode:selectedZipcode.label,
      isFilled
    },
    validationSchema: locationSchema,
    onSubmit: (values) => {},
  });

  useEffect(() => {
    const getCitizenshipData = async () => {
      const response = await getCitizenshipList();
      response.data.forEach((x) => {
        x.label = x.name;
        x.value = x._id;
      });

      if (response.data.length > 0) {
        const usCountry = response.data.find(
          (x) => x.code.toLowerCase() === "us"
        );
      }
    };
    getCitizenshipData();
  }, []);

  const handleGoBack = () => {
    dispatch(setActiveStep(DIRECT_JOB_STEPS.ADD_KEYINFO));
  };

  const handleGoToNextStep = () => {
   
   
    if (formik.values.selectedCountry==="") {
      toast.error("Country is required!");
      return;
    } else if (formik.values.selectedState==="") {
      toast.error("State is required!");
      return;
    } else if (formik.values.selectedCity==="") {
      toast.error("City is required!");
      return;
    } else if (formik.values.selectedStreet==="") {
      toast.error("Street is required!");
      return;
    } else {
      dispatch(
        setLocation({
          ...location,
          selectedCity: { label: formik.values.selectedCity, value: formik.values.selectedCity },
          selectedState: { label: formik.values.selectedState, value: formik.values.selectedState },
          selectedCountry: { label: formik.values.selectedCountry, value: formik.values.selectedCountry },
          selectedZipcode: { label: formik.values.selectedZipcode, value: formik.values.selectedZipcode },
          selectedStreet: { label: formik.values.selectedStreet, value: formik.values.selectedStreet },
          isFilled: true,
        })
      );
      dispatch(setActiveStep(DIRECT_JOB_STEPS.ADD_RATES));
    }
  };

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      const addressComponents: any = place.address_components;
      const result = {
        city: "",
        state: "",
        country: "",
        zipcode: "",
        streets: "",
      };
      const streetComponents: string[] = [];

      addressComponents.forEach(
        (component: google.maps.GeocoderAddressComponent) => {
          const types = component.types;
          if (types.includes("locality")) {
            result.city = component.long_name;
          } else if (types.includes("administrative_area_level_1")) {
            result.state = component.long_name;
          } else if (types.includes("country")) {
            result.country = component.long_name;
          } else if (types.includes("postal_code")) {
            result.zipcode = component.long_name;
          } else if (types.includes("premise")) {
            streetComponents.push(component.long_name);
          } else if (types.includes("street_number")) {
            streetComponents.push(component.long_name);
          } else if (types.includes("route")) {
            streetComponents.push(component.long_name);
          } else if (
            types.includes("sublocality") ||
            types.includes("sublocality_level_1") ||
            types.includes("sublocality_level_2")
          ) {
            streetComponents.push(component.long_name);
          }
        }
      );
      result.streets = streetComponents.join(", ");
      formik.setFieldValue("selectedCity", result.city);
      formik.setFieldValue("selectedState", result.state);
      formik.setFieldValue("selectedCountry", result.country);
      formik.setFieldValue("selectedZipcode", result.zipcode);
      formik.setFieldValue("selectedStreet", result.streets);
      
      setAddressDetails(result);
    }
  };

  const onLoad = (autocomplete: any) => {
    setAutocomplete(autocomplete);
  };

  const onInputChange = (value: string | boolean, key: string) => {
    dispatch(setAdditionalFields({ ...additionfiels, [key]: value }));
  };

  return (
    <>
      <HeaderContent />
      <div className="heightautoscroll contractradio">
        <form onSubmit={formik.handleSubmit} className="h-100">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
                  <div className="col-lg-6">
                    <div className={styles.registerLeft}>
                      <div className="col-lg-11 col-12 mx-auto text-center">
                        <figure>
                          <img
                            src={choosetype}
                            alt=""
                            className="img-fluid w-100"
                          />
                        </figure>
                        <div className="form-group me-4">
                          <div className="topTipHover mb-2">
                            <label className={styles.starlabel}>
                              Select Work Location
                              {/* <sup className="text-danger">*</sup> */}
                            </label>
                            <i>
                              <img className="customeTolImg" src={tip} alt="" />
                            </i>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className={styles.dropdownContainer}>
                                <Dropdown>
                                  <Dropdown.Toggle
                                    id="dropdown-basic"
                                    name="travelTip"
                                  >
                                    {workLocation ||
                                      "Select your work location"}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <div
                                      style={{
                                        maxHeight: "300px",
                                        overflowY: "scroll",
                                      }}
                                    >
                                      {options.map((item) => (
                                        <Dropdown.Item
                                          key={item}
                                          onClick={() =>
                                            onInputChange(
                                              item,
                                              "workLocation"
                                            )
                                          }
                                        >
                                          {item}
                                        </Dropdown.Item>
                                      ))}
                                    </div>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <h4 className="text-white text-32 fw-semibold">
                      Address Of The Job Site
                    </h4>
                    <p className="text-16 text-white opacity-75">
                      Where will the work take place?
                    </p>
                    <div className={styles.signUPContainer}>
                      <div className={styles.registerRight}>
                        <form>
                          <div
                            className={styles.fromGroup}
                            style={{ marginBottom: "30px" }}
                          >
                            {" "}
                            <p className="text-white bg-gradient p-2 border text-14 rounded-3">
                              <strong>Note :</strong> This address will serve as
                              your default business address moving forward.
                            </p>
                            <div className={styles.ScrollDiv}>
                              <div
                                className={styles.fromGroup}
                                style={{ marginBottom: "30px" }}
                              >
                                <div className="topTipHover">
                                  <label className={styles.starlabel}>
                                    Start typing your address
                                  </label>
                                  <i>
                                    <img
                                      className="customeTolImg"
                                      src={tip}
                                      alt=""
                                    />
                                  </i>
                                  <Toltip
                                    slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                    labelId={
                                      CONSTANT_MANAGER.ADD_CONTRACT.COUNTRY
                                    }
                                  />
                                </div>

                                {isLoaded && (
                                  <Autocomplete
                                    onLoad={onLoad}
                                    onPlaceChanged={onPlaceChanged}
                                  >
                                    <input
                                      type="text"
                                      name="address"
                                      placeholder="Start typing your address"
                                    />
                                  </Autocomplete>
                                )}
                              </div>

                              <div
                                className={styles.fromGroup}
                                style={{ marginBottom: "30px" }}
                              >
                                {" "}
                                <div className="topTipHover">
                                  <label className={styles.starlabel}>
                                    Street
                                  </label>
                                  <i>
                                    <img
                                      className="customeTolImg"
                                      src={tip}
                                      alt=""
                                    />
                                  </i>
                                  <Toltip
                                    slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                    labelId={
                                      CONSTANT_MANAGER.ADD_CONTRACT.STATE
                                    }
                                  />
                                </div>
                                <input
                                  type="text"
                                  value={formik.values.selectedStreet}
                                  name="selectedStreet"
                                  onChange={formik.handleChange}
                                />
                              </div>
                              <div
                                className={styles.fromGroup}
                                style={{ marginBottom: "30px" }}
                              >
                                {" "}
                                <div className="topTipHover">
                                  <label className={styles.starlabel}>
                                    Country
                                  </label>
                                  <i>
                                    <img
                                      className="customeTolImg"
                                      src={tip}
                                      alt=""
                                    />
                                  </i>
                                  <Toltip
                                    slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                    labelId={CONSTANT_MANAGER.ADD_CONTRACT.CITY}
                                  />
                                </div>
                                <input
                                  type="text"
                                  value={formik.values.selectedCountry}
                                  name="selectedCountry"
                                  onChange={formik.handleChange}
                                />
                              </div>
                              <div
                                className={styles.fromGroup}
                                style={{ marginBottom: "30px" }}
                              >
                                {" "}
                                <div className="topTipHover">
                                  <label className={styles.starlabel}>
                                    State
                                  </label>
                                  <i>
                                    <img
                                      className="customeTolImg"
                                      src={tip}
                                      alt=""
                                    />
                                  </i>
                                  <Toltip
                                    slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                    labelId={
                                      CONSTANT_MANAGER.ADD_CONTRACT.STREET
                                    }
                                  />
                                </div>
                                <input
                                  type="text"
                                  value={formik.values.selectedState}
                                  name="selectedState"
                                  onChange={formik.handleChange}
                                />
                              </div>
                              <div
                                className={styles.fromGroup}
                                style={{ marginBottom: "30px" }}
                              >
                                {" "}
                                <div className="topTipHover">
                                  <label className={styles.starlabel}>
                                    City
                                  </label>
                                  <i>
                                    <img
                                      className="customeTolImg"
                                      src={tip}
                                      alt=""
                                    />
                                  </i>
                                  <Toltip
                                    slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                    labelId={CONSTANT_MANAGER.ADD_CONTRACT.CITY}
                                  />
                                </div>
                                <input
                                  type="text"
                                  value={formik.values.selectedCity}
                                  name="selectedCity"
                                  onChange={formik.handleChange}
                                />
                              </div>
                              <div
                                className={styles.fromGroup}
                                style={{ marginBottom: "0px" }}
                              >
                                {" "}
                                <div className="topTipHover">
                                  <label className={styles.starlabel}>
                                    Zipcode
                                  </label>
                                  <i>
                                    <img
                                      className="customeTolImg"
                                      src={tip}
                                      alt=""
                                    />
                                  </i>
                                  <Toltip
                                    slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                    labelId={
                                      CONSTANT_MANAGER.ADD_CONTRACT.STREET
                                    }
                                  />
                                </div>
                                <input
                                  type="text"
                                  value={formik.values.selectedZipcode}
                                  name="selectedZipcode"
                                  onChange={formik.handleChange}
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="row align-items-center justify-content-between">
                      <div className="col-4 text-start my-3 btn-hover-effect">
                        <h6
                          onClick={() => handleGoBack()}
                          className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton"
                        >
                          <span className="px-lg-4 px-2 text-12 fw-semibold d-inline-block">
                            Back
                          </span>
                          <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                            <img src={blackarrow} alt="" />
                          </i>
                        </h6>
                      </div>
                      <div className="col-8">
                        <div className="d-flex align-items-center jusify-content-end">
                          {/* <div className="col-6">
                            <SaveAsDraftButton />
                          </div> */}
                          <div className="col-12 text-end my-3 btn-hover-effect">
                            <h6
                              onClick={handleGoToNextStep}
                              className="btn arrowblue border-grey border-1 position-relative pe-5"
                            >
                              <span className="px-lg-4 px-2 text-12 fw-semibold d-inline-block">
                                Next
                              </span>
                              <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                                <img src={whitearrow} alt="" />
                              </i>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </form>
        </div>
    </>
  );
};

export default AddLocationForDirectJob;