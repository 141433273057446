import React, { useState, useEffect } from "react";
import { ModalBody, ModalFooter, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import security from "../../../../assets/images/security.svg";
import blackarrow from "../../../../assets/images/blackarrow.svg";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import upload from "../../../../assets/images/upload.svg";
import styles from "../styles/style.module.css";
import { uploadFile } from "../../../../common/api";
import { updateUserProfile } from "../../../home/api";
import { toast } from "react-toastify";

interface ModalProps {
  showModal: boolean;
  closeModal: () => void;
  title: string;
  refresh: () => void;
  editData: any;
}

const EditResumeModal: React.FC<ModalProps> = ({
  showModal,
  closeModal,
  title,
  refresh,
  editData
}) => {
  const [file, setFile] = useState<any>("");
  const [name, setName] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<{ name: string; file: string }>({
    name: "",
    file: "",
  });

  // Pre-fill data from editData when available
  useEffect(() => {
    if (editData) {
      setName(editData?.name || "");
      setFile(editData?.filename || "");
    }
  }, [editData]);

  // Handle file upload
  const handleFileUpload = async (files: FileList | null) => {
    if (files) {
      const uploadedFile = files[0];
      // Validate file type and size
      if (uploadedFile.type !== "application/pdf") {
        setError((prev) => ({ ...prev, file: "Only PDF files are allowed." }));
        return;
      }
      if (uploadedFile.size > 5000000) {
        setError((prev) => ({
          ...prev,
          file: "File size is too large. Maximum allowed size is 5MB.",
        }));
        return;
      }
      setError((prev) => ({ ...prev, file: "" })); // Clear file error if file is valid

      const formData = new FormData();
      formData.append("file", uploadedFile);
      try {
        const response = await uploadFile(formData);
        setFile(response.data.files); // Store the file URL or filename
      } catch (err) {
        setError((prev) => ({
          ...prev,
          file: "Error occurred while uploading the file.",
        }));
      }
    }
  };

  // Handle resume upload
  const handleUploadResume = async () => {
    let valid = true;
    
    // Validate name
    if (!name) {
      setError((prev) => ({ ...prev, name: "Name is required." }));
      valid = false;
    } else {
      setError((prev) => ({ ...prev, name: "" }));
    }

    // Validate file
    if (!file) {
      setError((prev) => ({ ...prev, file: "Resume file is required." }));
      valid = false;
    } else {
      setError((prev) => ({ ...prev, file: "" }));
    }

    if (!valid) return;
    setIsSubmitting(true)

    const payload = {
      resumeDoc: {
        filename: file,
        name: name,
      },
    };

    try {
      await updateUserProfile(payload);
      refresh(); // Refresh after successful upload
      setIsSubmitting(false)
      toast.success("Updated successfully")
      closeModal(); // Close the modal
    } catch (error) {
      setError((prev) => ({
        ...prev,
        file: "Error occurred while uploading the resume.",
      }));
    }
  };

  return (
    <Modal show={showModal} onHide={closeModal} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <ModalBody>
        <div className="addModalPro">
          <form>
            <div className="row">
              <div className="col-12 mb-3">
                <div className="bg-lightblue row align-items-center justify-content-center p-3 mx-0 rounded-2">
                  <div className="col-1 text-center">
                    <i>
                      <img src={security} alt="" className="img-fluid" />
                    </i>
                  </div>
                  <div className="col-11">
                    <p className="text-14 fw-medium text-black mb-0">
                    Upload your latest resume. This is crucial for clients to understand your background and qualifications."
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="formGroup">
                  <label>Name</label>
                  <div>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="form-control"
                      placeholder="Add Name"
                    />
                    {error.name && <p className="text-danger">{error.name}</p>}
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="formGroup">
                  <label>Upload Resume</label>
                  <div className={styles.uploadimagefile}>
                    <input
                      type="file"
                      accept="application/pdf"
                      className="form-control"
                      onChange={({ target }) => handleFileUpload(target.files)}
                    />
                    <div className={styles.uploadimage}>
                      <i>
                        <img src={upload} alt="" />
                      </i>
                      <p className="mb-0 text-14 text-blue">
                        Upload PDF file
                      </p>
                    </div>
                  </div>
                  {error.file && <p className="text-danger">{error.file}</p>}
                </div>
              </div>
            </div>
          </form>
        </div>
      </ModalBody>
      <ModalFooter className="btn-hover-effect">
        <Button
          type="button"
          variant="secondary"
          onClick={closeModal}
          className="btn bg-white arrowyellow border border-1 rounded-3 position-relative ps-5 backbutton w-auto"
        >
          <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
            Back
          </span>
          <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
            <img
              src={blackarrow}
              alt=""
              width={15}
              height={15}
              style={{ transform: "rotate(-180deg)" }}
            />
          </i>
        </Button>
        <Button
          type="button"
          variant="secondary"
          onClick={handleUploadResume}
          className="btn bg-white arrowblue border border-1 rounded-3 position-relative pe-5 w-auto"
        >
           <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
            {isSubmitting ? (
              <Spinner animation="border" size="sm" className="me-2" />
            ) : (
              "Save"
            )}
          </span>
          <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
            <img src={whitearrow} alt="" width={15} height={15} />
          </i>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditResumeModal;
