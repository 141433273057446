import { IOption } from '../Interfaces';
import { ADD_PAID_ADS } from '../shared/enums';

export class CompanyPaidAdsRootState {
  started: boolean = false;
  activeStep: ADD_PAID_ADS = ADD_PAID_ADS.AD_DESCRIPTION;
  contractType: string = 'paid_ads';
  adDescription: string = '';
  uploadedImages: string[];
  planId: string;
  planPrice: number | null;
  socialPostDesc: string = '';
  videoLink: string;
  currentPlan: string;
  companyName: string;
  occupation: Occupation;
  contactInfo: string;
  occupationsList: IOption[] = [];

  constructor(props?: Partial<CompanyPaidAdsRootState>) {
    Object.assign(this, props);
  }

  // Mapping UI model to Backend Model
}

export interface ChooseAdTypes {
  chooseAdType: string;
  descriptionOdAd: string;
}
export interface UploadImage {
  uploadImage: string;
}
export interface ChoosePromotionPlan {
  propmotionPlan: string;
}

export interface VideoLink {
  videoLink: string;
}

export interface CurrentPlan {
  currentPlan: string;
}

export interface CompanyName {
  companyName: string;
}

export interface Occupation {
  occupation: IOption[];
}

export interface ContactInfo {
  contactInfo: string;
}