import React, { FC, useEffect, useState } from "react";
import thdots from "../../../assets/images/thdots.png";
import speaker from "../../../assets/images/speaker.svg";
import topTip from "./styles/style.module.css";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";

import { ToltipProps } from "./interface";
import { getHelpTextByFilter } from "./api";

const Toltip: FC<ToltipProps> = ({ slug, labelId,tooltipClass }) => {
  console.log("Received tooltipClass:", tooltipClass); 
  const [toolTipText, setToolTipText] = useState<string>("");
  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();

  useEffect(() => {
    const fetchTooltipData = async () => {
      if (slug && labelId) {
        const response = await getHelpTextByFilter(slug, labelId);
        if (response.data) {
          setToolTipText(response.data.text);
        } else {
          console.error("Tooltip API response data is undefined.");
        }
      }
    };
    fetchTooltipData();
  }, [slug, labelId]);

  const startListening = () => {
    SpeechRecognition.startListening({ continuous: true });

  };

  const stopListening = () => {
    SpeechRecognition.stopListening();
  };

  if (!browserSupportsSpeechRecognition) {
    return <p>Browser does not support speech recognition.</p>;
  }

  return (
    <div className={`cuatomeTop ${tooltipClass} ${topTip.cuatomeToptopoltip}`}>
      <div className={`MobOTP ${topTip.OuterToptip}`}>
        <div className={topTip.Toltip}>
          <div className={`row align-items-center g-0 ${topTip.topTiphead}`}>
            <div className="col-9">
            <h5 className="m-0 text-capitalize clamp clamp1">What Opportunity Do You Want To Post?</h5>
            </div>
            <div className="col-3 text-end">
              <button className="ml-auto">
                <i><img src={speaker} alt="" width={30} height={30} /></i>
              </button>
            </div>
          </div>
          <div className={topTip.whtTol}>
            <p className="">{toolTipText}</p>
          </div>
          <div className={topTip.Listen}>
            {/* <span>
              <img src={thdots} alt="" width={"40px"} height={"40px"} />
            </span>
            <button
              className="cmbtn"
              onMouseDown={startListening}
              onMouseUp={stopListening}
              disabled={listening}
            >
              {listening ? "Listening...." : "Start Listening"}
            </button>
            <button className="cmbtn" onClick={resetTranscript}>
              Reset
            </button>
            <div>
              <strong>Transcript:</strong> <p>{transcript}</p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Toltip;
