import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import crossicon from '../../../../../assets/images/cross.svg'
import libality from '../../../../../assets/images/libality-ensurance.png'

interface LiabilityInsuranceModalProps {
  onClose: () => void;
  onNext:()=> void;
  showModal:boolean
  onUpload:()=>void
  onApply:()=>void
}

const LiabilityInsuranceModal: React.FC<LiabilityInsuranceModalProps> = ({ onClose,onNext,showModal,onUpload,onApply }) => (
<Modal onHide={onClose} show={showModal} centered className="modal-md">
    {/* <ModalHeader closeButton></ModalHeader> */}
      <ModalBody>
      <div className="modal_catch d-flex flex-column justify-content-center text-center rounded-0 pt-4 position-relative px-4">
      <div className="position-absolute" style={{top: "10px", right: "10px"}}>
          <i>
            <img src={crossicon} onClick={onClose} alt="" width="25px" height="25px" />
          </i>
        </div>
            <div className="text-center">
              <figure>
                <img src={libality} alt="" className="img-fluid mx-auto my-4" />
              </figure>
            </div>
            <h1 className="text-20 fw-bold">Liability Insurance</h1>
            <p className="text-14 mb-0">
            If you are selected for this contract and have not uploaded a valid copy of your business liability insurance policy, Automate America will cover you under our policy. The cost will be passed onto you at a rate of 5% of the hourly rate.
            <br/><strong>The cost will be passed onto you at a rate of 5% of the hourly rate</strong></p>
        </div>
      </ModalBody>
      
      <ModalFooter className=" btn-hover-effect justify-content-center border-top-0 mb-3">
        <div className="col-lg-10 col-12 mx-auto row align-items-center justify-content-center">
        <div className="col-5">
        <Button
          onClick={onUpload}
            type="button"
            variant="secondary"
            className="btn bg-white arrowyellow border border-1 rounded-2 position-relative h-48 backbutton w-100 mb-0"
          >
            <span className="text-12 fw-medium text-black">
            Upload Document 
            </span>
          </Button>
        </div>
        <div className="col-5">
          <Button
            type="button"
            variant="secondary"
            className="btn arrowyellow border border-1 rounded-2 position-relative h-48 backbutton w-100 mb-0"
            style={{backgroundColor: "#1EC20F"}}
          >
            <span className="text-12 fw-medium text-white">
          <a className="text-white text-decoration-none" href="https://www.hiscox.com/?ref=cj&?cjdata=MXxOfDB8WXww&utm_source=CJ&utm_medium=affiliate&utm_campaign=Automate+America&_vsrefdom=cjcalls&cjevent=f5175303a89e11ef8224002f0a18b8fb"> Apply Now</a>
            </span>
          </Button>
          </div>
          <div className="col-12 text-center">
          <Button
          onClick={onNext}
            type="button"
            className="position-relative w-auto p-0 bg-white border-0 mt-4"
          >
            <span className="text-12 fw-medium text-black text-capitalize">
            skip
            </span>
          </Button>
          </div>
          </div>
        </ModalFooter>
    </Modal>
);

export default LiabilityInsuranceModal;
