import React, { FC } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { ProductAdDescription, ProductAdImage, ProductAdChoosePlan, ProductBoostAd } from './interface';
import { ADD_PAID_ADS } from '../../../shared/enums/AddPaidAdsSteps';

const ProductAdFlow: FC = () => {
  const productPaidAds = useAppSelector((state: RootState) => state.productAd);
  const { activeStep } = productPaidAds;

  const renderActiveStep = () => {
    switch (activeStep) {
      case ADD_PAID_ADS.AD_DESCRIPTION:
        return <ProductAdDescription/>;
      case ADD_PAID_ADS.UPLOAD_IMAGE:
        return <ProductAdImage />;
      case ADD_PAID_ADS.BOOST_ADD:
        return <ProductBoostAd />;
      case ADD_PAID_ADS.ADD_PROMOTION:
        return <ProductAdChoosePlan />;
      default:
        return <ProductAdDescription/>;
    }
  };
  return renderActiveStep();
};

export default ProductAdFlow;
