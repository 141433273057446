import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { login } from '../api';
import { MESSAGES } from '../../../../utils/messages';
import { useLocation, useNavigate } from 'react-router-dom';
import { DEVICE_TYPE } from '../../../../utils/enums/deviceType';
import { AUTH_ROUTES } from '../../../../utils/routes/AuthRoutes';
import { APIResponseError } from '../interface';
import loginSchema from '../validations';
import styles from '../styles/login.module.css';
import loginleft from '../../../../assets/images/loginleft.png';
import logo from '../../../../assets/images/logo.png';
import eye from '../../../../assets/images/eye.svg';
import closeye from '../../../../assets/images/closeeye.svg';
import google from '../../../../assets/images/google.svg';
import facebook from '../../../../assets/images/facebook.svg';
import whitearrow from '../../../../assets/images/whitearrow.svg';
import linked from '../../../../assets/images/linkdin.svg';
import storage from '../../../../utils/storage';
import { authenticateUser } from '../../../../redux/reducers/authentication';
import { APP_ROUTES } from '../../../../utils/routes/AppRoutes';
import { RootState } from '../../../../redux/store';

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const searchParams = new URLSearchParams(location.search);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const deviceToken = storage.getDeviceToken();
  const isAuthenticated = useSelector((state: RootState) => state.authentication.isAuthenticated);
  console.log(isAuthenticated,"isssssssssssssssssssssssssssssssssssss")


  useEffect(() => {
    const savedRememberMe = localStorage.getItem("rememberMe") === "true";
    setRememberMe(savedRememberMe);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const handleGoToFeeds = () => {
    if (!isAuthenticated) {
      toast.warning("Please log in to access the feed.");
      return;
    }
    
    navigate(APP_ROUTES.FEEDS); 
  };


  const handleCheckboxChange = (e: any) => {
    const isChecked = e.target.checked;
    setRememberMe(isChecked);
    localStorage.setItem("rememberMe", isChecked);
  };

  const loginFormik = useFormik({
    initialValues: {
      userName: '',
      password: '',
      deviceToken: '',
      deviceType: '',
    },
    onSubmit: (values) => {
      loginUser(values.userName, values.password);
    },
    validationSchema: loginSchema,
  });

  const loginUser = (userName: string, password: string): void => {
    const payload = {
      userName: userName,
      password: password,
      deviceToken: deviceToken ? deviceToken : "c1Gl7rzZW5ldGIO",
      deviceType: DEVICE_TYPE.WEB,
    };
    toast.promise(login(payload), {
      pending: {
        render() {
          return MESSAGES.LOGIN.VERIFY;
        },
      },
      success: {
        render({ data }) {
          let response = data;
          if (response?.data?.isSkip) {
            console.log(response, "res")
            storage.setToken(response?.data?.authToken);
            storage.setAuthUserId(response?.data?.userId);
            dispatch(
              authenticateUser({
                ...response.data,
                isLoading: false,
                token: response.data.authToken,

              })
            );
            loginFormik.resetForm();
            navigate(APP_ROUTES.FEEDS);
            return response.message;
          }else{
            const redirectToPath = searchParams.get('redirectTo');
            const activeStep = searchParams.get('activeStep');
            if (redirectToPath && redirectToPath !== '') {
              navigate(
                `${AUTH_ROUTES.LOGIN_VERIFICATIONS}?verificationToken=${response.data.verificationToken}&userName=${userName}&redirectTo=${redirectToPath}&activeStep=${activeStep}`
              );
            } else {
              navigate(
                `${AUTH_ROUTES.LOGIN_VERIFICATIONS}?verificationToken=${response.data.verificationToken}&userName=${userName}`
              );
            }
  
            return response.message;
          }
          // loginFormik.resetForm();
          // const incomingRedirection = searchParams.get('redirectTo');
          // const incomingActiveStep = searchParams.get('activeStep');
          // const redirectToPath = incomingRedirection
          //   ? `${incomingRedirection}?activeStep=${incomingActiveStep}`
          //   : APP_ROUTES.FEEDS;
          // navigate(redirectToPath);
          // return response.message;
          // const redirectToPath = searchParams.get('redirectTo');
          // const activeStep = searchParams.get('activeStep');


          // if (redirectToPath && redirectToPath !== '') {
          //   navigate(
          //     `${AUTH_ROUTES.LOGIN_VERIFICATIONS}?verificationToken=${response.data.verificationToken}&userName=${userName}&redirectTo=${redirectToPath}&activeStep=${activeStep}`
          //   );
          // } else {
          //   navigate(
          //     `${AUTH_ROUTES.LOGIN_VERIFICATIONS}?verificationToken=${response.data.verificationToken}&userName=${userName}`
          //   );
          // }

        },
      },
      error: {
        render(error) {
          let errorMesage = error as APIResponseError;
          return errorMesage?.data?.data?.message;
        },
      },
    });
  };

  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return (
    <>
      {loading ? (
        <div className="spinner-grow text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <div className={styles.loginOuter}>
          <div className={styles.loginLeft}>
            <div className={styles.logo}>
              <img src={logo} alt="logo" />
            </div>
            <div className={styles.welcomeloginleft}>
              <span>Welcome to</span>
              <h2>Automate America!</h2>
              <p>
                Discover top talent for your projects and access online
                <br /> contracts seamlessly.
              </p>
            </div>
            <img src={loginleft} alt="loginleft" className="img-fluid w-75" />
          </div>
          <div className={styles.loginRight}>

            <div className={styles.topLogin}>
              <p className="mb-0">
                Don’t have an account? <a href="/personal-info">Sign up</a>
              </p>
              <button>Need Help?</button>
            </div>
            <form onSubmit={loginFormik.handleSubmit}>
              <h4>Login</h4>
              <p>Welcome back! Please enter email and password</p>
              <div className={styles.formGroup}>
                <label>Phone Number / Email address</label>
                <input
                  type="text"
                  placeholder="example@gmail.com"
                  {...loginFormik.getFieldProps('userName')}
                />
                {loginFormik.touched.userName && loginFormik.errors.userName && (
                  <div className={styles.error}>
                    {loginFormik.errors.userName}
                  </div>
                )}
              </div>
              <div className="col-12">
                <div className={styles.formGroup}>
                  <label>
                    Password <a href="/forgot-password">Forget Password?</a>
                  </label>
                  <div className='position-relative'>
                  <input
                    type={passwordShown ? 'text' : 'password'}
                    placeholder="Password"
                    {...loginFormik.getFieldProps('password')}
                  />
                 
                  <span onClick={togglePasswordVisibility}>
                    {passwordShown ? (
                      <img src={eye} alt="" width={20} height={20} />
                    ) : (
                      <img src={closeye} alt="" width={20} height={20} />
                    )}
                  </span>
                  </div>
                  {loginFormik.touched.password &&
                    loginFormik.errors.password && (
                      <div className={styles.error}>
                        {loginFormik.errors.password}
                      </div>
                    )}
                </div>
              </div>
              <div className="">
                <div className={styles.checkboxwrapper}>
                  <input
                    type="checkbox"
                    id="check-23"
                    checked={rememberMe}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="check-23">
                    <svg viewBox="0,0,50,50">
                      <path d="M5 30 L 20 45 L 45 5"></path>
                    </svg>
                  </label>
                  <span className="ps-2 fw-normal">Remember this device</span>
                </div>
              </div>
              <div className="">
                <div className="text-center my-3 btn-hover-effect">
                  <button
                    type="submit"
                    className="btn arrowblue border-grey border-1 rounded-3 position-relative w-100 pe-5 text-center"
                  >
                    <span className="px-lg-4 px-2 text-12 fw-semibold w-100">
                      Login
                    </span>
                    <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                      <img src={whitearrow} alt="" />
                    </i>
                  </button>
                </div>
              </div>
              <h6 className={styles.feedText}>
                <a href="#" onClick={handleGoToFeeds}>Go to Feed</a>
              </h6>
              <div className={styles.Or}>
                <span>or login using</span>
                <hr />
              </div>
              <div className={styles.socialLogin}>
                <ul>
                  <li>
                    <img src={google} alt="" />
                  </li>
                  <li>
                    <img src={facebook} alt="" />
                  </li>
                  <li>
                    <img src={linked} alt="" />
                  </li>
                </ul>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );

};

export default Login;
