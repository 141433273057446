import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import storage from "../../../../utils/storage";
import { loadStripe } from "@stripe/stripe-js";
import { BOOST_TYPE } from "../../../../utils/enums/types";
import rdinfo from "../../../../assets/images/rdinfo.png";
import applepy from "../../../../assets/images/applepy.png";
import visacard from "../../../../assets/images/visacard.png";
import paypal from "../../../../assets/images/paypal.png";
import { PLAN_RESPONSE } from "../../../bostprofile/interface";
import styles from "../../../bostprofile/styles/style.module.css";
import { ConnectionPlanRes } from "../interface";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { getConnectionPlans, postBoostConnections } from "../api";
import { postBoostProfile } from "../../../bostprofile/api";

const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`
);

const ConnectionPayment = () => {
  const location = useLocation();
  const {
    connectionId,
    connectionPrice,
    connectionPlanType,
    connectionDiscount,
  } = useSelector((state: RootState) => state.connectionsPlan);
  const [isUntilPaused, setIsUntilPaused] = useState<boolean>(false);
  const [planID, setPlanID] = useState<any>(connectionId);
  const [selectedPlan, setSelectedPlan] = useState<ConnectionPlanRes | null>(
    null
  );
  const [planData, setPlanData] = useState<ConnectionPlanRes[]>([]);
  
  let [total, setTotal] = useState<any>();

  const handlePlanChange = (plan: ConnectionPlanRes) => {
    setSelectedPlan(plan);
    setPlanID(plan._id);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsUntilPaused(event.target.checked);
  };

  const _fetchPlans = async () => {
    if (connectionPlanType === "monthly") {
      const res = await getConnectionPlans("monthly");
      setPlanData(res?.data);
    }
    if (connectionPlanType === "yearly") {
      const res = await getConnectionPlans("yearly");
      setPlanData(res?.data);
    }
  };

  // const _totalPrice = () => {
  //   if (connectionPlanType === "monthly") {
  //     setTotal(selectedPlan?.price || connectionPrice);
  //   }
  //   if (connectionPlanType === "yearly") {
  //     if (selectedPlan?.price) {
  //       setTotal(selectedPlan?.price - selectedPlan?.discount);
  //     } else {
  //       setTotal(connectionPrice - connectionDiscount);
  //     }
  //   }
  // };

  useEffect(() => {
    _fetchPlans();
  }, []);

  // useEffect(() => {
  //   _totalPrice();
  // }, [selectedPlan]);

  const handleCheckOut = async () => {
    if (planData) {
      const payload = {
        subscriptionPlanId: planID,
      };
      try {
        const response = await postBoostConnections(payload);
        const sessionId = response.data.sessionId;
        storage.setSessionId(sessionId);
        const stripe = await stripePromise;
        if (stripe) {
          const { error } = await stripe.redirectToCheckout({
            sessionId: sessionId,
          });
          if (error) {
            console.error("Stripe Checkout error:", error);
          }
        }
      } catch (err) {
        console.log("Error redirecting to checkout:", err);
      }
    }
  };

  return (
    <div
      className={`col-12 py-4 connectionpaymenttab ${styles.connectionpaymenttab}`}
    >
      <div className="row align-items-center mx-auto">
        <h3 className="text-48 mb-2 text-center">Payment</h3>
        <div className={`pt-0 ${styles.boostProfile}`}>
          <div className="container">
            <div className={styles.boostBks}>
              <div className={styles.boastPayment}>
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <div className={styles.bostpaymentleft}>
                      <div className={styles.formGroup}>
                        <h5 className="mb-2">Set Duration</h5>
                        <Dropdown>
                          <Dropdown.Toggle id="dropdown-basic" name="travelTip">
                            {selectedPlan
                              ? `$${selectedPlan?.price} ${" "} ${
                                  selectedPlan?.type
                                }`
                              : `$${connectionPrice} ${connectionPlanType}`}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <div
                              style={{
                                maxHeight: "300px",
                                overflowY: "scroll",
                              }}
                            >
                              {planData && planData.length > 0 ? (
                                planData.map((plan, index) => (
                                  <Dropdown.Item
                                    key={index}
                                    onClick={() => handlePlanChange(plan)}
                                  >
                                    ${plan?.price} {plan?.type}
                                  </Dropdown.Item>
                                ))
                              ) : (
                                <Dropdown.Item disabled>
                                  No plans available
                                </Dropdown.Item>
                              )}
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="row align-items-center justify-content-between my-3">
                        <div className="col-9">
                          <p className="text-14 fw-medium">
                            Run this ad until I pause it
                          </p>
                        </div>
                        <div className="col-3 text-end">
                          <div className={styles.checkboxwrap}>
                            <label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="runUntilPaused"
                                id="runUntilPaused"
                                checked={isUntilPaused}
                                onChange={handleRadioChange}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className={styles.formGroup}>
                        <h5 className="mb-3 text-black">Payment Methods</h5>
                        <div className={styles.selctCard}>
                          <h6>
                            <img src={applepy} alt="" />
                            Apple Pay
                          </h6>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="exampleRadios"
                              id="exampleRadios1"
                              value="option1"
                              disabled
                            />
                          </div>
                        </div>
                        <div className={styles.selctCard}>
                          <h6>
                            <img src={visacard} alt="" />
                            Credit / Debit Card{" "}
                            <label>Visa, Mastercard, AMEX, CUP, JCB</label>
                          </h6>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="exampleRadios"
                              id="exampleRadios1"
                              value="option1"
                              defaultChecked
                            />
                          </div>
                        </div>
                        <div className={styles.selctCard}>
                          <h6>
                            <img src={paypal} alt="" />
                            Paypal
                          </h6>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="exampleRadios"
                              id="exampleRadios1"
                              value="option1"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className={styles.paymentForm}>
                      <h5 className="mb-3 text-black">Payment Summery</h5>
                      <div className={styles.paymentBox}>
                        <h6>Duration</h6>
                        <label>
                          {connectionPlanType}
                        </label>
                        <ul>
                          <li>
                            Subtotal{" "}
                            <span>
                              ${selectedPlan?.price || connectionPrice}
                            </span>
                          </li>
                          {/* <li>
                            Discounts{" "}
                            <span>
                              -$
                              {selectedPlan?.type === "yearly" ||
                              connectionPlanType === "yearly"
                                ? selectedPlan?.discount || connectionDiscount
                                : 0}
                            </span>
                          </li> */}
                          <li>
                            Total{" "}
                            <span>
                              ${selectedPlan?.price || connectionPrice}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <button className="text-capitalize" onClick={handleCheckOut}>Pay now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectionPayment;
