import React, { useState } from "react";
import styles from "../../css/styles/style.module.css";
import tip from "../../../../assets/images/info_icon.svg";
import Toltip from "../../toltip/page";
import DraftIndex from "./ContractDraft/DraftIndex";
import PendingIndex from "./ContractPending/PendingIndex";
import ChatModule from "../../../ChatModule/routes";

const DraftPending = () => {
  const [showChatModal, setShowChatModal] = useState<boolean>(false);
  const [data, setData] = useState<boolean>(false);

  const _handleOpenAdminChat = () => {
    setShowChatModal(true);
  };
  console.log(data, "data");

  return (
    <>
      <div className={`bg-white p-lg-4 p-2 shadow rounded-3 mt-3`}>
        <div className={`${styles.AllContracta}`}>
          <div className="row align-items-center justify-content-between mx-0 g-0">
            <div className="col-12">
              <div className="topTipHover">
                <div className="d-flex align-items-center">
                  <h4 className="form-check-label text-20 fw-bold font-inter mb-0">
                    Contracts Saved in Draft
                  </h4>
                  <i>
                    <img className="customeTolImg" src={tip} alt="" />
                  </i>
                  {data === false ? (
                    <span className="badge rounded-3 bg-lightblue text-black text-16 fw-semibold px-3 py-2 ms-2">
                      0
                    </span>
                  ) : (
                    ""
                  )}
                  <Toltip />
                </div>
              </div>
            </div>
          </div>

          <div className={`mt-3 ${styles.tabingnav}`}>
            <nav className="bg-lightblue navtabing">
              <div className="nav nav-tabs px-2" id="nav-tab" role="tablist">
                <button
                  className={`nav-link active`}
                  id="nav-draft-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-draft"
                  type="button"
                  role="tab"
                  aria-controls="nav-draft"
                  aria-selected="true"
                >
                  Draft Contract
                </button>
                <button
                  className={`nav-link`}
                  id="nav-pending-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-pending"
                  type="button"
                  role="tab"
                  aria-controls="nav-pending"
                  aria-selected="false"
                >
                  Contracts Pending Admin Approval
                </button>
              </div>
            </nav>

            <div className="tab-content py-3 px-2 bg-light" id="nav-tabContent">
              <DraftIndex setData={setData} />
              <PendingIndex />
            </div>
          </div>
        </div>
      </div>

      {showChatModal && <ChatModule onClose={() => setShowChatModal(true)} />}
    </>
  );
};

export default DraftPending;
