import React from "react";
import styles from "../../../../../components/contractBeforeLogin/styles/style.module.css";
import choosetype from "../../../../../assets/images/choosetype.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import {
  setActiveStep,
  setKeyInformation,
} from "../../../../../redux/reducers/addDirectJob";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import tip from "../../../../../assets/images/tip.png";
import Toltip from "../../../../common/toltip/page";
import { DIRECT_JOB_STEPS } from "../Enums";
import DatePicker from "react-datepicker";
import {timing} from "../../../../work/validations";

const AddTimingDirectJob = () => {
  const dispatch = useDispatch();
  const { keyInformations } = useSelector(
    (state: RootState) => state.directJob
  );

  const formik: any = useFormik({
    initialValues: {
      startDate: keyInformations.startDate,
    },
    validationSchema: timing,
    onSubmit: (value) => {
      dispatch(setActiveStep(DIRECT_JOB_STEPS.ADD_KEYINFO));
    },
  });

  const handleGoBack = () => {
    dispatch(setActiveStep(DIRECT_JOB_STEPS.ADD_DESCIPTION));
  };

  const onChangeDate = (value: Date | string | number | null, key: string) => {
    formik.setFieldValue(key, value);
    dispatch(
      setKeyInformation({
        ...keyInformations,
        startDate: keyInformations.startDate,
        [key]: value,
      })
    );
  };

  return (
    <>
      <HeaderContent />
      <div className="heightautoscroll contractradio">
        <form onSubmit={formik.handleSubmit} className="h-100">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
                    <div className="col-lg-6">
                      <div className={styles.registerLeft}>
                        <div className="col-lg-11 col-12 mx-auto text-center">
                          <figure>
                            <img
                              src={choosetype}
                              alt=""
                              className="img-fluid w-100"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                    <h4 className="text-white text-32 fw-semibold">
                          {" "}
                          Timing
                        </h4>
                        <p className="text-16 text-white opacity-75">
                          Tell us when the project starts?
                        </p>
                      {/* <div className={styles.ScrollDiv}> */}
                       

                        <div className={styles.signUPContainer}>
                          <div className={styles.registerRight}>
                            <div className={styles.fromGroup}>
                              {" "}
                              <div className="topTipHover">
                                <label className={styles.starlabel}>
                                  Start Date
                                </label>{" "}
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                                <Toltip />
                              </div>
                              <DatePicker
                                selected={keyInformations.startDate}
                                onChange={(date) =>
                                  onChangeDate(date, "startDate")
                                }
                                onBlur={formik.handleBlur}
                                className="form-control rounded-3 h-48"
                                placeholderText="StartDate"
                                minDate={new Date()}
                                dateFormat="MM-dd-yyyy"
                              />
                              {formik.touched.startDate &&
                              formik.errors.startDate ? (
                                <div className={styles.error}>
                                  {formik.errors.startDate}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      {/* </div> */}
                      <div className="row align-items-center justify-content-between">
                        <div className="col-4 text-start my-3 btn-hover-effect">
                          <h6
                            onClick={() => handleGoBack()}
                            className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton"
                          >
                            <span className="px-lg-4 px-2 text-12 fw-semibold d-inline-block">
                              Back
                            </span>
                            <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                              <img src={blackarrow} alt="" />
                            </i>
                          </h6>
                        </div>
                        <div className="col-8">
                          <div
                            className="d-flex align-items-center jusify-content-end"
                            style={{ gap: "10px" }}
                          >
                            <div className="col-12 text-end my-3 btn-hover-effect">
                              <button
                                type="submit"
                                className="btn arrowblue border-white border-1 position-relative pe-5"
                              >
                                <span className="px-lg-4 px-2 text-12 fw-semibold d-inline-block">
                                  Next
                                </span>
                                <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                                  <img src={whitearrow} alt="" />
                                </i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
          </form>
        </div>
    </>
  );
};

export default AddTimingDirectJob;
