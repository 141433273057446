import React, { FC, useState } from "react";
import styles from "./styles/style.module.css";
import { ExpensePayload } from "./interface";
import { toast } from "react-toastify";
import { addexpense } from "./api";
import { MESSAGES } from "../../../utils/messages";

import Swal from 'sweetalert2';
import { WORK_STATUS_TYPE } from "../../../utils/enums/status";
import { APIResponseError } from "../../work/interface";


interface Props {
  onClose: () => void;
  workId: any;
}

const AddExpense: FC<Props> = ({ onClose, workId }) => {
  const [showApprovalBtn, setShowApprovalBtn] = useState<boolean>(false);
  const [showAddBtn, setShowAddBtn] = useState<boolean>(true);
  const [date, setDate] = useState('');
  const [perdiem, setPerdiem] = useState('');
  const [travelDayPerDiem, setTravelDayPerDiem] = useState('');
  const [hotel, setHotel] = useState('');
  const [miles, setMiles] = useState('');
  const [mileage, setMileage] = useState('');
  const [rentalCarFuel, setRentalCarFuel] = useState('');
  const [fuelCharge, setFuelCharge] = useState('');
  const [airfare, setAirfare] = useState('');
  const [luggageFee, setLuggageFee] = useState('');
  const [dailyCoveredTotal, setDailyCoveredTotal] = useState('');

  const handleAddExpense = async (e: any) => {
    e.preventDefault();

    const payload: ExpensePayload = {
      workId: workId,
      total: 4,
      status: WORK_STATUS_TYPE.DRAFT,
      expenseDetails: [
        {
          workedDate: "2024-09-26T18:30:00.000+00:00",
          perDiem: 4,
          travelDayPerDiem: 4,
          hotel: 10,
          miles: 10,
          milage: 10,
          rentalCarFuel: 10,
          fuelCharge: 10,
          airfare: 10,
          luggageFee: 10,
          dailyTotal: 10,
          dailyCoveredTotal: 10,
        },
      ],
    };
    try {
      await toast.promise(addexpense(payload), {
        pending: MESSAGES.POSTING_CONTRACTS.VERIFYING,
        success: {
          render() {
            setShowApprovalBtn(true);
            setShowAddBtn(false);
            Swal.fire({
              title: 'Congratulations!',
              text: 'Expense added successfully!',
              icon: 'success',
              confirmButtonText: 'OK'
            });
            onClose()
            return "Expense added successfully!";
          },
        },
        error: {
          render({ data }: APIResponseError) {
            return data?.data?.message;
          },
        },
      });
    } catch (error) {
      console.error("Failed to add expense:", error);
    }
  };

  return (
    <div className="modal" style={{ display: "block" }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add New Expense Report</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              onClick={onClose}
            >
            </button>
          </div>
          <div className="modal-body">
            <div className={styles.expenseModal}>
              <form onSubmit={handleAddExpense}>
                <div className={styles.formGroup}>
                  <label>Add Details</label>
                  <input
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    placeholder="Date"
                  />
                </div>
                <div className={styles.formGroup}>
                  <input
                    type="number"
                    value={perdiem}
                    onChange={(e) => setPerdiem(e.target.value)}
                    placeholder="Enter perdiem"
                  />
                </div>
                <div className={styles.formGroup}>
                  <input
                    type="number"
                    value={travelDayPerDiem}
                    onChange={(e) => setTravelDayPerDiem(e.target.value)}
                    placeholder="Enter travelDayPerDiem"
                  />
                </div>
                <div className={styles.formGroup}>
                  <input
                    type="number"
                    value={hotel}
                    onChange={(e) => setHotel(e.target.value)}
                    placeholder="Enter hotel"
                  />
                </div>
                <div className={styles.formGroup}>
                  <input
                    type="number"
                    value={miles}
                    onChange={(e) => setMiles(e.target.value)}
                    placeholder="Enter miles"
                  />
                </div>
                <div className={styles.formGroup}>
                  <input
                    type="number"
                    value={mileage}
                    onChange={(e) => setMileage(e.target.value)}
                    placeholder="Enter mileage"
                  />
                </div>
                <div className={styles.formGroup}>
                  <input
                    type="number"
                    value={rentalCarFuel}
                    onChange={(e) => setRentalCarFuel(e.target.value)}
                    placeholder="Enter rentalCarFuel"
                  />
                </div>
                <div className={styles.formGroup}>
                  <input
                    type="number"
                    value={fuelCharge}
                    onChange={(e) => setFuelCharge(e.target.value)}
                    placeholder="Enter fuelCharge"
                  />
                </div>
                <div className={styles.formGroup}>
                  <input
                    type="number"
                    value={airfare}
                    onChange={(e) => setAirfare(e.target.value)}
                    placeholder="Enter airfare"
                  />
                </div>
                <div className={styles.formGroup}>
                  <input
                    type="number"
                    value={luggageFee}
                    onChange={(e) => setLuggageFee(e.target.value)}
                    placeholder="Enter luggageFee"
                  />
                </div>
                <div className={styles.formGroup}>
                  <input
                    type="number"
                    value={dailyCoveredTotal}
                    onChange={(e) => setDailyCoveredTotal(e.target.value)}
                    placeholder="Enter dailyCoveredTotal"
                  />
                </div>
                <div className={styles.formGroup}>
                    <input type="submit" value="Send for Approval" />
                  </div>
                {/* {showApprovalBtn && (
               
                )} */}
{/* 
                <button type="submit">Save</button> */}
              </form>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddExpense;
