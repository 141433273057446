import React, { useEffect, useState } from "react";
import styles from "../../css/styles/style.module.css";
import tip from "../../../../../src/assets/images/info_icon.svg";
import Toltip from "../../toltip/page";
import { CONSTANT_MANAGER } from "../../../../utils/constans/constants";
import OpenedProf from "../components/OpenedProf";
import RejectedProf from "../components/RejectedProf";

const CustomerRequest = () => {
  const [data, setData] = useState<boolean>(false);

  return (
    <>
      <div className={`bg-white p-lg-4 p-2 shadow rounded-3 mt-3`}>
        <div className={`${styles.AllContracta}`}>
          <div className="row align-items-center justify-content-between mx-0 g-0">
            <div className="col-12">
              <div className="topTipHover">
                <div className="d-flex align-items-center">
                  <h4 className="form-check-label text-20 fw-bold font-inter mb-0">
                    Professionals Requested
                  </h4>
                  <i>
                    <img className="customeTolImg" src={tip} alt="" />
                  </i>
                  {data === false ? <span className="badge rounded-3 bg-lightblue text-black text-16 fw-semibold px-3 py-2 ms-2">0</span> : ''}
                  <Toltip
                    slug={CONSTANT_MANAGER.SLUG_HOME.HOME}
                    labelId={
                      CONSTANT_MANAGER.HOME_DATA.REQUESTS_FROM_CUSTOMERS.DATA
                        .REQUESTS_FROM_CUSTOMERS
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`mt-3 ${styles.tabingnav}`}>
          <nav className="bg-lightblue navtabing">
            <div className="nav nav-tabs px-2" id="nav-tab" role="tablist">
              <button
                className={`nav-link active`}
                id="nav-open-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-open"
                type="button"
                role="tab"
                aria-controls="nav-open"
                aria-selected="true"
              >
                Open Requests
              </button>
              <button
                className={`nav-link`}
                id="nav-rejected-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-rejected"
                type="button"
                role="tab"
                aria-controls="nav-rejected"
                aria-selected="false"
              >
                Rejected Requests
              </button>
            </div>
          </nav>
          <div className="tab-content py-3 px-2 bg-light" id="nav-tabContent">
            <OpenedProf setData={setData}/>
            <RejectedProf />
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerRequest;
