import React, { useEffect, useState } from "react";
import styles from "../../../../../components/common/jobs/styles/style.module.css";
import Slider from "react-slick";
import loc from "../../../../../assets/images/icon_location.svg"
import cal from "../../../../../assets/images/calender_iconblue.svg"
import orbag from "../../../../../assets/images/bag_orange.svg"

import { Link } from "react-router-dom";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";
import tip from "../../../../../../src/assets/images/info_icon.svg";
import Toltip from "../../../toltip/page";
import { RootState } from "../../../../../redux/store";
import { useSelector } from "react-redux";
import { ContractResponse } from "../../interface";
import {
  getRejectedJobProfessional,
  getRejectedJobProfessionalDirect,
} from "../api";
import { WORK_TABS } from "../../../../../utils/enums/workTabs.enum";
import {
  CONTRACT_Type,
  JOB_STATUS,
  PLAN_TYPE,
} from "../../../../../utils/enums/types";
import { WORK_STATUS_TYPE } from "../../../../../utils/enums/status";

function JobOfferRejectedDirect() {
  const dashboardData = useSelector((state: RootState) => state.dashboard);
  const { activeTab } = dashboardData;
  const [rejectedJob, setRejectedJobs] = useState<ContractResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchRejectedJobs = async () => {
      setLoading(true);
      try {
        let response: ContractResponse | any;
        if (activeTab === WORK_TABS.DONEBYME) {
          response = await getRejectedJobProfessionalDirect(
            CONTRACT_Type.DIRECT_JOB,
            WORK_STATUS_TYPE.ONGOING,
            JOB_STATUS.REJECTED
          );
        } else {
          response = { data: [] };
        }
        setRejectedJobs(response.data);
      } catch (error) {
        console.error("Error fetching ongoing contracts:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchRejectedJobs();
  }, [activeTab]);

  const Jobslide = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    centerPadding: "60px",
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: rejectedJob.length > 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: Math.min(2.1, rejectedJob.length),
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: rejectedJob.length > 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: Math.min(1.2, rejectedJob.length),
          slidesToScroll: 1,
          infinite: rejectedJob.length > 1,
        },
      },
    ],
  };

  return (
    <>
    <div className={`bg-white p-lg-4 p-2 shadow rounded-3 mt-3`}>
        <div className={`${styles.AllContracta}`}>
          <div className="row align-items-center justify-content-between mx-0 g-0">
            <div className="col-lg-5 col-12">
              <div className="topTipHover">
                <div className="d-flex align-items-center">
                  <h4 className="form-check-label text-20 fw-bold  font-inter mb-0">
                    Job Offer | Rejected
                  </h4>
                  <i>
                    <img className="customeTolImg" src={tip} alt="" />
                  </i>
                  {rejectedJob?.length > 0 ? '' : <span className="badge rounded-3 bg-lightblue text-black text-16 fw-semibold px-3 py-2 ms-2">0</span>}
                  <Toltip
                      slug={CONSTANT_MANAGER.SLUG_HOME.HOME}
                      labelId={CONSTANT_MANAGER.HOME_DATA.JOBS.DATA.JOBS}
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
    
        <div className={`${styles.ContractTabs}`}>
        {loading ? (
          <div className="mt-3">
            <div className="spinner-grow text-primary" role="status">
              <span className="sr-only">Loading jobs...</span>
            </div>
          </div>
        ) : rejectedJob?.length > 0 ? (
          <Slider {...Jobslide}>
            {rejectedJob?.map((job, index) => (
              <div key={index} className={`mt-3 ${styles.JobSlider}`}>
                 <div className='row align-items-center g-0'>
                <div className='col-6'> 
                  <h5 className='clamp clamp1'>
                   {job.contractor.occupation.name || "developer"} 
                  </h5>
                </div>
                <div className='col-6 text-end'>
                  <span className={'py-2 rounded-2 px-2 mb-0 d-inline-flex align-items-center justify-content-center border-lightorange bg-lightorange text-lightorange text-capitalize text-12'}>
                    <i className='me-2'><img src={orbag} alt="Job type" /></i> {job?.workType}</span>
                </div>
                </div>
                <h6 className='mb-2 mt-2 clamp clamp1'>
                  <i className='d-inline-block'><img src={loc} alt="Location" width={12} height={15} /></i>{job?.address || 'City, State'}
                </h6>
                <h6>
                  <i><img src={cal} alt="Calendar" width={18} height={20} /></i>  {job?.times?.startDate
                      ? new Date(job.times.startDate).toLocaleDateString("en-US", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                      })
                      : "N/A"}
                  </h6>
                  <p>{job?.description || "No description provided."}</p>
                </div>
              ))}
            </Slider>
          ) : ''}
        </div>
      </div>
    </>
  );
}

export default JobOfferRejectedDirect;
