import React, { useEffect, useMemo, useState } from "react";
import styles from "../styles/style.module.css";
import tip from "../../../../assets/images/tip.png";
import penbl from "../../../../assets/images/penbl.png";
import selfrating from "../../../../assets/images/self_rating.svg";
import thumb from "../../../../assets/images/profile_picture.png";
import OtherSkillModal from "../modals/OtherSkillModal";
import { TechSkills } from "../../interface";
import { Link } from "react-router-dom";

interface ModalProps {
  otherSkill: TechSkills[];
  refresh: () => void;
}

const OtherSkills: React.FC<ModalProps> = ({ otherSkill = [], refresh }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editData, setEditData] = useState<TechSkills | null>(null);
  const [indexNumber, setIndexNumber] = useState<number | null>(null);
  const [skillName, setSkillName] = useState("");
  const [about, setAbout] = useState<any>("");
  const [manufacturer, setManufacturer] = useState("");
  const [model, setModel] = useState("");
  const [img, setImg] = useState<any>("");
  const [otherSkillData, setOtherSkillData] = useState<any[]>([]);

  const filteredSkills = useMemo(() => {
    return otherSkill
      .filter((skill) => skill?.type === "other")
      .map((skill, index) => ({ ...skill, index }));
  }, [otherSkill]);

  useEffect(() => {
    setOtherSkillData(filteredSkills);
  }, [filteredSkills]);


  console.log("otherSkillData", otherSkillData);

  const openModal = (data?: TechSkills, index?: number) => {
    setEditData(data || null); // Set data to edit or null for new entry
    setIsModalOpen(true);
    console.log("index---", index);
    if (index !== null && index !== undefined && index >= 0) {
      console.log("index-if", index);
      setIndexNumber(index);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditData(null); // Clear data when closing modal
  };

  return (
    <div className={styles.AboutEdit}>
      <div className=" mt-3">
        <div className="row align-items-center">
          <div className="col-10">
            <div className="topTipHover">
              <div className="d-flex align-items-center">
                <h4 className="text-20 fw-bold  font-inter mb-0">
                  Add additional skills*
                </h4>
                <i>
                  <img className="customeTolImg" src={tip} alt="" />
                </i>
              </div>
            </div>
          </div>
          {/* <div className="col-2 text-end">
            <i>
              {otherSkillData ? (
                <img
                  src={penbl}
                  className="cursor-pointer"
                  alt=""
                  onClick={() => openModal()}
                />
              ) : (
                <Link to={""} onClick={() => openModal()}>
                  Add Other Skill
                </Link>
              )}
            </i>
          </div> */}
        </div>
        {otherSkillData?.length > 0
          ? otherSkillData?.map((data, ind) => (
              <div key={ind} style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
                padding: "5px",
              }}>
                <div>
                  <div
                    className={styles.personalinfo}
                    style={{ marginTop: "20px" }}
                  >
                    <span className="d-block text-14">Skill Name*</span>
                    <label>{data?.occupationDetail?.name}</label>
                  </div>
                  <div className="newheadingstyle mt-3">
                    <h4 className="text-16 fw-semibold mb-2">About</h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data?.description ? data?.description : "",
                      }}
                    />
                    <h5 className="text-14">
                      Self Rating :{" "}
                      <i>
                        <img src={selfrating} alt="" />
                      </i>
                    </h5>
                    <ul
                      className={styles.personalinfo}
                      style={{ marginBottom: "0" }}
                    >
                      <li className="mb-3">
                        <span className="d-block text-14">Manufacturer</span>
                        <label>{data?.manufacturerDetail?.name}</label>
                      </li>
                      <li className="mb-3">
                        <span className="d-block text-14">
                          Model/Version/Controller
                        </span>
                        <label>{data?.manufacturerModelDetail?.name}</label>
                      </li>
                      <li className="">
                        <span className="d-block text-14">Image</span>
                        <img
                          src={`${data?.image}`}
                          alt=""
                          className="rouned-2"
                          width={100}
                          height={100}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <img
                    src={penbl}
                    className="cursor-pointer"
                    alt=""
                    onClick={() => openModal(data,ind)}
                  />
                </div>
              </div>
            ))
          : "You don't added other skill yet!"}
      </div>
      <OtherSkillModal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="Edit Other Skill"
        otherSkill={otherSkill}
        editData={editData}
        refresh={refresh}
      />
    </div>
  );
};

export default OtherSkills;
