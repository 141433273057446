import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ADD_PAID_ADS } from '../../shared/enums';
import { AddPaidAdsRootState } from '../../models';

const initialState = {
  ...new AddPaidAdsRootState({
    started: false,
    activeStep: ADD_PAID_ADS.AD_DESCRIPTION,
    contractType: 'paid_ads',
    adDescription: '',
    uploadedImages: [],
    planId: '',
    planPrice: null,
    socialPostDesc: '',
    videoLink: '',
    currentPlan: '',
  }),
};

const addPaidAdsSlice = createSlice({
  name: 'addPaidAds',
  initialState,
  reducers: {
    initPaidAds: (state, action: PayloadAction<AddPaidAdsRootState>) =>
      action.payload,
    setActiveStep: (state, action: PayloadAction<ADD_PAID_ADS>) => {
      state.activeStep = action.payload;
    },
    setStarted: (state, action: PayloadAction<boolean>) => {
      state.started = action.payload;
    },
    setAdDescription: (state, action: PayloadAction<string>) => {
      state.adDescription = action.payload;
    },
    setUploadedFiles: (state, action: PayloadAction<string[]>) => {
      state.uploadedImages = action.payload;
    },
    setPlanId: (state, action: PayloadAction<string>) => {
      state.planId = action.payload;
    },
    setPlanPrice: (state, action: PayloadAction<number>) => {
      state.planPrice = action.payload;
    },
    setSocialPostDesc: (state, action: PayloadAction<string>) => {
      state.socialPostDesc = action.payload;
    },
    setVideoLink: (state, action: PayloadAction<string>) => {
      state.videoLink = action.payload;
    },
    setCurrentPlan: (state, action: PayloadAction<string>) => {
      state.currentPlan = action.payload;
    },
  },
});

export const {
  setActiveStep,
  initPaidAds,
  setStarted,
  setAdDescription,
  setUploadedFiles,
  setPlanId,
  setPlanPrice,
  setSocialPostDesc,
  setVideoLink,
  setCurrentPlan,
} = addPaidAdsSlice.actions;

export default addPaidAdsSlice.reducer;
