import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom"; // Correct import for useNavigate

const LiveVideo: React.FC = () => {
  const [value, setValue] = useState<any>(""); // Initialized with an empty string
  const navigate = useNavigate(); // Initialize useNavigate

  const handleJoinRoom = useCallback(() => {
    if (value.trim()) {
      // Navigate to the desired route with the room code
      navigate(`/room/${value}`);
    } else {
      alert("Please enter a valid room code!");
    }
  }, [navigate, value]);

  return (
    <div>
      <input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        type="text"
        placeholder="Enter Room Code"
      />
      <button onClick={handleJoinRoom}>Join</button>
    </div>
  );
};

export default LiveVideo;







// import React, { useEffect, useState } from "react";
// import AgoraRTC from "agora-rtc-sdk-ng";
// import { useNavigate } from "react-router-dom";

// const appId = "your-app-id"; // Replace with your Agora App ID
// const channelName = "demo-channel"; // Replace with your channel name
// const token = null; // Use null if token-based authentication is not required
// const uid = null; // Automatically assigns a UID if null

// const LiveVideo: React.FC = () => {
//   const [client, setClient] = useState<any>(null);
//   const [localVideoTrack, setLocalVideoTrack] = useState<any>(null);
//   const [localAudioTrack, setLocalAudioTrack] = useState<any>(null);
//   const [isMicMuted, setIsMicMuted] = useState(false);
//   const [isCameraOff, setIsCameraOff] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const initClient = async () => {
//       const rtcClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
//       setClient(rtcClient);

//       try {
//         // Join the channel
//         await rtcClient.join(appId, channelName, token, uid);

//         // Create and publish local tracks
//         const [microphoneTrack, cameraTrack] = await AgoraRTC.createMicrophoneAndCameraTracks();
//         setLocalAudioTrack(microphoneTrack);
//         setLocalVideoTrack(cameraTrack);

//         // Check if cameraTrack is created successfully
//         if (!cameraTrack) {
//           console.error("Camera track could not be created.");
//           return;
//         }

//         // Publish tracks to the channel
//         await rtcClient.publish([microphoneTrack, cameraTrack]);

//         // Play the local video track
//         cameraTrack.play("localVideoContainer");

//       } catch (error) {
//         console.error("Error initializing video call:", error);
//       }
//     };

//     initClient();

//     return () => {
//       // Cleanup resources when the component unmounts
//       localAudioTrack?.close();
//       localVideoTrack?.close();
//       client?.leave();
//     };
//   }, []);

//   const toggleMic = () => {
//     if (isMicMuted) {
//       localAudioTrack?.setEnabled(true);
//       setIsMicMuted(false);
//     } else {
//       localAudioTrack?.setEnabled(false);
//       setIsMicMuted(true);
//     }
//   };

//   const toggleCamera = () => {
//     if (isCameraOff) {
//       localVideoTrack?.setEnabled(true);
//       setIsCameraOff(false);
//     } else {
//       localVideoTrack?.setEnabled(false);
//       setIsCameraOff(true);
//     }
//   };

//   const leaveCall = async () => {
//     try {
//       // Stop local tracks and leave the call
//       await client?.leave();
//       localAudioTrack?.close();
//       localVideoTrack?.close();

//       // Reset state
//       setIsMicMuted(false);
//       setIsCameraOff(false);
//       console.log("Left the call successfully!");

//       // Navigate to the Dashboard
//       navigate("/home");
//     } catch (error) {
//       console.error("Error leaving the call:", error);
//     }
//   };

//   return (
//     <div style={styles.container}>
//       <div id="localVideoContainer" style={styles.videoContainer}></div>
//       <div style={styles.controls}>
//         <button style={styles.button} onClick={toggleMic}>
//           {isMicMuted ? "Unmute Mic" : "Mute Mic"}
//         </button>
//         <button style={styles.button} onClick={toggleCamera}>
//           {isCameraOff ? "Turn On Camera" : "Turn Off Camera"}
//         </button>
//         <button style={styles.button} onClick={leaveCall}>
//           Leave Call
//         </button>
//       </div>
//     </div>
//   );
// };

// // Inline styles for the component
// const styles = {
//   container: {
//     display: "flex",
//     flexDirection: "column" as const,   
//     alignItems: "center",
//     justifyContent: "center",
//     height: "100vh",
//     backgroundColor: "#f5f5f5",
//   },
//   videoContainer: {
//     width: "800px",
//     height: "600px",
//     backgroundColor: "#000",
//     marginBottom: "20px",
//     borderRadius: "10px",
//     overflow: "hidden",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   controls: {
//     display: "flex",
//     flexDirection: "column" as const,
//     gap: "10px",
//   },
//   button: {
//     padding: "10px 20px",
//     fontSize: "16px",
//     fontWeight: "bold",
//     backgroundColor: "#007bff",
//     color: "#fff",
//     border: "none",
//     borderRadius: "5px",
//     cursor: "pointer",
//   },
// };

// export default LiveVideo;






//client's cred

//my cred priyanka
// const appId = '5af97c686c594d0986b8d33933c295f7'
// Jishan Token
// const appId = '4367dacd4572477a872a8f95bc014036'
// const primaryAppCertificate = '72e9397263e543229ab511bffd12abe0'

// const appId = '25a2296187cf4ec6a3626b687f850acb'


// import React, { useCallback, useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import AgoraRTC, {
//     LocalUser,
//     RemoteUser,
//     useLocalCameraTrack,
//     useLocalMicrophoneTrack,
//     useRemoteAudioTracks,
//     useRemoteUsers,
// } from "agora-rtc-react";
// import moment from "moment";
// import storage from "../../utils/storage";

// const LiveVideo: React.FC = () => {
//     const navigate = useNavigate();

//     // Function to get video information for the user
//     const getUserVideoInfo = useCallback(() => {
//         const currentDay = moment().format('MM-DD-YYYY');
//         const currentTime = moment().format('HH:mm:ss');
//         let userId: any = storage.getClientInterviewUserId() || null;
//         userId = userId && userId.split()[0];
//         const videoInfo = storage.getClientInterviewEventId(currentDay, userId, currentTime);
//         if (!Array.isArray(videoInfo?.videoCallDetails)) {
//             return {
//                 token: videoInfo?.videoCallDetails?.token,
//                 channelName: videoInfo?.videoCallDetails?.channelName,
//                 uid: videoInfo?.videoCallDetails?.uid,
//             };
//         }
//         return videoInfo[0];
//     }, []);
//     const token = "007eJxTYJgdfDbpY37b2ocRKl+1Ag67+Kg9ma4gfOhUfJDSyaoLwUkKDEamiUZGlmaGFubJaSapyWaJxmZGZklmFuZpFqYGiclJiX870xsCGRn+8VoxMTJAIIjPwpCSmpvPwAAAXy0fiQ==";  // Replace with your token
//     const channelName = "demo";  
//     const uid = 0;  

//     // const { token, channelName, uid } = getUserVideoInfo() || {};
//     console.log(`🚀 JISHH 🤦‍♂️😒 --  ~  file: LiveVideo.tsx:46 ~  uid:`, uid);
//     console.log(`🚀 JISHH 🤦‍♂️😒 --  ~  file: LiveVideo.tsx:46 ~  channelName:`, channelName);
//     console.log(`🚀 JISHH 🤦‍♂️😒 --  ~  file: LiveVideo.tsx:46 ~  token:`, token);

//     const [micOn, setMic] = useState(true);
//     const [cameraOn, setCamera] = useState(true);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState<string | null>(null);
//     const [activeConnection, setActiveConnection] = useState(true);
//     const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
//     const { localCameraTrack } = useLocalCameraTrack(cameraOn);

//     const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

//     useEffect(() => {
//         const joinAndPublishTracks = async () => {
//             try {
//                 console.log('Requesting permissions...');
//                 await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
//                 console.log('Permissions granted');

//                 console.log('Joining channel...');
//                 await client.join(appId, channelName, token, uid);

//                 console.log('Creating and publishing tracks...');
//                 const [microphoneTrack, cameraTrack] = await AgoraRTC.createMicrophoneAndCameraTracks();
//                 await client.publish([microphoneTrack, cameraTrack]);

//                 console.log('Joined channel and published tracks');
//                 setLoading(false);
//             } catch (err) {
//                 console.error('Error joining or publishing tracks:', err);
//                 setError(`Error: ${err}`);
//             }
//         };

//         if (token && channelName) {
//             joinAndPublishTracks();
//         }

//         return () => {
//             client.leave();
//         };
//     }, [token, channelName, appId, uid, client]);

//     const remoteUsers = useRemoteUsers();
//     const { audioTracks } = useRemoteAudioTracks(remoteUsers);
//     navigator.mediaDevices.enumerateDevices()
//     .then(devices => {
//         devices.forEach(device => {
//             console.log(device.kind, device.label);
//         });
//     })
//     .catch(err => {
//         console.error('Error fetching devices:', err);
//     });

//     useEffect(() => {
//         audioTracks.forEach(track => track.play());
//     }, [audioTracks]);

//     const handleToggleMic = () => setMic(prevState => !prevState);

//     const handleToggleCamera = async () => {
//         try {
//             if (cameraOn && localCameraTrack) {
//                 localCameraTrack.stop();
//                 await client.unpublish([localCameraTrack]);
//             } else if (!cameraOn) {
//                 const newCameraTrack = await AgoraRTC.createCameraVideoTrack();
//                 await client.publish([newCameraTrack]);
//             }
//             setCamera(prevState => !prevState);
//         } catch (err) {
//             console.error('Error toggling camera:', err);
//             setError(`Error: ${err}`);
//         }
//     };

//     const handleDisconnect = () => {
//         setActiveConnection(false);
//         storage.clearClientInterviewEventId();
//         storage.clearClientInterviewUserId();
//         navigate('/');
//     };

//     const handleMuteAllRemote = () => {
//         audioTracks.forEach(track => track.stop());
//     };

//     if (loading) {
//         return <div>Loading your video stream... Please wait</div>;
//     }

//     if (error) {
//         return <div>Error: {error}</div>;
//     }

//     return (
//         <div id="videoContainer">
//             <div id="remoteVideoGrid">
//                 {remoteUsers.map(user => (
//                     <div key={user.uid} className="remote-video-container">
//                         <RemoteUser user={user} />
//                     </div>
//                 ))}
//             </div>

//             <div id="localVideo">
//                 <LocalUser
//                     audioTrack={localMicrophoneTrack}
//                     videoTrack={localCameraTrack}
//                     cameraOn={cameraOn}
//                     micOn={micOn}
//                     playAudio={micOn}
//                     playVideo={cameraOn}
//                 />
//                 <div id="controlsToolbar">
//                     <div id="mediaControls">
//                         <button className="btn" onClick={handleToggleMic}>
//                             {micOn ? 'Mute Mic' : 'Unmute Mic'}
//                         </button>
//                         <button className="btn" onClick={handleToggleCamera}>
//                             {cameraOn ? 'Turn Off Camera' : 'Turn On Camera'}
//                         </button>
//                         <button className="btn" onClick={handleMuteAllRemote}>
//                             Mute All Remote Users
//                         </button>
//                     </div>
//                     <button id="endConnection" onClick={handleDisconnect}>
//                         Disconnect
//                     </button>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default LiveVideo;
// import React, { useEffect, useRef, useState } from "react";
// import DailyIframe, { DailyCall } from "@daily-co/daily-js";
// import { useLocation, useNavigate } from "react-router-dom";

// const LiveVideo = () => {
//   const videoContainerRef = useRef<HTMLDivElement | null>(null);
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { roomUrl } = location.state || {};
//   const callObjectRef = useRef<DailyCall | null>(null);
//   const [micOn, setMicOn] = useState(true);
//   const [cameraOn, setCameraOn] = useState(true);

//   useEffect(() => {
//     if (!roomUrl) {
//       console.error("Room URL is missing!");
//       return;
//     }

//     const callObject = DailyIframe.createCallObject();
//     callObjectRef.current = callObject;

//     callObject
//       .join({ url: roomUrl })
//       .then(() => console.log("Joined the call"))
//       .catch((error) => console.error("Error joining call:", error));

//     callObject.on("participant-joined", handleParticipantJoined);
//     callObject.on("participant-updated", handleParticipantUpdated);

//     return () => {
//       callObject.leave();
//       callObject.destroy();
//     };
//   }, [roomUrl]);

//   const handleParticipantJoined = (event: any) => {
//     attachParticipantVideo(event.participant);
//   };

//   const handleParticipantUpdated = (event: any) => {
//     attachParticipantVideo(event.participant);
//   };

//   const attachParticipantVideo = (participant: any) => {
//     if (!videoContainerRef.current || !participant.videoTrack) return;

//     let videoElement = document.getElementById(participant.user_id) as HTMLVideoElement;
//     if (!videoElement) {
//       videoElement = document.createElement("video");
//       videoElement.id = participant.user_id;
//       videoElement.autoplay = true;
//       videoElement.playsInline = true;
//       videoContainerRef.current.appendChild(videoElement);
//     }

//     if (participant.videoTrack) {
//       const stream = new MediaStream([participant.videoTrack]);
//       videoElement.srcObject = stream;
//     }
//   };

//   const toggleMic = () => {
//     const callObject = callObjectRef.current;
//     if (!callObject) return;

//     setMicOn((prev) => {
//       callObject.setLocalAudio(!prev);
//       return !prev;
//     });
//   };

//   const toggleCamera = () => {
//     const callObject = callObjectRef.current;
//     if (!callObject) return;

//     setCameraOn((prev) => {
//       callObject.setLocalVideo(!prev);
//       return !prev;
//     });
//   };

//   const leaveCall = () => {
//     const callObject = callObjectRef.current;
//     if (callObject) {
//       callObject.leave();
//       callObject.destroy();
//     }
//     navigate("/");
//   };

//   return (
//     <div
//       style={{
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         justifyContent: "center",
//         width: "100%",
//         height: "100vh",
//         backgroundColor: "#000",
//       }}
//     >
//       <div
//         ref={videoContainerRef}
//         style={{
//           width: "80%",
//           height: "70%",
//           backgroundColor: "#222",
//           display: "flex",
//           flexWrap: "wrap",
//         }}
//       >
//       </div>
//       <div style={{ marginTop: "20px", display: "flex", gap: "10px" }}>
//         <button onClick={toggleMic} style={buttonStyle}>
//           {micOn ? "Mute Mic" : "Unmute Mic"}
//         </button>
//         <button onClick={toggleCamera} style={buttonStyle}>
//           {cameraOn ? "Turn Off Camera" : "Turn On Camera"}
//         </button>
//         <button onClick={leaveCall} style={buttonStyle}>
//           Leave Call
//         </button>
//       </div>
//     </div>
//   );
// };

// const buttonStyle = {
//   padding: "10px 20px",
//   backgroundColor: "#007BFF",
//   color: "#fff",
//   border: "none",
//   borderRadius: "5px",
//   cursor: "pointer",
//   fontSize: "16px",
// };

// export default LiveVideo;
