import React, { FC } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { ContractAdDescription, ContractAdImage, ContractAdChoosePlan, ContractBoostAd } from './interface';
import { ADD_PAID_ADS } from '../../../shared/enums/AddPaidAdsSteps';

const ContractAdFlow: FC = () => {
  const contractPaidAds = useAppSelector((state: RootState) => state.contractAd);
  const { activeStep } = contractPaidAds;

  const renderActiveStep = () => {
    switch (activeStep) {
      case ADD_PAID_ADS.AD_DESCRIPTION:
        return <ContractAdDescription />;
      case ADD_PAID_ADS.UPLOAD_IMAGE:
        return <ContractAdImage />;
      case ADD_PAID_ADS.BOOST_ADD:
        return <ContractBoostAd />;
      case ADD_PAID_ADS.ADD_PROMOTION:
        return <ContractAdChoosePlan />;
      default:
        return <ContractAdDescription />;
    }
  };
  return renderActiveStep();
};

export default ContractAdFlow;
