export const APP_ROUTES = {
  DASHBOARD: "/",
  ADD_CALENDAR: "/add-calendar",
  ADD_CONTRACT: "/add-contract",
  ADD_DIRECT_JOBS: "/add-contract/direct-jobs",
  ADD_HOURLY_CONTRACT: "/add-contract/hourly-contract",
  COPY: "/copy",
  ADD_PAID_ADS: {
    PAID_AD_ROOT: "/add-contract/paid-adds",
    JOB_AD: "/add-contract/paid-adds/job-ad",
    CONTRACT_AD: '/add-contract/paid-adds/contract-ad',
    PRODUCT_AD: '/add-contract/paid-adds/product-ad',
    SERVICE_AD: '/add-contract/paid-adds/service-ad',
    COMPANY_AD: '/add-contract/paid-adds/company-ad',
  },
  ADD_SOCIAL_POST: "/add-contract/social-post",
  BOOST_PROFILE: "/boostprofile",
  SUBSCRIPTION: "/subscription",
  REVIEW: "/reviewcontract",
  CONGRATULATIION: "/congratulation",
  WORK_DETAILS: "/workDetails/:id",
  WORK: "/workDetails",
  HOME: "/home",
  FEEDS: "/feeds",
  PAY: "/pay",
  FIND_PROFESSIONALS: "/findProfessionals",
  CONTRACT_DETAILS: "/contractDetail",
  ONGOING_CONTRACTS: "/onGoingContracts",
  ONGOING_CONTRACT_DETAIL: "/details",
  OPPRTUNITY: "/opportunity",
  OPENCONTRACT_DETAILS: "/detailOpenContract",
  COMPLETED_JOB_DETAILS: "/completedJobDetails",
  VIEW_APPLICATION: "/viewapplication",
  EDITPROFILE: "/editprofile",
  ADDTIMESHEET: "/addtimesheet",
  EDITTIMESHEET: "/edittimesheet",
  JOBDETAIL: "/paymentTerms",
  AGGREMENT: "/agrement",
  TIMESHEET_TABLE: "/timesheet-table",
  TIMESHEET_DETAILS: "/timesheet-detail",
  TIMESHEETDETAIL_TABLE: "/detailTableTimesheet",
  SSAAGGREMENT: "/SSA",
  MSAAGGREMENT: "/MSA",
  CONGRATULATION_ApplyJob: "/congratulation",
  EXPENSE_TABLE: "/expenseTable",
  EXPENSE_DETAILS: "/expense-detail",
  TIMESHEET_STATUS: "/status-time-sheet",
  EXPENSE_REPORT: "/expense-report",
  EXPENSE_STATUS_TABLE: "/expense-table",
  INVOICE_TABLE: "/invoice-table",
  INVOICE_TABLE_PROFESSIONAL: "/invoice-table-professional",
  INVOICE_MAIN: "/invoice",
  INVOICE_MAIN_PROFESSIONAL: "/invoice-professional",
  USER_PROFILE: "/user-profile",
  REVIEW_HOULRY_CONTRACT: "/reviewHourlyContract",
  CHAT_MODULE: "chat",
  VIEW_PROFILE: "view-profile",
  CHAT_MESSAGE: "/chat-message",
  CHAT_PAGE: "chat-page",
  ADDS_PAGE: "/paid-adds",
  UPLOAD_IMAGE: "/upload-images",
  ADD_PROMOTION: "/add-promotion",
  BOOST_ADDS: "/boost-adds",
  MY_DASHBOARDS: "my-dasboards",
  MY_CONTRACT_OPEN: "/open-contract",
  NOTIFICATION: "/notificationList",
  VIDEO_CALL: {
    HOST: "/host-videocall",
    AUDIENCE: "/audience-videocall",
    INTERVIEW_CABIN: "/interviewcabin",
  },
  SIGNUP: "signup",
  SUCCESS: "/success",
  CANCEL: "/cancel",
  BUSINESS: "/business",
  FINDOPPORTUNITIES: "/findOpportunities",
  TEST: "/test",
  NEW_BUSINESS: "/newBusiness",
  BUSINESS_GROUP: "/newBusiness/businessGroup",
  ADD_NEW_EMPLOYEE: "/addNewEmployee",
  ADD_BUSINESS: "/addBusiness",
  JOIN: "/join",
  CONNECTION_PAYMENT: '/connectionPayment',
  EVENT: '/event/:title/:dateTime/:des',
  ROOM:'/room/:roomID'
};
