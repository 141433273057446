import React, { useEffect, useState } from "react";
import Header from "../../header/routes";
import { searchUserProfiles } from "../api";
import location from "../../../assets/images/icon_location.svg";
import paginationprev from "../../../assets/images/pagination_prev.svg";
import paginationnext from "../../../assets/images/pagination_next.svg";
import top_skill from "../../../assets/images/top_skill.svg";
import dummy from "../../../assets/images/dummy.jpeg";
import styles from "../../Feeds/styles/style.module.css";
import { APP_ROUTES } from "../../../utils/routes/AppRoutes";
import { useNavigate } from "react-router-dom";
import blsearch from "../../../assets/images/blsearch.png";
import shareprofile from "../../../assets/images/share_profile.svg";
import flag from "../../../assets/images/flag.svg";
import loc from "../../../assets/images/icon_location_filled.svg";
import star from "../../../assets/images/star.svg";
import selfrating from "../../../assets/images/self_rating.svg";
import gprs from "../../../assets/images/gprs.png";
import aeroplane from "../../../assets/images/airoplane.svg";
import { userProfileResponse } from "../interface";
import { MESSAGES } from '../../../utils/messages'
import { toast } from "react-toastify";
import Swal from 'sweetalert2';
import { sendRequest } from "../../ConnectFrnds/api";
import {
  faMicrophone,
  faMicrophoneLines,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AvailableForWork from "./AvailableForWork";
import BoostedProfessional from "./BoostedProfessional";


const FindProfessionals = () => {
  const [isListening, setIsListening] = useState(false);
  const [professional, setProfessional] = useState<userProfileResponse[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(5);
  const [requestedConnections, setRequestedConnections] = useState<Set<string>>(new Set());
  const handleConnectUser = async (id: string) => {
    const payload = {
      connectionId: id,
    };
    const initialResult = await Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to send a connection request?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Connect!',
      cancelButtonText: 'No, cancel',
    });
    if (initialResult.isConfirmed) {
      try {
        await toast.promise(sendRequest(payload), {
          pending: MESSAGES.SEND_REQ.PENDING,
          success: MESSAGES.SEND_REQ.SUCCESS,
          error: MESSAGES.SEND_REQ.ERROR,
        });

        setRequestedConnections(prev => new Set(prev.add(id)));
        await Swal.fire({
          title: 'Request Sent',
          text: 'Your connection request has been successfully sent.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      } catch (err) {
        console.log(err);
      }
    }
  }

  const fetchProfessionals = async (page: number) => {
    setLoading(true);
    try {
      const response = await searchUserProfiles(searchTerm, page, pageSize);
      setProfessional(response?.data);
      setTotalPages(response?.pagination?.totalPage || 1);
    } catch (error) {
      console.error("Error fetching professionals:", error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchProfessionals(currentPage);
  }, [searchTerm, currentPage]);

  const handleViewFullProfile = (value: string) => {
    navigate(`${APP_ROUTES.USER_PROFILE}/${value}`);
  };

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const renderPagination = () => {
    const paginationButtons = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        paginationButtons.push(
          <li key={i} className={`page-item ${currentPage === i ? "active" : ""}`}>
            <button
              className="page-link"
              onClick={() => handlePageChange(i)}
            >
              {i}
            </button>
          </li>
        );
      }
    } else {
      if (currentPage > 2) {
        paginationButtons.push(
          <li key={1} className="page-item">
            <button className="page-link" onClick={() => handlePageChange(1)}>
              1
            </button>
          </li>
        );
        if (currentPage > 3) {
          paginationButtons.push(
            <li key="prev-dots" className="page-item disabled">
              <span className="page-link">...</span>
            </li>
          );
        }
      }

      const startPage = Math.max(1, currentPage - 1);
      const endPage = Math.min(totalPages, currentPage + 1);

      for (let i = startPage; i <= endPage; i++) {
        paginationButtons.push(
          <li key={i} className={`page-item ${currentPage === i ? "active" : ""}`}>
            <button
              className="page-link"
              onClick={() => handlePageChange(i)}
            >
              {i}
            </button>
          </li>
        );
      }

      if (currentPage < totalPages - 2) {
        if (currentPage < totalPages - 3) {
          paginationButtons.push(
            <li key="next-dots" className="page-item disabled">
              <span className="page-link">...</span>
            </li>
          );
        }
        paginationButtons.push(
          <li key={totalPages} className="page-item">
            <button
              className="page-link"
              onClick={() => handlePageChange(totalPages)}
            >
              {totalPages}
            </button>
          </li>
        );
      }
    }

    return (
      <ul className="pagination my-5">
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <button
            className="page-link"
            onClick={() => handlePageChange(currentPage - 1)}
            aria-label="Previous"
          >
            <img src={paginationprev} alt="Previous" width="15" height="15" />
          </button>
        </li>
        {paginationButtons}
        <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
          <button
            className="page-link"
            onClick={() => handlePageChange(currentPage + 1)}
            aria-label="Next"
          >
            <img src={paginationnext} alt="Next" width="15" height="15" />
          </button>
        </li>
      </ul>
    );
  };

  return (
    <>
      <Header />
      <div className={`pb-3 ${styles.FondOpportuniy}`}>
        <div className="container">
        <div className={styles.SearchData}>
            <div className={`${styles.FormGroup}`} style={{width: "100%", borderRight: "0"}}>
              <span className="SeachIc me-2" style={{width: '20px'}}>
                <img src={blsearch} alt="" />
              </span>
              <input
                type="search"
                className="w-100"
                placeholder="Search by: Occupation, Skill, Manufacturer, Application, Keyword....."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <i className="btn bg-lightblue">
              {/* <FontAwesomeIcon
                icon={isListening ? faMicrophoneLines : faMicrophone}
                color={isListening ? "green" : "text-primary"}
                size="1x"
                onClick={toggleMic}
                style={{ cursor: "pointer" }}
              /> */}
              </i>
              <span>{isListening ? "Listening..." : ""}</span>
            </div>
            <div></div>
            {/* <div>
              <div>
                <button onClick={startListening} disabled={listening}>
                  🎤 Start
                </button>
                <button onClick={stopListening} disabled={!listening}>
                  🎤 Stop
                </button>
                {listening && <p>Listening...</p>}
              </div>
            </div> */}

            {/* <div className={`${styles.FormGroup}`}>
              <span className="SeachIc me-2" style={{width: '20px'}}>
                <img src={loc} alt="" />
              </span>
              <input 
              type=" text"  
              value={locationSearch} 
              onChange={(event) => setLocationSearch(event.target.value)} 
              placeholder="City, state or zip code" 
              />
              <span className={styles.SeachIcgps}>
                <img src={gprs} alt="" />
              </span>
            </div> */}
            {/* <div className={`${styles.FormGroup}`} style={{width: "10%"}}>
              <button
                className="btn btn-primary text-white">
                  Search
              </button>
              <div className={styles.textwhite}>
              <input
                type="search"
               
                placeholder="Search"
               
                className="btn btn-primary text-white"
              />
              </div>
            </div> */}
          </div>
          {/* <div className={styles.SearchData}>
            <div className={styles.FormGroup}>
              <span className="SeachIc" style={{ width: "20px" }}>
                <img src={blsearch} alt="" />
              </span>
              <input
                type="search"
                className="w-100"
                placeholder="Search by: Job title, Position, Keyword or People..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className={styles.FormGroup}>
              <span className="SeachIc" style={{ width: "20px" }}>
                <img src={loc} alt="" />
              </span>
              <input type="text" placeholder="City, state or zip code" />
              <span className={styles.SeachIcgps}>
                <img src={gprs} alt="" />
              </span>
            </div>
          </div> */}
        </div>
      </div>

      <div className="recommendedjobs">
        {loading ? (
          <div className="spinner-grow text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <div className="container">
            <div className="mb-3">
              <h4 className="mb-0 text-20 fw-semibold clamp clamp1">All Professionals</h4>
            </div>
            <div className="row align-items-center justify-content-start">
              {professional.length > 0 ? (
                professional.map((profile, index) => (
                  <>
                  {/* <div className="col-xl-3"> */}
                    <div className="col-xxl-3 col-lg-4 col-6" key={index}>
                      <div className=" overflow-hidden border rounded-3 mb-3">
                        <div className="row flex-column align-items-center justify-content-between mb-2 bg-lightbluewhite pt-3 mx-0">
                          <div className="row align-items-start justify-content-center gx-2">
                            <div className="col-3 profile_icon">
                              <figure className="profileCircle">
                              <img
                                  src={profile.profilePicture || dummy}
                                  alt={`${profile?.firstName} ${profile?.lastName}`}
                                  className="img-fluid rounded-circle"
                                />
                                <figcaption>
                                  <p className="text-8 mt-2 text-center rounded-pill pb-0 bg-lightblue text-blue">
                                    20 Connections
                                  </p>
                                </figcaption>
                              </figure>
                            </div>
                            <div className="col-9 ps-2">
                              <div className="d-flex align-items-start justify-content-between">
                                <div className="col-10">
                                  <div className="">
                                    <div className="d-flex align-items-center">
                                      <div className="col-7">
                                        <h4 className="mb-0 text-14 fw-semibold clamp clamp1">
                                        {`${profile?.firstName} ${profile?.lastName}`}
                                        </h4>
                                      </div>
                                      <div className="col-5">
                                        <span className={`text-9 text-white fw-normal ms-1 ${styles.boosted}`}>
                                          Boosted
                                        </span>
                                      </div>
                                    </div>
                                    <p className="text-10 my-0 clamp clamp1 ">
                                    {profile.occupationDetails?.[0]?.name || "N/A"}
                                    </p>
                                    <p className="text-10 my-0 d-flex align-items-center">
                                      Reviews : 20
                                      <span className="px-2">|</span>
                                      4.5
                                      <i className="ms-2">
                                        <img src={star} alt="" style={{objectFit: "contain"}}/>
                                      </i>
                                    </p>
                                  
                                    <p className="text-10 my-0 d-flex align-items-center">
                                      <i className="me-2">
                                        <img
                                          src={location}
                                          alt=""
                                          width={10}
                                          height={10}
                                        />
                                      </i>{" "}
                                      Address
                                    </p>
                                    <p className="text-10 my-0 d-flex align-items-center">
                                      <i className="me-2">
                                        <img
                                          src={flag}
                                          alt=""
                                          width={10}
                                          height={10}
                                        />
                                      </i>{" "}
                                      country
                                    </p>
                                    <p className="mb-0 bg-lightgreen text-blue rounded-pill text-center text-9 d-inline-block px-2 py-0">
                                    {profile.isAvailable ? "Available" : "Unavailable"}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-2 text-end">
                                  <i className="bg-white rounded-circle w-auto px-2 py-2 mb-0 text-center d-inline-flex align-items-center justify-content-center">
                                    <img src={shareprofile} alt="" />
                                  </i>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="row align-items-center gx-2 my-2 pt-2"
                            style={{
                              borderTop: "1px solid #3375D7 ",
                            }}
                          >
                            <div className="col-xl-12 col-12 px-0">
                              <ul className="d-flex mb-0 align-items-center justify-content-center ps-0 gap=2">
                                <li className="col-6">
                                  <p className="w-100 mb-0 bg-blue text-white rounded-pill text-center text-11 text-center px-2 py-1">
                                    Endorsements : 30
                                  </p>
                                </li>
                                <li className="col-6">
                                  <p className="w-100 mb-0 bg-lightgreen rounded-pill text-center text-11 d-inline-flex align-items-center px-2 py-1">
                                    <i className="me-1">
                                      <img src={aeroplane} alt="" />
                                    </i>
                                    Willing totravel : Yes
                                  </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="p-2">
                          <div className="row align-items-center">
                            <div className="col-5">
                              <div
                                className={`row align-items-start mb-3 g-0 ${styles.PenigSkilRate}`}
                              >
                                <div className="col-3 text-center">
                                  <i>
                                    <img
                                      src={top_skill}
                                      alt=""
                                      className="img-fluid"
                                      width="35px"
                                      height="35px"
                                    />
                                  </i>
                                </div>
                                <div className="col-9">
                                  <h5 className="mb-0 text-12 fw-semibold text-uppercase">
                                    Top Skill
                                  </h5>
                                  <p className="text-12 mb-0">
                                    Autocad
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-6">
                              <div
                                className={`row align-items-start mb-3 g-0 ${styles.PenigSkilRate}`}
                              >
                                <div className="col-3 text-center">
                                  <i>
                                    <img
                                      src={top_skill}
                                      alt=""
                                      className="img-fluid"
                                      width="27px"
                                      height="21px"
                                    />
                                  </i>
                                </div>
                                <div className="col-9">
                                  <h5 className="mb-0 text-12 fw-semibold text-uppercase">
                                    Second Skill
                                  </h5>
                                  <p className="text-12 mb-0">
                                    Programming
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={styles.PenigSkilRate}>
                            <h5>Hourly Rates</h5>
                            <ul>
                              <li>
                                <span>Base</span>
                                <label>
                                  $3
                                </label>
                              </li>
                              <li>
                                <span>Overtime</span>
                                <label>
                                  $3
                                </label>
                              </li>
                              <li>
                                <span>Sun/Holidays</span>
                                <label>
                                  $3
                                </label>
                              </li>
                            </ul>
                          </div>

                          <div className="mt-3">
                            <div className="row align-items-center g-2">
                              <div className="col">
                                <button
                                  type="button"
                                  className="btn btn-primary w-100 text-10 px-1 text-center "
                                  onClick={() => handleViewFullProfile(profile._id)}
                                >
                                  View Profile
                                </button>
                              </div>
                              <div className="col">
                              
                                <button
                                  type="button"
                                  className={`btn w-100 text-10 px-1 text-center 
                                      ${requestedConnections.has(profile?._id)
                                    ? "btn-secondary"
                                    : "btn-white border"
                                  }`}
                                onClick={() =>
                                  !requestedConnections.has(profile._id) &&
                                  handleConnectUser(profile?._id)
                                }
                              >
                                {requestedConnections.has(profile?._id)
                                  ? "Requested"
                                  : "Connect"}
                                </button>
                              </div>
                              <div className="col-12">
                                <button
                                  type="button"
                                  className="btn btn-white w-100 text-10 px-1 border text-center "
                                >
                                  Request This Professional
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                   
            
                  </>
                ))
              ) : (
                <p>No professionals found.</p>
              )}
            </div>
            <div className="mb-5 d-inline-block align-items-center w-100">
            {renderPagination()}
              </div>

              <AvailableForWork/>
              <BoostedProfessional/>
       
          </div>
        )}
      </div>
    </>
  );
};

export default FindProfessionals;
