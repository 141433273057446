import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import styles from "../../../css/styles/style.module.css";
import cal from "../../../../../assets/images/calender_icon.svg";
import loc from "../../../../../assets/images/icon_location.svg";
import grbag from "../../../../../assets/images/grbag.png";
import skill from "../../../../../assets/images/top_skill.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../../redux/store";
import { ContractResponse } from "../../interface";
import { WORK_TABS } from "../../../../../utils/enums/workTabs.enum";
import { getContractList } from "../../api";
import { CONTRACT_Type } from "../../../../../utils/enums/types";
import { TYPE, WORK_STATUS_TYPE } from "../../../../../utils/enums/status";
import { setActiveStep } from "../../../../../redux/reducers/addContract";
import storage from "../../../../../utils/storage";
import { AddContractRootState } from "../../../../../models";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import { APP_ROUTES } from "../../../../../utils/routes/AppRoutes";
import Swal from "sweetalert2";
import { sendForApproval } from "../../../../work/api";
import { APIResponseErrors } from "../../../../work/interface";
import { unApprovedEndContractJob } from "../../../../home/api";
import moment from "moment";
import CustomModal from "../../../../../shared/CustomModal/customModal";
import BoostContract from "../../../../../shared/CustomModal/BoostContractModal";

const PendingIndex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboardData = useSelector(
    (state: RootState) => state?.dashboard || null
  );
  const { activeTab } = dashboardData;
  const [isExpanded, setIsExpanded] = useState(false);
  const [pendingData, setPendingData] = useState<ContractResponse[]>([]);
  const [showChatModal, setShowChatmodal] = useState<boolean>(false);
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteCont, setDeleteCont] = useState<boolean>(false);
  const [workId, setWorkId] = useState("");
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const _handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
  };

  const _fetchContracts = async () => {
    setLoading(true);
    try {
      let response: any;
      if (activeTab === WORK_TABS.DONEFORME) {
        response = await getContractList(
          CONTRACT_Type.HOURLY_CONTRACT,
          TYPE.DONEFORME,
          WORK_STATUS_TYPE.PENDING
        );
      } else {
        response = { data: [] };
      }
      setPendingData(response.data);
    } catch (error) {
      console.error("Error fetching contracts:", error);
    } finally {
      setLoading(false);
    }
  };

  const _handleOpenAdminChat = () => {
    setShowChatmodal(true);
  };

  const _handleEdit = (contract: ContractResponse) => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.OVERVIEW_FORM));
    storage.saveContract({
      ...AddContractRootState.deserialize(contract),
      activeStep: ADD_CONTRACT_STEPS.OVERVIEW_FORM,
    });
    navigate(APP_ROUTES.ADD_HOURLY_CONTRACT);
  };

  const _sendForApproval = async (data: ContractResponse) => {
    try {
      const response = await sendForApproval(data, data?._id?.toString());
      Swal.fire({
        title: "Success",
        text: response.message as string,
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (error) {
      const apiError = error as APIResponseErrors;
      const errorMessage = apiError?.data?.message as string;
      if (apiError.data.status == 400) {
        Swal.fire({
          title: "Please Review the form and add all the mandatory details!",
          html: `<span style="color: red;">${errorMessage}</span>`,
          icon: "error",
          confirmButtonText: "Complete Details",
          cancelButtonText: "Cancel",
          showCancelButton: true,
          customClass: {
            confirmButton: "confirmBtn",
            cancelButton: "cancelBtn",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            _handleEdit(data);
          }
        });
      } else {
        Swal.fire({
          title: "Unexpected Error",
          text: errorMessage,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const _handleEndContractJob = async (id: string) => {
    const result = await Swal.fire({
      title: "Are you sure, you want to delete this Contract?",
      // text: "This job will be sent to Admin for deletion. Do you want to proceed?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
      customClass: {
        confirmButton: "confirmBtn",
        cancelButton: "cancelBtn",
      },
      buttonsStyling: false,
    });
    if (result.isConfirmed) {
      setLoading(true); // Start loading before making the API call
      try {
        const res = await unApprovedEndContractJob(id);
        Swal.fire({
          title: "Your Contract Has Been Deleted!",
          icon: "success",
          confirmButtonText: "OK",
        });
        setDeleteCont((prevState) => !prevState);
      } catch (error) {
        console.log("Error while ending the contract/job", error);
        const apiError = error as APIResponseErrors;
        const errorMessage = apiError?.data?.message as string;
        if (apiError.data.status == 400) {
          Swal.fire({
            html: `<span style="color: red;">${errorMessage}</span>`,
            icon: "error",
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "confirmBtn",
            },
            buttonsStyling: false,
          });
        } else {
          Swal.fire({
            title: "Unexpected Error",
            text: errorMessage,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } finally {
        setLoading(false); // Stop loading after the operation is complete
      }
    }
  };

  const _handleBoostContract = (id: string) => {
    setWorkId(id);
    setModalDetail({
      show: true,
      title: "",
      flag: "boostContract",
    });
  };

  useEffect(() => {
    _fetchContracts();
  }, [activeTab, deleteCont]);

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 2.4,
    centerPadding: "60px",
    slidesToScroll: 2,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.02,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div
        className={`tab-pane fade`}
        id="nav-pending"
        role="tabpanel"
        aria-labelledby="nav-pending-tab"
      >
        {" "}
        <div className={`${styles.OuterSlides}`}>
          <div className="col-12">
            <div className="custom-ongoing">
              {pendingData?.length > 0 ? (
                <Slider {...settings}>
                  {pendingData && pendingData?.length > 0
                    ? pendingData?.map((data: ContractResponse, ind) => {
                        return (
                          <>
                            <div key={ind} className={styles.ContarctOuter}>
                              <div className={styles.ContarctHead}>
                                <div className="row align-item-center justify-content-between mx-0 w-100 position-relative">
                                  <div className="col-xxl-8 col-lg-10 col-12 mb-2">
                                    <div className={styles.leftContract}>
                                      <h5>{data?.occupation?.name}</h5>
                                      <span>
                                        Start Date:{" "}
                                        {moment(data?.times?.startDate).format(
                                          "MM-DD-YYYY"
                                        )}
                                      </span>{" "}
                                      |
                                      <span>
                                        {" "}
                                        End Date:{" "}
                                        {moment(data?.times?.endDate).format(
                                          "MM-DD-YYYY"
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-xxl-4 col-lg-2 col-12 mb-2">
                                    <div className={styles.leftContract}>
                                      <button
                                        className={`position-absolute top-0 bg-lightorange text-lightorange ${styles.draftstatusdetails}`}
                                      >
                                        Pending
                                      </button>
                                    </div>
                                  </div>
                                  <div className="col-xl-12 col-lg-7 col-12">
                                    <div className={styles.leftContract}>
                                      <div className={styles.Local}>
                                        <div
                                          className={`justify-content-start ${styles.adrssLoc}`}
                                        >
                                          <span
                                            className={`py-2 lh-base rounded-2 me-2 mb-0 border-lightgreen bg-lightgreen`}
                                          >
                                            <img src={grbag} alt="" />
                                            {data?.planType === "whiteglove"
                                              ? "White Glove Contract"
                                              : data?.planType === "standard"
                                              ? "Standard Contract"
                                              : ""}
                                          </span>
                                          {data?.workLocation ? (
                                            <span
                                              className={`py-2 mb-0 rounded-2 bg-white text-blue border-blue lh-base`}
                                            >
                                              {data?.workLocation === "Remote"
                                                ? "Remote Contract"
                                                : data?.workLocation ===
                                                  "Onsite"
                                                ? "Onsite Contract"
                                                : data?.workLocation ===
                                                  "Hybrid"
                                                ? "Hybrid Contract"
                                                : "Unknown Location"}
                                            </span>
                                          ) : (
                                            <span className="py-2 mb-0 rounded-2 bg-white text-blue border-blue lh-base">
                                              No Work Location
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-xl-12 col-lg-5 col-12 mt-lg-0 mt-2">
                                    <div
                                      className={styles.leftContract}
                                      style={{ textAlign: "end" }}
                                    >
                                      <button
                                        className={`border-blue bg-blue text-white`}
                                      >
                                        <i className="far fa-copy"></i> Copy
                                      </button>
                                      <button
                                        className={`bg-white text-danger border-danger ${styles.deletecontracts}`}
                                        onClick={() =>
                                          _handleEndContractJob(data?._id)
                                        }
                                      >
                                        Delete
                                      </button>
                                    </div>
                                  </div>
                                  <div className="col-12"></div>
                                </div>
                              </div>
                              <div className={styles.OuterPadd}>
                                <div className={styles.PendingCOnatctList}>
                                  <div className={styles.Local}>
                                    <div className={styles.adrssLoc}>
                                      <p className="mb-1 text-13">
                                        <img src={loc} alt="" />
                                        {data?.address}{" "}
                                      </p>
                                    </div>
                                    <p className="text-13">
                                      <img src={cal} alt="" /> Sent For
                                      Approval:{" "}
                                      {moment(data?.times?.startDate).format(
                                        "MM-DD-YYYY"
                                      )}
                                    </p>
                                  </div>
                                  <div
                                    className="fw-semibold py-2 clamp clamp2"
                                    style={{ minHeight: "27px" }}
                                  >
                                    <div
                                      className="text-14"
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          isExpanded && data?.description
                                            ? data?.description
                                            : data?.description?.slice(0, 90),
                                      }}
                                    />
                                  </div>

                                  {data?.technicalSkills &&
                                  data?.technicalSkills?.length > 0 ? (
                                    <div className={styles.PenigSkils}>
                                      <ul className="row align-items-start justify-content-start gap-0">
                                        <li className="col-lg-4 col-5 pe-0">
                                          <div className="row align-items-start mb-3">
                                            <div className="col-lg-3 col-4 text-center pe-0">
                                              <i>
                                                <img
                                                  src={skill}
                                                  alt=""
                                                  className="img-fluid w-100"
                                                  width="15px"
                                                  height="15px"
                                                />
                                              </i>
                                            </div>
                                            <div className="col-lg-9 col-8 ps-0">
                                              <h5 className="mb-0 fw-bold text-14 text-uppercase">
                                                Top Skill
                                              </h5>
                                              <p className="text-14 mb-0">
                                                {" "}
                                                {data?.technicalSkills?.[0]
                                                  ?.manufacturerDetails?.name ||
                                                  "No Skill"}
                                              </p>
                                            </div>
                                          </div>
                                        </li>
                                        {data?.technicalSkills?.[1]
                                          ?.manufacturerDetails?.name ? (
                                          <li className="col-lg-4 col-5 pe-0">
                                            <div className="row align-items-start mb-3">
                                              <div className="col-lg-3 col-4 text-center px-0">
                                                <i>
                                                  <img
                                                    src={skill}
                                                    alt=""
                                                    className="img-fluid w-75"
                                                    width="15px"
                                                    height="15px"
                                                  />
                                                </i>
                                              </div>
                                              <div className="col-lg-9 col-8 ps-0">
                                                <h5 className="mb-0 fw-bold text-14 text-uppercase">
                                                  Second skill
                                                </h5>
                                                <p className="text-14 mb-0">
                                                  {data?.technicalSkills?.[1]
                                                    ?.manufacturerDetails
                                                    ?.name || "No Skill"}
                                                </p>
                                              </div>
                                            </div>
                                          </li>
                                        ) : null}
                                      </ul>
                                    </div>
                                  ) : null}

                                  <div
                                    className={`pt-0 ${styles.PenigSkilRate}`}
                                  >
                                    <h5 className="mb-2">Pay Rates</h5>
                                    <ul>
                                      <li>
                                        <span>Base</span>
                                        <label>${data?.rates?.baseRate}</label>
                                      </li>
                                      <li>
                                        <span>OverTime</span>
                                        <label>
                                          ${data?.rates?.overTimeRate}
                                        </label>
                                      </li>
                                      <li>
                                        <span>Sun/Holidays</span>
                                        <label>
                                          ${data?.rates?.doubleTimeRate}
                                        </label>
                                      </li>
                                    </ul>
                                  </div>
                                  <div
                                    className={`row align-items-center justify-content-between mt-3 ${styles.chatAdmin}`}
                                    // onClick={_handleOpenAdminChat}
                                  >
                                    {data?.boosts ? (
                                      <div className="col-12">
                                        <button
                                          type="button"
                                          className="bg-blue rounded-2 p-2 fw-semibold text-white text-14 d-flex align-items-center justify-content-center w-100 text-center"
                                        >
                                          Boosted On{" "}
                                          {moment(
                                            data?.boosts?.startDate
                                          ).format("DD-MM-YYYY")}{" "}
                                          {" | "}
                                          Renewal{" "}
                                          {moment(data?.boosts?.endDate).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </button>
                                      </div>
                                    ) : (
                                      <div className="col-12">
                                        <button
                                          type="button"
                                          className="bg-blue rounded-2 p-2 fw-semibold text-white text-14 d-flex align-items-center justify-content-center w-100 text-center"
                                          onClick={() =>
                                            _handleBoostContract(data?._id)
                                          }
                                        >
                                          Boost Contract
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })
                    : ""}
                </Slider>
              ) : 'No Data'}
            </div>
          </div>
        </div>
      </div>

      {modalDetail.show && (
        <CustomModal
          show={modalDetail.show}
          backdrop="static"
          showCloseBtn={true}
          isRightSideModal={false}
          mediumWidth={false}
          className={
            modalDetail.flag === "boostContract" ? "boostContract" : ""
          }
          child={
            modalDetail.flag === "boostContract" ? (
              <BoostContract
                onCloseModal={() => _handleOnCloseModal()}
                workId={workId}
              />
            ) : null
          }
          onCloseModal={() => _handleOnCloseModal()}
        />
      )}
    </>
  );
};

export default PendingIndex;
