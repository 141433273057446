import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import addicon from "../../assets/images/add_icon_grey.svg";
import styles from "../ConnectFrnds/styles/style.module.css";
import dummy from "../../assets/images/dummy.jpeg";
import share from "../../assets/images/share_grey.svg";
import Toltip from "../common/toltip/page";
import { CONSTANT_MANAGER } from "../../utils/constans/constants";
import tip from "../../assets/images/info_icon.svg";
import { toast } from "react-toastify";
import { getCustomers, CustomerData } from "./api";
import { faHand } from "@fortawesome/free-solid-svg-icons";
import { APP_ROUTES } from "../../utils/routes/AppRoutes";
import { useNavigate } from "react-router-dom";

const CustomerHired = () => {
  const [customerData, setCustomerData] = useState<CustomerData[]>([]);
  const navigate = useNavigate();

  const _fetchCustomers = async () => {
    try {
      const res = await getCustomers();
      if (res?.status === 200 && res?.data) {
        setCustomerData(res.data);
        console.log(res?.data, "customer dtaattataat");
      } else {
        toast.error("Failed to fetch customers");
      }
    } catch (error) {
      console.error("Error fetching customers:", error);
      toast.error("Error fetching customers");
    }
  };

  useEffect(() => {
    _fetchCustomers();
  }, []);
  const handleViewProfile = (id: any) => {
    if (id) {
      navigate(`${APP_ROUTES.USER_PROFILE}/${id}`);
    } else {
      console.error("Invalid user ID");
    }
  };

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 4,
    centerPadding: "60px",
    slidesToScroll: 2,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3.3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.8,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={`bg-white p-lg-4 p-2 shadow rounded-3 mt-3`}>
      <div className={`${styles.AllContracta}`}>
        <div className="row align-items-center justify-content-between mx-0 g-0">
          <div className="col-12">
            <div className="topTipHover">
              <div className="d-flex align-items-center">
                <h4 className="form-check-label text-20 fw-bold font-inter mb-0">
                  Customers That Have Hired Me in the Past
                </h4>
                <i>
                  <img className="customeTolImg" src={tip} alt="Tooltip Icon" />
                </i>
                {customerData?.length > 0 ? '' : <span className="badge rounded-3 bg-lightblue text-black text-16 fw-semibold px-3 py-2 ms-2">0</span>}
                <Toltip
                  slug={CONSTANT_MANAGER.SLUG_HOME.HOME}
                  labelId={CONSTANT_MANAGER.HOME_DATA.MY_TEAMS}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {customerData?.length > 0 && customerData ? (
        <>
          <div className="mt-3">
          <div className={`${styles.OuterSlides}`}>
              <div className="col-12">
                <div className="recommendedjobs share_connect_friends">
                  <Slider {...settings}>
                    {customerData?.length > 0
                      ? customerData?.map((customer, index) => {
                          const userData = customer?.customer?.userData || {};
                          return (
                            <div
                              key={index}
                              className="col border rounded-3 p-lg-3 p-2 text-center"
                            >
                              <div className="mb-2 mx-auto">
                                <div className="newheadingstyle text-center">
                                  <figure
                                    className={`mt-2 ${styles.profileCircle}`}
                                  >
                                    <img
                                      src={userData?.profilePicture || dummy}
                                      alt={userData?.name || "Customer"}
                                    />
                                  </figure>
                                  <h3 className="my-1 text-16">
                                    {userData.name || "No Name"}
                                  </h3>
                                  <p className="text-12 mb-1">
                                    {userData.address || "No Address"}
                                  </p>
                                </div>
                              </div>
                              <div className="mt-3">
                                <div className="row align-items-center g-2 pb-3">
                                  <div className="col">
                                    <button
                                      type="button"
                                      className="btn btn-white w-100 text-center border text-12 d-flex align-items-center justify-content-center text-grey"
                                      onClick={() =>
                                        handleViewProfile(userData?._id)
                                      }
                                    >
                                      <i className="me-1">
                                        <img
                                          src={addicon}
                                          alt="Add Icon"
                                          width={15}
                                          height={14}
                                        />
                                      </i>
                                      View Profile
                                    </button>
                                  </div>
                                  <div className="col">
                                    <button
                                      type="button"
                                      className="btn btn-white w-100 text-center border text-12 d-flex align-items-center justify-content-center text-grey"
                                    >
                                      <i className="me-1">
                                        <img
                                          src={share}
                                          alt="Share Icon"
                                          width={15}
                                          height={14}
                                        />
                                      </i>
                                      Share
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : ''}
    </div>
  );
};

export default CustomerHired;
