import React, { useState } from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../../assets/images/penbl.png";
import tip from "../../../../assets/images/tip.png";
import EditCompanyAdminContModal from "../modals/EditCompanyAdminContModal";
import { CompanyAdmin } from "../../interface";

interface CompanyAdminProps {
  companyAdmin: CompanyAdmin[];
  refresh: () => void;
}

const CompanyAdminContact: React.FC<CompanyAdminProps> = ({ refresh, companyAdmin }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [editData, setEditData] = useState<CompanyAdmin | null>(null);
  const [indexNumber, setIndexNumber] = useState<number | null>(null);

  // Open the modal with the selected admin's data and index
  const openModal = (data?: CompanyAdmin, index?: number) => {
    console.log(data,"datatatatatattta")
    setShowModal(true);
    setEditData(data || null);
    
    if (index !== null && index !== undefined && index >= 0) {
      setIndexNumber(index);
    }
  };

  // Close the modal
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-between mb-3">
        <div className="col-12">
          <div className="topTipHover">
            <div className="d-flex align-items-center">
              <h4 className="text-20 fw-bold font-inter mb-0">
                Company Administration Contacts
              </h4>
              <i>
                <img className="customeTolImg" src={tip} alt="" />
              </i>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.personalinfo}>
        {companyAdmin.length === 0 ? (
          <p>No company admins found.</p>
        ) : (
          companyAdmin.map((admin, index) => (
            <ul className="row align-items-center rounded-2 border bg-light p-2 mx-0 mb-2" key={index}>
              <li className="col-xl-3 col-6">
                <span>Name</span>
                <label className="clamp clamp1">{admin.name}</label>
              </li>
              <li className="col-xl-4 col-6">
                <span>Email</span>
                <label className="clamp clamp1">{admin.email}</label>
              </li>
              <li className="col-xl-2 col-6">
                <span>Phone</span>
                <label className="clamp clamp1">{admin.phoneNumber}</label>
              </li>
              <li className="col-xl-3 col-6">
                <img
                  src={penbl}
                  alt="Edit"
                  className="cursor-pointer ms-auto"
                  onClick={() => openModal(admin, index)}
                />
              </li>
              <li className="col-xl-3 col-6">
                <span>City</span>
                <label className="clamp clamp1">{admin.city}</label>
              </li>

              {/* Add Edit Icon */}
              
            </ul>
          ))
        )}

        {/* Add more button */}
        <div className="mt-2">
          <h4 className="text-14 fw-semibold text-blue cursor-pointer" onClick={() => openModal()}>
            + Add more
          </h4>
        </div>
      </div>

      <EditCompanyAdminContModal
        showModal={showModal}
        closeModal={closeModal}
        title="Edit Company Administration Contacts"
        refresh={refresh}
        editData={editData}      
        index={indexNumber}  
        companyAdminData={companyAdmin} 
      />
    </div>
  );
};

export default CompanyAdminContact;
