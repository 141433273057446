import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ADD_PAID_ADS } from "../../../../shared/enums";
import { APP_ROUTES } from "../../../../utils/routes/AppRoutes";
import {
  setActiveStep,
  setAdDescription,
} from "../../../../redux/reducers/jobPaidAd";
import HeaderContent from "../../../../shared/Components/HeaderContent/headerContent";
import styles from "../../../contractBeforeLogin/styles/style.module.css";
import Occupations from "../../../../assets/images/choosetype.png";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../assets/images/blackarrow.svg";
import tip from "../../../../assets/images/tip.png";
import { RootState } from "../../../../redux/store";
import Toltip from "../../../common/toltip/page";
import { CONSTANT_MANAGER } from "../../../../utils/constans/constants";
import { toast } from "react-toastify";
import TextEditorCustomComp from "../../../contractBeforeLogin/HourlyContractType/HourlyContract/StepsComonents/TextEditorCustomComp/TextEditorCustomComp";

const JobAdDesciption = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { adDescription } = useSelector((state: RootState) => state.jobAd);

  const handleClickNext = () => {
    if (adDescription === "") {
      toast.error("Please Add Description");
      return;
    } else {
      dispatch(setActiveStep(ADD_PAID_ADS.UPLOAD_IMAGE));
    }
  };

  const handleClickBack = () => {
    navigate(APP_ROUTES.ADD_PAID_ADS.PAID_AD_ROOT);
  };

  const onDescriptionChange = (value: string): void => {
    dispatch(setAdDescription(value));
  };

  return (
    <>
      <HeaderContent />
      <div className="heightautoscroll contractradio">
        <form className="h-100">
            <div className="container h-100">
              <div className="row h-100 align-items-center">
                <div className="col-lg-6">
                  <div className={styles.registerLeft}>
                    
                    <div className="col-lg-11 col-12 mx-auto text-center">
                      <figure>
                        <img
                          src={Occupations}
                          alt=""
                          className="img-fluid w-100"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className={styles.registerBord}>
                  <h4 className="text-white text-32 fw-semibold">
                      Ad Description
                    </h4>
                    <div className={styles.signUPContainer}>
                    <div className={styles.ScrollDiv}>
                      <div className={styles.registerRight}>
                        <div className={styles.fromGroup}>
                          <div className="topTipHover">
                            <div className="d-flex align-items-center">
                            <label  className={styles.starlabel}>
                                Description of your ad
                              </label>
                              <i>
                                <img
                                  className="customeTolImg"
                                  src={tip}
                                  alt=""
                                />
                              </i>
                              <Toltip
                                slug={
                                  CONSTANT_MANAGER.SLUG.ADD_CONTRACT
                                }
                                labelId={
                                  CONSTANT_MANAGER.ADD_CONTRACT.PAID_AD
                                    .DATA.DESCRIPTION_OF_YOUR_ADD
                                }
                              />
                            </div>
                          </div>
                          <TextEditorCustomComp
                            data={adDescription}
                            setDesc={(value: string) =>
                              onDescriptionChange(value)
                            }
                          />
                        </div>
                      </div>
                      </div>
                      <div className="row align-items-center justify-content-between">
                        <div
                          className="col-4 text-start my-3 btn-hover-effect"
                          onClick={handleClickBack}
                        >
                          <h6 className="btn bg-grey arrowyellow border-grey border-1 rounded-3 position-relative ps-5 backbutton">
                            <span className="px-lg-4 px-2 text-12 fw-semibold d-inline-block">
                              Back
                            </span>
                            <i className="bg-yellow rounded-3 arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                              <img src={blackarrow} alt="" />
                            </i>
                          </h6>
                        </div>
                        <div className="col-8">
                          <div className="d-flex align-items-center jusify-content-end">
                            <div
                              className="col-12 text-end my-3 btn-hover-effect"
                              onClick={handleClickNext}
                            >
                              <h6 className="btn arrowblue border-grey border-1 position-relative pe-5">
                                <span className="px-lg-4 px-2 text-12 fw-semibold d-inline-block">
                                  Continue
                                </span>
                                <i className="bg-blue rounded-3 arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                                  <img src={whitearrow} alt="" />
                                </i>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </form>
      </div>
    </>
  );
};

export default JobAdDesciption;
