import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ADD_SOCIAL_POST } from "../../../shared/enums";
import { APP_ROUTES } from "../../../utils/routes/AppRoutes";
import {
  setActiveStep,
  setSocialPostDesc,
} from "../../../redux/reducers/socialPost";
import HeaderContent from "../../../shared/Components/HeaderContent/headerContent";
import styles from "../../contractBeforeLogin/styles/style.module.css";
import Occupations from "../../../assets/images/occupations.png";
import whitearrow from "../../../assets/images/whitearrow.svg";
import blackarrow from "../../../assets/images/blackarrow.svg";
import { RootState } from "../../../redux/store";
import tip from "../../../assets/images/tip.png";
import Toltip from "../../common/toltip/page";
import { CONSTANT_MANAGER } from "../../../utils/constans/constants";
import TextEditorCustomComp from "../../contractBeforeLogin/HourlyContractType/HourlyContract/StepsComonents/TextEditorCustomComp/TextEditorCustomComp";
import { toast } from "react-toastify";

const AddDescription: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { socialPostDesc } = useSelector(
    (state: RootState) => state.socialPost
  );

  const handleClickNext = () => {
    if(socialPostDesc){
      dispatch(setActiveStep(ADD_SOCIAL_POST.UPLOAD_IMAGE));
    }else{
      toast.error("Please Add Post Description");
    }
  };

  const handleClickBack = () => {
    navigate(APP_ROUTES.ADD_CONTRACT);
  };

  const onDescriptionChange = (value: string): void => {
    dispatch(setSocialPostDesc(value));
  };

  return (
    <>
      <HeaderContent />
      <div className="py-4 heightautoscroll">
        <div className={styles.signuppage}>
          {/* <ProgressBar now={20} /> */}
          <div className="contractpages">
            <form className="h-100">
              <div
                className={styles.registerOuter}
                style={{ position: "relative" }}
              >
                <div className="container h-100">
                  <div className=" h-100">
                    <div className={styles.registerBord}>
                      <div className="row pt-5 h-100 align-items-start">
                        <div className="col-md-6">
                          <div className={styles.registerLeft}>
                            <div className="col-lg-11 col-12">
                              <figure>
                                <img
                                  src={Occupations}
                                  alt=""
                                  className="img-fluid"
                                />
                              </figure>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className={styles.registerBord}>
                            <h4 className="text-white text-32 fw-semibold">
                              {" "}
                              Add Post Description
                            </h4>
                            <div className={styles.signUPContainer}>
                              <div className={styles.ScrollDiv}>
                                <div className={styles.registerRight}>
                                  <div className={styles.fromGroup}>
                                    <div className="topTipHover">
                                      <div className="d-flex align-items-center">
                                        <label className={styles.starlabel}>
                                          Description of your social post
                                        </label>
                                        <i>
                                          <img
                                            className="customeTolImg"
                                            src={tip}
                                            alt=""
                                          />
                                        </i>
                                        <Toltip
                                          slug={
                                            CONSTANT_MANAGER.SLUG.ADD_CONTRACT
                                          }
                                          labelId={
                                            CONSTANT_MANAGER.ADD_CONTRACT
                                              .SOCIAL_POST_FOR_FEED.DATA
                                              .DESCRIPTION_OF_YOUR_ADD_SOCIAL_POST
                                          }
                                        />
                                      </div>
                                    </div>
                                    <TextEditorCustomComp
                                      data={socialPostDesc}
                                      setDesc={(value: string) =>
                                        onDescriptionChange(value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row align-items-center justify-content-between">
                                <div
                                  className="col-4 text-start my-3 btn-hover-effect"
                                  onClick={handleClickBack}
                                >
                                  <h6 className="btn bg-grey arrowyellow border-grey border-1 position-relative ps-5 backbutton">
                                    <span className="px-lg-4 px-2 text-12 fw-semibold d-inline-block">
                                      Back
                                    </span>
                                    <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                                      <img src={blackarrow} alt="" />
                                    </i>
                                  </h6>
                                </div>
                                <div className="col-8">
                                  <div className="d-flex align-items-center jusify-content-end">
                                    <div
                                      className="col-12 text-end my-3 btn-hover-effect"
                                      onClick={handleClickNext}
                                    >
                                      <h6 className="btn arrowblue border-grey border-1 position-relative pe-5">
                                        <span className="px-lg-4 px-2 text-12 fw-semibold d-inline-block">
                                          Next
                                        </span>
                                        <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                                          <img src={whitearrow} alt="" />
                                        </i>
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDescription;
