import React from "react";
import "react-markdown-editor-lite/lib/index.css";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import storage from "../../../../../utils/storage";
import choosetype from "../../../../../assets/images/choosetype.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import { RootState } from "../../../../../redux/store";
import {
  setActiveStep,
  setEligibilityQuestions,
} from "../../../../../redux/reducers/addDirectJob";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import styles from "../../../../../components/contractBeforeLogin/styles/style.module.css"
import { DIRECT_JOB_STEPS } from "../Enums";
import TextEditorCustomComp from "../../../HourlyContractType/HourlyContract/StepsComonents/TextEditorCustomComp/TextEditorCustomComp";

const AddEligibilityQusForDirectJob: React.FC = () => {
  const dispatch = useDispatch();
  const { eligibilityQuestions } = useSelector(
    (state: RootState) => state.directJob
  );

  const formIk: any = useFormik({
    initialValues: {
      eligibilityQuestions,
    },
    onSubmit: () => {
      // dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_INTERVIEW_QUESTIONS));
    },
  });

  const addNewQuestion = (): void => {
    const updatedQuestion = [...eligibilityQuestions];
    updatedQuestion.push("");
    dispatch(setEligibilityQuestions([...updatedQuestion]));
  };

  const onValueChange = (value: string, index: number): void => {
    const updatedQuestions = [...eligibilityQuestions];
    updatedQuestions[index] = value;

    dispatch(setEligibilityQuestions(updatedQuestions));
    formIk.setFieldValue("eligibilityQuestions", updatedQuestions);
  };

  const deleteQuestion = (index: number) => {
    const updatedQuestion = [...eligibilityQuestions];
    updatedQuestion.splice(index, 1);
    dispatch(setEligibilityQuestions([...updatedQuestion]));
  };

  const handleGoBack = () => {
    dispatch(setActiveStep(DIRECT_JOB_STEPS.ADD_ADDITIONALS));
  };
  const handleGoToNextStep = () => {
    dispatch(setActiveStep(DIRECT_JOB_STEPS.ADD_INTERVIEWQUESTIONS));
  };
  return (
    <>
      <HeaderContent />
      <div className="heightautoscroll contractradio">
        <form onSubmit={formIk.handleSubmit} className="h-100">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-lg-6">
                <div className={styles.registerLeft}>


                  <div>
                    {/* <p>Add a Legal information of the work that you need</p> */}
                    <div className="col-lg-11 col-12 text-center mx-auto">
                      <figure>
                        <img
                          src={choosetype}
                          alt=""
                          className="img-fluid w-100"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
              <h4 className="text-white text-32 fw-semibold">
                    Eligibility Questions
                  </h4>
                  <p className="text-16 text-white opacity-75">
                    To ensure candidates meet essential job requirements
                    and legal standards, Automate America Company uses
                    the following yes or no eligibility questions.
                  </p>
                <div className={styles.ScrollDiv}>
                 
                  <div className={styles.signUPContainers}>
                    <div className={`mt-0 ${styles.registerRight}`}>
                      <label className={styles.starlabel}>
                        Create Eligibility Questions
                      </label>

                      {eligibilityQuestions?.map((x: string, i: number) => (
                        <>
                          <div
                            className={`mt-2 ${styles.fromGroup}`}
                            key={i}
                            style={{ maxWidth: "100%" }}
                          >

                            <label className={styles.starlabel}>
                              Question{i + 1}:
                            </label>

                            <div>
                              <div className="row align-items-center justify-content-between w-100">
                                <div className="col-11">
                                  <TextEditorCustomComp
                                    data={x}
                                    setDesc={(value: string) =>
                                      onValueChange(value, i)
                                    }
                                    index={i}
                                  />
                                </div>
                                
                                  <div className={`col-1 px-0 ${styles.AddBtn}`}>
                                    {i ===
                                      eligibilityQuestions.length - 1 &&
                                      eligibilityQuestions.length <
                                      5 && (
                                        <button
                                          type="button"
                                          className="btn btn-primary py-2 my-1 rounded-circle text-14"
                                          onClick={addNewQuestion}
                                        >
                                          +
                                        </button>
                                      )}
                                    {eligibilityQuestions.length >
                                      1 && (
                                        <button
                                          type="button"
                                          className="btn btn-primary py-2 my-1 rounded-circle text-14"
                                          onClick={() =>
                                            deleteQuestion(i)
                                          }
                                        >
                                          -
                                        </button>
                                      )}
                                  </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="row align-items-center justify-content-between">
                  <div className="col-xl-4 col-12 text-start my-3 btn-hover-effect">
                    <h6
                      onClick={() => handleGoBack()}
                      className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton"
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold">
                        Back
                      </span>
                      <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                        <img src={blackarrow} alt="" />
                      </i>
                    </h6>
                  </div>
                  <div className="col-xl-8 xol-12">
                    <div className="d-flex align-items-center jusify-content-end">
                      <div className="col-0">
                        {/* <SaveAsDraftButton /> */}
                      </div>
                      <div className="col-12 text-end my-3 btn-hover-effect">
                        <h6
                          onClick={handleGoToNextStep}
                          className="btn arrowblue border-grey border-1 position-relative pe-5"
                        >
                          <span className="px-lg-4 px-2 text-12 fw-semibold">
                            Next
                          </span>
                          <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                            <img src={whitearrow} alt="" />
                          </i>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddEligibilityQusForDirectJob;
