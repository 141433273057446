import React, { FC, useEffect, useState } from 'react';
import HeaderContent from '../../../../../shared/Components/HeaderContent/headerContent';
import styles from "../../../../../components/contractBeforeLogin/styles/style.module.css"
import choosetype from "../../../../../assets/images/choosetype.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { RootState } from "../../../../../redux/store";
import { getMasterTypes } from "../../../../../feature/auth/signup/api";
import { IOption } from "../../../../../Interfaces";
import { MASTER_TYPE } from "../../../../../utils/enums/masterTypes.enum";
import { toast } from "react-toastify";
import { MESSAGES } from "../../../../../utils/messages";
import tip from "../../../../../assets/images/tip.png";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../../../utils/routes/AppRoutes";
import Toltip from "../../../../common/toltip/page";
import { validationSchemaForOccupation } from "../../../../work/validations";
import { postMasterType } from "../../../../work/api";
import { APIResponseError } from "../../../../work/interface";
import {
  setActiveStep,
  setOccupation,
  setOccupationsList,
} from "../../../../../redux/reducers/addDirectJob";
import { DIRECT_JOB_STEPS } from "../Enums";
import CreatableSelect from "react-select/creatable";

const AddOccupationForDirectJob: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filterValue, setFilterValue] = useState<string>("");

  const { occupation, occupationsList } = useSelector(
    (state: RootState) => state.directJob
  );

  const formik = useFormik({
    initialValues: {
      primaryOccupation: occupation.primaryOccupation?.value || "",
    },
    validationSchema: validationSchemaForOccupation,
    onSubmit: (values) => { },
  });

  const getOccupationData = async () => {
    const response = await getMasterTypes(MASTER_TYPE.OCCUPATIONS);
    const mappedOccupations = response.data.map<IOption>((x) => ({
      label: x.name,
      value: x._id,
    }));

    dispatch(setOccupationsList(mappedOccupations));
  };

  useEffect(() => {
    getOccupationData();
  }, []);

  const handleGoToNextStep = () => {
    if (
      !occupation.primaryOccupation &&
      occupation.secondaryOccupations.length === 0
    ) {
      toast.error("Please Add Occupation");
    } else {
      dispatch(setActiveStep(DIRECT_JOB_STEPS.ADD_DESCIPTION));
    }
  };

  const handleGoBack = () => {
    navigate(APP_ROUTES.ADD_CONTRACT);
    //dispatch(setActi(veStep(ADD_CONTRACT_STEPS.POST_TYPE));
  };

  const handleOccupationChange = (value: any, key: string) => {
    dispatch(setOccupation({ ...occupation, [key]: value }));
  };

  const handleSubmitCustomOccupation = async (
    newValue?: any,
    type?: string
  ) => {
    const payload = {
      type: MASTER_TYPE.OCCUPATIONS,
      name: newValue || searchTerm,
      alias: newValue || searchTerm,
    };

    toast.promise(postMasterType(payload), {
      pending: {
        render() {
          return MESSAGES.POSTING_CONTRACTS.VERIFYING;
        },
      },
      success: {
        render(newOccupation) {
          const newId = newOccupation?.data?.data?._id || newValue;
          if (type === "SECONDARY_OCCUPATION") {
            const _obj: any = {
              label: newOccupation?.data?.data?.name || newValue,
              value: newId,
            };
            const newArr = [...occupation.secondaryOccupations, _obj];
            dispatch(
              setOccupation({ ...occupation, secondaryOccupations: newArr })
            );
            return MESSAGES.POSTING_CONTRACTS.ADDING;
          } else {
            const _obj: any = {
              label: newOccupation?.data?.data?.name || newValue,
              value: newId,
            };
            dispatch(setOccupation({ ...occupation, primaryOccupation: _obj }));
            formik.setFieldValue(
              "primaryOccupation",
              newOccupation.data.data.name
            );
            return MESSAGES.POSTING_CONTRACTS.ADDING;
          }
        },
      },
      error: {
        render(error) {
          let errorMesage = error as APIResponseError;
          return errorMesage?.data?.data?.message;
        },
      },
    });
  };

  const handleCreate = (inputValue: string, type?: string) => {
    handleSubmitCustomOccupation(inputValue, type);
    // setTimeout(() => {
    //   const newOption = createOption(inputValue);
    //   setOptions((prev) => [...prev, newOption]);
    //   setValue(newOption);
    // }, 1000);
  };

  const primaryOccOptions =
    filterValue && filterValue !== ""
      ? occupationsList.filter((x) =>
        x.label.toLowerCase().includes(filterValue.toLowerCase())
      )
      : occupationsList;

  return (
    <>
      <HeaderContent />
      <div className="heightautoscroll contractradio">
        <form onSubmit={formik.handleSubmit} className="h-100">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
                    <div className="col-lg-6">
                      <div className={styles.registerLeft}>
                        
                        <div className="col-lg-11 col-12 mx-auto text-center">
                          <figure>
                            <img
                              src={choosetype}
                              alt=""
                              className="img-fluid w-100"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                    <h4 className="text-white text-32 fw-semibold">
                          Who Do You Need?
                        </h4>
                        <p className="text-16 text-white opacity-75">
                          What is the Primary and Secondary Occupations of
                          <br />
                          the person that you are looking for?
                        </p>
                      <div className={styles.registerBord}>
                        <div className={styles.signUPContainer}>
                          <div className={styles.registerRight}>
                            <div
                              className={styles.fromGroup}
                              style={{ marginBottom: "30px" }}
                            >
                              <div className="topTipHover">
                                <label className={styles.starlabel}>
                                  Primary Occupation
                                </label>
                                <i className="">
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                                <Toltip
                                  slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                  labelId={
                                    CONSTANT_MANAGER.ADD_CONTRACT
                                      .PRIMARY_OCCUPATION
                                  }
                                />
                              </div>
                              <CreatableSelect
                                onCreateOption={(_cb) =>
                                  handleCreate(_cb, "PRIMARY_OCCUPATION")
                                }
                                name="dropdown-basic"
                                options={primaryOccOptions as any}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(val) =>
                                  handleOccupationChange(
                                    val,
                                    "primaryOccupation"
                                  )
                                }
                                value={occupation.primaryOccupation}
                              />
                              {formik.errors.primaryOccupation &&
                                formik.touched.primaryOccupation && (
                                  <div className={styles.error}>
                                    {formik.errors.primaryOccupation}
                                  </div>
                                )}
                            </div>

                            <div
                              className={styles.fromGroup}
                              style={{ marginBottom: "30px" }}
                            >
                              <div className="topTipHover">
                                <label className={styles.starlabel}>Secondary Occupations</label>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                                <Toltip
                                  slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                  labelId={
                                    CONSTANT_MANAGER.ADD_CONTRACT
                                      .SECONDARY_OCCUPATION
                                  }
                                />
                              </div>
                              <CreatableSelect
                                isMulti
                                onCreateOption={(_cb) =>
                                  handleCreate(_cb, "SECONDARY_OCCUPATION")
                                }
                                name="colors"
                                options={occupationsList as any}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(val) =>
                                  handleOccupationChange(
                                    val,
                                    "secondaryOccupations"
                                  )
                                }
                                value={occupation.secondaryOccupations}
                              />
                            </div>
                          </div>
                          <div className="row align-items-center justify-content-between mt-lg-5 mt-0">
                            <div className="col-4 text-start my-3 btn-hover-effect">
                              <h6
                                onClick={() => handleGoBack()}
                                className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton mb-0"
                              >
                                <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                                  <img src={blackarrow} alt="" />
                                </i>
                                <span className="px-lg-4 px-2 ps-3 text-12 fw-semibold d-block">
                                  Back
                                </span>
                              </h6>
                            </div>
                            <div className="col-8">
                              <div
                                className="d-flex align-items-center jusify-content-end"
                                style={{ gap: "10px" }}
                              >
                                <div className="col-12 text-end my-3 btn-hover-effect">
                                  <h6
                                    onClick={handleGoToNextStep}
                                    className="btn arrowblue border-grey border-1 position-relative pe-5 mb-0"
                                  >
                                    <span className="px-lg-4 px-2 pe-lg-3 text-12 fw-semibold d-block">
                                      Next
                                    </span>
                                    <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                                      <img src={whitearrow} alt="" />
                                    </i>
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </form>
          </div>
    </>
  );
};

export default AddOccupationForDirectJob;
