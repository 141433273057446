import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ADD_SOCIAL_POST} from '../../shared/enums';
import { AddSocialPostRootState } from '../../models';

const initialState = {
    ...new AddSocialPostRootState({
      started: false,
      activeStep: ADD_SOCIAL_POST.SOCIAL_POST_DESC,
      uploadedImages: [],
      socialPostDesc: '',
    }),
  };

  const addSocialPostSlice = createSlice({
    name: 'addSocialPost',
    initialState,
    reducers: {
      initSocialPost: (state, action: PayloadAction<AddSocialPostRootState>) =>
        action.payload,
      setActiveStep: (state, action: PayloadAction<ADD_SOCIAL_POST>) => {
        state.activeStep = action.payload;
      },
      setStarted: (state, action: PayloadAction<boolean>) => {
        state.started = action.payload;
      },
      setUploadedFiles: (state, action: PayloadAction<string[]>) => {
        state.uploadedImages = action.payload;
      },
      setSocialPostDesc: (state, action: PayloadAction<string>) => {
        state.socialPostDesc = action.payload;
      },
    },
  });
  
  export const {
    setActiveStep,
    initSocialPost,
    setStarted,
    setUploadedFiles,
    setSocialPostDesc,
  
  } = addSocialPostSlice.actions;
  
  export default addSocialPostSlice.reducer;