import React, { useState } from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../../assets/images/penbl.png";
import tip from "../../../../assets/images/tip.png";
import security from "../../../../assets/images/security.svg";
import downloadbalck from "../../../../assets/images/download_black.svg";
import printblack from "../../../../assets/images/print_black.svg";
import penciledit from "../../../../assets/images/penciledit.svg";
import photoid from "../../../../assets/images/photoid.jpg";
import EditPhotoModal from "../modals/EditPhotoModal";
import { PhotoIdProps } from "../../interface";
import { toast } from "react-toastify";

const PhotoId: React.FC<PhotoIdProps> = ({ refresh, docFile }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const handlePrint = () => {
    const printWindow = window.open("", "_blank", "width=800,height=600");
    if (printWindow) {
      printWindow.document.write(`
        <html>
          <head>
            <title>Print Photo ID</title>
            <style>
              body { font-family: Arial, sans-serif; margin: 0; padding: 20px; }
              img { max-width: 100%; height: auto; }
            </style>
          </head>
          <body>
            <h2>Photo ID: ${docFile?.name}.pdf</h2>
            <img src="${docFile?.filename}" alt="Photo ID" />
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.print();
    }
  };

  const handleDownload = async () => {
    if (docFile?.filename) {
      try {
        const response = await fetch(docFile.filename);
        const blob = await response.blob();
        const link = document.createElement("a");
  
        const blobUrl = URL.createObjectURL(blob);
        link.href = blobUrl;
        link.setAttribute("download", docFile?.name);
  
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
  
        URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error("Error downloading the image:", error);
        toast.error("Failed to download the image");
      }
    }
  };
  

  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-between">
        <div className="col-10">
          <div className="topTipHover">
            <div className="d-flex align-items-center">
              <h4 className="text-20 fw-bold font-inter mb-0">Photo ID</h4>
              <i>
                <img className="customeTolImg" src={tip} alt="" />
              </i>
            </div>
          </div>
        </div>
        <div className="col-2">
          <div className="text-end">
            <span>
              <img
                src={penbl}
                className="cursor-pointer ms-auto"
                alt=""
                onClick={openModal}
              />
            </span>
          </div>
        </div>
      </div>
      <ul className={styles.experienceSet}>
        <div className="row align-items-center justify-content-start g-3 mt-2">
          <li className="col-lg-6 col-12">
            <div className="w-100 border rounded-3 p-2">
              <h4>{docFile?.name}.pdf</h4>

              <div className="position-relative overflow-hidden rounded-3">
                <figure className="mb-0 photobanner">
                  <img
                    src={docFile?.filename}
                    alt=""
                    className="img-fluid w-100 objectfitcover"
                    height={280}
                  />
                </figure>
                <ul
                  className="d-flex align-items-center justify-content-end position-absolute top-0 end-0"
                  style={{ gap: "15px" }}
                >
                  <li
                    onClick={openModal}
                    className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2"
                  >
                    <i className="me-1">
                      <img src={penciledit} alt="" width={12} height={12} />
                    </i>
                    <span className="text-10">Edit</span>
                  </li>
                  <li
                    onClick={handlePrint}
                    className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2"
                  >
                    <i className="me-1">
                      <img src={printblack} alt="" width={12} height={12} />
                    </i>
                    <span className="text-10">Print</span>
                  </li>
                  <li onClick={handleDownload} className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
                    <i className="me-1">
                      <img src={downloadbalck} alt="" width={12} height={12} />
                    </i>
                    <span className="text-10">Download</span>
                  </li>
                </ul>
              </div>
            </div>
          </li>

          <li>
            <div className={styles.note}>
              <div className="d-flex align-items-center bg-lightblue p-3 rounded-2">
                <div className="col-lg-1 col-2 text-center">
                  <i>
                    <img src={security} alt="" />
                  </i>
                </div>
                <div className="col-lg-11 col-10">
                  <p className="text-14 fw-medium mb-0 ps-2">
                    Upload a copy of your photo ID. This helps verify your
                    identity.
                  </p>
                </div>
              </div>
            </div>
          </li>
        </div>
      </ul>
      <EditPhotoModal
        showModal={showModal}
        closeModal={closeModal}
        title="Edit Photo ID"
        refresh={refresh}
        editData={docFile}
      />
    </div>
  );
};

export default PhotoId;
