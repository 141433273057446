export enum CONTRACT_STATUS {
  PENDING = "pending",
  APPROVED = "approved",
  OPEN = "open",
  ONGOING = "ongoing",
  CLOSED = "closed",
  FILLED = "filled",
  DRAFT = "draft",
  COMPLETED = "completed",
  DEAD = "dead",
  All="all"
}
export enum WORK_STATUS {
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
  APPLIED = 'Applied',
  RECEIVED = 'Received',
  SELECTED = 'Selected',
}
export enum WORK_STATUS_TYPE {
  PENDING = 'pending',
  APPROVED = 'approved',
  OPEN = 'open',
  ONGOING = 'ongoing',
  CLOSED = 'closed',
  FILLED = 'filled',
  DRAFT = 'draft',
  COMPLETED = 'completed',
  DEAD = 'dead',
  SELECTED="selected"
}
export enum TYPE {
  DONEBYME = 1,
  DONEFORME = 2
}

export enum ACCOUNT_STATUS {
  FALSE = 'false',
  TRUE = 'true',
}