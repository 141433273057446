import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../../../../../src/components/common/css/styles/style.module.css";
import info from "../../../../../assets/images/info_icon.svg";
import grbag from "../../../../../assets/images/grbag.png";
import skill from "../../../../../assets/images/top_skill.svg";
import tip from "../../../../../../src/assets/images/info_icon.svg";
import timeling from "../../../../../assets/images/timeiing.png";
import loc from "../../../../../assets/images/icon_location.svg";
import Slider from "react-slick";
import moment from "moment";
import { TYPE, WORK_STATUS_TYPE } from "../../../../../utils/enums/status";
import { CONTRACT_Type, JOB_STATUS } from "../../../../../utils/enums/types";
import { APP_ROUTES } from "../../../../../utils/routes/AppRoutes";
import { ContractResponse } from "../../../Contracts/interface";
import { getOpenContractList } from "../../api";
import { useSelector } from "react-redux";
import { WORK_TABS } from "../../../../../utils/enums/workTabs.enum";
import Toltip from "../../../toltip/page";
import { RootState } from "../../../../../redux/store";
import { getCompletedClosedContracts } from "../api";

const CompletedClosedJobsDirect = () => {
  const navigate = useNavigate();
  const dashboardData = useSelector((state: RootState) => state.dashboard);
  const { activeTab } = dashboardData;
  const [closedContract, setClosedContract] = useState<ContractResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const fetchOngoingContracts = async () => {
      setLoading(true);
      try {
        let response: ContractResponse | any;
        if (activeTab === WORK_TABS.DONEFORME) {
          response = await getOpenContractList(
            CONTRACT_Type.DIRECT_JOB,
            TYPE.DONEFORME,
            WORK_STATUS_TYPE.CLOSED
          );
        } else if (activeTab === WORK_TABS.DONEBYME) {
          response = await getCompletedClosedContracts(
            CONTRACT_Type.DIRECT_JOB,
            TYPE.DONEBYME,
            WORK_STATUS_TYPE.CLOSED
          );
          console.log(response.data, "data for contracts closed completed");
        } else {
          response = { data: [] };
        }
        setClosedContract(response.data);
      } catch (error) {
        console.error("Error fetching ongoing contracts:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchOngoingContracts();
  }, [activeTab]);

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 2,
    centerPadding: "60px",
    slidesToScroll: 2,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.02,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`bg-white p-lg-4 p-2 shadow rounded-3 mt-3`}>
      <div className="custom-ongoing">
        <div className={styles.OuterSlides}>
          <div className={`${styles.AllContracta}`}>
            <div className="row align-items-center justify-content-between mx-0 g-0">
              <div className="col-lg-5 col-12">
                <div className="topTipHover w-100">
                  <div className="d-flex align-items-center w-100">
                    <h4 className="form-check-label text-20 fw-bold  font-inter mb-0">
                      Completed/ Closed Direct In-House Jobs
                    </h4>
                    <i>
                      <img className="customeTolImg" src={tip} alt="" />
                    </i>
                    {closedContract?.length > 0 ? (
                      ""
                    ) : (
                      <span className="badge rounded-3 bg-lightblue text-black text-16 fw-semibold px-3 py-2 ms-2">
                        0
                      </span>
                    )}
                    <Toltip />
                  </div>
                </div>
              </div>
            </div>
          </div>
          
            {loading ? (
              <div className="mt-3">
              <div className="spinner-grow text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              </div>
            ) : (
              <div className="">
              <Slider {...settings}>
                {closedContract?.map((item: ContractResponse, ind) => (
                  <div key={ind} className={`mt-3 ${styles.ContarctOuter}`}>
                    <div className={styles.ContarctHead}>
                      <div className={`w-100 ${styles.leftContract}`}>
                        <div className="row align-item-center justify-content-between mx-0 w-100 position-relative">
                          <div className="col-xxl-7 col-lg-10 col-12 mb-2">
                            <div className={styles.leftContract}>
                              <h5>{item?.occupation?.name}</h5>
                              <span>
                                Start Date :{" "}
                                {moment(item?.times?.startDate).format(
                                  "MM-DD-YYYY"
                                )}
                              </span>{" "}
                              <span>
                                End Date :{" "}
                                {moment(item?.times?.endDate).format(
                                  "MM-DD-YYYY"
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="col-xxl-5 col-lg-2 col-12 mb-2">
                            <div
                              className={styles.leftContract}
                              style={{ textAlign: "end" }}
                            >
                              {/* <button>{data?.workStatus === WORK_STATUS_TYPE.ONGOING ? "Ongoing" : data.workStatus === WORK_STATUS_TYPE.APPROVED ? "Approved" : data.workStatus === WORK_STATUS_TYPE.OPEN ? "Open" : ""}</button> */}
                              <button
                                className={`position-absolute top-0 ${styles.statusdetails}`}
                              >
                                Completed
                              </button>
                            </div>
                          </div>
                          <div className="col-xl-8 col-lg-12 col-12">
                            <div className={`w-100 ${styles.leftContract}`}>
                              <div className={styles.Local}>
                                <div
                                  className={`justify-content-start ${styles.adrssLoc}`}
                                >
                                  <span
                                    className={`py-2 mb-0 rounded-2 me-2  border-lightgreen bg-lightgreen lh-base`}
                                  >
                                    <img src={grbag} alt="" />
                                    {item?.planType === "whiteglove"
                                      ? "White Glove Contract"
                                      : item?.planType === "standard"
                                      ? "Standard Contract"
                                      : ""}
                                  </span>

                                  {item?.workLocation ? (
                                    <span
                                      className={`py-2 mb-0 rounded-2 bg-white text-blue border-blue lh-base`}
                                    >
                                      {item?.workLocation === "Remote"
                                        ? "Remote Contract"
                                        : item?.workLocation === "onsite"
                                        ? "Onsite Contract"
                                        : item?.workLocation === "Hybrid"
                                        ? "Hybrid Contract"
                                        : "Unknown Location"}
                                    </span>
                                  ) : (
                                    <span className="py-2 mb-0 rounded-2 bg-white text-blue border-blue lh-base">
                                      No Work Location
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-12 col-12 mt-lg-0 mt-2">
                            <div style={{ textAlign: "end" }}>
                              <button
                                className={`border-blue bg-blue text-white`}
                              >
                                <i className="far fa-copy"></i> Copy
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={styles.draftCity}>
                      <div className={styles.gratCtyLoc}>
                        <div>
                          <p>
                            <img src={loc} alt="" /> {item?.address}
                          </p>
                          <p>
                            <img src={timeling} alt="" />
                            Completed On:{" "}
                            {moment(item?.times?.endDate).format(
                              "MM-DD-YYYY"
                            )}{" "}
                          </p>
                          <div
                            className="fw-semibold clamp clamp1 py-2"
                            style={{ height: "36px" }}
                          >
                            <div
                              className={`text-14 clamp clamp1 ${styles.desblock}`}
                              dangerouslySetInnerHTML={{
                                __html:
                                  isExpanded && item.description
                                    ? item?.description
                                    : item?.description?.slice(0, 100),
                              }}
                            />
                            {/* <span
                              onClick={toggleReadMore}
                              className={`fw-medium ${styles.readMore}`}
                            >
                              {isExpanded
                                ? "Show Less"
                                : "Read More"}
                            </span> */}
                          </div>
                        </div>
                      </div>

                      {item?.technicalSkills?.length && (
                        <div key={ind} className={styles.draftSkills}>
                          <h5 className="mt-3">Top Skills</h5>
                          {/* <ul>
                            {item?.technicalSkills?.map((data, ind) => (
                              <li key={ind}>
                                <span>{data?.manufacturerDetails?.name}</span>
                              </li>
                            ))}
                          </ul> */}
                          <div className={styles.PenigSkils}>
                            <ul className="row align-items-start justify-content-start gap-0 mb-0">
                              <li className="col-3">
                                <div className="row align-items-start mb-3 g-2">
                                  <div className="col-4 text-center">
                                    <i>
                                      <img
                                        src={skill}
                                        alt=""
                                        className="img-fluid"
                                        width="35px"
                                        height="35px"
                                      />
                                    </i>
                                  </div>
                                  <div className="col-8">
                                    <h5 className="mb-0 fw-bold text-14 text-uppercase">
                                      Top Skill
                                    </h5>
                                    <p className="text-14 mb-0">Autocad</p>
                                  </div>
                                </div>
                              </li>
                              <li className="col-4">
                                <div className="row align-items-start mb-3 g-2">
                                  <div className="col-3 text-center">
                                    <i>
                                      <img
                                        src={skill}
                                        alt=""
                                        className="img-fluid"
                                        width="35px"
                                        height="35px"
                                      />
                                    </i>
                                  </div>
                                  <div className="col-9">
                                    <h5 className="mb-0 fw-bold text-14 text-uppercase">
                                      Second skill
                                    </h5>
                                    <p className="text-14 mb-0">Programming</p>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}
                      <div className={`mb-3 ${styles.draftPayrate}`}>
                        <h5 className="mb-2">Pay Rates</h5>
                        <ul>
                          <li>
                            <span>Base</span>
                            <label>${item?.rates?.baseRate}</label>
                          </li>
                          <li>
                            <span>Overtime</span>
                            <label>${item?.rates?.overTimeRate}</label>
                          </li>
                          <li>
                            <span>Sun/Holidays</span>
                            <label>${item?.rates?.doubleTimeRate}</label>
                          </li>
                        </ul>
                      </div>

                      <div
                        className={`row align-items-center justify-content-start g-3 ${styles.invoiceBox}`}
                      >
                        <div className="col">
                          <div className={`${styles.invoiceBoxInner}`}>
                            <h4>Invoices</h4>
                            <h5>20</h5>
                          </div>
                        </div>
                        <div className="col">
                          <div className={`${styles.invoiceBoxInner}`}>
                            <h4>Purchase Orders</h4>
                            <h5>20</h5>
                          </div>
                        </div>
                        <div className="col">
                          <div className={`${styles.invoiceBoxInner}`}>
                            <h4>Time Sheets</h4>
                            <h5>20</h5>
                          </div>
                        </div>
                        <div className="col">
                          <div className={`${styles.invoiceBoxInner}`}>
                            <h4>Expense Reports</h4>
                            <h5>20</h5>
                          </div>
                        </div>
                        <div className="col">
                          <div className={`${styles.invoiceBoxInner}`}>
                            <h4>Contrctors Hired</h4>
                            <h5>20</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
              </div>
            )}
          </div>
        </div>
      </div>
  );
};

export default CompletedClosedJobsDirect;
