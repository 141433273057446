import { userProfileResponse } from "../interface";
import { API_ROUTES } from "../../../utils/routes/apiRoutes";
import { axios } from "../../../lib/axios";
import { AxiosResponse } from "../../../shared/models";

export const getAllUserProfiles = async (): Promise<AxiosResponse<userProfileResponse[]>> => {
  return  axios.get(`${API_ROUTES.PROFILE.GET_ALL_USER_PROFILES}?limit=100&page=1`);
};

// export const searchUserProfiles = async (searchTerm: string): Promise<AxiosResponse<userProfileResponse[]>> => {
//   const queryParams = searchTerm ? `?search=${encodeURIComponent(searchTerm)}` : "";
//   return axios.get(`${API_ROUTES.PROFILE.PROFESSIONALS}${queryParams}`);
// };
export const searchUserProfiles = async (
  searchTerm: string = "",
  page: number,
  pageSize: number
): Promise<AxiosResponse<userProfileResponse[]>> => {
  const queryParams = new URLSearchParams({
    ...(searchTerm && { search: searchTerm }),
    page: page.toString(),
    limit: pageSize.toString(),
  });

  const url = `${API_ROUTES.PROFILE.PROFESSIONALS}?${queryParams.toString()}`;
  
  return axios.get(url);
};
export const getAvailableProfessionals = async (): Promise<AxiosResponse<userProfileResponse[]>> => {
  const url = "https://dev-api.automateamerica.com/api/user/professionals?isAvailable=true";
  return axios.get(url);
};

export const getBoostedProfessional = async (): Promise<AxiosResponse<userProfileResponse[]>> => {
  const url = "https://dev-api.automateamerica.com/api/user/professionals?isBoosted=true";
  return axios.get(url);
};







