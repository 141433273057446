import { boolean } from "yup";
import { IOption } from "../Interfaces";
import { ISaveDirectJobApiRequestBody } from "../Interfaces/saveDirectJobApiRequestBody";
import { DIRECT_JOB_STEPS } from "../components/contractBeforeLogin/DirectJobType/AddDirectJob/Enums";
import { WORK_STATUS_TYPE } from "../utils/enums/status";
import { JobResponseDirect } from "../components/common/Contracts/interface/directjobInterface";
export interface ChooseOccupationforDirectJob {
  primaryOccupation?: IOption;
  secondaryOccupations: IOption[];
}
export interface KeyInformations {
  startDate: any;
  shift: string;
  plannedHoursPerDay: number;
  plannedDaysPerWeek: number;
}
export interface Locations {
  selectedCountry: IOption;
  selectedStreet: IOption;
  selectedState: IOption;
  selectedCity: IOption;
  selectedZipcode: IOption;
  isFilled?: boolean | undefined;
  // for UI only
  countries: IOption[];
  states: IOption[];
  cities: IOption[];
  streets: IOption[];
  zipcode: IOption[];

}
export interface Rate {
  baseRate: number | string;
  otRate: number | string;
  dtRate: number | string;
  paymentTerms: IOption;
  paymentTermsList?: IOption[];
}
export interface TechnicalSkillsDataDirectJob {
  skillName: IOption[];
  skillTypes: IOption[];
  skills: TechnicalSkillDirectJob[];
  skillImg: string[];
}
export interface TechnicalSkillDirectJob {
  category: IOption;
  subCategory: IOption[];
  isRequired: boolean;
  subCategoriesList: IOption[];
}
export interface EligibilityAnswersDirectJob {
  Answers: boolean;
  questionId: string;
}
export interface InterviewAnswersDirectJob {
  Answers: string;
  questionId: string;
}
export interface Additionfiels {
  workLocation: string;
  isSameCitizenship: boolean,
  isHealthInsurance: boolean,
  isVacationAndTimeOff: boolean,
  isOtherBenefits: boolean,
}
export interface ProceduresAndPolicies {
  proceduresAndPolicies: string;
}
export class AddDirectJobRootState {
  started: boolean = false;
  jobStatus?: WORK_STATUS_TYPE = WORK_STATUS_TYPE.DRAFT;
  activeStep: DIRECT_JOB_STEPS = DIRECT_JOB_STEPS.ADD_OCCUPATION;
  contractType: string = "direct_job";
  occupationsList: IOption[] = [];
  occupation: ChooseOccupationforDirectJob = {
    secondaryOccupations: [],
  };
  workDescription: string = "";
  keyInformations: KeyInformations = {
    startDate: new Date(),
    shift: "",
    plannedHoursPerDay: 0,
    plannedDaysPerWeek: 0,
  };
  location: Locations = {
    selectedCountry: { label: "", value: "" },
    selectedCity: { label: "", value: "" },
    selectedState: { label: "", value: "" },
    selectedStreet: { label: "", value: "" },
    selectedZipcode: { label: '', value: '' },
    isFilled: false,
    cities: [],
    countries: [],
    states: [],
    streets: [],
    zipcode: [],
  };
  rates: Rate = {
    baseRate: '',
    dtRate: '',
    otRate: '',
    paymentTerms: { label: '', value: '' },
    paymentTermsList: [],
  };
  additionfiels: Additionfiels = {
    workLocation: '',
    isSameCitizenship: false,
    isHealthInsurance: false,
    isVacationAndTimeOff: false,
    isOtherBenefits: false,
  };

  technicalSkillsData: TechnicalSkillsDataDirectJob = {
    skillName: [],
    skillTypes: [],
    skills: [
      {
        subCategory: [],
        isRequired: false,
        category: { label: "", value: "" },
        subCategoriesList: [],
      },
    ],
    skillImg: [],
  };
  eligibilityQuestions: string[] = [""];
  interviewQuestions: string[] = [""];
  proceduresAndPolicies: ProceduresAndPolicies = { proceduresAndPolicies: '' };

  constructor(props?: Partial<AddDirectJobRootState>) {
    Object.assign(this, props);
  }
  
  // Mapping UI model to Backend Model
  public serialize(): ISaveDirectJobApiRequestBody {
    return {
      workType: this.contractType,
      description: this.workDescription,
      workStatus: this.jobStatus || '',
      countryId: this.location.selectedCountry?.value,
      state: this.location.selectedState?.label,
      city: this.location.selectedCity?.label,
      occupationIds: this.occupation.primaryOccupation
        ? [this.occupation.primaryOccupation.value]
        : [],
      secondaryOccupationIds: this.occupation.secondaryOccupations?.map(
        (x) => x.value
      ),
      technicalSkills: this.technicalSkillsData.skills.map((x) => ({
        manufacturerIds: x.category.value,
        isGoodToHave: !x.isRequired,
        isMandatory: x.isRequired,
        manufacturerModelIds:
          x.subCategoriesList.length > 0 ? x.subCategoriesList[0].value : "",
        skillImg: this.technicalSkillsData.skillImg || [], // Ensure this is an array
        skillName: this.technicalSkillsData.skillName[0]?.value || "",
      })),

      eligibilityQuestions: this.eligibilityQuestions?.map((x) => ({
        question: x,
      })),
      interviewQuestions: this.interviewQuestions?.map((x) => ({
        question: x,
      })),
      proceduresAndPolicies: {
        description: this.proceduresAndPolicies.proceduresAndPolicies,
      },
      times: {
        startDate: this.keyInformations.startDate
          ? new Date(this.keyInformations.startDate).toISOString()
          : "",
        plannedDaysPerWeek: this.keyInformations.plannedDaysPerWeek,
        plannedHoursPerDay: this.keyInformations.plannedHoursPerDay,
        shift: this.keyInformations.shift,
      },
      hourlyPaymentTermId: this.rates.paymentTerms.value,
      rates: {
        baseRate: this.rates.baseRate,
        overTimeRate: this.rates.otRate,
        doubleTimeRate: this.rates.dtRate,
      },
      location: {
        type: "Point",
        coordinates: [0, 0],
      },
      isHealthInsurance: Boolean(this.additionfiels.isHealthInsurance),
      isSameCitizenship: Boolean(this.additionfiels.isSameCitizenship),
      isVacationAndTimeOff: Boolean(this.additionfiels.isVacationAndTimeOff),
      isOtherBenefits: Boolean(this.additionfiels.isOtherBenefits),
      workLocation: this.additionfiels.workLocation,
    }
  }

  public static deserialize(apiData?: JobResponseDirect) {
    if (!apiData) {
      return new AddDirectJobRootState();
    }

    return new AddDirectJobRootState({
      ...apiData,
      contractType: apiData?.workType,
      workDescription: apiData?.description,
      location: {
        selectedCountry: { label: apiData?.countryId, value: apiData?.countryId },
        selectedCity: { label: apiData.city, value: apiData.city },
        selectedState: { label: apiData.state, value: apiData.state },
        selectedStreet: { label: apiData?.street || '', value: apiData.street || '' },
        selectedZipcode: { label: apiData?.zipcode || '', value: apiData?.zipcode || '' },
        cities: [],
        states: [],
        streets: [],
        countries: [],
        zipcode: [],
      },
      occupation: {
        primaryOccupation: {
          label: apiData?.occupationIds[0],
          value: apiData?.occupationIds[0],
        },
        secondaryOccupations: [],
      },
      technicalSkillsData: {
        skillName: apiData?.technicalSkills?.map((x) => ({
          label: x.skillName || '', // Assuming `skillName` is an object or string
          value: x.skillName || '', // Adjust based on your actual structure
        })) || [],
        skillTypes: apiData?.technicalSkills?.map((x) => ({
          label: x.manufacturerIds, // Adjust based on your actual structure
          value: x.manufacturerIds,
        })) || [],
        skills: [],
        skillImg: Array.isArray(apiData?.technicalSkills[0].skillName)
          ? apiData?.technicalSkills[0].skillName.flat() // Flatten if it's an array of arrays
          : [],
      },
      eligibilityQuestions: apiData?.eligibilityQuestions?.map((x) => x.question) || [],
      interviewQuestions: apiData?.interviewQuestions?.map((x) => x.question) || [],
      keyInformations: {
        startDate: apiData?.times?.startDate
          ? new Date(apiData.times.startDate).toISOString()
          : "",
        plannedDaysPerWeek: apiData?.times?.plannedDaysPerWeek || 0,
        plannedHoursPerDay: apiData?.times?.plannedHoursPerDay || 0,
        shift: apiData?.times?.shift || "",
      },
      rates: {
        baseRate: apiData?.rates?.baseRate,
        otRate: apiData?.rates?.overTimeRate,
        dtRate: apiData?.rates?.doubleTimeRate,
        paymentTerms: {
          label: apiData.rates.paymentTerms?.label || '',
          value: apiData.rates.paymentTerms?.value || '',
        },
      },
      additionfiels: {
        isOtherBenefits: Boolean(apiData?.isOtherBenefits),
        isHealthInsurance: Boolean(apiData?.isHealthInsurance),
        isSameCitizenship: Boolean(apiData?.isSameCitizenship),
        isVacationAndTimeOff: Boolean(apiData?.isVacationAndTimeOff),
        workLocation: apiData?.workLocation,
      },
      proceduresAndPolicies: {
        proceduresAndPolicies: apiData?.proceduresAndPolicies?.toString() || '',
      },
    });
  }

}
