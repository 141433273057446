import React, { FC, useState } from "react";
import HeaderContent from "../../shared/Components/HeaderContent/headerContent";
import styles from "../../components/contractBeforeLogin/styles/style.module.css";
import { useNavigate } from "react-router-dom";
import choosetype from "../../assets/images/choosetype.png";
import blackarrow from "../../assets/images/blackarrow.svg";
import whitearrow from "../../assets/images/whitearrow.svg";
import tip from "../../assets/images/tip.png";
import { CONSTANT_MANAGER } from "../../utils/constans/constants";
import Toltip from "../../components/common/toltip/page";
import { APP_ROUTES } from "../../utils/routes/AppRoutes";
import storage from "../../utils/storage";
import groupImg from "../../assets/images/Group.svg";

const ChooseAdType: FC = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState<string>(
    APP_ROUTES.ADD_PAID_ADS.JOB_AD
  );

  const onInputChange = (step: string): void => setActiveStep(step);

  const goToNextStep = (): void => {
    if (
      activeStep === APP_ROUTES.ADD_PAID_ADS.JOB_AD ||
      activeStep === APP_ROUTES.ADD_PAID_ADS.CONTRACT_AD
    ) {
      storage.clearSavedContract();
    }
    navigate(activeStep);
  };

  const handleGoBack = () => {
    navigate(APP_ROUTES.ADD_CONTRACT);
  };

  const isActive = (step: string) => activeStep === step;

  return (
    <>
      <HeaderContent />
      <div className="heightautoscroll contractradio">
        <form className="h-100">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-lg-6">
                <div className={styles.registerLeft}>
                  <div className="text-center col-lg-10 col-12 mx-auto">
                    <figure className="mb-0">
                      <img
                        src={groupImg}
                        alt="Choose Type"
                        className="img-fluid w-100"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <h4 className="text-white text-32 fw-semibold font-inter">
                  Ad Posting on Automate America
                </h4>
                <p className="text-white text-14 opacity-75">
                  Ready to promote your job listings, online contracts, or RFQ
             
                  on Automate America? Follow this easy process to create and{" "}
              
                  boost your ad! Start by choosing your ad type, then add all{" "}
             
                  the important details: title, description, location, and{" "}
                  
                  contact info. Make your ad stand out by uploading clear and{" "}
               
                  attractive pictures or graphics that showcase your offer.{" "}
        
                </p>
                <div className={styles.signUPContainer}>
                  <div className={styles.registerRight}>
                    <ul
                      className={`customRadio row align-items-start ${styles.radioBTn}`}
                    >
                      {/* Jobs Ad */}
                      <li
                        className={`col-lg-6 mb-4 ${
                          isActive(APP_ROUTES.ADD_DIRECT_JOBS)
                            ? styles.active
                            : ""
                        }`}
                      >
                        <div className="row align-items-center py-4 g-0">
                          <div className="col-lg-2 col-1 text-center">
                            <label className="form-check-label">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id={CONSTANT_MANAGER.ADD_CONTRACT.DIRECT_JOB}
                                onChange={() =>
                                  onInputChange(APP_ROUTES.ADD_DIRECT_JOBS)
                                }
                                checked={isActive(APP_ROUTES.ADD_DIRECT_JOBS)}
                              />
                            </label>
                          </div>
                          <div className="col-lg-10 col-11">
                            <div className="topTipHover w-100">
                              <div className="d-flex align-items-center mb-0">
                                <h4 className="form-check-label text-14 fw-bold font-inter mb-0">
                                  Jobs
                                </h4>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt="Tooltip"
                                  />
                                </i>
                                <Toltip
                                  slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                  labelId={
                                    CONSTANT_MANAGER.ADD_CONTRACT.DIRECT_JOB
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>

                      {/* Contracts Ad */}
                      <li
                        className={`col-lg-6 mb-4 ${
                          isActive(APP_ROUTES.ADD_HOURLY_CONTRACT)
                            ? styles.active
                            : ""
                        }`}
                      >
                        <div className="row align-items-center py-4 g-0">
                          <div className="col-lg-2 col-1 text-center">
                            <label className="form-check-label">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id={
                                  CONSTANT_MANAGER.ADD_CONTRACT.HOURLY_CONTRACT
                                }
                                onChange={() =>
                                  onInputChange(APP_ROUTES.ADD_HOURLY_CONTRACT)
                                }
                                checked={isActive(
                                  APP_ROUTES.ADD_HOURLY_CONTRACT
                                )}
                              />
                            </label>
                          </div>
                          <div className="col-lg-10 col-11">
                            <div className="topTipHover w-100">
                              <div className="d-flex align-items-center mb-0">
                                <h4 className="form-check-label text-14 fw-bold font-inter mb-0">
                                  Contracts
                                </h4>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt="Tooltip"
                                  />
                                </i>
                                <Toltip
                                  slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                  labelId={
                                    CONSTANT_MANAGER.ADD_CONTRACT
                                      .HOURLY_CONTRACT
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>

                      {/* Products Ad */}
                      <li
                        className={`col-lg-6 mb-4 ${
                          activeStep === APP_ROUTES.ADD_PAID_ADS.PRODUCT_AD
                            ? styles.active
                            : ""
                        }`}
                      >
                        <div className="row align-items-center py-4 g-0">
                          <div className="col-lg-2 col-1 text-center">
                            <label className="form-check-label">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id={CONSTANT_MANAGER.ADD_CONTRACT.POST_YOUR_AD}
                                onChange={() =>
                                  onInputChange(
                                    APP_ROUTES.ADD_PAID_ADS.PRODUCT_AD
                                  )
                                }
                                checked={
                                  activeStep ===
                                  APP_ROUTES.ADD_PAID_ADS.PRODUCT_AD
                                }
                              />
                            </label>
                          </div>
                          <div className="col-lg-10 col-11">
                            <div className="topTipHover w-100">
                              <div className="d-flex align-items-center mb-0">
                                <h4 className="form-check-label text-14 fw-bold font-inter mb-0">
                                  Product
                                </h4>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                                <Toltip
                                  slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                  labelId={
                                    CONSTANT_MANAGER.ADD_CONTRACT.PAID_AD.DATA
                                      .PAID_AD
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>

                      {/* Services Ad */}
                      <li
                        className={`col-lg-6 mb-4 ${
                          activeStep === APP_ROUTES.ADD_PAID_ADS.SERVICE_AD
                            ? styles.active
                            : ""
                        }`}
                      >
                        <div className="row align-items-center py-4 g-0">
                          <div className="col-lg-2 col-1 text-center">
                            <label className="form-check-label">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id={CONSTANT_MANAGER.ADD_CONTRACT.POST_YOUR_AD}
                                onChange={() =>
                                  onInputChange(
                                    APP_ROUTES.ADD_PAID_ADS.SERVICE_AD
                                  )
                                }
                                checked={
                                  activeStep ===
                                  APP_ROUTES.ADD_PAID_ADS.SERVICE_AD
                                }
                              />
                            </label>
                          </div>
                          <div className="col-lg-10 col-11">
                            <div className="topTipHover w-100">
                              <div className="d-flex align-items-center">
                                <h4 className="form-check-label text-14 fw-bold font-inter">
                                  Service
                                </h4>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                                <Toltip
                                  slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                  labelId={
                                    CONSTANT_MANAGER.ADD_CONTRACT.PAID_AD.DATA
                                      .PAID_AD
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>

                      {/* Company's Ad */}
                      <li
                        className={`col-lg-6 mb-4 ${
                          activeStep === APP_ROUTES.ADD_PAID_ADS.COMPANY_AD
                            ? styles.active
                            : ""
                        }`}
                      >
                        <div className="row align-items-center py-4 g-0">
                          <div className="col-lg-2 col-1 text-center">
                            <label className="form-check-label">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id={CONSTANT_MANAGER.ADD_CONTRACT.POST_YOUR_AD}
                                onChange={() =>
                                  onInputChange(
                                    APP_ROUTES.ADD_PAID_ADS.COMPANY_AD
                                  )
                                }
                                checked={
                                  activeStep ===
                                  APP_ROUTES.ADD_PAID_ADS.COMPANY_AD
                                }
                              />
                            </label>
                          </div>
                          <div className="col-lg-10 col-11">
                            <div className="topTipHover w-100">
                              <div className="d-flex align-items-center">
                                <h4 className="form-check-label text-14 fw-bold font-inter">
                                  Company Ads
                                </h4>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                                <Toltip
                                  slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                  labelId={
                                    CONSTANT_MANAGER.ADD_CONTRACT.PAID_AD.DATA
                                      .PAID_AD
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="row align-items-center justify-content-bewteen g-0">
                  <div className="col-6 mb-3 btn-hover-effect">
                    <h6
                      onClick={handleGoBack}
                      className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton"
                    >
                      <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                        <img src={blackarrow} alt="Back Arrow" />
                      </i>
                      <span className="px-lg-4 px-2 text-12 fw-semibold">
                        Back
                      </span>
                    </h6>
                  </div>
                  <div className="col-6 text-end mb-3 btn-hover-effect">
                    <h6
                      onClick={goToNextStep}
                      className="btn arrowblue border-grey border-1 position-relative pe-5"
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold">
                        Continue
                      </span>
                      <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                        <img src={whitearrow} alt="Next Arrow" />
                      </i>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ChooseAdType;
