import React from 'react'
import styles from "../styles/style.module.css"
import Jobs from "../../../assets/images/job.svg"
import Requests from "../../../assets/images/request.svg"
import Posts from "../../../assets/images/post.svg"
import People from "../../../assets/images/people.svg"
import blsearch from "../../../assets/images/blsearch.png"
import Search from "../../../assets/images/search.svg"
import vic from "../../../assets/images/vic.svg"
import Plus from "../../../assets/images/plus.png"
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '../../../utils/routes/AppRoutes'

const Subheader = () => {
  const navigate = useNavigate()
  return (
    <div className='container'>
    <div className={styles.HeaderMain}>
      <div className={styles.seachFeeds}>
        <div className={styles.foirmGroup}>
          <span><img src={blsearch} alt=""/></span>
          <input type='text' placeholder='Search'/>
          <button type='submit'><img src={vic} alt=""/></button>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg">
        <div className="container pt-lg-2">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-2">
              <li className={styles.active}>
                <a className="nav-link d-flex" href="#">
                  <i className="me-2">
                    <img src={Jobs} alt="" />
                  </i>
                  Jobs
                </a>
              </li>
              <li className="">
                <a className="nav-link d-flex" href="#">
                  <i className="me-2">
                    <img src={Requests} alt="" />
                  </i>
                  Requests
                </a>
              </li>
              <li className="">
                <a className="nav-link d-flex" href="#">
                  <i className="me-2">
                    <img src={Posts} alt="" />
                  </i>
                  Posts
                </a>
              </li>
              <li className="">
                <a className="nav-link d-flex" href="#">
                  <i className="me-2">
                    <img src={People} alt="" />
                  </i>
                  People
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.Addpost} onClick={() => navigate(APP_ROUTES.ADD_CONTRACT)}>
            <div className="me-lg-0 me-4">
              <button className="btn btn-primary h-40 text-14 d-flex align-items-center justify-content-center">
                <i className="me-lg-2 me-1">
                  <img src={Plus} alt="" width={10} height={10} />
                </i>
                Add Post
              </button>
            </div>
          </div>
        </div>
      </nav>
    </div>
    </div>
  );
}

export default Subheader