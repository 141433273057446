import React, { useState } from "react";
import styles from "../styles/style.module.css";
import info from "../../../assets/images/info_icon.svg";
import ChatListing from "../../ChatListing/routes";
import ChatModule from "../../ChatModule/routes";
import AdminChatIcon from "../../common/ChatBotIcon/AdminChatIcon";

const ChatWithAdmin = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={styles.chatblock}>
      <div className="container">
        <div className={styles.new_feeds}>
          <div className="row align-items-center justify-content-between g-lg-5 g-3">
            <div className="col-lg-6 col-12">
              <div className={styles.chatListing}>
                <div className="newheadingstyle">
                  <h3 className="mb-4">
                    Chat with Admin
                    <i className="ms-2">
                      <img src={info} alt="" />
                    </i>
                  </h3>
                </div>
                <ChatListing />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className={styles.chatingblock}>
                {isModalOpen && (
                  <div className="">
                    <ChatModule onClose={handleClose} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {!isModalOpen && <AdminChatIcon onOpen={handleOpen} />} */}
    </div>
  );
};

export default ChatWithAdmin;
