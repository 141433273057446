import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../EditProfile/styles/style.module.css";
import Header from "../../header/routes";
import Editprofile from "../EditProfile/Editprofile";
import ConnectionPlan from "../Connections/ConnectionPlan";
import { logout } from "../../../feature/auth/login/api";
import { toast } from "react-toastify";
import storage from "../../../utils/storage";
import { APP_ROUTES } from "../../../utils/routes/AppRoutes";

const EditProfileMain = () => {
  const navigate = useNavigate();
  const [selectedComponent, setSelectedComponent] = useState<string>("Profile");
  const hideHeaderPaths = ["/newBusiness/businessGroup", "/home"];
  const showHeader = !hideHeaderPaths.includes(location.pathname);

  const handleLogout = () => {
    logOutUser();
  };
  const logOutUser = (): void => {
    toast.promise(logout(), {
      pending: {
        render() {
          return "Logout user";
        },
      },
      success: {
        render() {
          storage.clearToken();
          storage.clearAuthUserId();
          storage.clearSavedContract();
          storage.clearProfieImg();
          // dispatch(logoutUser());
          navigate(APP_ROUTES.DASHBOARD);
          return "User Logged out successfully!";
        },
      },
      error: {
        render({ data }) {
          return (data as any)?.data?.message;
        },
      },
    });
  };

  const renderComponent = () => {
    switch (selectedComponent) {
      case "Profile":
        return <Editprofile />;
      case "Connections":
        return <ConnectionPlan />;
      case "Invoices/Payment":
        return <div>Invoices/Payment Content</div>;
      case "Drafts Posts":
        return <div>Drafts Posts Content</div>;
      case "Rating & Feedback":
        return <div>Rating & Feedback Content</div>;
      case "Contact us":
        return <div>Contact us Content</div>;
      case "Notification Settings":
        return <div>Notification Settings Content</div>;
      case "Logout":
        handleLogout();
        return <div>Logging out...</div>;
      default:
        return <div>Profile Content</div>;
    }
  };

  return (
    <>
      {showHeader && <Header />}
      <div className={`editprofilepage ${styles.EditProfieOuter}`}>
        <div className="container">
          <div className={styles.EditProfieOuterBord}>
            <div className="row mx-0">
              <div className="col-lg-4 col-12 p-0">
                <div className={styles.LeftditPro}>
                  <h3 className="fw-semibold">My Profile</h3>
                  <ul>
                    <li>
                      <Link
                        to="#"
                        onClick={() => setSelectedComponent("Profile")}
                        className={
                          selectedComponent === "Profile"
                            ? styles.activeLink
                            : ""
                        }
                      >
                        Profile
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        onClick={() => setSelectedComponent("Connections")}
                        className={
                          selectedComponent === "Connections"
                            ? styles.activeLink
                            : ""
                        }
                      >
                        Connections
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        onClick={() => setSelectedComponent("Invoices/Payment")}
                        className={
                          selectedComponent === "Invoices/Payment"
                            ? styles.activeLink
                            : ""
                        }
                      >
                        Invoices/Payment
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        onClick={() => setSelectedComponent("Drafts Posts")}
                        className={
                          selectedComponent === "Drafts Posts"
                            ? styles.activeLink
                            : ""
                        }
                      >
                        Drafts Posts
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        onClick={() =>
                          setSelectedComponent("Rating & Feedback")
                        }
                        className={
                          selectedComponent === "Rating & Feedback"
                            ? styles.activeLink
                            : ""
                        }
                      >
                        Rating & Feedback
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        onClick={() => setSelectedComponent("Contact us")}
                        className={
                          selectedComponent === "Contact us"
                            ? styles.activeLink
                            : ""
                        }
                      >
                        Contact us
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        onClick={() =>
                          setSelectedComponent("Notification Settings")
                        }
                        className={
                          selectedComponent === "Notification Settings"
                            ? styles.activeLink
                            : ""
                        }
                      >
                        Notification Settings
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        onClick={() => setSelectedComponent("Logout")}
                        className={
                          selectedComponent === "Logout"
                            ? styles.activeLink
                            : ""
                        }
                      >
                        Logout
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-8 col-12 p-0">{renderComponent()}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfileMain;
