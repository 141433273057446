import React, { useEffect, useState } from "react";
import styles from "../../../../../../components/common/css/styles/style.module.css";
// import style from "../../css/styles/style.module.css";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import loc from "../../../../../../assets/images/icon_location_filled.svg";
import profile from "../../../../../../assets/images/profile_picture.png";
import cross from "../../../../../../assets/images/reject.svg";
import rdinfo from "../../../../../../assets/images/rdinfo.png";
import { Link, useNavigate } from "react-router-dom";
import tip from "../../../../../../../src/assets/images/info_icon.svg";
import Toltip from "../../../../toltip/page";
import {
  acceptJobOffer,
  getJobOfferPendingProfessional,
  getOngoingContractListProfesional,
  rejectJobOffer,
} from "../../api";
import { RootState } from "../../../../../../redux/store";
import { ContractResponse } from "../../../interface";
import { WORK_TABS } from "../../../../../../utils/enums/workTabs.enum";
import {
  CONTRACT_Type,
  JOB_STATUS,
  PLAN_TYPE,
  TYPE,
} from "../../../../../../utils/enums/types";
import { WORK_STATUS_TYPE } from "../../../../../../utils/enums/status";
import { APIResponseErrors } from "../../../../../work/interface";
import { toast } from "react-toastify";

const JobOfferPending = () => {
  const dashboardData = useSelector((state: RootState) => state.dashboard);
  const { activeTab } = dashboardData;
  const [jobOfferData, setJobOfferData] = useState<ContractResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchOngoingContracts = async () => {
      setLoading(true);
      try {
        let response: ContractResponse | any;
        if (activeTab === WORK_TABS.DONEBYME) {
          response = await getJobOfferPendingProfessional(
            CONTRACT_Type.HOURLY_CONTRACT,
            WORK_STATUS_TYPE.OPEN,
            PLAN_TYPE.WHITEGLOVE,
            JOB_STATUS.SHORTLISTED
          );
        } else {
          response = { data: [] };
        }
        setJobOfferData(response.data);
      } catch (error) {
        console.error("Error fetching ongoing contracts:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchOngoingContracts();
  }, [activeTab]);

  const RecjectOffer = async (workId: string) => {
    try {
      const response = await rejectJobOffer(workId);
      if (response.status === 200) {
        toast.success(response.message);
      }
      console.log("Job offer rejected successfully:", response.data);

      setJobOfferData((prevOffers) =>
        prevOffers.filter((offer) => offer._id !== workId)
      );
    } catch (error) {
      const apiError = error as APIResponseErrors;
      const errorMessage = apiError?.data?.message as string;
      console.error("Error rejecting job offer:", error);
      toast.error(errorMessage);
    }
  };

  const AcceptOffer = async (workId: string) => {
    try {
      const response = await acceptJobOffer(workId);
      if (response.status === 200) {
        toast.success(response.message);
      }
      console.log("Job offer selected successfully:", response.data);

      setJobOfferData((prevOffers) =>
        prevOffers.filter((offer) => offer._id !== workId)
      );
    } catch (error) {
      const apiError = error as APIResponseErrors;
      const errorMessage = apiError?.data?.message as string;
      console.error("Error accepting job offer:", error);
      toast.error(errorMessage);
    }
  };

  var Jobslide = {
    dots: false,
    speed: 500,
    slidesToShow: 2,
    centerPadding: "60px",
    slidesToScroll: 2,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1.4,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.02,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={`bg-white p-lg-4 p-2 shadow rounded-3 mt-3`}>
      <div className={`${styles.AllContracta}`}>
        <div className="row align-items-center justify-content-between mx-0 g-0">
          <div className="col-lg-5 col-12">
            <div className="topTipHover">
              <div className="d-flex align-items-center">
                <h4 className="form-check-label text-20 fw-bold  font-inter mb-0">
                  Job Offers Pending
                </h4>
                <i>
                  <img className="customeTolImg" src={tip} alt="" />
                </i>
                {jobOfferData?.length > 0 ? '' : <span className="badge rounded-3 bg-lightblue text-black text-16 fw-semibold px-3 py-2 ms-2">0</span>}
                <Toltip />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className='row align-items-center g-3'
      >
        <Slider {...Jobslide}>
          {jobOfferData.map((offer, index) => (
            <div className="col mt-3" key={index}>
              <div
                className={`border bg-white rounded-3 ${styles.ContractTabs}`}
              >
                <div className={`px-3 py-2 ${styles.ContarctHead}`}>
                  <div className={`py-1 ${styles.leftContract}`}>
                    <h5 className="fw-semibold text-14 mb-0">
                      {offer?.contractor?.occupation?.name}
                    </h5>
                    <span>
                      Start Date:{" "}
                      {offer?.times?.startDate
                        ? new Date(offer.times.startDate).toLocaleDateString(
                            "en-US",
                            {
                              month: "2-digit",
                              day: "2-digit",
                              year: "numeric",
                            }
                          )
                        : "N/A"}
                    </span>
                  </div>
                </div>
                <div>
                  <div className={styles.outerNameDetail}>
                    <div className="row align-items-center pb-3 border-bottom">
                      <div className="col-md-12">
                        <div className={styles.outerNDetail}>
                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="mb-3">
                                <h4 className="fw-semibold text-18">
                                  {" "}
                                  Business Details
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-12 pe-0">
                        <div className={styles.leftName}>
                          <div className={styles.PrileImg}>
                            <span className="text-center w-100">
                              <i>
                                <img
                                  src={offer?.contractor?.profilePicture}
                                  alt=""
                                  style={{ width: "100px", minHeight: "60px" }}
                                  className=" img-fluid rounded-circle"
                                />
                              </i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-6">
                        <div className={styles.leftName}>
                          <div className={styles.PrileImg}>
                            <div className="">
                              <h5 className="clamp clamp1">
                                {offer?.contractor?.name}
                              </h5>
                              <h6 className="text-14 clamp clamp1">
                                Software Developer
                              </h6>
                              <p className="clamp clmap1 d-inline-block">
                                <i className="">
                                  <img src={loc} alt="" />
                                </i>{" "}
                                {offer?.contractor?.address}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-6">
                        <div className={`ps-3 ${styles.rightName}`}>
                          <p>
                            {" "}
                            Start Date:{" "}
                            {offer?.times?.startDate
                              ? new Date(
                                  offer.times.startDate
                                ).toLocaleDateString("en-US", {
                                  month: "2-digit",
                                  day: "2-digit",
                                  year: "numeric",
                                })
                              : "N/A"}
                          </p>
                          <p>Rate Per Hour :{offer?.rates?.baseRate}</p>
                          <p>
                            Expense Terms : Net{" "}
                            {offer?.expensePayment?.paymentTerm}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <ul className="row align-items-center justify-content-between mx-3 g-2">
                      <li className="col-6">
                        <button
                          className="rounded-2 p-2 h-48 text-black fw-semibold text-14 d-flex align-items-center w-100 justify-content-center"
                          style={{ background: "#d6d6d6" }}
                          onClick={() => RecjectOffer(offer._id)}
                        >
                          <i className="me-2">
                            <img src={cross} alt="" />
                          </i>
                          Reject
                        </button>
                      </li>
                      <li className="col-6">
                        <button
                          className="bg-blue rounded-2 p-2 h-48 fw-semibold text-white text-14 d-flex align-items-center justify-content-center w-100 text-center"
                          onClick={() => AcceptOffer(offer._id)}
                          type="button"
                        >
                          {" "}
                          Accept offer
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className={styles.Infor}>
                    <p className="text-14 d-flex align-items-center">
                      <i className="me-2">
                        <img src={rdinfo} alt="" />
                      </i>{" "}
                      After selecting the applicant, You can schedule an
                      interview following the sending of a video call request.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default JobOfferPending;
