import React, { FC, useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import CustomModal from "../../../../../shared/CustomModal/customModal";
import {
  WORK_STATUS,
  WORK_STATUS_TYPE,
} from "../../../../../utils/enums/status";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import { PLAN_TYPE } from "../../../../../utils/enums/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import {
  initContract,
  setPlanType,
} from "../../../../../redux/reducers/addContract";
import Swal from "sweetalert2";
import storage from "../../../../../utils/storage";
import { AddContractRootState } from "../../../../../models";
import { saveContract, updateContract } from "../../../api";
import { APIResponseErrors } from "../../../interface";
import { SaveAsDraftAuthErrorDialog } from "../../Components";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../../../utils/routes/AppRoutes";
import Post from "../../../../../shared/CustomModal/PostTypeConfirmationModal";

interface Props {
  onClosePlanModal: () => void;
}
const Subscription: FC<Props> = ({ onClosePlanModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Plantype } = useSelector((state: RootState) => state.addContract);
  const contractStatus = WORK_STATUS_TYPE.PENDING;
  const token = storage.getToken();
  const { authentication } = useSelector((state: RootState) => state);
  const { isAuthenticated } = authentication;
  const addContract = useSelector((state: RootState) => state.addContract);
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const [lat, setLat] = useState<number>(0);
  const [long, setLong] = useState<number>(0);

  useEffect(() => {
    handleLocation();
  }, []);

  const handleLocation = () => {
    console.log("hello locationn");

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log("Geolocation not supported");
    }
  };

  const success = (position: any) => {
    setLat(position.coords.latitude);
    setLong(position.coords.longitude);
    console.log(`Latitude: ${lat}, Longitude: ${long}`);
  };

  const error = () => {
    console.log("Unable to retrieve your location");
  };

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
  };

  const handlePublishContract = (value: PLAN_TYPE) => {
    console.log(value,"slected contract")
    dispatch(setPlanType(value));
  };

  const publishContract = async () => {
    const savedContractId = addContract._id;
    const payload = {
      ...new AddContractRootState({
        ...addContract,
        contractStatus,
      }).serialize(),
      location: {
        type: "Point",
        coordinates: [lat, long],
      },
      countryValueType: 'string',
    };
    // delete payload.interviewQuestions;
    // delete payload.eligibilityQuestions;
    if (savedContractId && savedContractId !== "") {
      try {
        const response = await updateContract(payload, savedContractId);
        Swal.fire({
          title: "Contract updated successfully!",
          text: "Your contract has been updated successfully!",
          icon: "success",
          confirmButtonText: "View Dashboard",
          cancelButtonText: "Continue",
          showCancelButton: true,
          customClass: {
            confirmButton: "swal-custom-button", // Custom class for confirm button
            cancelButton: "swal-cancel-button", // Custom class for cancel button
          },
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Your work has been saved",
              showConfirmButton: false,
              timer: 1500,
            });
            navigate(APP_ROUTES.HOME);
          }
        });
      } catch (error) {
         const apiError = error as APIResponseErrors;
              const errorMessage = apiError?.data?.message as string;
        Swal.fire({
          title: 'Error',
          text: `${errorMessage}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } else {
      try {
        await saveContract(payload);
        onClosePlanModal();
        Swal.fire({
          title: "Congratulations!",
          text: "You will hear from your account manager very soon!",
          icon: "success",
          confirmButtonText: "View Dashboard",
          backdrop: "static",
        }).then((result) => {
          if (result.isConfirmed) {
            storage.clearSavedContract();
            dispatch(initContract(new AddContractRootState()));
            navigate(APP_ROUTES.HOME);
          }
        });
      } catch (error) {
        const apiError = error as APIResponseErrors;
              const errorMessage = apiError?.data?.message as string;
        Swal.fire({
          title: 'Error',
          text: `${errorMessage}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const handleSubmitContract = async () => {
    if (!token && !isAuthenticated) {
      setShowErrorDialog(true);
      return;
    } else if (Plantype === PLAN_TYPE.STANDARD) {
      setModalDetail({
        show: true,
        title: "",
        flag: "post",
      });
    } else {
      await publishContract();
    }
  };

 

  const onClose = () => {
    onClosePlanModal();
  };
  return (
    <div className={styles.Subacription} style={{ padding: "40px 0 0" }}>
      <SaveAsDraftAuthErrorDialog
        show={showErrorDialog}
        onHide={() => setShowErrorDialog(false)}
      />
      <div className="container">
        <div className={styles.PricingConatiner}>
          <div className="row align-items-start g-3">
            <div className="col-md-6">
              <div
                className={`${styles.innerSubscription} ${
                  Plantype === PLAN_TYPE.WHITEGLOVE ? styles.GradBorder : ""
                }`}
              >
                <h4>White Glove</h4>
                <h6>
                  Let Automate America Help You Choose the Right Contractor,
                  While We Manage Billing and Payments for a Seamless,
                  Stress-Free Experience from Start to Finish.
                </h6>
                <span
                  onClick={() => handlePublishContract(PLAN_TYPE.WHITEGLOVE)}
                  style={{ cursor: "pointer" }}
                >
                  Publish with White Glove{" "} <strong>$0 Cost to you</strong>
                </span>

                <ul>
                  <li>
                    <label>Dedicated Account Management:</label>
                    <p>
                      Receive <b>personalized, premium assistance</b> from your
                      dedicated account manager who will guide you through every
                      step of the process. You’ll get <b>priority support</b> by
                      phone, text, email, video meeting and in-app messaging,
                      ensuring all your needs are met quickly and efficiently.
                      We help you navigate the marketplace, schedule interviews,
                      and even choose the candidate!
                    </p>
                  </li>
                  <li>
                    <label>
                      Seamless Payment Processing: Invoicing by Automate America
                      :
                    </label>
                    <p>
                      Automate America manages <b>all billing and payments</b>,
                      so you can focus on your project while we take care of the
                      financial details. We handle{" "}
                      <b>invoices, timesheets, and expenses</b>, ensuring
                      professionals are paid promptly based on your approval.
                    </p>
                  </li>

                  <li>
                    <label>Flexible Contractor Payments:</label>
                    <p>
                      <b>Streamlined Payments for Your Convenience:</b> When you
                      post a contract, <b>Automate America</b> handles all
                      payments to contractors or service companies. This ensures
                      a hassle-free experience for you—no need to manage
                      suppliers or 1099’s. Professionals can choose when they
                      want to get paid, whether weekly, bi-weekly, or on-demand,
                      giving them <b>ultimate flexibility</b> while you stay
                      focused on your project.
                    </p>
                  </li>
                  <li> 
                    <label>Contract Broadcasting:</label>
                    <p>
                      <b>Reach Thousands of Qualified Engineers Instantly:</b>{" "}
                      When you White Glove, <b>Automate America</b> broadcasts
                      it to a vast network of skilled engineers, ensuring your
                      opportunity reaches the right professionals quickly. Using{" "}
                      <b>email, SMS, in-app messaging</b>, and even{" "}
                      <b>direct calls</b>, we actively communicate your contract
                      to ensure rapid responses from the best candidates. Your
                      contract gets the attention it deserves, with
                      professionals able to <b>apply immediately</b>.
                    </p>
                  </li>
                  <li>
                    <label>Contractor Payments – No Extra Cost to You:</label>
                    <p>
                      With <b>White Glove Contracts</b>, Automate America
                      handles everything. We deduct a small percentage from the
                      rate you set before showing it to the professionals. This
                      covers our services for recruiting top talent and
                      advancing payments to contractors—without any additional
                      cost to you.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className={`${styles.innerSubscription} ${
                  Plantype === PLAN_TYPE.STANDARD ? styles.GradBorder : ""
                }`}
              >
                <h4>Standard</h4>
                <h6>
                  <b>You Select, You Pay, You Manage:</b> With{" "}
                  <b>Standard Contracts</b>, you have full control—choose your
                  contractor, handle payments directly, and manage the 1099s and
                  supplier relationship on your terms.
                </h6>
                <span
                  onClick={() => handlePublishContract(PLAN_TYPE.STANDARD)}
                  style={{ cursor: "pointer" }}
                >
                  Publish with Standard{" "} <strong>$0 Cost to you</strong>
                </span>
                <ul>
                  <li>
                    <label>Posting contracts</label>
                    <p>
                      Users can manage applicants by shortlisting, hiring, and
                      rejecting candidates.
                    </p>
                  </li>
                  <li>
                    <label>Communication Features: In-App Communication:</label>
                    <p>
                      The platform offers in-app messaging and video interviews
                      for streamlined communication.
                    </p>
                  </li>

                  <li>
                    <label>Direct Billing:</label>
                    <p>
                      You’ll handle payments directly with the contractor or
                      service company, giving you full control over invoicing
                      and financial arrangements, unlike the seamless management
                      provided with the White Glove option.
                    </p>
                  </li>
                  <li>
                    <label>Usage Restrictions:</label>
                    <p>
                      With the Standard option, users will manage timesheets,
                      expense reports, and invoicing outside of the Automate
                      America platform. You won’t have access to our integrated
                      in-app timesheets, expense report management, or automated
                      invoicing and purchase order tools, which are exclusive to
                      the White Glove option. This means you'll be responsible
                      for tracking contractor hours, managing expenses, and
                      handling financial paperwork manually. Somewhere near the
                      end of the additional information for all types of
                      contracts and jobs, you must ask, Remote, Onsite or
                      Hybrid.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mx-0">
        <div className="col-6 my-3 btn-hover-effect">
          <h6
            onClick={onClose}
            className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton"
          >
            <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
              <img src={blackarrow} alt="" />
            </i>
            <span className="px-lg-4 px-2 text-12 fw-semibold">Not Now</span>
          </h6>
        </div>
        <div className="col-6 text-end my-3 btn-hover-effect">
          <h6
            onClick={handleSubmitContract}
            className="btn arrowblue border-grey border-1 position-relative pe-5"
          >
            <span className="px-lg-4 px-2 text-12 fw-semibold">
              Send For Approval
            </span>
            <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
              <img src={whitearrow} alt="" />
            </i>
          </h6>
        </div>
      </div>
      {modalDetail.show && (
        <CustomModal
          show={modalDetail.show}
          backdrop="static"
          showCloseBtn={true}
          isRightSideModal={false}
          mediumWidth={false}
          className={modalDetail.flag === "post" ? "post" : ""}
          child={
            modalDetail.flag === "post" ? (
              <Post
                onCloseModal={() => handleOnCloseModal()}
                publishContract={() => publishContract()}
              />
            ) : null // Render nothing if none of the conditions match
          }
          onCloseModal={() => handleOnCloseModal()}
        />
      )}
    </div>
  );
};

export default Subscription;
