import React, { useState } from "react";
import styles from "../styles/style.module.css";
import tip from "../../../../assets/images/tip.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import edit from '../../../../assets/images/penbl.png'
import EditBusinessModal from "../../../NewBusiness/modals/EditBusinessModal";
import { userProfileResponse } from "../../../home/interface";

const NewBusiness = ({ business, ProfileDetails }: any) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const businessNameRedux = useSelector((state: RootState) => state.business.businessName);
    const navigate = useNavigate();
    const closeModal = () => { setShowModal(false) };


    const openBusinessModal = () => {
        setShowModal(true)
    }
    const createBusiness = () => navigate('/addBusiness');
    const createBusinessGroup = () => {
        navigate('/newBusiness')
    }

    return (
        <div className={styles.AboutEdit}>
            <div className="row align-items-center justify-content-between mb-3">
                <div className="col-6">
                    <div className="topTipHover">
                        <div className="d-flex align-items-center">
                            <h4 className="text-20 fw-bold font-inter mb-0">Business</h4>
                            <i>
                                <img className="customeTolImg" src={tip} alt="" />
                            </i>
                        </div>
                    </div>
                </div>
                <div className="col-6 text-end">
                    <span className="float-end">
                        {businessNameRedux ? (
                            <img src={edit} onClick={openBusinessModal} alt="" />
                        ) : (
                            <button className="border-blue bg-blue text-white py-2 text-14 px-2 rounded-2"
                                style={{
                                    cursor: 'pointer'
                                }}
                                onClick={createBusiness}
                            >
                                + Create New Business
                            </button>
                        )}

                    </span>
                </div>
            </div>

            <div className={styles.personalinfo}>
                {businessNameRedux ? (
                    <ul className="row align-items-center border rounded-2 bg-light p-2 g-0">
                        <li className="col-12 mb-2">
                            <span className="">Name of Facility</span>
                            <label className="clamp clamp1">{business?.businessName}</label>
                        </li>
                        <li className="col-xl-4 col-md-6 col-12">
                            <span className="">Country</span>
                            <label className="clamp clamp1">{business?.state}</label>
                        </li>
                        {business?.street && (
                            <li className="col-xl-4 col-md-6 col-12">
                                <span className="">Street</span>
                                <label className="clamp clamp1">{business.street}</label>
                            </li>
                        )}
                        <li className="col-xl-4 col-md-6 col-12">
                            <span className="">City</span>
                            <label className="clamp clamp1">{business?.city}</label>
                        </li>
                        <li className="col-xl-4 col-md-6 col-12">
                            <span className="">Contact Name</span>
                            <label className="clamp clamp1">{ProfileDetails?.data?.name}</label>
                        </li>
                        <li className="col-xl-4 col-md-6 col-12">
                            <span className="">Contact Phone</span>
                            <label className="clamp clamp1">{ProfileDetails?.data?.phoneNumber}</label>
                        </li>
                        <li className="col-xl-4 col-md-6 col-12">
                            <span className="">Contact Email</span>
                            <label className="clamp clamp1">{ProfileDetails?.data?.email}</label>
                        </li>
                    </ul>
                ) : (
                    <p className="text-muted">You currently have no business added.</p>
                )}
            </div>

            {businessNameRedux && (
                <div className="text-start mt-3">
                    <button
                        className="border-blue bg-blue text-white py-2 text-14 px-2 rounded-2"
                        onClick={createBusinessGroup}
                        style={{
                            cursor: 'pointer'
                        }}
                    >
                        + Create Business Group
                    </button>
                </div>
            )}
            <EditBusinessModal closeModal={closeModal} showModal={showModal} />
        </div>

    );
};

export default NewBusiness;
