import React, { FC } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { CompanyAdDescription, CompanyAdImage, CompanyAdChoosePlan, CompanyBoostAd } from './interface';
import { ADD_PAID_ADS } from '../../../shared/enums/AddPaidAdsSteps';

const CompanyAdFlow: FC = () => {
  const companyPaidAds = useAppSelector((state: RootState) => state.companyAd);
  const { activeStep } = companyPaidAds;

  const renderActiveStep = () => {
    switch (activeStep) {
      case ADD_PAID_ADS.AD_DESCRIPTION:
        return <CompanyAdDescription/>;
      case ADD_PAID_ADS.UPLOAD_IMAGE:
        return <CompanyAdImage />;
      case ADD_PAID_ADS.BOOST_ADD:
        return <CompanyBoostAd/>;
      case ADD_PAID_ADS.ADD_PROMOTION:
        return <CompanyAdChoosePlan />;
      default:
        return <CompanyAdDescription/>;
    }
  };
  return renderActiveStep();
};

export default CompanyAdFlow;
