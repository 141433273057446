import React, { useEffect, useState } from "react";
import styles from "../../css/styles/style.module.css";
import loc from "../../../../assets/images/icon_location_filled.svg";
import bags2 from "../../../../assets/images/bags.png";
import skill from "../../../../assets/images/top_skill.svg";
import share from "../../../../assets/images/share_profile.svg";
import modal from "../../../../assets/images/modal.svg";
import admin from "../../../../assets/images/admin_icon.svg";
import fullcontractimg from "../../../../assets/images/fullcontractimg_1.jpg";
import calls from "../../../../assets/images/calls.svg";
import email from "../../../../assets/images/email.svg";
import manufacture from "../../../../assets/images/manufacture.svg";
import cross from "../../../../assets/images/reject.svg";
import peopleneeded from "../../../../assets/images/peopleneeded.svg";
import duration from "../../../../assets/images/duration.svg";
import startdate from "../../../../assets/images/startdate.svg";
import hire from "../../../../assets/images/hire.svg";
import Header from "../../../header/routes";
import rdinfo from "../../../../assets/images/rdinfo.png";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Confirm from "../OpenContracts/modals/Confirm";
import { toast } from "react-toastify";
import { APP_ROUTES } from "../../../../utils/routes/AppRoutes";
import { COUNT_TYPE, WORK_APPLY_TYPE } from "../../../../utils/enums/types";
import ViewInterViewQuestions from "./viewInterviewQuestions";
import { ContractDetails, getEventDetails } from "../OpenContracts/interface";
import {
  changeWorkStatus,
  getEventsById,
  getFilteredContractsByStatus,
  getWorkDetail,
} from "../OpenContracts/api";
import InterviewScheduleModal from "../../modals/ScheduleInterviewModal/InterviewScheduleModal";

const DetailOpenContract = () => {
  const [interviewScheduled, setInterviewScheduled] = useState<boolean>();
  const { id } = useParams();
  const navigate = useNavigate();
  const [openContractDetails, setOpenContractDetails] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [
    selectedContractForInterviewQuestion,
    setselectedContractForInterviewQuestion,
  ] = useState<ContractDetails | null>(null);
  const [selectedId, setSelectedId] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [professionalId, setProfessionalId] = useState<string>("");
  const [professionalName, setProfessionalName] = useState<string>("");
  const [filteredContacts, setFilteredContracts] = useState<ContractDetails[]>(
    []
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [eventResponseData, setEventResponseData] = useState<getEventDetails[]>(
    []
  );
  // hardcoded values temp
  let eventType = "interview";
  let interviewType = "video";

  const openModal = (contract: any) => {
    setIsModalOpen(true);
    setProfessionalId(contract.user._id);
    setProfessionalName(contract.user?.name);
  };
  const closeModal = () => setIsModalOpen(false);
  const [activeSelectedContractType, setActiveSelectedContractType] =
    useState<any>(WORK_APPLY_TYPE.RECEIVED);

  useEffect(() => {
    openContractDetailsList();
  }, []);

  const handleCallback = (data: boolean) => {
    setInterviewScheduled(data);
    setIsModalOpen(false);
    closeModal();
  };

  const openContractDetailsList = async () => {
    const response: any = await getWorkDetail(id, COUNT_TYPE.WORK_APPLY);
    setOpenContractDetails(response?.data);
  };

  const refresh = (type: string) => {
    setLoading(true);
    setActiveSelectedContractType(type);
    const filteredContractList = async () => {
      const response = await getFilteredContractsByStatus(id, type);
      setFilteredContracts(response?.data);
      let internalUserId = response.data[0]?.userId;
      let internalWorkId = response.data[0]?.workId;
      const getEventDetails = async () => {
        const responseEvent: any = await getEventsById(
          internalUserId,
          internalWorkId,
          eventType,
          interviewType
        );
        setEventResponseData(responseEvent?.data);
      };
      // await getEventDetails()
      setLoading(false);
    };
    filteredContractList();
  };

  const interviewEventTime = eventResponseData[0]?.eventTime;

  // useEffect(() => {
  //   // start call 5 min before the interview time
  //   const startCall = () => {
  //     const eventTime = moment(eventResponseData[0]?.eventTime, "HH:mm").valueOf();
  //     const currentTime = +new Date();
  //     const timeDifference = eventTime - currentTime;
  //     if (timeDifference <= 5 * 60 * 1000) {
  //       setIsDisabled(false)
  //     }
  //   };
  //   startCall();

  //   const interval = setInterval(startCall, 1000);
  //   return () => clearInterval(interval);
  // }, [interviewEventTime]);

  const handleStatusChange = (id: string, status: string) => {
    setSelectedId(id);
    setSelectedStatus(status);
    // changeWorkStatus(id, status,openContractDetails?._id).then((res) => {
    //     refresh(activeSelectedContractType
    // })
    setConfirmModal(true);
  };
  const handleClose = () => {
    openContractDetailsList();
    setConfirmModal(false);
  };
  const changeStatus = () => {
    toast.promise(
      changeWorkStatus(selectedId, selectedStatus, openContractDetails?._id),
      {
        pending: {
          render() {
            return "Changing Status";
          },
        },
        success: {
          render({}) {
            refresh(activeSelectedContractType);
            handleClose();
            return "Status Changed Successfully";
          },
        },
        error: {
          render(error) {
            return "Status Change Failed";
          },
        },
      }
    );
  };
  useEffect(() => {
    refresh(WORK_APPLY_TYPE.RECEIVED);
  }, []);

  const handleFilterContracts = async (value: string) => {
    refresh(value);
  };
  const handleShowInterviewQus = (value: ContractDetails) => {
    setselectedContractForInterviewQuestion(value);
  };
  const handleViewUserProfile = (value: ContractDetails) => {
    navigate(`${APP_ROUTES.USER_PROFILE}/${value.userId}`);
  };

  const isInterviewAlreadyScheduleForToday = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    let currentDate = yyyy + "-" + mm + "-" + dd;

    let isInterviewScheduled = eventResponseData?.filter(
      (item: any) =>
        item.eventType == "interview" &&
        item.workId["_id"] === id &&
        item.eventType === "interview" &&
        item.interviewType === "video" &&
        moment(item.eventDate).format("YYYY-MM-DD") === currentDate
    );
    return isInterviewScheduled?.length > 0 ? true : false;
  };

  let interviewToday = isInterviewAlreadyScheduleForToday();
  const handleJoinCall = () =>
    navigate(`${APP_ROUTES.VIDEO_CALL.INTERVIEW_CABIN}`);
  const eventDateInUTC = moment
    .utc(eventResponseData[0]?.eventDate)
    .local()
    .format("MM-DD-YYYY hh:mm A");

  return (
    <>
      <Header />
      <InterviewScheduleModal
        isOpen={isModalOpen}
        onClose={closeModal}
        professional_id={professionalId}
        work_id={openContractDetails?._id}
        occupation_name={openContractDetails?.occupation?.name}
        professional_name={professionalName}
        interviewScheduled={handleCallback}
      />
      {selectedContractForInterviewQuestion && (
        <ViewInterViewQuestions
          userId={selectedContractForInterviewQuestion.userId}
          workId={selectedContractForInterviewQuestion.workId}
          onClose={() => setselectedContractForInterviewQuestion(null)}
        />
      )}
      <div className="OpenContarct">
        <Confirm
          action="Select"
          handleClose={handleClose}
          showModal={confirmModal}
          id={selectedId}
          status={selectedStatus}
          workStatus={openContractDetails.id}
          changeStatus={changeStatus}
        />

        <div className="container">
          <div className={`bg-white mt-4  ${styles.PendingCOnatctList}`}>
            <div className={`${styles.contractPast}`}>
              <>
                {/* <ContractData /> */}
                <div className="col-lg-12">
                  <div className={styles.RightOpportunity}>
                    <div className={styles.ContarctOuter}>
                      <div className={styles.opprBox}>
                        <div
                          className={`row align-items-start ${styles.PendingCOnatctList}`}
                        >
                          <div className="col-8">
                            {/* <h5 className="fw-400 text-14">
                              Reference No. : #2312414
                            </h5> */}
                            <h6
                              className={`text-capitalize ${styles.name_robo}`}
                            >
                              {openContractDetails?.occupation?.name}
                              {/* <span className={styles.boosted}>boosted</span> */}
                            </h6>
                            {/* <span className={styles.Hrrate}>planType</span> */}
                            <div className="row align-items-center gap-3 mt-3 mx-0">
                              <span className={`w-auto mb-3 ${styles.Hrrate}`}>
                                {" "}
                                <i className="me-2">
                                  <img src={bags2} alt="" />
                                </i>
                                {openContractDetails?.planType}
                              </span>
                              {/* <span
                                className={`btn mb-3 w-auto h-auto px-3 ${styles.commonbtn} ${styles.applybtn}`}
                              >
                               workDetails
                              </span> */}

                              <span className={`btn mb-3 w-auto h-auto px-3 ${styles.commonbtn}`}>
                                {openContractDetails?.workLocation}
                              </span>
                            </div>
                          </div>
                          <div className="col-4 text-end">
                            <div className="row align-items-center justify-content-end">
                              <div className="col-4">
                                <button
                                  type="button"
                                  className={`btn w-100 px-3 ${styles.commonbtn} ${styles.applybtn}`}
                                >
                                  Unpublish
                                </button>
                              </div>
                              <div className="col-4">
                                <button
                                  type="button"
                                  className={`btn w-100 px-3 ${styles.commonbtn} ${styles.applybtn}`}
                                >
                                  <i className="far fa-copy"></i> Copy
                                </button>
                              </div>
                              <div className="col-4">
                                {" "}
                                <button
                                  className={`btn w-100 px-3 bg-white text-danger border-danger ${styles.commonbtn}`}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className={styles.Loca}>
                            <p className={styles.Locate}>
                              <img src={loc} alt="" />{" "}
                              {openContractDetails?.city},{" "}
                              {openContractDetails?.state}
                            </p>
                          </div>
                          <div
                            className={`${styles.PenigSkilRate} ${styles.fullcontractSkilRate}`}
                          >
                            <ul className="row align-items-start justify-content-start gap-0">
                              <li className="col-4 me-0">
                                <div className="row align-items-start mb-3">
                                  <div className="col-2 text-center ps-0">
                                    <i>
                                      <img
                                        src={skill}
                                        alt=""
                                        className="img-fluid"
                                       width="35px"
                                      height="35px"
                                      />
                                    </i>
                                  </div>
                                  <div className="col-10 ps-0">
                                    <h5 className="mb-0 fw-bold text-14">
                                      Top Skill
                                    </h5>
                                    <p className="text-14 mb-0">{"x"}</p>
                                  </div>
                                </div>
                              </li>
                              <li className="col-4 me-0">
                                <div className="row align-items-start mb-3">
                                  <div className="col-2 text-center ps-0">
                                    <i>
                                      <img
                                        src={manufacture}
                                        alt=""
                                        className="img-fluid"
                                        width="35px"
                                      height="35px"
                                      />
                                    </i>
                                  </div>
                                  <div className="col-10 ps-0">
                                    <h5 className="mb-0">MANUFACTURER</h5>

                                    <p className="text-14 mb-0">x</p>
                                  </div>
                                </div>
                              </li>
                              <li className="col-4 me-0">
                                <div className="row align-items-start mb-3">
                                  <div className="col-2 text-center ps-0">
                                    <i>
                                      <img
                                        src={modal}
                                        alt=""
                                        className="img-fluid w-75"
                                        width="35px"
                                      height="35px"
                                      />
                                    </i>
                                  </div>
                                  <div className="col-10 ps-0">
                                    <h5 className="mb-0">
                                      Model/Version/ Controller
                                    </h5>

                                    <p className="text-14 mb-0">X</p>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className={`${styles.PenigSkilRats}`}>
                            <ul className="row align-items-start justify-conetnt-start gap-0 mt-2">
                              <li className="col-2 me-0">
                                <div className="row align-items-start mb-3">
                                  <div className="col-10 text-center ps-0">
                                    <i>
                                      <img
                                        src={fullcontractimg}
                                        alt=""
                                        className="img-fluid w-100"
                                      />
                                    </i>
                                  </div>
                                </div>
                              </li>
                              <li className="col-2 me-0">
                                <div className="row align-items-start mb-3">
                                  <div className="col-10 text-center ps-0">
                                    <i>
                                      <img
                                        src={fullcontractimg}
                                        alt=""
                                        className="img-fluid w-100"
                                      />
                                    </i>
                                  </div>
                                </div>
                              </li>
                              <li className="col-2 me-0">
                                <div className="row align-items-start mb-3">
                                  <div className="col-10 text-center ps-0">
                                    <i>
                                      <img
                                        src={fullcontractimg}
                                        alt=""
                                        className="img-fluid w-100"
                                      />
                                    </i>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className={styles.PenigSkilRate}>
                            <h5 className="mb-2">Pay Rates</h5>
                            <ul>
                              <li>
                                <span>Base</span>
                                <label>
                                  ${openContractDetails?.rates?.baseRate}
                                </label>
                              </li>
                              <li>
                                <span>Overtime</span>
                                <label>
                                  ${openContractDetails?.rates?.overTimeRate}
                                </label>
                              </li>
                              <li>
                                <span>Sun/Holidays</span>
                                <label>
                                  ${openContractDetails?.rates?.doubleTimeRate}
                                </label>
                              </li>
                            </ul>
                          </div>

                          <div className="col-md-12">
                            <div className={styles.bordeerBottom}></div>
                            <h5 className={styles.payrate}>
                              Travel & Expenses{" "}
                            </h5>
                            <div className={` ${styles.trevelexpense}`}>
                              <div className="col-12">
                                <div className="row border-bottom pb-3 mb-3">
                                  <div className="col-lg-2 col-sm-6">
                                    <div
                                      className={styles.datesFlex2}
                                      style={{ borderRight: "1px solid #eee" }}
                                    >
                                      <h6>Travel Time Rate</h6>
                                      <p className={styles.dollar}>
                                        $
                                        {
                                          openContractDetails?.travels
                                            ?.travelTimeRate
                                        }
                                      </p>
                                    </div>
                                  </div>

                                  <div className="col-lg-3 col-sm-6">
                                    <div
                                      className={styles.datesFlex2}
                                      style={{ borderRight: "1px solid #eee" }}
                                    >
                                      <h6>
                                        Number of Allowed Trips (Per Month)
                                      </h6>
                                      <p className={`mb-0 ${styles.dollar}`}>
                                        {
                                          openContractDetails?.travels
                                            ?.tipAllowed
                                        }
                                      </p>
                                      <h6>Receipt are required</h6>
                                    </div>
                                  </div>

                                  <div className="col-lg-3 col-sm-6">
                                    <div
                                      className={styles.datesFlex2}
                                      style={{ borderRight: "1px solid #eee" }}
                                    >
                                      <h6>Travel Hours Per Round Trip</h6>
                                      <p className={`mb-0 ${styles.dollar}`}>
                                        {
                                          openContractDetails?.travels
                                            ?.perRoundTrip
                                        }
                                      </p>
                                      <h6>Receipt are required</h6>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 col-sm-6">
                                    <div
                                      className={styles.datesFlex2}
                                      style={{ borderRight: "1px solid #eee" }}
                                    >
                                      <h6>Daily Per Diem</h6>
                                      <p className={`mb-0 ${styles.dollar}`}>
                                        X
                                      </p>
                                      <h6>Receipt are required</h6>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 col-sm-6">
                                    <div className={styles.datesFlex2}>
                                      <h6>Travel Day Per Diem</h6>
                                      <p className={`mb-0 ${styles.dollar}`}>
                                        X
                                      </p>
                                      <h6>Receipt are required</h6>
                                    </div>
                                  </div>
                                </div>
                                <div className="row pb-3 mb-3">
                                  <div className="col-lg-2 col-sm-6">
                                    <div
                                      className={styles.datesFlex2}
                                      style={{ borderRight: "1px solid #eee" }}
                                    >
                                      <h6>Hotel Allowance Per Day</h6>
                                      <p className={styles.dollar}>$X</p>
                                    </div>
                                  </div>

                                  <div className="col-lg-3 col-sm-6">
                                    <div
                                      className={styles.datesFlex2}
                                      style={{ borderRight: "1px solid #eee" }}
                                    >
                                      <h6>Rental Car Allowance</h6>
                                      <p className={`mb-0 ${styles.dollar}`}>
                                        X
                                      </p>
                                      <h6>Receipt are required</h6>
                                    </div>
                                  </div>

                                  <div className="col-lg-3 col-sm-6">
                                    <div
                                      className={styles.datesFlex2}
                                      style={{ borderRight: "1px solid #eee" }}
                                    >
                                      <h6>Airfare Allowance</h6>
                                      <p className={`mb-0 ${styles.dollar}`}>
                                        X
                                      </p>
                                      <h6>Receipt are required</h6>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 col-sm-6">
                                    <div
                                      className={styles.datesFlex2}
                                      style={{ borderRight: "1px solid #eee" }}
                                    >
                                      <h6>Mileage Allowance</h6>
                                      <p className={`mb-0 ${styles.dollar}`}>
                                        X
                                      </p>
                                      <h6>Receipt are required</h6>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 col-sm-6">
                                    <div className={styles.datesFlex2}>
                                      <h6>Luggage Fees Allowance</h6>
                                      <p className={`mb-0 ${styles.dollar}`}>
                                        $X
                                      </p>
                                      <h6>Receipt are required</h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12"></div>

                          <div className={styles.bordeerBottom}></div>
                          <h5 className={styles.payrate}>SCHEDULE</h5>
                          <div className="row">
                            <div className="col-lg-7 col-sm-12">
                              <div className="row">
                                <div className="col-lg-6 col-sm-6">
                                  <div className={styles.datesFlex}>
                                    <h6>Start date </h6>
                                    <p className="mb-0">
                                      {openContractDetails?.times?.startDate}
                                    </p>
                                    <i className="me-2">
                                      {" "}
                                      <img src={startdate} alt="" width={15} />
                                    </i>
                                  </div>
                                </div>

                                <div className="col-lg-6 col-sm-6">
                                  <div className={styles.datesFlex}>
                                    <h6>Duration</h6>
                                    <p className="mb-0">
                                      {openContractDetails?.times?.duration} Hr
                                    </p>
                                    <i className="me-2">
                                      {" "}
                                      <img src={startdate} alt="" width={15} />
                                    </i>
                                  </div>
                                </div>

                                <div className="col-lg-6 col-sm-6">
                                  <div className={styles.datesFlex}>
                                    <h6>People Needed </h6>
                                    <p className="mb-0">
                                      {openContractDetails?.times?.noOfPepole}
                                    </p>
                                    <i className="me-2">
                                      {" "}
                                      <img
                                        src={peopleneeded}
                                        alt=""
                                        width={15}
                                      />
                                    </i>
                                  </div>
                                </div>

                                <div className="col-lg-6 col-sm-6">
                                  <div className={styles.datesFlex}>
                                    <h6> Shift </h6>
                                    <p className="mb-0">
                                      {openContractDetails?.times?.shift}
                                    </p>
                                    <i className="me-2">
                                      {" "}
                                      <img src={duration} alt="" width={15} />
                                    </i>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                  <div className={styles.datesFlex}>
                                    <h6>Planned Hours Per Day </h6>
                                    <p className="mb-0">
                                      {
                                        openContractDetails?.times
                                          ?.plannedHoursPerDay
                                      }
                                    </p>
                                    <i className="me-2">
                                      {" "}
                                      <img src={startdate} alt="" width={15} />
                                    </i>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                  <div className={styles.datesFlex}>
                                    <h6>Planned Days Per Week </h6>
                                    <p>
                                      {
                                        openContractDetails?.times
                                          ?.plannedDaysPerWeek
                                      }
                                    </p>
                                    <i className="me-2">
                                      {" "}
                                      <img src={duration} alt="" width={15} />
                                    </i>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={styles.bordeerBottom}></div>
                            <div
                              className={styles.PenigSkil}
                              style={{ marginTop: "15px" }}
                            >
                              <h5>Description</h5>
                              <p>{openContractDetails?.description}</p>
                            </div>
                            <div className={styles.bordeerBottom}></div>
                            <div className="col-md-12">
                              <h5 className={styles.payrate}>
                                Eligilibility Questions
                              </h5>
                              <div>
                                <ul>
                                  {openContractDetails?.eligibilityQuestions?.map(
                                    (ques: any, ind: any) => (
                                      <li key={ques._id}>
                                        <div>
                                          <h6>
                                            {`Question ${ind + 1} : ${
                                              ques.question
                                            }`}
                                          </h6>{" "}
                                          <p className="text-14">
                                            Ans: {ques.required.toString()}
                                          </p>
                                        </div>{" "}
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </div>
                            <div className={styles.bordeerBottom}></div>

                            <div className="col-md-12">
                              <h5 className={styles.payrate}>
                                Interview Questions
                              </h5>
                              <div>
                                <ul>
                                  {openContractDetails?.interviewQuestions?.map(
                                    (ques: any, ind: any) => (
                                      <li key={ques._id}>
                                        <div>
                                          <h6>
                                            {`Question ${ind + 1} : ${
                                              ques.question
                                            }`}
                                          </h6>{" "}
                                          <p className="text-14">
                                            Ans: {ques.required.toString()}
                                          </p>
                                        </div>{" "}
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </div>
                            <div className={styles.bordeerBottom}></div>

                            <div className="col-md-12 ">
                              <h5 className={styles.payrate}>
                                on site customer contact
                              </h5>
                              <div>
                                <h6 className="mb-0 mt-3">Primary Contact</h6>
                                <ul className="row algign-items-center">
                                  <li className="col-2">
                                    <p className="text-14 d-flex align-items-center">
                                      <i className="me-2">
                                        <img src={admin} alt="" />
                                      </i>
                                      {
                                        openContractDetails?.primaryContact
                                          ?.name
                                      }
                                    </p>
                                  </li>
                                  <li className="col-2">
                                    <p className="text-14 d-flex align-items-center">
                                      <i className="me-2">
                                        <img src={calls} alt="" />
                                      </i>
                                      {
                                        openContractDetails?.primaryContact
                                          ?.mobileNumber
                                      }
                                    </p>
                                  </li>
                                  <li className="col-2">
                                    <p className="text-14 d-flex align-items-center">
                                      <i className="me-2">
                                        <img src={email} alt="" />
                                      </i>
                                      {
                                        openContractDetails?.primaryContact
                                          ?.email
                                      }
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div>
                                <h6 className="mb-0">Secondary Contact</h6>

                                <ul className="row align-items-center">
                                  <li className="col-2">
                                    <p className="text-14 d-flex align-items-center">
                                      <i className="me-2">
                                        <img src={admin} alt="Admin Icon" />
                                      </i>
                                      {
                                        openContractDetails?.secondaryContact
                                          ?.name
                                      }
                                    </p>
                                  </li>

                                  <li className="col-2">
                                    <p className="text-14 d-flex align-items-center">
                                      <i className="me-2">
                                        <img src={calls} alt="Phone Icon" />
                                      </i>
                                      {
                                        openContractDetails?.secondaryContact
                                          ?.mobileNumber
                                      }
                                    </p>
                                  </li>

                                  <li className="col-2">
                                    <p className="text-14 d-flex align-items-center">
                                      <i className="me-2">
                                        <img src={email} alt="Email Icon" />
                                      </i>
                                      {
                                        openContractDetails?.secondaryContact
                                          ?.email
                                      }
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className={styles.bordeerBottom}></div>
                              <div className={styles.flexbtns}>
                                <button
                                  type="button"
                                  className={`btn ${styles.commonbtn} ${styles.applybtn}`}
                                >
                                  Boost This Contract
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
              <div className={styles.ClientProjects}>
                <div className="row g-3">
                  <div className="col-md-3">
                    <div className={styles.clientProjectInner}>
                      <h5>Applications Received</h5>
                      <span>{openContractDetails?.receivedApplication}</span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className={styles.clientProjectInner}>
                      <h5>Shortlisted Applicants</h5>
                      <span>{openContractDetails?.shortlistedApplication}</span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className={styles.clientProjectInner}>
                      <h5>Offers Pending</h5>
                      <span>{openContractDetails?.pendingApplication}</span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div
                      className={`${styles.clientProjectInner} ${styles.RejectedAppl}`}
                    >
                      <h5>Rejected Applicants</h5>
                      <span>{openContractDetails?.rejectedApplication}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.OpenContactOuter}>
                <div className="container">
                  <div className={styles.commonNav}>
                    <ul>
                      <li
                        className={
                          activeSelectedContractType === "received"
                            ? styles.active
                            : ""
                        }
                        onClick={() => {
                          handleFilterContracts(WORK_APPLY_TYPE.RECEIVED);
                          openContractDetailsList();
                        }}
                      >
                        <span>Applications Received</span>
                      </li>
                      <li
                        className={
                          activeSelectedContractType === "applied"
                            ? styles.active
                            : ""
                        }
                        onClick={() =>
                          handleFilterContracts(WORK_APPLY_TYPE.APPLIED)
                        }
                      >
                        <span>Offer Pending</span>
                      </li>
                      <li
                        className={
                          activeSelectedContractType === "shortlisted"
                            ? styles.active
                            : ""
                        }
                        onClick={() => {
                          handleFilterContracts(WORK_APPLY_TYPE.SHORTLISTED);
                          openContractDetailsList();
                        }}
                      >
                        <span>Shortlisted Applicants</span>
                      </li>
                      <li
                        className={
                          activeSelectedContractType === "selected"
                            ? styles.active
                            : ""
                        }
                        onClick={() => {
                          handleFilterContracts(WORK_APPLY_TYPE.SELECTED);
                          openContractDetailsList();
                        }}
                      >
                        <span>Applications Selected</span>
                      </li>
                      <li
                        className={
                          activeSelectedContractType === "rejected"
                            ? styles.active
                            : ""
                        }
                        onClick={() => {
                          handleFilterContracts(WORK_APPLY_TYPE.REJECTED);
                          openContractDetailsList();
                        }}
                      >
                        <span>Rejected Applications</span>
                      </li>
                    </ul>
                  </div>
                  {!loading ? (
                    <div className={styles.OuterSlides}>
                      <div className={styles.ContarctOuter}>
                        {filteredContacts?.length > 0
                          ? filteredContacts?.map((contract) => {
                              if (
                                activeSelectedContractType ===
                                WORK_APPLY_TYPE.RECEIVED
                              ) {
                                return (
                                  <div
                                    key={contract._id}
                                    className={styles.contractPast}
                                  >
                                    <div
                                      className={`px-3 py-2 ${styles.ContarctHead}`}
                                    >
                                      <div className={styles.leftContract}>
                                        <h5 className="fw-semibold text-14">
                                          ID: #{contract.worksInfo.workNumber}
                                        </h5>
                                      </div>
                                      <div className={styles.leftContract}>
                                        {/* <button className={styles.shedule}>
                                        {contract.status === "applied"
                                          ? "Applied"
                                          : contract.status === "accepted"
                                            ? "Shortlisted"
                                            : contract.status === "rejected"
                                              ? "Rejected"
                                              : contract.status === "selected"
                                                ? "Selected"
                                                : ""}
                                      </button> */}
                                        <button className="py-2 bg-blue text-white rounded-pill d-flex align-items-center border-blue text-12 fw-medium">
                                          <i className="me-2">
                                            <img src={share} alt="" />
                                          </i>
                                          Share Profile
                                        </button>
                                      </div>
                                    </div>
                                    {/* <div className={styles.outerNameDetail}>
                                    <div className="row align-items-center">
                                      <div className="col-md-12">
                                        <div className={styles.leftName}>
                                          <h4 className="text-16 fw-semibold">Professional Details</h4>
                                        </div>
                                      </div>
                                      <div className="col-lg-2 col-12">
                                        <div className={styles.leftName}>
                                          <div className={styles.PrileImg}>
                                            <figure>
                                              <img
                                                src={
                                                  contract.user.profilePicture
                                                }
                                                alt=""
                                                style={{ width: "100px", minHeight: "70px" }}
                                                className=" img-fluid rounded-circle"
                                              />
                                            </figure>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-5 col-6">
                                        <div className={styles.leftName}>
                                          <div className={styles.PrileImgs}>
                                            <h5>{contract.user?.name}</h5>
                                            <span>
                                              {contract.user.occupation?.name}
                                            </span>
                                            <p>
                                              <img src={loc} alt="" />{" "}
                                              {contract?.user?.country?.name}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-5 col-6">
                                        <div className={styles.rightName}>
                                          <p>
                                            Start Date:{" "}
                                            {
                                              <span>
                                                {moment(
                                                  contract.worksInfo.times
                                                    .startDate
                                                ).format("MMM DD, YYYY")}
                                              </span>
                                            }
                                          </p>
                                          <p>
                                            End Date:{" "}
                                            {
                                              <span>
                                                {moment(
                                                  contract.worksInfo.times
                                                    .startDate
                                                ).format("MMM DD, YYYY")}
                                              </span>
                                            }
                                          </p>
                                          <p>
                                            Rate Per Hour : $
                                            {contract.worksInfo.rates.baseRate}
                                          </p>
                                          <p>
                                            Expense Terms : net$
                                            {
                                              contract.expensePayment
                                                .paymentTerm
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}
                                    <div className={styles.outerNameDetail}>
                                      <div className="row align-items-center pb-3 border-bottom">
                                        <div className="col-md-12">
                                          <div className={styles.outerNDetail}>
                                            <div className="row align-items-center">
                                              <div className="col-12">
                                                <div className="mb-2">
                                                  <h4 className="fw-medium text-18">
                                                    {" "}
                                                    Professional Details
                                                  </h4>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-2 col-12 pe-0">
                                          <div className={styles.leftName}>
                                            <div className={styles.PrileImg}>
                                              <span className="text-center w-100">
                                                <i>
                                                  <img
                                                    src={
                                                      contract.user
                                                        .profilePicture
                                                    }
                                                    alt=""
                                                    style={{
                                                      width: "100px",
                                                      minHeight: "60px",
                                                    }}
                                                    className=" img-fluid rounded-circle"
                                                  />
                                                </i>
                                                <h4
                                                  onClick={() => {
                                                    handleViewUserProfile(
                                                      contract
                                                    );
                                                  }}
                                                  className="mt-2 mb-0 fw-semibold px-1 py-2 text-10 text-capitalize bg-lightblue text-blue rounded-pill"
                                                >
                                                  view profile
                                                </h4>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-4 col-6">
                                          <div className={styles.leftName}>
                                            <div className={styles.PrileImg}>
                                              <div className="">
                                                <h5>{contract.user?.name}</h5>
                                                <h6 className="text-14">
                                                  {
                                                    contract.user.occupation
                                                      ?.name
                                                  }
                                                </h6>
                                                <p>
                                                  <img src={loc} alt="" />{" "}
                                                  {
                                                    contract?.user?.country
                                                      ?.name
                                                  }
                                                </p>
                                                <h6>Reviews: X</h6>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6 col-6">
                                          <div
                                            className={`ps-3 ${styles.rightName}`}
                                          >
                                            <p>
                                              Start Date:{" "}
                                              {moment(
                                                contract?.worksInfo?.times
                                                  .startDate
                                              ).format("MM-DD-YYYY")}
                                            </p>
                                            <p>
                                              Rate Per Hour : $
                                              {
                                                contract?.hourlyPayment
                                                  ?.paymentTerm
                                              }
                                            </p>
                                            <p>
                                              Expense Terms :{" "}
                                              {
                                                contract?.expensePayment
                                                  ?.paymentTerm
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    {contract.status ===
                                      WORK_APPLY_TYPE.APPLIED && (
                                      <div className="col-12">
                                        <ul className="row align-items-center justify-content-between mx-3 g-2">
                                          {/* <button><i className="far fa-times-circle"></i> Cancel Contract</button> */}
                                          {/* <button onClick={() => handleStatusChange(contract._id, WORK_APPLY_TYPE.REJECTED)} className=''><i className="far fa-times-circle"></i> View Profile </button>
                                                                <button onClick={() => handleStatusChange(contract._id, WORK_APPLY_TYPE.SELECTED)} className={styles.videBtn}><i className="far fa-check-circle"></i> View Interview Questions</button>
                                                                <button className={styles.videBtn}><i className="fas fa-video"></i>Shortlist</button> */}
                                          <li className="col-2">
                                            <button
                                              className="rounded-2 p-2 text-black fw-semibold text-14 d-flex align-items-center w-100 justify-content-center"
                                              style={{ background: "#d6d6d6" }}
                                              onClick={() =>
                                                handleStatusChange(
                                                  contract._id,
                                                  WORK_APPLY_TYPE.REJECTED
                                                )
                                              }
                                            >
                                              <i className="me-2">
                                                <img src={cross} alt="" />
                                              </i>
                                              Reject
                                            </button>
                                          </li>
                                          <li className="col-3">
                                            <button
                                              className="bg-blue rounded-2 p-2 fw-semibold text-white text-14 d-flex align-items-center justify-content-center w-100 text-center"
                                              onClick={() =>
                                                handleStatusChange(
                                                  contract._id,
                                                  WORK_APPLY_TYPE.SHORTLISTED
                                                )
                                              }
                                            >
                                              {" "}
                                              <i className="me-2">
                                                <img src={hire} alt="" width={15} height={15} />
                                              </i>
                                              Shortlist
                                            </button>
                                          </li>
                                          <li className="col-2">
                                            <button
                                              className="bg-blue rounded-2 p-2 fw-semibold text-white text-14 d-flex align-items-center justify-content-center w-100 text-center"
                                              onClick={() =>
                                                handleStatusChange(
                                                  contract._id,
                                                  WORK_APPLY_TYPE.SELECTED
                                                )
                                              }
                                            >
                                              <i className="me-2">
                                                <img src={hire} alt="" width={15} height={15}/>
                                              </i>
                                              Hire{" "}
                                            </button>
                                          </li>
                                          <li className="col-5">
                                            <button
                                              className="bg-blue rounded-2 p-2 fw-semibold text-white text-14 d-flex align-items-center justify-content-center w-100 text-center"
                                              onClick={() =>
                                                handleShowInterviewQus(contract)
                                              }
                                              type="button"
                                            >
                                              {" "}
                                              Request for Interview
                                            </button>
                                          </li>
                                        </ul>
                                      </div>
                                    )}
                                    {contract.status ===
                                      WORK_APPLY_TYPE.SHORTLISTED && (
                                      <div className="col-12">
                                        <ul className="row align-items-center justify-content-between mx-3 g-2">
                                          {/* <button><i className="far fa-times-circle"></i> Cancel Contract</button> */}
                                          {/* <button onClick={() => handleStatusChange(contract._id, WORK_APPLY_TYPE.REJECTED)} className=''><i className="far fa-times-circle"></i> View Profile </button>
                                                                <button onClick={() => handleStatusChange(contract._id, WORK_APPLY_TYPE.SELECTED)} className={styles.videBtn}><i className="far fa-check-circle"></i> View Interview Questions</button>
                                                                <button className={styles.videBtn}><i className="fas fa-video"></i>Shortlist</button> */}
                                          <li className="col-2">
                                            <button
                                              className="rounded-2 p-2 text-black fw-semibold text-14 d-flex align-items-center w-100 justify-content-center"
                                              style={{ background: "#d6d6d6" }}
                                              onClick={() =>
                                                handleStatusChange(
                                                  contract._id,
                                                  WORK_APPLY_TYPE.REJECTED
                                                )
                                              }
                                            >
                                              <i className="me-2">
                                                <img src={cross} alt=""  width={15} height={15}/>
                                              </i>
                                              Reject
                                            </button>
                                          </li>

                                          <li className="col-2">
                                            <button
                                              className="bg-blue rounded-2 p-2 fw-semibold text-white text-14 d-flex align-items-center justify-content-center w-100 text-center"
                                              onClick={() =>
                                                handleStatusChange(
                                                  contract._id,
                                                  WORK_APPLY_TYPE.SELECTED
                                                )
                                              }
                                            >
                                              <i className="me-2">
                                                <img src={hire} alt=""  width={15} height={15}/>
                                              </i>
                                              Hire{" "}
                                            </button>
                                          </li>
                                          <li className="col-5">
                                            <button
                                              className="bg-blue rounded-2 p-2 fw-semibold text-white text-14 d-flex align-items-center justify-content-center w-100 text-center"
                                              onClick={() =>
                                                handleShowInterviewQus(contract)
                                              }
                                              type="button"
                                            >
                                              {" "}
                                              Request for Interview
                                            </button>
                                          </li>
                                        </ul>
                                      </div>
                                    )}
                                    {contract.status ===
                                      WORK_APPLY_TYPE.SELECTED && (
                                      <>
                                        <div className={styles.VideoCallBtn}>
                                          <button
                                            onClick={() =>
                                              handleViewUserProfile(contract)
                                            }
                                          >
                                            View Full Profile{" "}
                                          </button>
                                          <button
                                            onClick={() =>
                                              handleStatusChange(
                                                contract._id,
                                                WORK_APPLY_TYPE.REJECTED
                                              )
                                            }
                                          >
                                            <i className="far fa-times-circle"></i>{" "}
                                            Reject
                                          </button>
                                        </div>
                                      </>
                                    )}
                                    {contract.status ===
                                      WORK_APPLY_TYPE.REJECTED && (
                                      <div className={styles.VideoCallBtn}>
                                        <button className="videBtn">
                                          Remove
                                        </button>
                                        <button
                                          onClick={() =>
                                            handleStatusChange(
                                              contract._id,
                                              WORK_APPLY_TYPE.APPLIED
                                            )
                                          }
                                          className={styles.videBtn}
                                        >
                                          <i className="fas fa-undo"></i>{" "}
                                          Unreject{" "}
                                        </button>
                                      </div>
                                    )}

                                    <div className={styles.Infor}>
                                      <p className="text-14 mb-0">
                                        <img src={rdinfo} alt="" /> If you
                                        cancel a project that you committed to,
                                        your account will be subject to
                                        cancelation.
                                      </p>
                                    </div>
                                    <div className={`mb-3 ${styles.Infor}`}>
                                      <button
                                        onClick={() =>
                                          navigate(
                                            `${APP_ROUTES.VIEW_APPLICATION}/${id}/${contract._id}`
                                          )
                                        }
                                        className="border-blue text-14 fw-semibold p-2 py-2 border-2 text-blue rounded-3 w-100"
                                      >
                                        View Application
                                      </button>
                                    </div>
                                  </div>
                                );
                              } else if (
                                activeSelectedContractType ===
                                WORK_APPLY_TYPE.APPLIED
                              ) {
                                return (
                                  <div
                                    key={contract._id}
                                    className={styles.contractPast}
                                  >
                                    <div
                                      className={`px-3 py-2 ${styles.ContarctHead}`}
                                    >
                                      <div className={styles.leftContract}>
                                        <h5 className="fw-semibold text-14">
                                          ID: #{contract.worksInfo.workNumber}
                                        </h5>
                                      </div>
                                      <div className={styles.leftContract}>
                                        {/* <button className={styles.shedule}>
                                      {contract.status === "applied"
                                        ? "Applied"
                                        : contract.status === "accepted"
                                          ? "Shortlisted"
                                          : contract.status === "rejected"
                                            ? "Rejected"
                                            : contract.status === "selected"
                                              ? "Selected"
                                              : ""}
                                    </button> */}
                                        <button className="py-2 bg-blue text-white rounded-pill d-flex align-items-center border-blue text-12 fw-medium">
                                          <i className="me-2">
                                            <img src={share} alt="" />
                                          </i>
                                          Share Profile
                                        </button>
                                      </div>
                                    </div>
                                    {/* <div className={styles.outerNameDetail}>
                                  <div className="row align-items-center">
                                    <div className="col-md-12">
                                      <div className={styles.leftName}>
                                        <h4 className="text-16 fw-semibold">Professional Details</h4>
                                      </div>
                                    </div>
                                    <div className="col-lg-2 col-12">
                                      <div className={styles.leftName}>
                                        <div className={styles.PrileImg}>
                                          <figure>
                                            <img
                                              src={
                                                contract.user.profilePicture
                                              }
                                              alt=""
                                              style={{ width: "100px", minHeight: "70px" }}
                                              className=" img-fluid rounded-circle"
                                            />
                                          </figure>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-5 col-6">
                                      <div className={styles.leftName}>
                                        <div className={styles.PrileImgs}>
                                          <h5>{contract.user?.name}</h5>
                                          <span>
                                            {contract.user.occupation?.name}
                                          </span>
                                          <p>
                                            <img src={loc} alt="" />{" "}
                                            {contract?.user?.country?.name}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-5 col-6">
                                      <div className={styles.rightName}>
                                        <p>
                                          Start Date:{" "}
                                          {
                                            <span>
                                              {moment(
                                                contract.worksInfo.times
                                                  .startDate
                                              ).format("MMM DD, YYYY")}
                                            </span>
                                          }
                                        </p>
                                        <p>
                                          End Date:{" "}
                                          {
                                            <span>
                                              {moment(
                                                contract.worksInfo.times
                                                  .startDate
                                              ).format("MMM DD, YYYY")}
                                            </span>
                                          }
                                        </p>
                                        <p>
                                          Rate Per Hour : $
                                          {contract.worksInfo.rates.baseRate}
                                        </p>
                                        <p>
                                          Expense Terms : net$
                                          {
                                            contract.expensePayment
                                              .paymentTerm
                                          }
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                                    <div className={styles.outerNameDetail}>
                                      <div className="row align-items-center pb-3 border-bottom">
                                        <div className="col-md-12">
                                          <div className={styles.outerNDetail}>
                                            <div className="row align-items-center">
                                              <div className="col-12">
                                                <div className="mb-2">
                                                  <h4 className="fw-medium text-18">
                                                    {" "}
                                                    Professional Details
                                                  </h4>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-2 col-12 pe-0">
                                          <div className={styles.leftName}>
                                            <div className={styles.PrileImg}>
                                              <span className="text-center w-100">
                                                <i>
                                                  <img
                                                    src={
                                                      contract.user
                                                        .profilePicture
                                                    }
                                                    alt=""
                                                    style={{
                                                      width: "100px",
                                                      minHeight: "60px",
                                                    }}
                                                    className=" img-fluid rounded-circle"
                                                  />
                                                </i>
                                                <h4
                                                  onClick={() => {
                                                    handleViewUserProfile(
                                                      contract
                                                    );
                                                  }}
                                                  className="mt-2 mb-0 fw-semibold px-1 py-2 text-10 text-capitalize bg-lightblue text-blue rounded-pill"
                                                >
                                                  view profile
                                                </h4>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-4 col-6">
                                          <div className={styles.leftName}>
                                            <div className={styles.PrileImg}>
                                              <div className="">
                                                <h5>{contract.user?.name}</h5>
                                                <h6 className="text-14">
                                                  {
                                                    contract.user.occupation
                                                      ?.name
                                                  }
                                                </h6>
                                                <p>
                                                  <img src={loc} alt="" />{" "}
                                                  {
                                                    contract?.user?.country
                                                      ?.name
                                                  }
                                                </p>
                                                <h6>Reviews: X</h6>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6 col-6">
                                          <div
                                            className={`ps-3 ${styles.rightName}`}
                                          >
                                            <p>
                                              Start Date:{" "}
                                              {moment(
                                                contract?.worksInfo?.times
                                                  .startDate
                                              ).format("MM-DD-YYYY")}
                                            </p>
                                            <p>
                                              Rate Per Hour : $
                                              {
                                                contract?.hourlyPayment
                                                  ?.paymentTerm
                                              }
                                            </p>
                                            <p>
                                              Expense Terms :{" "}
                                              {
                                                contract?.expensePayment
                                                  ?.paymentTerm
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-12">
                                      <ul className="row align-items-center justify-content-between mx-3 g-2">
                                        {/* <button><i className="far fa-times-circle"></i> Cancel Contract</button> */}
                                        {/* <button onClick={() => handleStatusChange(contract._id, WORK_APPLY_TYPE.REJECTED)} className=''><i className="far fa-times-circle"></i> View Profile </button>
                                                              <button onClick={() => handleStatusChange(contract._id, WORK_APPLY_TYPE.SELECTED)} className={styles.videBtn}><i className="far fa-check-circle"></i> View Interview Questions</button>
                                                              <button className={styles.videBtn}><i className="fas fa-video"></i>Shortlist</button> */}
                                        <li className="col-2">
                                          <button
                                            className="rounded-2 p-2 text-black fw-semibold text-14 d-flex align-items-center w-100 justify-content-center"
                                            style={{ background: "#d6d6d6" }}
                                            onClick={() =>
                                              handleStatusChange(
                                                contract._id,
                                                WORK_APPLY_TYPE.REJECTED
                                              )
                                            }
                                          >
                                            <i className="me-2">
                                              <img src={cross} alt="" />
                                            </i>
                                            Reject
                                          </button>
                                        </li>
                                        <li className="col-3">
                                          <button
                                            className="bg-blue rounded-2 p-2 fw-semibold text-white text-14 d-flex align-items-center justify-content-center w-100 text-center"
                                            onClick={() =>
                                              handleStatusChange(
                                                contract._id,
                                                WORK_APPLY_TYPE.SHORTLISTED
                                              )
                                            }
                                          >
                                            {" "}
                                            <i className="me-2">
                                              <img src={hire} alt=""  width={15} height={15}/>
                                            </i>
                                            Shortlist
                                          </button>
                                        </li>
                                        <li className="col-2">
                                          <button
                                            className="bg-blue rounded-2 p-2 fw-semibold text-white text-14 d-flex align-items-center justify-content-center w-100 text-center"
                                            onClick={() =>
                                              handleStatusChange(
                                                contract._id,
                                                WORK_APPLY_TYPE.SELECTED
                                              )
                                            }
                                          >
                                            <i className="me-2">
                                              <img src={hire} alt=""  width={15} height={15}/>
                                            </i>
                                            Hire{" "}
                                          </button>
                                        </li>
                                        <li className="col-5">
                                          <button
                                            className="bg-blue rounded-2 p-2 fw-semibold text-white text-14 d-flex align-items-center justify-content-center w-100 text-center"
                                            onClick={() =>
                                              handleShowInterviewQus(contract)
                                            }
                                            type="button"
                                          >
                                            {" "}
                                            Request for Interview
                                          </button>
                                        </li>
                                      </ul>
                                    </div>

                                    <div className={styles.Infor}>
                                      <p className="text-14 mb-0">
                                        <img src={rdinfo} alt="" /> If you
                                        cancel a project that you committed to,
                                        your account will be subject to
                                        cancelation.
                                      </p>
                                    </div>
                                    <div className={`mb-3 ${styles.Infor}`}>
                                      <button
                                        onClick={() =>
                                          navigate(
                                            `${APP_ROUTES.VIEW_APPLICATION}/${id}/${contract._id}`
                                          )
                                        }
                                        className="border-blue text-14 fw-semibold p-2 border-2 text-blue rounded-3 w-100"
                                      >
                                        View Application
                                      </button>
                                    </div>
                                  </div>
                                );
                              } else if (
                                activeSelectedContractType ===
                                WORK_APPLY_TYPE.SHORTLISTED
                              ) {
                                return (
                                  <div
                                    key={contract._id}
                                    className={styles.contractPast}
                                  >
                                    <div
                                      className={`px-3 py-2 ${styles.ContarctHead}`}
                                    >
                                      <div className={styles.leftContract}>
                                        <h5 className="fw-semibold text-14">
                                          ID: #{contract.worksInfo.workNumber}
                                        </h5>
                                      </div>
                                      <div className={styles.leftContract}>
                                        {/* <button className={styles.shedule}>
                                      {contract.status === "applied"
                                        ? "Applied"
                                        : contract.status === "accepted"
                                          ? "Shortlisted"
                                          : contract.status === "rejected"
                                            ? "Rejected"
                                            : contract.status === "selected"
                                              ? "Selected"
                                              : ""}
                                    </button> */}
                                        <button className="py-2 bg-blue text-white rounded-pill d-flex align-items-center border-blue text-12 fw-medium">
                                          <i className="me-2">
                                            <img src={share} alt="" />
                                          </i>
                                          Share Profile
                                        </button>
                                      </div>
                                    </div>
                                    {/* <div className={styles.outerNameDetail}>
                                  <div className="row align-items-center">
                                    <div className="col-md-12">
                                      <div className={styles.leftName}>
                                        <h4 className="text-16 fw-semibold">Professional Details</h4>
                                      </div>
                                    </div>
                                    <div className="col-lg-2 col-12">
                                      <div className={styles.leftName}>
                                        <div className={styles.PrileImg}>
                                          <figure>
                                            <img
                                              src={
                                                contract.user.profilePicture
                                              }
                                              alt=""
                                              style={{ width: "100px", minHeight: "70px" }}
                                              className=" img-fluid rounded-circle"
                                            />
                                          </figure>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-5 col-6">
                                      <div className={styles.leftName}>
                                        <div className={styles.PrileImgs}>
                                          <h5>{contract.user?.name}</h5>
                                          <span>
                                            {contract.user.occupation?.name}
                                          </span>
                                          <p>
                                            <img src={loc} alt="" />{" "}
                                            {contract?.user?.country?.name}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-5 col-6">
                                      <div className={styles.rightName}>
                                        <p>
                                          Start Date:{" "}
                                          {
                                            <span>
                                              {moment(
                                                contract.worksInfo.times
                                                  .startDate
                                              ).format("MMM DD, YYYY")}
                                            </span>
                                          }
                                        </p>
                                        <p>
                                          End Date:{" "}
                                          {
                                            <span>
                                              {moment(
                                                contract.worksInfo.times
                                                  .startDate
                                              ).format("MMM DD, YYYY")}
                                            </span>
                                          }
                                        </p>
                                        <p>
                                          Rate Per Hour : $
                                          {contract.worksInfo.rates.baseRate}
                                        </p>
                                        <p>
                                          Expense Terms : net$
                                          {
                                            contract.expensePayment
                                              .paymentTerm
                                          }
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                                    <div className={styles.outerNameDetail}>
                                      <div className="row align-items-center pb-3 border-bottom">
                                        <div className="col-md-12">
                                          <div className={styles.outerNDetail}>
                                            <div className="row align-items-center">
                                              <div className="col-12">
                                                <div className="mb-2">
                                                  <h4 className="fw-medium text-18">
                                                    {" "}
                                                    Professional Details
                                                  </h4>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-2 col-12 pe-0">
                                          <div className={styles.leftName}>
                                            <div className={styles.PrileImg}>
                                              <span className="text-center w-100">
                                                <i>
                                                  <img
                                                    src={
                                                      contract.user
                                                        .profilePicture
                                                    }
                                                    alt=""
                                                    style={{
                                                      width: "100px",
                                                      minHeight: "60px",
                                                    }}
                                                    className=" img-fluid rounded-circle"
                                                  />
                                                </i>
                                                <h4
                                                  onClick={() => {
                                                    handleViewUserProfile(
                                                      contract
                                                    );
                                                  }}
                                                  className="mt-2 mb-0 fw-semibold px-1 py-2 text-10 text-capitalize bg-lightblue text-blue rounded-pill"
                                                >
                                                  view profile
                                                </h4>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-4 col-6">
                                          <div className={styles.leftName}>
                                            <div className={styles.PrileImg}>
                                              <div className="">
                                                <h5>{contract.user?.name}</h5>
                                                <h6 className="text-14">
                                                  {
                                                    contract.user.occupation
                                                      ?.name
                                                  }
                                                </h6>
                                                <p>
                                                  <img src={loc} alt="" />{" "}
                                                  {
                                                    contract?.user?.country
                                                      ?.name
                                                  }
                                                </p>
                                                <h6>Reviews: X</h6>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6 col-6">
                                          <div
                                            className={`ps-3 ${styles.rightName}`}
                                          >
                                            <p>
                                              Start Date:{" "}
                                              {moment(
                                                contract?.worksInfo?.times
                                                  ?.startDate
                                              ).format("MMM DD, YYYY")}
                                            </p>
                                            <p>
                                              Rate Per Hour : $
                                              {
                                                contract?.hourlyPayment
                                                  ?.paymentTerm
                                              }
                                            </p>
                                            <p>
                                              Expense Terms :{" "}
                                              {
                                                contract?.expensePayment
                                                  ?.paymentTerm
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-12">
                                      <ul className="row align-items-center justify-content-between mx-3 g-2">
                                        {/* <button><i className="far fa-times-circle"></i> Cancel Contract</button> */}
                                        {/* <button onClick={() => handleStatusChange(contract._id, WORK_APPLY_TYPE.REJECTED)} className=''><i className="far fa-times-circle"></i> View Profile </button>
                                                              <button onClick={() => handleStatusChange(contract._id, WORK_APPLY_TYPE.SELECTED)} className={styles.videBtn}><i className="far fa-check-circle"></i> View Interview Questions</button>
                                                              <button className={styles.videBtn}><i className="fas fa-video"></i>Shortlist</button> */}
                                        <li className="col-2">
                                          <button
                                            className="rounded-2 p-2 text-black fw-semibold text-14 d-flex align-items-center w-100 justify-content-center"
                                            style={{ background: "#d6d6d6" }}
                                            onClick={() =>
                                              handleStatusChange(
                                                contract._id,
                                                WORK_APPLY_TYPE.REJECTED
                                              )
                                            }
                                          >
                                            <i className="me-2">
                                              <img src={cross} alt=""  width={15} height={15}/>
                                            </i>
                                            Reject
                                          </button>
                                        </li>

                                        <li className="col-2">
                                          <button
                                            className="bg-blue rounded-2 p-2 fw-semibold text-white text-14 d-flex align-items-center justify-content-center w-100 text-center"
                                            onClick={() =>
                                              handleStatusChange(
                                                contract._id,
                                                WORK_APPLY_TYPE.SELECTED
                                              )
                                            }
                                          >
                                            <i className="me-2">
                                              <img src={hire} alt=""  width={15} height={15}/>
                                            </i>
                                            Hire{" "}
                                          </button>
                                        </li>
                                        <li className="col-5">
                                          <button
                                            className="bg-blue rounded-2 p-2 fw-semibold text-white text-14 d-flex align-items-center justify-content-center w-100 text-center"
                                            onClick={() =>
                                              handleShowInterviewQus(contract)
                                            }
                                            type="button"
                                          >
                                            {" "}
                                            Request for Interview
                                          </button>
                                        </li>
                                      </ul>
                                    </div>

                                    <div className={styles.Infor}>
                                      <p className="text-14 mb-0">
                                        <img src={rdinfo} alt="" /> If you
                                        cancel a project that you committed to,
                                        your account will be subject to
                                        cancelation.
                                      </p>
                                    </div>
                                    <div className={`mb-3 ${styles.Infor}`}>
                                      <button
                                        onClick={() =>
                                          navigate(
                                            `${APP_ROUTES.VIEW_APPLICATION}/${id}/${contract._id}`
                                          )
                                        }
                                        className="border-blue text-14 fw-semibold p-2 border-2 text-blue rounded-3 w-100"
                                      >
                                        View Application
                                      </button>
                                    </div>
                                  </div>
                                );
                              } else if (
                                activeSelectedContractType ===
                                WORK_APPLY_TYPE.SELECTED
                              ) {
                                return (
                                  <div
                                    key={contract._id}
                                    className={styles.contractPast}
                                  >
                                    <div
                                      className={`px-3 py-2 ${styles.ContarctHead}`}
                                    >
                                      <div className={styles.leftContract}>
                                        <h5 className="fw-semibold text-14">
                                          ID: #{contract.worksInfo.workNumber}
                                        </h5>
                                      </div>
                                      <div className={styles.leftContract}>
                                        {/* <button className={styles.shedule}>
                                        {contract.status === "applied"
                                          ? "Applied"
                                          : contract.status === "accepted"
                                            ? "Shortlisted"
                                            : contract.status === "rejected"
                                              ? "Rejected"
                                              : contract.status === "selected"
                                                ? "Selected"
                                                : ""}
                                      </button> */}
                                        <button className="py-2 bg-blue text-white rounded-pill d-flex align-items-center border-blue text-12 fw-medium">
                                          <i className="me-2">
                                            <img src={share} alt="" />
                                          </i>
                                          Share Profile
                                        </button>
                                      </div>
                                    </div>
                                    {/* <div className={styles.outerNameDetail}>
                                    <div className="row align-items-center">
                                      <div className="col-md-12">
                                        <div className={styles.leftName}>
                                          <h4 className="text-16 fw-semibold">Professional Details</h4>
                                        </div>
                                      </div>
                                      <div className="col-lg-2 col-12">
                                        <div className={styles.leftName}>
                                          <div className={styles.PrileImg}>
                                            <figure>
                                              <img
                                                src={
                                                  contract.user.profilePicture
                                                }
                                                alt=""
                                                style={{ width: "100px", minHeight: "70px" }}
                                                className=" img-fluid rounded-circle"
                                              />
                                            </figure>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-5 col-6">
                                        <div className={styles.leftName}>
                                          <div className={styles.PrileImgs}>
                                            <h5>{contract.user?.name}</h5>
                                            <span>
                                              {contract.user.occupation?.name}
                                            </span>
                                            <p>
                                              <img src={loc} alt="" />{" "}
                                              {contract?.user?.country?.name}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-5 col-6">
                                        <div className={styles.rightName}>
                                          <p>
                                            Start Date:{" "}
                                            {
                                              <span>
                                                {moment(
                                                  contract.worksInfo.times
                                                    .startDate
                                                ).format("MMM DD, YYYY")}
                                              </span>
                                            }
                                          </p>
                                          <p>
                                            End Date:{" "}
                                            {
                                              <span>
                                                {moment(
                                                  contract.worksInfo.times
                                                    .startDate
                                                ).format("MMM DD, YYYY")}
                                              </span>
                                            }
                                          </p>
                                          <p>
                                            Rate Per Hour : $
                                            {contract.worksInfo.rates.baseRate}
                                          </p>
                                          <p>
                                            Expense Terms : net$
                                            {
                                              contract.expensePayment
                                                .paymentTerm
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}
                                    <div className={styles.outerNameDetail}>
                                      <div className="row align-items-center pb-3 border-bottom">
                                        <div className="col-md-12">
                                          <div className={styles.outerNDetail}>
                                            <div className="row align-items-center">
                                              <div className="col-12">
                                                <div className="mb-2">
                                                  <h4 className="fw-medium text-18">
                                                    {" "}
                                                    Professional Details
                                                  </h4>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-2 col-12 pe-0">
                                          <div className={styles.leftName}>
                                            <div className={styles.PrileImg}>
                                              <span className="text-center w-100">
                                                <i>
                                                  <img
                                                    src={
                                                      contract.user
                                                        .profilePicture
                                                    }
                                                    alt=""
                                                    style={{
                                                      width: "100px",
                                                      minHeight: "60px",
                                                    }}
                                                    className=" img-fluid rounded-circle"
                                                  />
                                                </i>
                                                <h4
                                                  onClick={() => {
                                                    handleViewUserProfile(
                                                      contract
                                                    );
                                                  }}
                                                  className="mt-2 mb-0 fw-semibold px-1 py-2 text-10 text-capitalize bg-lightblue text-blue rounded-pill"
                                                >
                                                  view profile
                                                </h4>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-4 col-6">
                                          <div className={styles.leftName}>
                                            <div className={styles.PrileImg}>
                                              <div className="">
                                                <h5>{contract.user?.name}</h5>
                                                <h6 className="text-14">
                                                  {
                                                    contract.user.occupation
                                                      ?.name
                                                  }
                                                </h6>
                                                <p>
                                                  <img src={loc} alt="" />{" "}
                                                  {
                                                    contract?.user?.country
                                                      ?.name
                                                  }
                                                </p>
                                                <h6>Reviews: X</h6>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6 col-6">
                                          <div
                                            className={`ps-3 ${styles.rightName}`}
                                          >
                                            <p>
                                              Start Date:{" "}
                                              {moment(
                                                contract?.worksInfo?.times
                                                  ?.startDate
                                              ).format("MMM DD, YYYY")}
                                            </p>
                                            <p>
                                              Rate Per Hour : $
                                              {
                                                contract?.hourlyPayment
                                                  ?.paymentTerm
                                              }
                                            </p>
                                            <p>
                                              Expense Terms :{" "}
                                              {
                                                contract?.expensePayment
                                                  ?.paymentTerm
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <>
                                      <div className={styles.VideoCallBtn}>
                                        <button
                                          onClick={() =>
                                            handleViewUserProfile(contract)
                                          }
                                        >
                                          View Full Profile{" "}
                                        </button>
                                        <button
                                          onClick={() =>
                                            handleStatusChange(
                                              contract._id,
                                              WORK_APPLY_TYPE.REJECTED
                                            )
                                          }
                                        >
                                          <i className="far fa-times-circle"></i>{" "}
                                          Reject
                                        </button>
                                      </div>
                                    </>

                                    <div className={styles.Infor}>
                                      <p className="text-14 mb-0">
                                        <img src={rdinfo} alt="" /> If you
                                        cancel a project that you committed to,
                                        your account will be subject to
                                        cancelation.
                                      </p>
                                    </div>
                                    <div className={`mb-3 ${styles.Infor}`}>
                                      <button
                                        onClick={() =>
                                          navigate(
                                            `${APP_ROUTES.VIEW_APPLICATION}/${id}/${contract._id}`
                                          )
                                        }
                                        className="border-blue text-14 fw-semibold p-2 border-2 text-blue rounded-3 w-100"
                                      >
                                        View Application
                                      </button>
                                    </div>
                                  </div>
                                );
                              } else {
                                return (
                                  <div
                                    key={contract._id}
                                    className={styles.contractPast}
                                  >
                                    <div
                                      className={`px-3 py-2 ${styles.ContarctHead}`}
                                    >
                                      <div className={styles.leftContract}>
                                        <h5 className="fw-semibold text-14">
                                          ID: #{contract.worksInfo.workNumber}
                                        </h5>
                                      </div>
                                      <div className={styles.leftContract}>
                                        {/* <button className={styles.shedule}>
                                      {contract.status === "applied"
                                        ? "Applied"
                                        : contract.status === "accepted"
                                          ? "Shortlisted"
                                          : contract.status === "rejected"
                                            ? "Rejected"
                                            : contract.status === "selected"
                                              ? "Selected"
                                              : ""}
                                    </button> */}
                                        <button className="py-2 bg-blue text-white rounded-pill d-flex align-items-center border-blue text-12 fw-medium">
                                          <i className="me-2">
                                            <img src={share} alt="" />
                                          </i>
                                          Share Profile
                                        </button>
                                      </div>
                                    </div>
                                    {/* <div className={styles.outerNameDetail}>
                                  <div className="row align-items-center">
                                    <div className="col-md-12">
                                      <div className={styles.leftName}>
                                        <h4 className="text-16 fw-semibold">Professional Details</h4>
                                      </div>
                                    </div>
                                    <div className="col-lg-2 col-12">
                                      <div className={styles.leftName}>
                                        <div className={styles.PrileImg}>
                                          <figure>
                                            <img
                                              src={
                                                contract.user.profilePicture
                                              }
                                              alt=""
                                              style={{ width: "100px", minHeight: "70px" }}
                                              className=" img-fluid rounded-circle"
                                            />
                                          </figure>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-5 col-6">
                                      <div className={styles.leftName}>
                                        <div className={styles.PrileImgs}>
                                          <h5>{contract.user?.name}</h5>
                                          <span>
                                            {contract.user.occupation?.name}
                                          </span>
                                          <p>
                                            <img src={loc} alt="" />{" "}
                                            {contract?.user?.country?.name}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-5 col-6">
                                      <div className={styles.rightName}>
                                        <p>
                                          Start Date:{" "}
                                          {
                                            <span>
                                              {moment(
                                                contract.worksInfo.times
                                                  .startDate
                                              ).format("MMM DD, YYYY")}
                                            </span>
                                          }
                                        </p>
                                        <p>
                                          End Date:{" "}
                                          {
                                            <span>
                                              {moment(
                                                contract.worksInfo.times
                                                  .startDate
                                              ).format("MMM DD, YYYY")}
                                            </span>
                                          }
                                        </p>
                                        <p>
                                          Rate Per Hour : $
                                          {contract.worksInfo.rates.baseRate}
                                        </p>
                                        <p>
                                          Expense Terms : net$
                                          {
                                            contract.expensePayment
                                              .paymentTerm
                                          }
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                                    <div className={styles.outerNameDetail}>
                                      <div className="row align-items-center pb-3 border-bottom">
                                        <div className="col-md-12">
                                          <div className={styles.outerNDetail}>
                                            <div className="row align-items-center">
                                              <div className="col-12">
                                                <div className="mb-2">
                                                  <h4 className="fw-medium text-18">
                                                    {" "}
                                                    Professional Details
                                                  </h4>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-2 col-12 pe-0">
                                          <div className={styles.leftName}>
                                            <div className={styles.PrileImg}>
                                              <span className="text-center w-100">
                                                <i>
                                                  <img
                                                    src={
                                                      contract.user
                                                        .profilePicture
                                                    }
                                                    alt=""
                                                    style={{
                                                      width: "100px",
                                                      minHeight: "60px",
                                                    }}
                                                    className=" img-fluid rounded-circle"
                                                  />
                                                </i>
                                                <h4
                                                  onClick={() => {
                                                    handleViewUserProfile(
                                                      contract
                                                    );
                                                  }}
                                                  className="mt-2 mb-0 fw-semibold px-1 py-2 text-10 text-capitalize bg-lightblue text-blue rounded-pill"
                                                >
                                                  view profile
                                                </h4>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-4 col-6">
                                          <div className={styles.leftName}>
                                            <div className={styles.PrileImg}>
                                              <div className="">
                                                <h5>{contract.user?.name}</h5>
                                                <h6 className="text-14">
                                                  {
                                                    contract.user.occupation
                                                      ?.name
                                                  }
                                                </h6>
                                                <p>
                                                  <img src={loc} alt="" />{" "}
                                                  {
                                                    contract?.user?.country
                                                      ?.name
                                                  }
                                                </p>
                                                <h6>Reviews: X</h6>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6 col-6">
                                          <div
                                            className={`ps-3 ${styles.rightName}`}
                                          >
                                            <p>
                                              Start Date:{" "}
                                              {moment(
                                                contract?.worksInfo?.times
                                                  .startDate
                                              ).format("MMM DD, YYYY")}
                                            </p>
                                            <p>
                                              Rate Per Hour : $
                                              {
                                                contract?.hourlyPayment
                                                  ?.paymentTerm
                                              }
                                            </p>
                                            <p>
                                              Expense Terms :{" "}
                                              {
                                                contract?.expensePayment
                                                  ?.paymentTerm
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className={styles.VideoCallBtn}>
                                      {/* <button><i className="far fa-times-circle"></i> Cancel Contract</button> */}
                                      <button className="videBtn">
                                        <i
                                          className="fa-solid fa-xmark"
                                          style={{ color: "#ffffff" }}
                                        ></i>{" "}
                                        Remove
                                      </button>
                                      <button
                                        onClick={() =>
                                          handleStatusChange(
                                            contract._id,
                                            WORK_APPLY_TYPE.APPLIED
                                          )
                                        }
                                        className={styles.videBtn}
                                      >
                                        <i className="fas fa-undo"></i> Unreject{" "}
                                      </button>
                                      {/* <button><i className="far fa-times-circle"></i> Remove</button> */}
                                    </div>

                                    <div className={styles.Infor}>
                                      <p className="text-14 mb-0">
                                        <img src={rdinfo} alt="" /> If you
                                        cancel a project that you committed to,
                                        your account will be subject to
                                        cancelation.
                                      </p>
                                    </div>
                                    <div className={`mb-3 ${styles.Infor}`}>
                                      <button
                                        onClick={() =>
                                          navigate(
                                            `${APP_ROUTES.VIEW_APPLICATION}/${id}/${contract._id}`
                                          )
                                        }
                                        className="border-blue text-14 fw-semibold p-2 border-2 text-blue rounded-3 w-100"
                                      >
                                        View Application
                                      </button>
                                    </div>
                                  </div>

                                  // <div
                                  //   key={contract._id}
                                  //   className={styles.contractPast}
                                  // >
                                  //   <div className={styles.ContarctHead}>
                                  //     <div className={styles.leftContract}>
                                  //       <h5>
                                  //         Contract No.: #
                                  //         {contract?.worksInfo?.workNumber}
                                  //       </h5>
                                  //     </div>
                                  //     <div className={styles.leftContract}>
                                  //       <button className={styles.shedule}>
                                  //         {contract.status}
                                  //       </button>
                                  //     </div>
                                  //   </div>
                                  //   <div className={styles.outerNameDetail}>
                                  //     <div className="row align-items-center">
                                  //       <div className="col-md-6">
                                  //         <div className={styles.leftName}>
                                  //           <h4>Contractor Details</h4>
                                  //           <div className={styles.PrileImg}>
                                  //             <span>
                                  //               <img
                                  //                 src={
                                  //                   contract.user.profilePicture
                                  //                 }
                                  //                 alt=""
                                  //               />
                                  //             </span>
                                  //             <div>
                                  //               <h5>{contract.user?.name}</h5>
                                  //               <span>
                                  //                 {contract.user.occupation?.name}
                                  //               </span>
                                  //               <p>
                                  //                 <img src={loc} alt="" />{" "}
                                  //                 {contract.worksInfo.address}
                                  //               </p>
                                  //             </div>
                                  //           </div>
                                  //         </div>
                                  //       </div>
                                  //       <div className="col-md-6">
                                  //         <div className={styles.rightName}>
                                  //           <p>
                                  //             Start Date:{" "}
                                  //             {
                                  //               <span>
                                  //                 {moment(
                                  //                   contract.worksInfo.times
                                  //                     .startDate
                                  //                 ).format("MMM DD, YYYY")}
                                  //               </span>
                                  //             }
                                  //           </p>
                                  //           <p>
                                  //             End Date:{" "}
                                  //             {
                                  //               <span>
                                  //                 {moment(
                                  //                   contract.worksInfo.times
                                  //                     .startDate
                                  //                 ).format("MMM DD, YYYY")}
                                  //               </span>
                                  //             }
                                  //           </p>
                                  //           <p>
                                  //             Rate Per Hour : $
                                  //             {contract.worksInfo.rates.baseRate}
                                  //           </p>
                                  //           <p>
                                  //             Expense Terms : net$
                                  //             {
                                  //               contract.expensePayment
                                  //                 .paymentTerm
                                  //             }
                                  //           </p>
                                  //         </div>
                                  //       </div>
                                  //     </div>
                                  //   </div>
                                  //   <div className={styles.VideoCallBtn}>
                                  //     {/* <button><i className="far fa-times-circle"></i> Cancel Contract</button> */}
                                  //     <button className="videBtn">
                                  //     <i className="fa-solid fa-xmark" style={{color:"#ffffff"}}></i> Remove
                                  //     </button>
                                  //     <button
                                  //       onClick={() =>
                                  //         handleStatusChange(
                                  //           contract._id,
                                  //           WORK_APPLY_TYPE.APPLIED
                                  //         )
                                  //       }
                                  //       className={styles.videBtn}
                                  //     >
                                  //       <i className="fas fa-undo"></i> Unreject{" "}
                                  //     </button>
                                  //     {/* <button><i className="far fa-times-circle"></i> Remove</button> */}
                                  //   </div>
                                  //   <div className={styles.Infor}>
                                  //     <p>
                                  //       <img src={rdinfo} alt="" /> If you cancel
                                  //       a project that you committed to, your
                                  //       account will be subject to cancelation.
                                  //     </p>
                                  //   </div>
                                  // </div>
                                );
                              }
                            })
                          : ""}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className={`text-center`}>
                        <div className="spinner-border text-blue" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DetailOpenContract;
