import React, { useEffect, useState } from "react";
import styles from "../style/style.module.css";
import { getConnectionPlans } from "../api";
import { ConnectionPlanRes } from "../interface";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setConnectionDiscount,
  setConnectionId,
  setConnectionPlanType,
  setConnectionPrice,
} from "../../../../redux/reducers/connectionsPlan";
import { APP_ROUTES } from "../../../../utils/routes/AppRoutes";

const YearlyPlan = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [planData, setPlanData] = useState<ConnectionPlanRes[]>([]);
  const [loading, setLoading] = useState(true);

  const _fetchPlans = async () => {
    const res = await getConnectionPlans("yearly");
    setPlanData(res?.data);
    setLoading(false);
  };

  useEffect(() => {
    _fetchPlans();
  }, []);

  const _handleStart = (
    price: number,
    type: string,
    id: string,
    discount: number
  ) => {
    dispatch(setConnectionPrice(price));
    dispatch(setConnectionPlanType(type));
    dispatch(setConnectionId(id));
    dispatch(setConnectionDiscount(discount));
    navigate(APP_ROUTES.CONNECTION_PAYMENT);
  };

  return (
    <div className={styles.boxContainer} id="yearly">
      <div className="row align-items-center g-4 position-relative">
        {loading ? (
          <div className="spinner-grow text-primary" role="status" style={{width: "25px", height: "25px"}}>
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <>
            {planData?.length > 0
              ? planData?.map((data, ind) => (
                  <>
                    <div key={ind} className={`col-xl-4 col-6`}>
                      <div className={`p-3 position-relative ${styles.box}`}>
                        <h3 className="text-36 lh-xs fw-bolder mb-0 font-poppins">{data?.name?.split(" ")[0].replace("Connections", "")}</h3>
                        <h4 className="text-22 fw-semibold mb-3 font-poppins">
                          Connections
                        </h4>
                        <h4 className="text-18 d-block font-poppins font-semibold">
                          ${data?.price}/month
                          <span className="text-blue text-12 d-block">{data?.freeMonths} Months Free</span>
                        </h4>
                        <button
                          type="button"
                          className="btn btn-outline-primary w-100 mt-3"
                          onClick={() =>
                            _handleStart(
                              data?.price,
                              data?.type,
                              data?._id,
                              data?.discount
                            )
                          }
                        >
                          Start Now
                        </button>
                        <div className={styles.badge}>
                          <div className={styles.text}>
                            <span className={styles.price}>
                              ${data?.discount}
                            </span>
                            <span className={styles.label}>Discount</span>
                          </div>
                        </div>
                        <span className={styles.ribbon}></span>
                      </div>
                    </div>
                  </>
                ))
              : "No Plans Available"}
          </>
        )}
      </div>
    </div>
  );
};

export default YearlyPlan;
