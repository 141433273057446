// src/utils/contractTypes.ts
export const CONSTANT_MANAGER = {
  SLUG: {
    ADD_CONTRACT: "add_contract",
  },
  ADD_CONTRACT: {
    HOURLY_CONTRACT: "hourlycontract",
    DIRECT_JOB: "directJob",
    POST_YOUR_AD: "postAd",
    PRIMARY_OCCUPATION: "primaryoccupation",
    SECONDARY_OCCUPATION: "secondaryoccupation",
    DESCRIPTION: "description",
    START_DATE: "startdate",
    ESTIMATED_END_DATE: "estimatedenddate",
    DURATION_IN_DAYS: "durationindays",
    PEOPLE_NEEDED: "peopleneeded",
    SHIFT: "shift",
    PLANNED_HOURS_PER_DAY: "plannedhoursperday",
    PLANNED_DAYS_PER_WEEK: "planneddaysperweek",
    COUNTRY: "country",
    STATE: "state",
    CITY: "city",
    STREET: "street",
    RENTAL_CAR_ALLOWANCE: "rentalcarallowance",
    FUEL: "fuel",
    AIRFAREALLOWANCE: "airfareallowance",
    MILEAGE: "mileage",
    WHERE_TRAVEL_FOR_THIS_JOB: "wheretravelforjob",
    TRAVEL_TIME_RATE: "traveltimerate",
    TRAVEL_TRIP_ALLOWED: "traveltripallowed",
    TRAVEL_HOURS_PER_ROUND_TRIP: "travelhoursperroundtrip",
    SELECT_NET_PAYMENT_CONTRACT: "selectpaymentcontract",
    BASE_RATE: "baserates",
    OT_RATES: "otrates",
    DT_RATE: "dtrates",
    TRAVEL_EXPENSE_REIMBURSED: "travelexpensereimbursed",
    DAILY_PER_DIEM: "dailyperdiem",
    TRAVEL_DAY_PER_DIEM: "traveldayperdiem",
    HOTEL_ALLOWANCE_PER_DAY: "hotelallowanceperday",
    ONSITE_CUSTOMER: "onsitecustomer",
    PRIMARY_NAME: "primaryname",
    PRIMARY_PHONE: "primaryphonenumber",
    PRIMARY_EMAIL: "primaryemail",
    SECONDARY_NAME: "secondaryname",
    SECONDARY_PHONE_NUMBER: "secondaryphonenumber",
    SECONDARY_EMAIL: "secondaryemail",
    PAID_AD: {
      DATA: {
        PAID_AD: "paidAdd",
        DESCRIPTION_OF_YOUR_ADD: "descriptionOffYourAdd",
        UPLOAD_IMAGE: "uploadImage",
        CHOOSE_YOUR_PLAN_FOR_PROMOTION: "chooseYourAdPromotionPlan",
        BOOST_ADD: "boostAds",
        BOOST_ADD_DESCRIPTION: "bootAddDescription",
        RUN_UNTIL_IT_PAUSE: "runUntilItPuase",
        SPENDING_LIMIT_PAYMENT: "spendingLimitPayment",
      },
    },
    SOCIAL_POST_FOR_FEED: {
      DATA: {
        SOCIAL_POST_FOR_FEED: "socialpost",
        DESCRIPTION_OF_YOUR_ADD_SOCIAL_POST: "descriptionOffYourAddSocislPost",
        UPLOAD_IMAGE_SOCIAL_POST: "uploadImageSocialPost",
        CHOOSE_YOUR_PLAN_FOR_PROMOTION: "chooseYourAdPromotionPlan",
        BOOST_ADD: "boostAds",
        BOOST_ADD_DESCRIPTION: "bootAddDescription",
        RUN_UNTIL_IT_PAUSE: "runUntilItPuase",
      },
    },
  },
  SLUG_FEED: {
    FEED: "feed",
  },
  FEED_DATA: {
    CONTRACTS_RECOMMENDED_FOR_YOU: "ContractsRecommentedForYou",
    JOBS_RECOMMENDED_FOR_YOU: "JobsRecommentedForYou",
    PROFESSIONALS: "Professionals",
    SEARCH_ON_MAPS: "SearchOnMaps",
    CONNECT_WITH_FRIENDS: "ConnectWithFriends",
    MANAGE_CONNECTIONS: "ManageConnections",
    POSTS: "Posts",
    PLACE_ADS: "PlaceAds",
    COMPLETED_CONTRACTS: "CompletedContracts",
    ONGOING_CONTRACTS: "OngoingContracts",
    ENDORSE_YOUR_FRIENDS: "EndorseYourFriends",
    MY_FRIENDS: "MyFriends",
    TECH_SCHOOL_ADVERTISEMENTS: "TechSchoolAdvertisements",
    FOLLOW_US_ON: "FollowUsOn",
    MY_TEAMS: "MyTeams",
    ADD_MISSING_PROFILE_INFORMATION: "AddMissingProfileInformations",
    ADMIN_ANNOUNCEMENTS: "AdminAnnouncements",
  },
  SLUG_HOME: {
    HOME: "home",
  },
  HOME_DATA: {
    SCHEDULED_CONTRACTS: "ScheduledContracts",

    REQUESTS: "Requests",
    MY_POSTS: "MyPosts",
    MY_TEAMS: "MyTeams",
    REQUESTS_FROM_CUSTOMERS: {
      DATA: {
        REQUESTS_FROM_CUSTOMERS: "RequestsFromCustomers",
        REQUESTS: "Requests",
      },
    },
    JOBS: {
      DATA: {
        JOBS: "Jobs",
        COMPLETE_CONTRACTS: "CompleteContracts",
      },
    },
    CONTRACTS_PENDING_APPROVAL: "contractsPendingApproval",
    OPEN_CONTRACTS: "openContracts",
    HOME_DATA_DIRECT_JOB: "homeDataDirectJob",
  },
  EXPENSE: {
    SLUG: {
      APPROVED: 'Approved data',
    }
  }
};
