import React, { useEffect, useState, useRef } from "react";
import io, { Socket } from "socket.io-client";
import styles from "../styles/style.module.css";
import { format, formatDistanceToNow, isToday } from "date-fns";
import storage from "../../../utils/storage";
import { getuserProfile } from "../../home/api";
import chatone from "../../../assets/images/chatone.png";
import mic from "../../../assets/images/mic_ic.svg";
import send from "../../../assets/images/send_ic.svg";

interface Message {
  _id: string;
  receiverId: string;
  message: string;
  createdAt: string;
  senderId: string;
  timestamp: string;
  isAdmin?: boolean;
}
interface Props {
  onClose?: () => void;
}

function ChatModule({ onClose }: Props) {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState("");
  const scrollToBottomRef = useRef<HTMLDivElement>(null);
  const [messagess, setAllMessages] = useState<any[]>([]);
  const [profilePicture, setProfilePicture] = useState<string | null>(null);
  const serverURL = process.env.REACT_APP_SERVER_URL as string;

  useEffect(() => {
    const socketConnection = io(serverURL);
    setSocket(socketConnection);
    return () => {
      socketConnection.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!socket) return;
    socket.on("connect", () => {
      console.log("Connected to server");
    });
    socket.on("disconnect", () => {
      console.log("Disconnected from server");
    });
    socket.emit("joinAdminChat", {
      senderId: storage.getAuthUserId(),
      isAdmin: false,
    });
    socket.on("adminMessageHistory", (history: Message[]) => {
      console.log("Message history:", history);
      setMessages(history);
      setAllMessages(history);
      scrollToBottom();
    });
    socket.on("userJoined", (message: string) => {
      console.log("User joined:", message);
    });

    // socket.on('getChats', (data: { data: Message[]; pagination: any }) => {
    //   const history = data.data;
    //   console.log("Chat history received:", data);
    //   setMessages(history);
    //   scrollToBottom();
    // });

    socket.on("receiveAdminMessage", (newMessage: Message) => {
      console.log("New message received:", newMessage);
      let newMessagecreatedAt = { createdAt: newMessage?.timestamp };
      let newMessageN = {
        ...newMessage,
        ...newMessagecreatedAt,
      };
      // setMessages((prevMessages) => {
      //   console.log('Previous messages:', prevMessages.length);
      //   return [...prevMessages, newMessageWithTime];
      // });
      setAllMessages((prevMessages) => {
        console.log("prevMessages", prevMessages.length);
        const updatedMessages = [...prevMessages, newMessageN];
        socket.emit("markAsAdminSeen", {
          senderId: storage.getAuthUserId(),
          isAdmin: false,
        });
        return updatedMessages;
      });
      scrollToBottom();
    });
    socket.on("adminMessagesSeen", ({ roomId, receiverId, isSeen }) => {
      console.log(
        `Messages seen for room ${roomId} by receiver ${receiverId}:`,
        isSeen
      );
      // Handle UI updates if necessary, e.g., marking messages as seen in the UI
    });
    return () => {
      socket.off("adminMessageHistory");
      socket.off("userJoined");
      socket.off("getChats");
      socket.off("receiveAdminMessage");
      socket.off("adminMessagesSeen");
    };
  }, [socket]);

  const handleMessageSend = () => {
    if (newMessage.trim() && socket) {
      scrollToBottom();
      const message = {
        // _id: Date.now().toString(), // Ensure unique ID
        message: newMessage.trim(),
        createdAt: new Date().toISOString(),
        senderId: storage.getAuthUserId(),
        receiverId: undefined,
        isAdmin: false,
      };
      // setMessages((prevMessages) => [...prevMessages, newMessage]);
      console.log("Sending message:", message);
      socket.emit("sendAdminMessage", message);
      setNewMessage(""); // Clear the input field
    }
  };

  const handleMessageSendOnEnter = (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleMessageSend();
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  const scrollToBottom = () => {
    if (messages?.length <= 10)
      scrollToBottomRef.current?.scrollIntoView({
        block: "end",
        inline: "nearest",
      });
  };

  // const getFormattedTime = (dateString:any) => {
  //   const date = new Date(dateString);
  //   return `${format(date, 'hh:mm a')}${isToday(date) ? ', Today' : ''}`;
  // };
  useEffect(() => {
    const fetchProfilePicture = async () => {
      try {
        const response: any = await getuserProfile();
        if (response.data?.profilePicture) {
          setProfilePicture(response?.data?.profilePicture);
        }
      } catch (error) {
        console.error("Failed to fetch profile picture:", error);
      }
    };
    fetchProfilePicture();
  }, []);

  return (
    <div className={styles.chatmodule}>
      <div className="h-100">
        <div className="row justify-content-center h-100">
          <div className={styles.chat}>
            <div className={styles.card}>
              <div className={styles.card_header}>
                <div className={styles.msg_head}>
                  <div className={styles.bdhighlight}>
                    <div className={styles.img_cont}>
                      <img
                        src="https://static.turbosquid.com/Preview/001292/481/WV/_D.jpg"
                        className={styles.user_img}
                        alt="User"
                      />
                      <span className={styles.online_icon}></span>
                    </div>
                    <div className={styles.user_info}>
                      <span>Admin</span>
                      <p>Active 1h ago</p>
                    </div>
                  </div>
                  <div className={styles.closetag} onClick={onClose}>
                    <span>
                      <i className="fas fa-times cursor-pointer"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="card-body">
                
                <div className={styles.msg_card_body}>
                {messagess.length === 0 && <p>No messages to display.</p>}
                  {messagess.map((msg, index) => (
                    <div
                      key={index}
                      className={`d-flex mb-4 ${
                        msg.senderId === storage.getAuthUserId()
                          ? "justify-content-end"
                          : "justify-content-start"
                      }`}
                    >
                      {msg.senderId !== storage.getAuthUserId() && (
                        <div className={styles.img_cont_msg}>
                          
                          <img
                            src="https://static.turbosquid.com/Preview/001292/481/WV/_D.jpg"
                            className={styles.user_img_msg}
                            alt="User"
                          />
                          {/* <span className="text-10">Admin</span> */}
                        </div>
                      )}
                      <div
                        className={
                          msg.senderId === storage.getAuthUserId()
                            ? styles.msg_cotainer_send
                            : styles.msg_cotainer
                        }
                      >
                        <label>{msg.message}</label>
                        <span
                          className={
                            msg.senderId === storage.getAuthUserId()
                              ? styles.msg_time_send
                              : styles.msg_time
                          }
                        >
                          {/* {getFormattedTime(msg.createdAt)}  */}
                          {formatDistanceToNow(new Date(msg.createdAt), {
                            addSuffix: true,
                          })}
                        </span>
                      </div>
                      {msg.senderId === storage.getAuthUserId() && (
                        <div className={styles.img_cont_msg}>
                          {/* <span>User</span> */}
                          {/* <img
                            src="https://static.turbosquid.com/Preview/001292/481/WV/_D.jpg"
                            className={styles.user_img_msg}
                            alt="User"
                          /> */}
                          {profilePicture ? (
                            <img
                              src={profilePicture}
                              className={styles.user_img_msg}
                              alt="Profile"
                              height={48}
                              width={48}
                            />
                          ) : (
                            <img src={chatone} alt="" />
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                  <div ref={scrollToBottomRef} />
                </div>
              </div>
              <div className={styles.card_footer}>
                <div className={`position-relative ${styles.cardfootertextarea}`}>
                  <textarea
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyDown={handleMessageSendOnEnter}
                    className="form-control type_msg"
                    placeholder="Type your message..."
                  />

                  <div className={`position-absolute d-flex align-items-center justify-content-end gap-3 ${styles.micsend}`}>
                    <div className={styles.mic}><i><img src={mic} alt="" className="" /></i></div>
                    <div className={styles.send}><i><img src={send} alt="" className="" /></i></div>                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatModule;
