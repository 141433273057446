import React, { useEffect, useState } from "react";
import styles from "./styles/style.module.css";
import userlog from "../../assets/images/userlog.png";
import loc from "../../assets/images/icon_location_filled.svg";
import Header from "../header/routes";
import dummy from "../../assets/images/dummy.jpeg";
import revies from "../../assets/images/revies.png";
import stars from "../../assets/images/stars.png";
import orbag from "../../assets/images/orbag.png";
import grbag from "../../assets/images/grbag.png";
import { Link, useParams } from "react-router-dom";
import { getUserProfileById } from "./api";
import { UserProfileByIdData } from "./interface";
import { toast } from "react-toastify";
import Swal from 'sweetalert2';
import { sendRequest } from "../ConnectFrnds/api";
import { MESSAGES } from "../../utils/messages";

const UserProfile = () => {
  const { id } = useParams();
  const [getUserProfile, setUserProfile] = useState<UserProfileByIdData>();
  const [loading, setLoading] = useState<boolean>(true);
  const [requestedConnections, setRequestedConnections] = useState<Set<string>>(new Set());

  const userprofileData = async () => {
    const response = await getUserProfileById(id as string);
    console.log("User Profile data", response.data);
    setUserProfile(response?.data);
    setLoading(false);
  };

  useEffect(() => {
    userprofileData();
  }, []);

  const handleConnect = async (id: string) => {
    const payload = {
      connectionId: id,
    };
    const initialResult = await Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to send a connection request?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Connect!',
      cancelButtonText: 'No, cancel',
    });
    if (initialResult.isConfirmed) {
      try {
        await toast.promise(sendRequest(payload), {
          pending: MESSAGES.SEND_REQ.PENDING,
          success: MESSAGES.SEND_REQ.SUCCESS,
          error: MESSAGES.SEND_REQ.ERROR,
        });

        setRequestedConnections(prev => new Set(prev.add(id)));
        await Swal.fire({
          title: 'Request Sent',
          text: 'Your connection request has been successfully sent.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      } catch (err) {
        console.log(err);
      }
    }
  }

  return (
    <>
      <Header />
      {loading ? (
        <div className="spinner-grow text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <div className="container">
          <div className={styles.UserProfileOuter}>
            <div className={styles.UerImage}>
              <figure><img
                src={
                  getUserProfile?.profilePicture
                    ? getUserProfile?.profilePicture
                    : dummy
                }
                alt=""
              />
              </figure>
              <h6>
                {getUserProfile?.name} <span>4.5</span>{" "}
                <i className="fas fa-star"></i>
              </h6>
              <h5>{getUserProfile?.occupation?.name}</h5>
              <h4 className="d-flex align-items-center justify-content-start">
                <i className="me-2"><img src={loc} alt="" /></i>
                {getUserProfile?.country?.name}{" "}
              </h4>
              <p>{getUserProfile?.bio}</p>
              <div className={styles.payRates}>
                <span>Pay Rates</span>
                <ul className="mb-2">
                  <li>
                    <h6 className="fw-semibold">Base</h6>
                    <label>${getUserProfile?.rates?.baseRate}</label>
                  </li>
                  <li>
                    <h6 className="fw-semibold">Overtime</h6>
                    <label>${getUserProfile?.rates?.overTimeRate}</label>
                  </li>
                  <li>
                    <h6 className="fw-semibold">Sun/Holidays</h6>
                    <label>${getUserProfile?.rates?.doubleTimeRate}</label>
                  </li>
                </ul>
              </div>
              <div className={styles.SipBtns}>
                <button className={` ${requestedConnections.has(getUserProfile?._id as string)
                    ? "btn-white border text-grey"
                    : "btn-secondary text-white"

                  }`} onClick={() =>
                    !requestedConnections.has(getUserProfile?._id as string) &&
                    handleConnect(getUserProfile?._id as string)
                  }>
                  {requestedConnections.has(getUserProfile?._id as string)
                    ? "Requested"
                    : "+ Connect"}
                </button>
                <button className={styles.availble}>Available</button>
                <span>
                  <i className="fas fa-ellipsis-h"></i>
                </span>
              </div>
            </div>
          </div>
          <div className={styles.aboutProfile}>
            <h4 className="fw-semibold">About</h4>
            <p>{getUserProfile?.bio ? getUserProfile.bio : "No data available"}</p>
          </div>

          <div className={styles.aboutProfile}>
            <h4 className="fw-semibold">Technical Skills </h4>
            <ul className="skillsTab">
              {getUserProfile?.skilledTrade && getUserProfile.skilledTrade.length > 0 ? (
                getUserProfile.skilledTrade.map((data, idx) => (
                  <li key={idx}>
                    <span>{data?.name}</span>
                  </li>
                ))
              ) : (
                <li>No data available</li>
              )}
            </ul>
          </div>
          <div className={styles.aboutProfile}>
            <h4 className="fw-semibold">Industries </h4>
            <ul className="skillsTab">
              {getUserProfile?.industries && getUserProfile.industries.length > 0 ? (
                getUserProfile.industries.map((data, idx) => (
                  <li key={idx}>
                    <span>{data?.name}</span>
                  </li>
                ))
              ) : (
                <li>No data available</li>
              )}
            </ul>

          </div>
          <div className={styles.aboutProfile}>
            <h4 className="fw-semibold">Occupation </h4>
            <ul className="skillsTab">
              <li>
                <span>{getUserProfile?.occupation?.name ? getUserProfile?.occupation?.name : "No data available"}</span>
              </li>
            </ul>
          </div>

          <div className={`${styles.aboutProfile} ${styles.Ediuca}`}>
            <h4 className="fw-semibold">Educations, Certificates and training</h4>
            <ul className={`${styles.OccupationTab} ${styles.InterviewQueston}`}>
              {getUserProfile?.educations && getUserProfile.educations.length > 0 ? (
                getUserProfile.educations.map((data, idx) => (
                  <li key={idx}>
                    <span>{/* <img src={userlog} alt="" /> */}</span>
                    <div>
                      <h6>{data?.schoolDetails?.name || "No school name provided"}</h6>
                      <h6>{data?.degreeDetails?.name || "No degree name provided"}</h6>
                      <h6>
                        {data?.specializationDetails?.name || "No specialization provided"}
                      </h6>
                      <h6>{data?.projects || "No projects provided"}</h6>
                      <h6>{data?.completionYear || "No completion year provided"}</h6>
                    </div>
                  </li>
                ))
              ) : (
                <li>No data available</li>
              )}
            </ul>

          </div>
          <div className={styles.aboutProfile}>
            <h4 className="fw-semibold">Social Media </h4>
            <ul className="row align-items-center justify-content-start g-3 social-media">
              <li>
                Instagram:{" "}
                <Link to={`${getUserProfile?.instagramLink}`}>
                  {getUserProfile?.instagramLink}
                </Link>
              </li>
              <li>
                LinkedIn:{" "}
                <Link to={`${getUserProfile?.linkedinLink}`}>
                  {getUserProfile?.linkedinLink}
                </Link>
              </li>
              <li>
                Facebook:{" "}
                <Link to={`${getUserProfile?.facebookLink}`}>
                  {getUserProfile?.facebookLink}
                </Link>
              </li>
              <li>
                Twitter:{" "}
                <Link to={`${getUserProfile?.twitterLink}`}>
                  {getUserProfile?.twitterLink}
                </Link>
              </li>
            </ul>
          </div>
          <div className={styles.ProfileReview}>
            <h4 className="fw-semibold">Reviews</h4>
            <ul className="mt-3 review_media">
              <li>
                <div className={styles.reviesTop}>
                  <span>
                    <span>
                      <img src={revies} alt="" />
                    </span>
                  </span>
                  <div>
                    Legacy Industries | Robot Programmer - Jimmy G
                    {getUserProfile?.reviews}
                    <img src={stars} alt="" />
                  </div>
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  dapibus eros eu vehicula interdum. Cras nec ultricies massa.
                  Curabitur rutrum, diam id consequat consequat. Lorem ipsum
                  dolor sit amet, consectetur adipiscing elit. Sed dapibus eros
                  eu vehicula interdum. Cras nec ultricies massa. Curabitur
                  rutrum, diam id consequat consequat.
                </p>
              </li>
            </ul>
            {/* <div className={styles.SeeAll}>
            <Link to="#">See all reviews</Link>
          </div> */}
          </div>
          <div className={styles.aboutProfile}>
            <h4 className="fw-semibold">
              Endorsements <i className="fas fa-plus-circle"></i>
            </h4>
            <ul>
              <li className={styles.active}>
                <Link to="#">Received</Link>
              </li>
              <li>
                <Link to="#">Given</Link>
              </li>
            </ul>
            <div className={styles.Endrosement}>
              <span>
                {" "}
                <img src={getUserProfile?.profilePicture ? getUserProfile?.profilePicture : dummy} alt="" />
              </span>
              <div>
                <h6>{getUserProfile?.name}</h6>
                <h5>{getUserProfile?.occupation?.name}</h5>
                <span>2 months ago</span>
              </div>
            </div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed luctus,
              augue eget scelerisque efficitur.Lorem ipsum dolor sit amet,
              consectetur adipiscing elit. Sed luctus, augue eget scelerisque
              efficitur.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Sed luctus, augue eget scelerisque efficitur.Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. Sed luctus, augue eget
              scelerisque efficitur.Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Sed luctus, augue eget scelerisque efficitur.Lorem
              ipsum dolor sit amet, consectetur adipiscing elit. Sed luctus, augue
              eget scelerisque efficitur.
            </p>
            <div className={styles.SeeAll}>
              <Link to="#">See all reviews</Link>
            </div>
          </div>

          <div className={styles.aboutProfile}>
            <h4 className="fw-semibold">Applications</h4>
            <div className="row">
              <div className="col-md-4">
                <div className={styles.ApplicatioInner}>
                  <h4>
                    Robot Programming{" "}
                    <span className={styles.Open}>(Open)</span>
                  </h4>
                  <span className="d-flex align-items-center justify-content-start">
                    <i className="me-2"><img src={loc} alt="" /></i> City, State
                  </span>
                  <label className="d-inline-flex align-items-center justify-content-start px-3">
                    <i className="me-2"><img src={orbag} alt="" /></i> Direct Job
                  </label>
                  <h6>total Applications(5)</h6>
                </div>
              </div>
              <div className="col-md-4">
                <div className={styles.ApplicatioInner}>
                  <h4>
                    Robot Programming{" "}
                    <span className={styles.close}>(Close)</span>
                  </h4>
                  <span className="d-flex align-items-center justify-content-start">
                    <i className="me-2"><img src={loc} alt="" /></i> City, State
                  </span>
                  <label className="d-inline-flex align-items-center justify-content-start px-3">
                    <i className="me-2"><img src={orbag} alt="" /></i> Direct Job
                  </label>
                  <h6>total Applications(5)</h6>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.aboutProfile}>
            <h4 className="fw-semibold">Interview Question</h4>
            <ul
              className={`${styles.OccupationTab} ${styles.InterviewQueston}`}
            >
              <li>
                <span>
                  <img src={dummy} alt="" />
                </span>
                <div>
                  <h6>Willing to work more than 60 miles from home city</h6>
                </div>
              </li>

              <li>
                <span>
                  <img src={dummy} alt="" />
                </span>
                <div>
                  <h6>Willing to work more than 60 miles from home city</h6>
                </div>
              </li>
              <li>
                <span>
                  <img src={dummy} alt="" />
                </span>
                <div>
                  <h6>Willing to work more than 60 miles from home city</h6>
                </div>
              </li>
            </ul>
          </div>
          <div className={styles.aboutProfile}>
            <h4 className="fw-semibold">Work Experiences</h4>
            <ul className={styles.OccupationTab}>
              {getUserProfile?.workExperiences && getUserProfile.workExperiences.length > 0 ? (
                getUserProfile.workExperiences.map((data, idx) => (
                  <li key={idx}>
                    <span>
                      {/* <img src={userlog} alt="" /> */}
                    </span>
                    <div>
                      <h6>{data?.occupation?.name}</h6>
                      <h5>{data?.company?.name}</h5>
                      <h4>
                        <span>{data?.from}</span> <span>{data?.to}</span>
                      </h4>
                      <p>{data?.description}</p>
                    </div>
                  </li>
                ))
              ) : (
                <li>No data available</li>
              )}
            </ul>

          </div>
          <div className={styles.aboutProfile}>
            <h4 className="fw-semibold">
              Endorsements <i className="fas fa-plus-circle"></i>
            </h4>
            <ul>
              <li className={styles.active}>
                <Link to="#">Received</Link>
              </li>
              <li>
                <Link to="#">Given</Link>
              </li>
            </ul>
            <div className={styles.Endrosement}>
              <span>
                {" "}
                <img
                  src={
                    getUserProfile?.profilePicture
                      ? getUserProfile?.profilePicture
                      : dummy
                  }
                  alt=""
                />
              </span>
              <div>
                <h6>{getUserProfile?.name}</h6>
                <h5>{getUserProfile?.occupation?.name}</h5>
                <span>2 months ago</span>
              </div>
            </div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              luctus, augue eget scelerisque efficitur.Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. Sed luctus, augue eget
              scelerisque efficitur.Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Sed luctus, augue eget scelerisque
              efficitur.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Sed luctus, augue eget scelerisque efficitur.Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. Sed luctus, augue eget
              scelerisque efficitur.Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Sed luctus, augue eget scelerisque efficitur.
            </p>
            <div className={styles.SeeAll}>
              <Link to="#">See all Endorsements</Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserProfile;
