import { axios } from "../../../lib/axios";
import { TYPE } from "../../../utils/enums/types";
import { API_ROUTES } from "../../../utils/routes/apiRoutes";
import { PostEvents, PostEventsResponse } from "../interface";

export const addEvents = (payload: PostEvents): Promise<any> => {
    return axios.post(API_ROUTES.EVENTS_CALENDAR.ADD_EVENTS, payload);
};

export const getEvents = (type: string): Promise<any> => {
    if (type == TYPE.MONTH) {
        return axios.get(API_ROUTES.EVENTS_CALENDAR.GET_EVENTS, {
            params: {
                type,
                month: new Date().getMonth() + 1,
                year: new Date().getFullYear(),
            }
        });
    }
    return axios.get(API_ROUTES.EVENTS_CALENDAR.GET_EVENTS, { params: { type, limit: 5 } });
};

export const getInterviewData = (eventType: string): Promise<any> => {
    return axios.get(API_ROUTES.EVENTS_CALENDAR.GET_EVENTS, {params:{ eventType}});
}