import React, { useState } from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../../assets/images/penbl.png";
import tip from "../../../../assets/images/tip.png";
import MarketingContModal from "../modals/MarketingContModal";
import { MarketingContacts } from "../../interface";
interface MarketingContactProps {
  MarketingContacts: MarketingContacts[];
  refresh: () => void;
}

const MarketingContact: React.FC<MarketingContactProps> = ({ refresh, MarketingContacts }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [editData, setEditData] = useState<MarketingContacts | null>(null);
  const [indexNumber, setIndexNumber] = useState<number | null>(null);
  const openModal = (data?: MarketingContacts, index?: number) => {
    setEditData(data || null);

    if (index !== null && index !== undefined && index >= 0) {
      setIndexNumber(index);
    }
    setShowModal(true);
  }
  const closeModal = () => { setShowModal(false) };
  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-between mb-3">
        <div className="col-10">
          <div className="topTipHover">
            <div className="d-flex align-items-center">
              <h4 className="text-20 fw-bold  font-inter mb-0">
                Marketing Contact
              </h4>
              <i>
                <img className="customeTolImg" src={tip} alt="" />
              </i>
            </div>
          </div>
        </div>
        {/* <div className="col-2 text-end">
          <span>
            <img src={penbl} alt="" className="cursor-pointer ms-auto" onClick={openModal}/>
          </span>
        </div> */}
      </div>
      <div className={styles.personalinfo}>
        {MarketingContacts?.length === 0 ? (
          <p>No Accounts found.</p>
        ) : (
          MarketingContacts?.map((admin, index) => (
            <ul className="row align-items-center rounded-2 border bg-light p-2 mx-0 mb-2" key={index}>
              <li className="col-12">
                <img
                  src={penbl}
                  alt="Edit"
                  className="cursor-pointer ms-auto"
                  onClick={() => openModal(admin, index)}
                />
              </li>
              <li className="col-xl-4 col-lg-6 col-12">
                <span>Name</span>
                <label className="clamp clamp1">{admin.name}</label>
              </li>
              <li className="col-xl-4 col-lg-6 col-12">
                <span>Email</span>
                <label className="clamp clamp1">{admin.email}</label>
              </li>
              <li className="col-xl-4 col-lg-6 col-12">
                <span>Phone</span>
                <label className="clamp clamp1">{admin.phoneNumber}</label>
              </li>
              <li className="col-xl-4 col-lg-6 col-12">
                <span>City</span>
                <label className="clamp clamp1">{admin.city}</label>
              </li>

              {/* Add Edit Icon */}

            </ul>
          ))
        )}

        {/* Add more button */}
        <div className="mt-2">
          <h4 className="text-14 fw-semibold text-blue cursor-pointer" onClick={() => openModal()}>
            + Add more
          </h4>
        </div>
      </div>
      <MarketingContModal showModal={showModal}
        refresh={refresh}
        closeModal={closeModal}
        title="Edit Marketing Contract Modal"
        editData={editData}        // Pass the selected data for editing
        index={indexNumber}
        marketingData={MarketingContacts}
      />

    </div>
  );
};

export default MarketingContact;
