import React, { useEffect, useState } from "react";
import Header from "../../header/routes";
import { useNavigate } from "react-router-dom";
import Subheader from "../../subheader/routes";
import { getRecommendedWork } from "../api";
import { FEED } from "../../../utils/feed";
import { ReccommendedWork } from "../interface";
import { getuserProfile } from "../../home/api";
import styles from "../styles/style.module.css";
import PeopleAvailable from "../components/PeopleAvailable";
import UserProfile from "../components/UserProfile";
import FindContract from "../components/FindContract";
import FindProfessional from "../components/FindProfessional";
import FindJob from "../components/FindJob";
import RecomendedContractsForYou from "../components/RecomendedContractsForYou";
import AvailableForWork from "../components/AvailableForWork";
import RecomendedJobsForYou from "../components/RecomendedJobsForYou";
import ProfessionalsComp from "../components/ProfessionalsComp";
import SearchOnMap from "../components/SearchOnMap";
import GoogleAds from "../components/GoogleAds";
import ConnectWithFriendsComp from "../components/ConnectWithFriendsComp";
import PostsComp from "../components/PostsComp";
import PlaceAndAds from "../components/PlaceAndAds";
import PostYourAds from "../components/PostYourAds";
import CompletedContractsComp from "../components/CompletedContractsComp";
import OngoingContractsComp from "../components/OngoingContractsComp";
import BoostProfile from "../components/BoostProfile";
import EndorseFriends from "../components/EndorseFriends";
import AutomateToHomeScreen from "../components/AutomateToHomeScreen";
import MyFriendsComp from "../components/MyFriendsComp";
import Apprenticeships from "../components/Apprenticeships";
import AdvertiseYourSchool from "../components/AdvertiseYourSchool";
import TechSchoolAds from "../components/TechSchoolAds";
import LetsTalkWithUs from "../components/LetsTalkWithUs";
import ChatWithAdmin from "../components/ChatWithAdmin";
import FollowUsOn from "../components/FollowUsOn";
import MyTeamsComp from "../components/MyTeamsComp";
import AddMissingInfo from "../components/AddMissingInfo";
import WhatsNew from "../components/WhatsNew";
import AdminAnnouncementComp from "../components/AdminAnnouncementComp";
import CurrentData from "../components/CurrentData";
import ManageConnections from "../components/ManageConnections";
import LoginSucessModal from "../../../shared/CustomModal/LoginSuccessModal";
import { FeedsProps } from "../interface";
import { setBusinessDetails } from "../../../redux/reducers/businessDetails";
import { useDispatch } from "react-redux";
import { setBusinessGroupData } from "../../../redux/reducers/businessGroupData";
import storage from "../../../utils/storage";


const Feeds: React.FC<FeedsProps> = ({ showHeader = true, showShoulder = true }) => {
  // const[showModal,setShowModal]=useState<boolean>(false);
  const [contracts, setContracts] = useState<ReccommendedWork[]>([]);
  const [rfq, setRfq] = useState<ReccommendedWork[]>([]);
  const [jobs, setJobs] = useState<ReccommendedWork[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [profileDetetails, setProfileDetails] = useState<any>();
  const dispatch=useDispatch()

  useEffect(() => {
    //setShowModal(true);
    refresh();    
  }, []);

  useEffect(() => {
    if (!loading) {
      window.scrollTo(0, 0);
    }
  }, [loading]);

  const refresh = () => {
    setLoading(true);
    getRecommendedWork(FEED.WORK_TYPES.RFQ ).then((res) => {
      setRfq(res.data);
      window.scrollTo(0, 0);
    });
    getRecommendedWork(FEED.WORK_TYPES.DIRECT_JOB ).then((res) => {
      setJobs(res.data);
      window.scrollTo(0, 0);
    });
    getRecommendedWork(FEED.WORK_TYPES.HOURLY_CONTRACT).then(
      (res) => {
        setContracts(res.data);
        setLoading(false);
        window.scrollTo(0, 0);
      }
    );
    getuserProfile().then((res) => {
      storage.setUserProfileImg(res?.data?.profilePicture);
      dispatch(setBusinessDetails({businessName:res.data?.businessDetail?.businessName,_id:res.data?.businessDetail?._id, groupName:res.data?.businessDetail?.group}))
      dispatch(setBusinessGroupData(res.data?.businessGroupData));  
      setProfileDetails(res);
      setLoading(false);
      window.scrollTo(0, 0);
    });

   
  };

  return (
    <>
      <div className="feeds">
        {showHeader && <Header />}
        {showShoulder && <Subheader />}
        {/* {showModal && <LoginSucessModal/>} */}

        {!loading ? (
          <>
            <div className={styles.feeds}>
              <div className="container">
                <PeopleAvailable />
              </div>
            </div>
            <div className={styles.ProfileDetails}>
              <div className="container pt-5">
                <div className="row align-items-start justify-content-center">
                  <UserProfile refresh={refresh}/>
                  <div className="col-lg-5 col-12">
                    <div className="">
                      <ul className="ps-0">
                        <FindContract />
                        <FindProfessional />
                        <FindJob />
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <RecomendedContractsForYou />
            <AvailableForWork />
            <RecomendedJobsForYou />
            <ProfessionalsComp />
            <SearchOnMap />
            <GoogleAds />
            <ConnectWithFriendsComp />
            <ManageConnections />
            <PostsComp />
            <PlaceAndAds />
            <PostYourAds />
            <CompletedContractsComp />
            <OngoingContractsComp />
            <BoostProfile />
            <EndorseFriends />
            <AutomateToHomeScreen />
            <MyFriendsComp />
            {/* <Apprenticeships /> */}
            {/* <AdvertiseYourSchool /> */}
            {/* <TechSchoolAds /> */}
            <LetsTalkWithUs />
            <ChatWithAdmin />
            <FollowUsOn />
            <MyTeamsComp />
            <AddMissingInfo />
            <WhatsNew />
            <AdminAnnouncementComp />
            <CurrentData />
          </>
        ) : (
          <>
            <div className={`text-center ${styles.spinner}`}>
              <div className="spinner-border text-blue" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Feeds;
