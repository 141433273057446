import React, { useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import tip from "../../../../assets/images/tip.png";
import penbl from "../../../../assets/images/penbl.png";
import selfrating from "../../../../assets/images/self_rating.svg";
import thumb from "../../../../assets/images/profile_picture.png";
import TopSkillModal from "../modals/TopSkillModal";
import { TechSkills } from "../../interface";
import { Link } from "react-router-dom";

interface ModalProps {
  topSkill: TechSkills[];
  refresh: () => void;
}

const TopSkill: React.FC<ModalProps> = ({ topSkill = [], refresh }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editData, setEditData] = useState<string | null>(null);
  const [indexNumber, setIndexNumber] = useState<number | null>(null);
  const [skillName, setSkillName] = useState("");
  const [about, setAbout] = useState<any>("");
  const [manufacturer, setManufacturer] = useState("");
  const [model, setModel] = useState("");
  const [img, setImg] = useState<any>("");
  // const [topObj,setTopObj] = useState<object>({})

  const topSkillData = () => {
    topSkill?.map((data, ind) => {
      if (data?.type === "top") {
        console.log("type", data);
        setSkillName(data?.occupationDetail?.name);
        setAbout(data?.description);
        setManufacturer(data?.manufacturerDetail?.name);
        setModel(data?.manufacturerModelDetail?.name);
        setImg(data?.image);
      }
    });
  };

  useEffect(() => {
    topSkillData();
  }, []);

  const openModal = (data?: string, index?: number) => {
    setEditData(data || null); // Set data to edit or null for new entry
    setIsModalOpen(true);
    console.log("index---", index);
    if (index !== null && index !== undefined && index >= 0) {
      console.log("index-if", index);
      setIndexNumber(index);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditData(null); // Clear data when closing modal
  };

  return (
    <div className={styles.AboutEdit}>
      <div className=" mt-3">
        <div className="row align-items-center">
          <div className="col-9">
            <div className="topTipHover">
              <div className="d-flex align-items-center">
                <h4 className="text-20 fw-bold  font-inter mb-0">Top Skill*</h4>
                <i>
                  <img className="customeTolImg" src={tip} alt="" />
                </i>
              </div>
            </div>
          </div>
          <div className="col-3 text-end">
            <i>
              {skillName ? (
                <img
                  src={penbl}
                  className="cursor-pointer ms-auto"
                  alt=""
                  onClick={() => openModal()}
                />
              ) : (
                <Link to={""} onClick={() => openModal()} className="fw-normal text-blue fst-normal fw-semibold">
                  Add Skill
                </Link>
              )}
            </i>
          </div>
        </div>
        {skillName ? (
          <div>
            <div className={styles.personalinfo} style={{ marginTop: "20px" }}>
              <span className="d-block text-14">Skill Name*</span>
              <label>{skillName}</label>
            </div>
            <div className="newheadingstyle mt-3">
              <h4 className="text-16 fw-semibold mb-2">About</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: about ? about : "",
                }}
              />
              <h5 className="text-14">
                Self Rating :{" "}
                <i>
                  <img src={selfrating} alt="" />
                </i>
              </h5>
              <ul className={styles.personalinfo} style={{ marginBottom: "0" }}>
                <li className="mb-3">
                  <span className="d-block text-14">Manufacturer</span>
                  <label>{manufacturer}</label>
                </li>
                <li className="mb-3">
                  <span className="d-block text-14">
                    Model/Version/Controller
                  </span>
                  <label>{model}</label>
                </li>
                <li className="mb-3">
                  <span className="d-block text-14">Image</span>
                  <img
                    src={`${img}`}
                    alt=""
                    className="rouned-2"
                    width={100}
                    height={100}
                  />
                </li>
              </ul>
            </div>
          </div>
        ) : (
          "You don't add other skill yet!"
        )}
      </div>
      <TopSkillModal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="Edit Top Skill"
        topSkill={topSkill}
        refresh={refresh}
        about={about}
      />
    </div>
  );
};

export default TopSkill;
