import React from "react";
import { Dropdown, FormControl, ModalBody, ModalFooter } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import blackarrow from "../../../../assets/images/blackarrow.svg";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import upload from "../../../../assets/images/upload.svg";
import styles from "../styles/style.module.css";

interface ModalProps {
  showModal: boolean;
  closeModal: () => void;
  title: string;
}

const EditBusinessGroupModal: React.FC<ModalProps> = ({
  showModal,
  closeModal,
  title,
}) => {
  return (
    <Modal show={showModal} onHide={closeModal} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <form>
        <ModalBody>
          <div className="addModalPro">
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Business Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Business Name"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Name of Facility</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name of Facility"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Business Location</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Business Location"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Country*</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Country"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Street*</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Street"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>City*</label>
                  <input
                    type="tel"
                    className="form-control"
                    placeholder="City"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>State*</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="state"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>ZipCode*</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="ZipCode"
                  />
                </div>
              </div>
              <div className="col-lg-12 col-12">
                <div className="formGroup">
                  <label>Upload Image</label>
                  <div className={styles.uploadimagefile}>
                    <input
                      type="file"
                      accept="image/*"
                      className="form-control"
                      placeholder="eg : Version 1.4, IRC5, Mustang Etc."
                      // onChange={({ target }) =>
                      //   handleFileUpload(target.files)
                      // }
                    />
                    <div className={styles.uploadimage}>
                      <i>
                        <img src={upload} alt="" />
                      </i>
                      <p className="mb-0 text-14 text-blue">
                        Upload jpeg/png file
                      </p>
                    </div>
                  </div>
                  {/* {img && (
                      <li>
                        <div className={styles.uploadImages}>
                          <img src={img} alt="" className="img-fluid" />
                          <i
                            className="fas fa-times"
                            onClick={removeUploadedFiles} // Call the function to remove the image
                          ></i>
                        </div>
                      </li>
                    )} */}
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="btn-hover-effect">
          <Button
            type="button"
            variant="secondary"
            onClick={closeModal}
            className="btn bg-white arrowyellow border border-1 rounded-3 position-relative ps-5 backbutton w-auto"
          >
            <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
              Back
            </span>
            <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
              <img
                src={blackarrow}
                alt=""
                width={15}
                height={15}
                style={{ transform: "rotate(-180deg)" }}
              />
            </i>
          </Button>
          <Button
            type="button"
            variant="secondary"
            onClick={closeModal}
            className="btn bg-white arrowblue border border-1 rounded-3 position-relative pe-5 w-auto"
          >
            <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
              Save
            </span>
            <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
              <img src={whitearrow} alt="" width={15} height={15} />
            </i>
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditBusinessGroupModal;
