import React from "react";
import styles from "./styles/style.module.css";
import adminchatlogo from "../../../assets/images/chatlogo.svg";

interface Props {
  onOpen: () => void;
}

const AdminChatIcon:React.FC<Props> = ({onOpen}) => {
  return (
    <div className={`bg-blue p-2 d-flex align-items-center ${styles.floatingIcon}`} onClick={onOpen}>
      <i><img src={adminchatlogo} alt="Chat Logo" /></i>
      <span className="ps-2 text-14 text-white">Chat with Admin</span>
    </div>
  );
};

export default AdminChatIcon;
