import React, { useEffect, useState } from 'react'
import moment from 'moment'
import styles from "../../expense/styles/style.module.css"
import tip from "../../../../assets/images/info_icon.svg"
import print from "../../../../assets/images/print.png"
import dnlod from "../../../../assets/images/dnlod.png"
import Header from '../../../header/routes'
import { useLocation, useParams } from 'react-router-dom'
import { getTimesheetById } from '../api'
import { GetTimesheetByIdResponse } from '../interface/getTimesheetById'
import { getMondayOfCurrentWeek, getWeekDaysMap } from '../../../../utils/daysMap'
import WhiteGloveContractDetails from '../../Contracts/whiteGloveHourlyContract/whileGloveHourlyContract'
import Toltip from '../../toltip/page'
import ExpenseDetails from '../../expense/expenseDetails'


const TimesheetStatus = () => {
    const { id } = useParams();
    const location = useLocation();
    const currentStatus = location?.state?.activeStatus
    const ongoingContractsDetails = location?.state?.currentOngoingData
    const [timeSheetStatusData, setTimesheetStatusData] = useState<GetTimesheetByIdResponse>()
    const [activeTab, setActiveTab] = useState(currentStatus);
    const [currentdata,setCurrentData]=useState<any>([])

    const approvedTimesheets = async () => {
        const response: any = await getTimesheetById(id);
        setTimesheetStatusData(response?.data);
    };

    useEffect(() => {
        approvedTimesheets();
        setCurrentData(ongoingContractsDetails)
        setActiveTab(currentStatus);
    }, [])

    const mondayDate = getMondayOfCurrentWeek();
    const daysMap = getWeekDaysMap();

    return (
        <>
            <Header />
            <div className={styles.pageOuter}>
                <div className='container'>


                    <div className={styles.rightContainer}>
                        <h2>Timesheet Details</h2>
                        <ul className={styles.timeSheetFilter}>

                            {['current', 'due', 'pending', 'approved'].includes(activeTab) && (
                                <li className={styles.active}>
                                    <span>
                                        {activeTab === 'current' && 'Current Timesheet'}
                                        {activeTab === 'due' && 'Due Timesheet'}
                                        {activeTab === 'pending' && 'Pending Timesheet'}
                                        {activeTab === 'approved' && 'Approved'}
                                    </span>
                                </li>
                            )}


                        </ul>
                        <div className={`${styles.baseInfo} ${styles.noMarg}`}>
                            <WhiteGloveContractDetails expenseDetails={currentdata} ongoingContractDetails={currentdata} />
                            <div
                                className={
                                    timeSheetStatusData?.status === "approved"
                                        ? styles.approved
                                        : styles.Starptimsshetet
                                }
                            >
                                <h3>
                                    {timeSheetStatusData?.status === "approved"
                                        ? "Approved"
                                        : timeSheetStatusData?.status === "pending"
                                            ? "Pending Approval"
                                            : timeSheetStatusData?.status === "draft"
                                                ? "Current"
                                                : timeSheetStatusData?.isDue === true
                                                    ? "Due"
                                                    : ""}
                                </h3>
                                <button>
                                    {timeSheetStatusData?.status === "approved"
                                        ? "Approved Timesheet"
                                        : timeSheetStatusData?.status === "pending"
                                            ? "Pending Timesheet"
                                            : timeSheetStatusData?.status === "draft"
                                                ? "Current Timesheet"
                                                : timeSheetStatusData?.isDue === true
                                                    ? "Due Timesheet"
                                                    : ""}
                                </button>
                            </div>
                            <div className={`rounded-4 p-0 border-0 ${styles.baseInfo}`}>
            <div className={`rounded-3 rounded-top-0 col-12 ${styles.timeSheetdetils} ${styles.noMarg}`}>
              <div className={`${styles.tabletimesheet}`}>
                <div className="row align-items-center mb-3">
                  <div className="col-4 topTipHover">
                    <div className="w-100 d-flex align-items-center">
                      <h4 className="form-check-label text-20 fw-bold mb-0 font-inter">
                        Timesheet details
                      </h4>
                      <i>
                        <img className="customeTolImg" src={tip} alt="" />
                      </i>
                      <Toltip />
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="d-flex align-items-center justify-content-end g-2">
                      <div className="px-1">
                        {" "}
                        <button className="border text-14 d-flex align-items-center py-2 px-3 rounded-pill font-inter fw-semibold">
                          <i className="me-2">
                            <img src={print} alt="" />
                          </i>{" "}
                          Print
                        </button>
                      </div>
                      <div className="px-1">
                        {" "}
                        <button className="border text-14 d-flex align-items-center py-2 px-3 rounded-pill font-inter fw-semibold">
                          <i className="me-2">
                            <img src={dnlod} alt="" />
                          </i>{" "}
                          Export Data
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive ${styles.tabletimesheet}">
                  <table className="table align-middle">
                    <tr>
                      <td>
                        <span className="fw-semibold">Professional : </span>
                      </td>
                      <td>
                        <span className="fw-normal">
                        {timeSheetStatusData?.user?.name}
                        </span>
                      </td>
                      <td colSpan={2} className=" text-center">
                        <span className="fw-semibold">Monday / Week Start Date</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="fw-semibold">Business Name :</span>
                      </td>
                      <td>
                        <span className="fw-normal">
                          ---
                        </span>
                      </td>
                      <td rowSpan={2} colSpan={2} className=" text-center">
                        <span className="fw-normal">{mondayDate}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="fw-semibold">Business Number :  </span>
                      </td>
                      <td>
                        <span className="fw-normal">47110</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="fw-semibold">Business Contact :</span>
                      </td>
                      <td>
                        <span className="fw-normal">
                        {timeSheetStatusData?.work?.user?.name}
                        </span>
                      </td>
                      <td>
                        <span className="fw-semibold">Business Email : </span>
                      </td>
                      <td>
                        <span className="fw-normal">
                          {timeSheetStatusData?.work?.user?.email}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span className="fw-semibold">Contract Number:</span>
                      </td>
                      <td colSpan={4}>
                        <span className="fw-normal">#{timeSheetStatusData?.work?.workNumber}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="fw-semibold">End Customer : </span>
                      </td>
                      <td colSpan={4}>
                        <span className="fw-normal">Jerry Vemia</span>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="table-responsive table-striped ${styles.tabletimesheet}">
                  <table className="table align-middle mb-0">
                    <thead>
                      <th>
                        <span className="fw-normal">Day</span>
                      </th>
                      <th>
                        <span className="fw-normal">Date</span>
                      </th>
                      <th>
                        <span className="fw-normal">Hours Worked Per Day</span>
                      </th>
                      <th>
                        <span className="fw-normal">Holiday</span>
                      </th>
                      <th>
                        <span className="fw-normal">Travel Time Hours</span>
                      </th>
                      <th>
                        <span className="fw-normal"> Total</span>
                      </th>
                    </thead>
                    <tbody>
                    {timeSheetStatusData?.timesheetDetails?.map((data, idx) => {
                    return (
                        <>
                      <tr>
                        <td className="text-center">
                          <span className="fw-semibold">Monday Work Report</span>
                        </td>
                        <td>
                          <span className="fw-semibold">{moment(data?.workedDate).format("DD-MM-YYYY")}</span>
                        </td>
                        <td>
                        {data?.hourPerDay}
                        </td>
                        <td>
                          <div className="checkboxes__item">
                            <label className="checkbox style-c">
                            <input
                                // disabled={isFutureDate}
                                type="checkbox"
                                checked={data?.isHoliday}
                                readOnly
                                
                              />
                              <div className="checkbox__checkmark"></div>
                            </label>
                          </div>
                        </td>
                        <td>
                        {data?.travelTime}
                        </td>
                        <td> {data?.total}</td>
                      </tr>
                      <tr>
                        <td colSpan={6} className="p-0 border-0 ">
                            {/* {data?.dailyStatus} */}
                            <textarea
                            // disabled={isFutureDate}
                            className="form-control w-100 bg-transparent"
                            placeholder="1"
                            >
                          </textarea>
                        </td>
                      </tr>
                      </>
                      );
                    })}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                <div className="col-6">
                    <div className={`table-responsive ${styles.tabletimesheet}`}>
                      <table className="table align-middle">
                          <tr>
                              <td><span className="fw-medium">Total Straight Time</span></td>
                              <td className="text-end"><span className="fw-normal">{timeSheetStatusData?.totalStraightTime}</span></td>                          
                          </tr>
                          <tr>
                              <td><span className="fw-medium">Total Over Time</span></td>
                              <td className="text-end"><span className="fw-normal">{timeSheetStatusData?.overTime}</span></td>
                          </tr>
                          <tr>
                              <td><span className="fw-medium">Sunday/Holiday</span></td>
                              <td className="text-end"><span className="fw-normal">---</span></td>
                          </tr>
                          <tr>
                              <td><span className="fw-medium">Total Double Time</span></td>
                              <td className="text-end"><span className="fw-normal">{timeSheetStatusData?.doubleTime}</span></td>
                          </tr>
                          <tr>
                              <td><span className="fw-medium">Total Travel TIme</span></td>
                              <td className="text-end"><span className="fw-normal">{timeSheetStatusData?.totalTravelTime}</span></td>
                          </tr>
                          <tr>
                              <td><span className="fw-medium">Total Hours</span></td>
                              <td className="text-end"><span className="fw-normal">{timeSheetStatusData?.totalHours}</span></td>                        
                          </tr>
                      </table>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className={`table-responsive ${styles.tabletimesheet}`}>
                      <table className="table align-middle">
                          <tr>
                              <td><span className="fw-semibold">Bill Rates</span></td>
                              <td className="text-end"><span className="fw-normal">{timeSheetStatusData?.ssaRates?.baseRate}</span></td>                          
                          </tr>
                          <tr>
                              <td><span className="fw-medium">Base Rate (per hour)</span></td>
                              <td className="text-end"><span className="fw-normal">
                              ${" "}
                              {timeSheetStatusData?.ssaRates?.baseRate}
                        </span></td>
                          </tr>
                          <tr>
                              <td><span className="fw-medium">OT Rate (per hour)</span></td>
                              <td className="text-end"><span className="fw-normal">
                              ${" "}
                              {timeSheetStatusData?.ssaRates?.overTimeRate}</span></td>
                          </tr>
                          <tr>
                              <td><span className="fw-medium">Sunday/Holiday</span></td>
                              <td className="text-end"><span className="fw-normal"> ${" "}
                              {timeSheetStatusData?.ssaRates?.doubleTimeRate}</span></td>
                          </tr>
                          <tr>
                              <td><span className="fw-medium">Travel Time Rate</span></td>
                              <td className="text-end"><span className="fw-normal">{timeSheetStatusData?.totalTravelTime}</span></td>                        
                          </tr>
                          <tr>
                              <td><span className="fw-medium">Travel Trips Allowed (per month)</span></td>
                              <td className="text-end"><span className="fw-normal">{timeSheetStatusData?.ssaRates?.travelTimeAllowed}</span>
                              </td>                        
                          </tr>
                          <tr>
                              <td><span className="fw-medium">Travel Trips Allowed (per month)</span></td>
                              <td className="text-end"><span className="fw-normal">-----</span></td>                        
                          </tr>
                      </table>
                    </div>
                  </div>
                </div>
                <div className={styles.finalDta}>
                  {/* <p>Approved By : Elinrew Johns</p> */}
                  <p className="mb-3 text-14 fw-semibold">Approved By : {timeSheetStatusData?.approvedBy?.name}</p>
                  <p className="text-14"> Approved Date:{" "}
                                        {timeSheetStatusData?.approvedDate
                                            ? moment(timeSheetStatusData.approvedDate).format("MM-DD-YYYY")
                                            : "N/A"}</p>
                  <p className="text-14 mb-3"> {timeSheetStatusData?.status === "pending" ?
                                        <p className='text-danger'>Pending For Approval</p>
                                        : ""}</p>
                  <p className="text-14">Approval Signature -----</p>
                </div> 
              </div>
            </div>
          </div>




                            {/* <div className={`${styles.timeSheetdetils} ${styles.noMarg}`}>
                                <div className="d-flex align-items-center">
                                    <h4 className="form-check-label text-20 fw-bold mb-0 font-inter">
                                    Timesheet Details
                                    </h4>
                                    <i>
                                        <img className="customeTolImg" src={info} alt="" />
                                    </i>
                                    <Toltip
                                    />
                                </div>
                                <div className={styles.personalDetials}>
                                    <div className={styles.topBrd}>
                                        <div className={styles.innerbrd}><b>Engineers :</b></div>
                                        <div className={styles.innerbrd}>{timeSheetStatusData?.user?.name}</div>
                                        <div className={styles.innerbrd}><b>Monday / Week Start Date</b></div>
                                    </div>
                                    <div className={styles.topBrd}>
                                        <div className={styles.innerbrd}><b>Customer Company Name</b></div>
                                        <div className={styles.innerbrd}>..</div>
                                        <div className={styles.innerbrd}>{mondayDate}</div>
                                    </div>
                                    <div className={styles.topBrd}>
                                        <div className={styles.innerbrd}><b>Customer # :</b></div>
                                        <div className={styles.innerbrd}>47110</div>
                                    </div>
                                    <div className={styles.topBrd}>
                                        <div className={styles.innerbrd}><b>Customer Contact : </b></div>
                                        <div className={styles.innerbrd}>{timeSheetStatusData?.work?.user?.name}</div>
                                        <div className={styles.innerbrd}><b>Customer Email : </b></div>
                                        <div className={styles.innerbrd}>{timeSheetStatusData?.work?.user?.email}</div>
                                    </div>
                                    <div className={styles.topBrd}>
                                        <div className={styles.innerbrd}><b>Work Number :  </b></div>
                                        <div className={styles.innerbrd}>#{timeSheetStatusData?.work?.workNumber}</div>
                                    </div>
                              
                                </div>

                                <div className={styles.personalDetials}>
                                    <div className={`${styles.Morefieldss} ${styles.topBrd}`}>
                                        <div className={styles.innerbrd}><b>Day</b></div>
                                        <div className={styles.innerbrd}><b>Date</b></div>
                                        <div className={styles.innerbrd}><b>Hours Worked Per Day</b></div>
                                        <div className={styles.innerbrd}><b>Sunday / Holidays</b></div>
                                        <div className={styles.innerbrd}><b>Travel Time Hours</b></div>
                                        <div className={styles.innerbrd}><b>Total</b></div>
                                    </div>

                                    {timeSheetStatusData?.timesheetDetails?.map((data, idx) => {
                                        return (
                                            <>
                                                <div key={idx} className={`${styles.topBrd} ${styles.Morefieldss}`}>

                                                    <div className={styles.innerbrd}><b></b></div>
                                                    <div className={styles.innerbrd}><b>{moment(data?.workedDate).format("DD-MM-YYYY")}</b></div>
                                                    <div className={styles.innerbrd}><b>{data?.hourPerDay}</b></div>
                                                    <div className={styles.innerbrd}>
                                                        <input
                                                            type="checkbox"
                                                            checked={data?.isHoliday}
                                                            readOnly
                                                        />
                                                    </div>

                                                    <div className={styles.innerbrd}><b>{data?.travelTime}</b></div>
                                                    <div className={styles.innerbrd}><b>{data?.total}</b></div>
                                                </div>
                                                <div className={`${styles.topBrd} ${styles.brdColor}`}>
                                                    <div className={styles.innerbrd}> Work Report</div>
                                                    <div className={styles.innerbrd}>{data?.dailyStatus}</div>
                                                </div>
                                            </>
                                        )
                                    })}




                                </div>

                                <div className={styles.billingDetails}>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className={styles.billingDeailLeft}>
                                                <ul>
                                                    <li><span><b>Total Straight Time</b></span> <label><b>{timeSheetStatusData?.totalStraightTime}</b></label></li>
                                                    <li><span>Total Over Time</span> <label>{timeSheetStatusData?.overTime}</label></li>
                                                    <li><span>Total Double TIme</span> <label>{timeSheetStatusData?.doubleTime}</label></li>
                                                    <li><span>Total Travel TIme</span> <label>{timeSheetStatusData?.totalTravelTime}</label></li>
                                                    <li><span>Total Hours</span> <label>{timeSheetStatusData?.totalHours}</label></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className={styles.billingDeailLeft}>
                                                <ul>
                                                    <li><span><b>Bill Rates</b></span> <label><b>{timeSheetStatusData?.ssaRates?.baseRate}</b></label></li>
                                                    <li><span>Base Rate (per hour)</span> <label>{timeSheetStatusData?.ssaRates?.baseRate}</label></li>
                                                    <li><span>OT Rate (per hour)</span> <label>{timeSheetStatusData?.ssaRates?.overTimeRate}</label></li>
                                                    <li><span>DT Rate (per hour)</span> <label>{timeSheetStatusData?.ssaRates?.doubleTimeRate}</label></li>
                                                    <li><span>Travel Time Rate</span> <label>{timeSheetStatusData?.totalTravelTime}</label></li>
                                                    <li><span>Travel Trips Allowed (per month)</span> <label>{timeSheetStatusData?.ssaRates?.travelTimeAllowed}</label></li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                
                                </div>

                                <div className={styles.addComment}>
                                    <p>Approved By : {timeSheetStatusData?.approvedBy?.name}</p>
                                    <p>
                                        Approved Date:{" "}
                                        {timeSheetStatusData?.approvedDate
                                            ? moment(timeSheetStatusData.approvedDate).format("MMMM D, YYYY")
                                            : "N/A"}
                                    </p>
                                    {timeSheetStatusData?.status === "pending" ?
                                        <p style={{ color: 'red' }}>Pending For Approval</p>
                                        : ""}

                                    <p>Approval Signature
                                        -------
                                    </p>
                                </div>
                            </div> */}


                        </div>


                    </div>
                </div>

            </div>
        </>

    )
}

export default TimesheetStatus