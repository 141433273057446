import React, { FC } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import {
  JobAdDescription,
  JobImageUpload,
  JobAdChoosePlan,
  JobBoostAd,
} from "./interface";
import { ADD_PAID_ADS } from "../../../shared/enums/AddPaidAdsSteps";

const JobAdFlow: FC = () => {
  const jobPaidAds = useAppSelector((state: RootState) => state.jobAd);
  const { activeStep } = jobPaidAds;

  const renderActiveStep = () => {
    switch (activeStep) {
      case ADD_PAID_ADS.AD_DESCRIPTION:
        return <JobAdDescription />;
      case ADD_PAID_ADS.UPLOAD_IMAGE:
        return <JobImageUpload />;
      case ADD_PAID_ADS.BOOST_ADD:
        return <JobBoostAd />;
      case ADD_PAID_ADS.ADD_PROMOTION:
        return <JobAdChoosePlan />;
      default:
        return <JobAdDescription />;
    }
  };
  return renderActiveStep();
};

export default JobAdFlow;
