import React, { useEffect, useState } from "react";
import styles from "./style/style.module.css";
import { getConnectionPlans } from "./api";
import MonthlyPlan from "./components/MonthlyPlan";
import YearlyPlan from "./components/YearlyPlan";

const ConnectionPlan = () => {
  const [selectedPlan, setSelectedPlan] = useState<string>("Monthly");

  const _handlePlanChange = (plan: string) => {
    setSelectedPlan(plan);
  };

  return (
    <div className={`col-12 py-4 connectiontab ${styles.connectiontab}`}>
      <div className="row align-items-center mx-auto">
        <div className="col-lg-8 col-12 mx-auto text-center">
          <h3 className="text-48 mb-2">Connection Plans Available</h3>
          <p className="text-14 text-muted">
            Explore flexible connection plans starting at just $10/month, with
            discounts and 2 months free on every plan. Perfect for scaling your
            connections effortlessly
          </p>
        </div>
        <div className="mx-auto my-4 text-center">
          <div className={`${styles.connectiontabnav}`}>
            <nav
              className={`navtabing d-inline-flex bg-lightgrey ${styles.navtabing}`}
            >
              <div className="nav nav-tabs p-2" id="nav-tab" role="tablist">
                <button
                  type="button"
                  className={`nav-link active`}
                  onClick={() => _handlePlanChange("Monthly")}
                  id="nav-monthly-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-monthly"
                  role="tab"
                  aria-controls="nav-monthly"
                  aria-selected="true"
                >
                  Monthly
                </button>
                <button
                  className={`nav-link`}
                  type="button"
                  onClick={() => _handlePlanChange("Yearly")}
                  id="nav-yearly-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-yearly"
                  role="tab"
                  aria-controls="nav-yearly"
                  aria-selected="true"
                >
                  Yearly
                </button>
              </div>
            </nav>

            <div className="tab-content mt-5 py-3 px-2" id="nav-tabContent">
              <div
                className={`tab-pane fade show active`}
                id="nav-monthly"
                role="tabpanel"
                aria-labelledby="nav-monthly-tab"
              >
                {selectedPlan === "Monthly" && <MonthlyPlan />}
              </div>
              <div
                className={`tab-pane fade show active`}
                id="nav-yearly"
                role="tabpanel"
                aria-labelledby="nav-yearly-tab"
              >
                {selectedPlan === "Yearly" && <YearlyPlan />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectionPlan;
