import React from "react";
import {
  Button,
  Dropdown,
  FormControl,
  Modal,
  ModalBody,
  ModalFooter,
} from "react-bootstrap";
import penbl from "../../../../assets/images/penbl.png";
import upload from "../../../../assets/images/upload.svg";
import blackarrow from "../../../../assets/images/blackarrow.svg";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import styles from "../styles/style.module.css";
import thumb from "../../../../assets/images/profile_picture.png";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  //   refresh: () => void;
  //   indexNumber: number | null;
}

const CertInsuranceModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
}) => {
  if (!isOpen) return null;
  return (
    <Modal show={isOpen} onHide={onClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <form>
        <ModalBody>
          <div className="addModalPro">
            <div className="row">
              <div className="col-12">
                <div className="formGroup">
                  <label>Insurance Name</label>
                  <div>
                    <Dropdown>
                      <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        {/* {degree || "Please select an option"} */}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div className="">
                          <FormControl
                            autoFocus
                            className="mx-3 my-2 w-auto"
                            placeholder="Search..."
                            // onChange={(e) => setFilterValue(e.target.value)}
                            // value={filterValue}
                          />
                        </div>

                        <div
                          style={{
                            maxHeight: "300px",
                            overflowY: "scroll",
                          }}
                        >
                          {/* {filteredDegreeData.length === 0 ? (
                    <Dropdown.Item disabled>No options found</Dropdown.Item>
                  ) : (
                    filteredDegreeData.map((option, index: number) => (
                      <>
                        <Dropdown.Item
                          key={index}
                          onClick={() =>
                            handleDegreeChange(option.label, option.value)
                          }
                        >
                          {option.label}
                        </Dropdown.Item>
                      </>
                    ))
                  )} */}
                          <div>
                            <div className="col-8">
                              <input
                                type="text"
                                placeholder="Type degree/certificate here you want to add..."
                                name="degreeCertificate"
                                // value={searchTerm}
                                // onChange={(e) => setSearchTerm(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    // handleSubmitCustomSchool();
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="formGroup">
                  <label>Expiry Date</label>
                  <div style={{ marginBottom: "30px" }}>
                    <input
                      type="Date"
                      className="form-control"
                      placeholder="02/25/2024"
                    />
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="formGroup">
                  <label>Upload Image</label>
                  <div
                    className={styles.uploadimagefile}
                    style={{ marginBottom: "30px" }}
                  >
                    <input
                      type="file"
                      className="form-control"
                      placeholder="eg : Version 1.4, IRC5, Mustang Etc."
                    />

                    <div className={styles.uploadimage}>
                      <i>
                        <img src={upload} alt="" />
                      </i>
                      <p className="mb-0 text-14 text-blue">Upload jpeg/png file</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="btn-hover-effect">
          <Button
            type="button"
            variant="secondary"
            onClick={onClose}
            className="btn bg-white arrowyellow border border-1 rounded-3 position-relative ps-5 backbutton w-auto"
          >
            <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
              Back
            </span>
            <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
              <img
                src={blackarrow}
                alt=""
                width={15}
                height={15}
                style={{ transform: "rotate(-180deg)" }}
              />
            </i>
          </Button>
          <Button
            type="button"
            variant="secondary"
            onClick={onClose}
            className="btn bg-white arrowblue position-relative pe-5 w-auto border border-1 rounded-3"
          >
            <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
              Save
            </span>
            <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
              <img src={whitearrow} alt="" width={15} height={15} />
            </i>
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default CertInsuranceModal;
