import React, { useState } from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../../assets/images/penbl.png";
import tip from "../../../../assets/images/tip.png";
import EditCompanyInfoModal from "../modals/EditCompanyInfoModal";
import { companyInfoProps } from "../../interface";

const CompanyInfo: React.FC<companyInfoProps> = ({companyContact,refresh}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const openModal = () => {
    setShowModal(true);
  }
  const closeModal = () => { setShowModal(false)};
  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-between mb-3">
        <div className="col-lg-11 col-12">
          <div className="topTipHover">
            <div className="d-flex align-items-center">
              <h4 className="text-20 fw-bold  font-inter mb-0">
                Company Information (Mandatory for job postings)
              </h4>
              <i>
                <img className="customeTolImg" src={tip} alt="" />
              </i>
            </div>
          </div>
        </div>
        <div className="col-lg-1 col-12 text-end">
          <span>
            <img src={penbl} className="cursor-pointer ms-auto" alt=""  onClick={openModal}/>
          </span>
        </div>
      </div>
      <div className={styles.personalinfo}>
        <ul className="row align-items-center">
          <li className="col-xl-4 col-6">
            <span className="">Company Name*</span>
            {/* <input
          className={styles.editable}
          name={PROFILE.FORMIK.name}
          onChange={profileDetailsFormik.handleChange}
          value={profileDetailsFormik.values?.name}
        /> */}
            <label className="clamp clamp1">Company Name</label>
          </li>
        </ul>
        <ul className="row align-items-center">
          <li className="col-xl-4 col-6">
            <span className="">Company Address*</span>
            {/* <input
        className={styles.editable}
        name={PROFILE.FORMIK.name}
        onChange={profileDetailsFormik.handleChange}
        value={profileDetailsFormik.values?.name}
      /> */}
            <label className="clamp clamp1">Chandigarh</label>
          </li>
          <li className="col-xl-4 col-6">
            <span className="">Company Phone*</span>
            {/* <input
        className={styles.editable}
        name={PROFILE.FORMIK.name}
        onChange={profileDetailsFormik.handleChange}
        value={profileDetailsFormik.values?.name}
      /> */}
            <label className="clamp clamp1">+91 0987654321</label>
          </li>
          <li className="col-xl-4 col-6">
            <span className="">Company Email*</span>
            {/* <input
        className={styles.editable}
        name={PROFILE.FORMIK.name}
        onChange={profileDetailsFormik.handleChange}
        value={profileDetailsFormik.values?.name}
      /> */}
            <label className="clamp clamp1">Andre.g@gmail.com</label>
          </li>
        </ul>
      </div>
      <EditCompanyInfoModal refresh={refresh} showModal={showModal} closeModal={closeModal} title="Edit Company Information (Mandatory for job postings....)"/>
    </div>
  );
};

export default CompanyInfo;
