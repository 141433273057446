import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "../../css/styles/style.module.css"
import info from "../../../../assets/images/info.png";
import pro from "../../../../assets/images/pro.png";
import loc from "../../../../assets/images/icon_location_filled.svg";
import bags from "../../../../assets/images/bags.png";
import rate from "../../../../assets/images/rate.png";
import lst from "../../../../assets/images/lst.png";
import Slider from "react-slick";


const OnGoingContracts = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        centerPadding: "60px",
        slidesToScroll: 2,
    };

    return (
        <div className="Contracts">
            <div className="container">

                <div className={styles.ContractTabs}>


                    <div className={styles.AllContract}>
                        <h5>
                            Ongoing Contracts <img src={info} alt="" />
                        </h5>
                        <Link to="#">
                            View All{" "}
                            <span>
                                {" "}
                                <i className="fas fa-chevron-right"></i>{" "}
                            </span>{" "}
                        </Link>
                    </div>
                    <div className="custom-ongoing">
                        <div className={styles.OuterSlides}>
                            <Slider {...settings}>
                                <div className={styles.ContarctOuter}>
                                    <div className={styles.ContarctHead}>
                                        <div className={styles.leftContract}>
                                            <h5>Contract No.: #457</h5>
                                            <span>Start Date : 12 Jan 2024</span>
                                        </div>
                                        <div className={styles.leftContract}>
                                            <button className={styles.CoptText}>
                                                <i className="far fa-copy"></i> Copy
                                            </button>
                                            <button>Ongoing</button>
                                        </div>
                                    </div>
                                    <div className={styles.outerNameDetail}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className={styles.leftName}>
                                                    <h4>Contractor Details</h4>
                                                    <div className={styles.PrileImg}>
                                                        <div className={styles.profileView}>
                                                            <span>
                                                                <img src={pro} alt="" />
                                                            </span>
                                                            <a href="#">View Profile</a>
                                                        </div>

                                                        <div>
                                                            <h5>Bradely Innes</h5>
                                                            <h6>Pia Automation . inc</h6>
                                                            <p>
                                                                <img src={loc} alt="" /> Evansville. IN
                                                            </p>
                                                            <h6>Reviews : 20 <img src={rate} alt="" /></h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={styles.rightName}>
                                                    <h6><img src={bags} alt="" />White Glove Contract</h6>
                                                    <p>Start Date: 10/18/2023</p>
                                                    <p>End Date: 12/18/2023</p>
                                                    <p>Rate Per Hour : $70.84(net 15)</p>
                                                    <p>Expense Terms : Net 15</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.timeSheets}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className={styles.timesheetData}>
                                                    <h4>Timesheet</h4>
                                                    <ul>
                                                        <li>
                                                            <span>Current Timesheet</span> <label>20</label>
                                                        </li>
                                                        <li className={styles.red}>
                                                            <span>Due Timesheet</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.yeloo}>
                                                            <span>Pending</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.gren}>
                                                            <span>Approved</span> <label>19</label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={styles.timesheetData}>
                                                    <h4>Expenses</h4>
                                                    <ul>
                                                        <li>
                                                            <span>Current Expense</span> <label>20</label>
                                                        </li>
                                                        <li className={styles.red}>
                                                            <span>Due Expense</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.yeloo}>
                                                            <span>Pending</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.gren}>
                                                            <span>Approved</span> <label>19</label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={styles.timesheetData}>
                                                    <h4>Invoices</h4>
                                                    <ul>
                                                        <li>
                                                            <span>Current Invoice</span> <label>20</label>
                                                        </li>
                                                        <li className={styles.red}>
                                                            <span>Due Invoice</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.yeloo}>
                                                            <span>Pending</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.gren}>
                                                            <span>Approved</span> <label>19</label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={styles.timesheetData}>
                                                    <h4>Purchase Orders</h4>
                                                    <ul>
                                                        <li>
                                                            <span>Current Order</span> <label>20</label>
                                                        </li>
                                                        <li className={styles.red}>
                                                            <span>Due Order</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.yeloo}>
                                                            <span>Pending</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.gren}>
                                                            <span>Approved</span> <label>19</label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.HorcontractOuter}>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className={styles.Horcontract}>
                                                        <h6>
                                                            <img src={lst} alt="" />Applications Received
                                                        </h6>
                                                        <span>20</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className={styles.Horcontract}>
                                                        <h6>
                                                            <img src={lst} alt="" />Applications Received
                                                        </h6>
                                                        <span>20</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className={styles.Horcontract}>
                                                        <h6>
                                                            <img src={lst} alt="" />Applications Received
                                                        </h6>
                                                        <span>20</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className={styles.Horcontract}>
                                                        <h6>
                                                            <img src={lst} alt="" />Applications Received
                                                        </h6>
                                                        <span>20</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className={`${styles.Horcontract} ${styles.rejected}`}>
                                                        <h6>
                                                            <img src={lst} alt="" />Applications Received
                                                        </h6>
                                                        <span>20</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.MainLink}>
                                            <Link to="#">View MSA</Link>
                                            <button>Track Progress</button>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.ContarctOuter}>
                                    <div className={styles.ContarctHead}>
                                        <div className={styles.leftContract}>
                                            <h5>Contract No.: #457</h5>
                                            <span>Start Date : 12 Jan 2024</span>
                                        </div>
                                        <div className={styles.leftContract}>
                                            <button className={styles.CoptText}>
                                                <i className="far fa-copy"></i> Copy
                                            </button>
                                            <button>Ongoing</button>
                                        </div>
                                    </div>
                                    <div className={styles.outerNameDetail}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className={styles.leftName}>
                                                    <h4>Contractor Details</h4>
                                                    <div className={styles.PrileImg}>
                                                        <div className={styles.profileView}>
                                                            <span>
                                                                <img src={pro} alt="" />
                                                            </span>
                                                            <a href="#">View Profile</a>
                                                        </div>

                                                        <div>
                                                            <h5>Bradely Innes</h5>
                                                            <h6>Pia Automation . inc</h6>
                                                            <p>
                                                                <img src={loc} alt="" /> Evansville. IN
                                                            </p>
                                                            <h6>Reviews : 20 <img src={rate} alt="" /></h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={styles.rightName}>
                                                    <h6><img src={bags} alt="" />White Glove Contract</h6>
                                                    <p>Start Date: 10/18/2023</p>
                                                    <p>End Date: 12/18/2023</p>
                                                    <p>Rate Per Hour : $70.84(net 15)</p>
                                                    <p>Expense Terms : Net 15</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.timeSheets}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className={styles.timesheetData}>
                                                    <h4>Timesheet</h4>
                                                    <ul>
                                                        <li>
                                                            <span>Current Timesheet</span> <label>20</label>
                                                        </li>
                                                        <li className={styles.red}>
                                                            <span>Due Timesheet</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.yeloo}>
                                                            <span>Pending</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.gren}>
                                                            <span>Approved</span> <label>19</label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={styles.timesheetData}>
                                                    <h4>Expenses</h4>
                                                    <ul>
                                                        <li>
                                                            <span>Current Expense</span> <label>20</label>
                                                        </li>
                                                        <li className={styles.red}>
                                                            <span>Due Expense</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.yeloo}>
                                                            <span>Pending</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.gren}>
                                                            <span>Approved</span> <label>19</label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={styles.timesheetData}>
                                                    <h4>Invoices</h4>
                                                    <ul>
                                                        <li>
                                                            <span>Current Invoice</span> <label>20</label>
                                                        </li>
                                                        <li className={styles.red}>
                                                            <span>Due Invoice</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.yeloo}>
                                                            <span>Pending</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.gren}>
                                                            <span>Approved</span> <label>19</label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={styles.timesheetData}>
                                                    <h4>Purchase Orders</h4>
                                                    <ul>
                                                        <li>
                                                            <span>Current Order</span> <label>20</label>
                                                        </li>
                                                        <li className={styles.red}>
                                                            <span>Due Order</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.yeloo}>
                                                            <span>Pending</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.gren}>
                                                            <span>Approved</span> <label>19</label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.HorcontractOuter}>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className={styles.Horcontract}>
                                                        <h6>
                                                            <img src={lst} alt="" />Applications Received
                                                        </h6>
                                                        <span>20</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className={styles.Horcontract}>
                                                        <h6>
                                                            <img src={lst} alt="" />Applications Received
                                                        </h6>
                                                        <span>20</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className={styles.Horcontract}>
                                                        <h6>
                                                            <img src={lst} alt="" />Applications Received
                                                        </h6>
                                                        <span>20</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className={styles.Horcontract}>
                                                        <h6>
                                                            <img src={lst} alt="" />Applications Received
                                                        </h6>
                                                        <span>20</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className={`${styles.Horcontract} ${styles.rejected}`}>
                                                        <h6>
                                                            <img src={lst} alt="" />Applications Received
                                                        </h6>
                                                        <span>20</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.MainLink}>
                                            <Link to="#">View MSA</Link>
                                            <button>Track Progress</button>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.ContarctOuter}>
                                    <div className={styles.ContarctHead}>
                                        <div className={styles.leftContract}>
                                            <h5>Contract No.: #457</h5>
                                            <span>Start Date : 12 Jan 2024</span>
                                        </div>
                                        <div className={styles.leftContract}>
                                            <button className={styles.CoptText}>
                                                <i className="far fa-copy"></i> Copy
                                            </button>
                                            <button>Ongoing</button>
                                        </div>
                                    </div>
                                    <div className={styles.outerNameDetail}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className={styles.leftName}>
                                                    <h4>Contractor Details</h4>
                                                    <div className={styles.PrileImg}>
                                                        <div className={styles.profileView}>
                                                            <span>
                                                                <img src={pro} alt="" />
                                                            </span>
                                                            <a href="#">View Profile</a>
                                                        </div>

                                                        <div>
                                                            <h5>Bradely Innes</h5>
                                                            <h6>Pia Automation . inc</h6>
                                                            <p>
                                                                <img src={loc} alt="" /> Evansville. IN
                                                            </p>
                                                            <h6>Reviews : 20 <img src={rate} alt="" /></h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={styles.rightName}>
                                                    <h6><img src={bags} alt="" />White Glove Contract</h6>
                                                    <p>Start Date: 10/18/2023</p>
                                                    <p>End Date: 12/18/2023</p>
                                                    <p>Rate Per Hour : $70.84(net 15)</p>
                                                    <p>Expense Terms : Net 15</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.timeSheets}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className={styles.timesheetData}>
                                                    <h4>Timesheet</h4>
                                                    <ul>
                                                        <li>
                                                            <span>Current Timesheet</span> <label>20</label>
                                                        </li>
                                                        <li className={styles.red}>
                                                            <span>Due Timesheet</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.yeloo}>
                                                            <span>Pending</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.gren}>
                                                            <span>Approved</span> <label>19</label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={styles.timesheetData}>
                                                    <h4>Expenses</h4>
                                                    <ul>
                                                        <li>
                                                            <span>Current Expense</span> <label>20</label>
                                                        </li>
                                                        <li className={styles.red}>
                                                            <span>Due Expense</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.yeloo}>
                                                            <span>Pending</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.gren}>
                                                            <span>Approved</span> <label>19</label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={styles.timesheetData}>
                                                    <h4>Invoices</h4>
                                                    <ul>
                                                        <li>
                                                            <span>Current Invoice</span> <label>20</label>
                                                        </li>
                                                        <li className={styles.red}>
                                                            <span>Due Invoice</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.yeloo}>
                                                            <span>Pending</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.gren}>
                                                            <span>Approved</span> <label>19</label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={styles.timesheetData}>
                                                    <h4>Purchase Orders</h4>
                                                    <ul>
                                                        <li>
                                                            <span>Current Order</span> <label>20</label>
                                                        </li>
                                                        <li className={styles.red}>
                                                            <span>Due Order</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.yeloo}>
                                                            <span>Pending</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.gren}>
                                                            <span>Approved</span> <label>19</label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.HorcontractOuter}>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className={styles.Horcontract}>
                                                        <h6>
                                                            <img src={lst} alt="" />Applications Received
                                                        </h6>
                                                        <span>20</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className={styles.Horcontract}>
                                                        <h6>
                                                            <img src={lst} alt="" />Applications Received
                                                        </h6>
                                                        <span>20</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className={styles.Horcontract}>
                                                        <h6>
                                                            <img src={lst} alt="" />Applications Received
                                                        </h6>
                                                        <span>20</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className={styles.Horcontract}>
                                                        <h6>
                                                            <img src={lst} alt="" />Applications Received
                                                        </h6>
                                                        <span>20</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className={`${styles.Horcontract} ${styles.rejected}`}>
                                                        <h6>
                                                            <img src={lst} alt="" />Applications Received
                                                        </h6>
                                                        <span>20</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.MainLink}>
                                            <Link to="#">View MSA</Link>
                                            <button>Track Progress</button>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.ContarctOuter}>
                                    <div className={styles.ContarctHead}>
                                        <div className={styles.leftContract}>
                                            <h5>Contract No.: #457</h5>
                                            <span>Start Date : 12 Jan 2024</span>
                                        </div>
                                        <div className={styles.leftContract}>
                                            <button className={styles.CoptText}>
                                                <i className="far fa-copy"></i> Copy
                                            </button>
                                            <button>Ongoing</button>
                                        </div>
                                    </div>
                                    <div className={styles.outerNameDetail}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className={styles.leftName}>
                                                    <h4>Contractor Details</h4>
                                                    <div className={styles.PrileImg}>
                                                        <div className={styles.profileView}>
                                                            <span>
                                                                <img src={pro} alt="" />
                                                            </span>
                                                            <a href="#">View Profile</a>
                                                        </div>

                                                        <div>
                                                            <h5>Bradely Innes</h5>
                                                            <h6>Pia Automation . inc</h6>
                                                            <p>
                                                                <img src={loc} alt="" /> Evansville. IN
                                                            </p>
                                                            <h6>Reviews : 20 <img src={rate} alt="" /></h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={styles.rightName}>
                                                    <h6><img src={bags} alt="" />White Glove Contract</h6>
                                                    <p>Start Date: 10/18/2023</p>
                                                    <p>End Date: 12/18/2023</p>
                                                    <p>Rate Per Hour : $70.84(net 15)</p>
                                                    <p>Expense Terms : Net 15</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.timeSheets}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className={styles.timesheetData}>
                                                    <h4>Timesheet</h4>
                                                    <ul>
                                                        <li>
                                                            <span>Current Timesheet</span> <label>20</label>
                                                        </li>
                                                        <li className={styles.red}>
                                                            <span>Due Timesheet</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.yeloo}>
                                                            <span>Pending</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.gren}>
                                                            <span>Approved</span> <label>19</label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={styles.timesheetData}>
                                                    <h4>Expenses</h4>
                                                    <ul>
                                                        <li>
                                                            <span>Current Expense</span> <label>20</label>
                                                        </li>
                                                        <li className={styles.red}>
                                                            <span>Due Expense</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.yeloo}>
                                                            <span>Pending</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.gren}>
                                                            <span>Approved</span> <label>19</label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={styles.timesheetData}>
                                                    <h4>Invoices</h4>
                                                    <ul>
                                                        <li>
                                                            <span>Current Invoice</span> <label>20</label>
                                                        </li>
                                                        <li className={styles.red}>
                                                            <span>Due Invoice</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.yeloo}>
                                                            <span>Pending</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.gren}>
                                                            <span>Approved</span> <label>19</label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={styles.timesheetData}>
                                                    <h4>Purchase Orders</h4>
                                                    <ul>
                                                        <li>
                                                            <span>Current Order</span> <label>20</label>
                                                        </li>
                                                        <li className={styles.red}>
                                                            <span>Due Order</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.yeloo}>
                                                            <span>Pending</span> <label>9</label>
                                                        </li>
                                                        <li className={styles.gren}>
                                                            <span>Approved</span> <label>19</label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.HorcontractOuter}>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className={styles.Horcontract}>
                                                        <h6>
                                                            <img src={lst} alt="" />Applications Received
                                                        </h6>
                                                        <span>20</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className={styles.Horcontract}>
                                                        <h6>
                                                            <img src={lst} alt="" />Applications Received
                                                        </h6>
                                                        <span>20</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className={styles.Horcontract}>
                                                        <h6>
                                                            <img src={lst} alt="" />Applications Received
                                                        </h6>
                                                        <span>20</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className={styles.Horcontract}>
                                                        <h6>
                                                            <img src={lst} alt="" />Applications Received
                                                        </h6>
                                                        <span>20</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className={`${styles.Horcontract} ${styles.rejected}`}>
                                                        <h6>
                                                            <img src={lst} alt="" />Applications Received
                                                        </h6>
                                                        <span>20</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.MainLink}>
                                            <Link to="#">View MSA</Link>
                                            <button className="edit">Edit</button>
                                            <button>Track Progress</button>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>

            
                </div>
            </div>
        </div>
    );
};

export default OnGoingContracts;
