import { axios } from "../../../../../lib/axios";
import { AxiosResponse} from "../../../../../shared/models";
import { API_ROUTES} from "../../../../../utils/routes/apiRoutes";
import { WorkResponse } from "../../OpenContracts/interface";
import { ContractResponse } from "../../interface";


export const getOngoingContractListProfesional = async (
    workType: string,
    workStatus: string,
    planType: string,
  ): Promise<WorkResponse> => {
    return axios.get(
      `${API_ROUTES.CONTRACTS.OPEN_CONTRACTS
      }?workType=${workType}&type=${1}&workStatus=${workStatus}&planType=${planType}`
    );
  }; 

  export const getJobOfferPendingProfessional=async(
    workType: string,
    workStatus: string,
    planType: string,
    jobStatus:string
  ): Promise<WorkResponse>=>{
    return axios.get(
        `${API_ROUTES.JOBS.SELECTEDJOB
        }?workType=${workType}&type=${1}&workTypeState=${workStatus}&planType=${planType}&jobStatus=${jobStatus}`
      );
  }
  export const getJobOfferPendingProfessionalDirect=async(
    workType: string,
    workStatus: string,
    jobStatus:string
  ): Promise<WorkResponse>=>{
    return axios.get(
        `${API_ROUTES.JOBS.SELECTEDJOB
        }?workType=${workType}&type=${1}&workTypeState=${workStatus}&jobStatus=${jobStatus}`
      );
  }
  export const getAppliedJobProfessional=async(
    workType: string,
    workStatus: string,
    planType: string,
    jobStatus:string
  ): Promise<WorkResponse>=>{
    return axios.get(
        `${API_ROUTES.JOBS.SELECTEDJOB
        }?workType=${workType}&type=${1}&workStatus=${workStatus}&planType=${planType}&jobStatus=${jobStatus}`
      );
  }
  export const getAppliedJobProfessionalDirect=async(
    workType: string,
    workStatus: string,
    jobStatus:string
  ): Promise<WorkResponse>=>{
    return axios.get(
        `${API_ROUTES.JOBS.SELECTEDJOB
        }?workType=${workType}&type=${1}&workStatus=${workStatus}&jobStatus=${jobStatus}`
      );
  }
  export const getRejectedJobProfessional=async(
    workType: string,
    workStatus: string,
    planType: string,
    jobStatus:string
  ): Promise<WorkResponse>=>{
    return axios.get(
        `${API_ROUTES.JOBS.SELECTEDJOB
        }?workType=${workType}&type=${1}&workStatus=${workStatus}&planType=${planType}&jobStatus=${jobStatus}`
      );
  }
  export const getRejectedJobProfessionalDirect=async(
    workType: string,
    workStatus: string,
    jobStatus:string
  ): Promise<WorkResponse>=>{
    return axios.get(
        `${API_ROUTES.JOBS.SELECTEDJOB
        }?workType=${workType}&type=${1}&workStatus=${workStatus}&jobStatus=${jobStatus}`
      );
  }

  export const getOngoingContProfessional=async(
    workType: string,
    workStatus: string,
  ): Promise<WorkResponse>=>{
    return axios.get(
        `${API_ROUTES.JOBS.SELECTEDJOB
        }?workType=${workType}&type=${1}&workStatus=${workStatus}`
      );
  }

  export const getCompletedClosedContracts = async (workType: string,type:number,workTypeState:string,): Promise<ContractResponse[]> => {
    return axios.get(`${API_ROUTES.HOURLY_CONTRACTS.GET_CONTRACTS}?workType=${workType}&type=${type}&workTypeState=${workTypeState}`);
};
export const rejectJobOffer = async (workId: string): Promise<AxiosResponse<any>> => {
  return axios.put(`${API_ROUTES.JOBS.REJECTJOB}/${workId}`);
};
export const acceptJobOffer = async (workId: string): Promise<AxiosResponse<any>> => {
  return axios.put(`${API_ROUTES.JOBS.SELECTJOB_PROF}/${workId}`);
};
