import React, { useEffect, useState } from "react";
import info from "../../../assets/images/info.png";
import styles from "../styles/style.module.css";
import Header from "../../header/routes";
import { applyWork } from "../../Feeds/api";
import { toast } from "react-toastify";
import { APIResponseErrors } from "../../work/interface";
import { getPaymentTermsByWorkId } from "../api";
import { PaymentTermResponse } from "../interface";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import CustomModal from "../../../shared/CustomModal/customModal";
import Congratulation from "../../congratulations/routes";
import { APP_ROUTES } from "../../../utils/routes/AppRoutes";
import { CongratulationComp } from "../../NewBusiness/modals/CongratulationComp";
import { CongModal } from "./CongModal";

const PaymentTerms = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [paymentTerms, setPaymentTerms] = useState<PaymentTermResponse>();
  const [hotelChecked, setHotelChecked] = useState<boolean>(false);
  const [rentalCarChecked, setRentalCarChecked] = useState<boolean>(false);
  const [airfareChecked, setAirfareChecked] = useState<boolean>(false);
  const [hourlyPaymentTermId, setHourlyPaymentTermId] = useState<string>();
  const [expensePaymentTermId, setExpensePaymentTermId] = useState<string>();
  const { eligibilityAnswers, interviewAnswers, liability_insurance } = useSelector(
    (state: RootState) => state.dashboard
  );
  const { workType } = useSelector((state: RootState) => state.workType);
  const [showModal, setShowModal] = useState<boolean>(false);

  console.log(workType, "workType");


  const [modalDetail, setModalDetail] = useState({
    show: true,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setShowModal(false);
  };

  const handleHotelChange = (event: any) => {
    setHotelChecked(event.target.checked);
  };

  const handleRentalCarChange = (event: any) => {
    setRentalCarChecked(event.target.checked);
  };

  const handleAirfareChange = (event: any) => {
    setAirfareChecked(event.target.checked);
  };

  const handleChnageHourlyPayment = (value: string) => {
    setHourlyPaymentTermId(value);
  };
  const handleChnageExpensePayment = (value: string) => {
    setExpensePaymentTermId(value);
  };

  const handleApplyJob = async () => {
    if (!hourlyPaymentTermId) {
      toast.error("Please select Hourly Payment Term");
      return;
    } else if (!expensePaymentTermId) {
      toast.error("Please select Expense Payment Term");
      return;
    }
    const payload: any = {
      hourlyPaymentTermId: hourlyPaymentTermId,
      expensePaymentTermId: expensePaymentTermId,
      eligibilityAnswers: eligibilityAnswers?.map((x) => ({
        answer: x.Answers,
        questionId: x.questionId,
      })),
      interviewAnswers: interviewAnswers?.map((x) => ({
        answer: x.Answers,
        questionId: x.questionId,
      })),
      payForHotel: hotelChecked,
      payForRentalCar: rentalCarChecked,
      payForAirfare: airfareChecked,
      liability_insurance :{
        fileName: liability_insurance?.fileName,
        fileUrl: liability_insurance?.fileUrl,
      },
    };

    try {
      const response = await applyWork(id as string, payload);
      setShowModal(true);
    } catch (error) {
      const apiError = error as APIResponseErrors;
      const errorMessage = apiError?.data?.message as string;
      toast.error(errorMessage); 
    }
  };

  const PayementTerms = async () => {
    const response: any = await getPaymentTermsByWorkId(id as string);
    setPaymentTerms(response?.data);
  };

  useEffect(() => {
    PayementTerms();
  }, []);

  return (
    <>
      <Header />
      <div className={`selectpaymentterms ${styles.jobDetails}`}>
        <div className="container">
          <div className={styles.outerJobdetails}>
            <h5>Select your Payment Terms</h5>
            <div className={styles.innerFRomjob}>
              <h6>Select Your Desired Hourly Payment Terms</h6>
              <span className={styles.budget}>
                Customer's Net Terms : Net 45 <img src={info} alt="" />
              </span>
              <div className={styles.radioBtn}>
                {paymentTerms?.hourly?.map((data, idx) => {
                  return (
                    <div key={idx} className={styles.formCheck}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault1"
                        id="flexRadioDefault1"
                        checked={hourlyPaymentTermId === data?._id}
                        onChange={() => handleChnageHourlyPayment(data?._id)}
                      />
                      <label className="form-check-label">
                        net{data?.paymentTerm}
                      </label>
                    </div>
                  );
                })}
              </div>
              <p>
                Your Hourly rates and expenses are based on the payment terms
                you select.
                <p>
                  {" "}
                  See the tables below for adjusted amounts based on terms.
                </p>
              </p>
              <span className={styles.instruction}>
                You will receive payments 60 days after you create the invoice
              </span>
              <table>
                <tr>
                  <th>Hourly Payment Terms</th>
                  <th>Base Rate</th>
                  <th>OT Rate</th>
                  <th>DT Rate</th>
                  <th>Travel Time Rate</th>
                </tr>
                {paymentTerms?.hourly?.map((data, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{data?.paymentTerm}</td>
                      <td>${data?.baseRate}</td>
                      <td>${data?.overTimeRate}</td>
                      <td>${data?.doubleTimeRate}</td>
                      <td>${data?.travelTimeRate}</td>
                    </tr>
                  );
                })}
              </table>
              <h6>Select Your Desired Expenses payment Terms</h6>

              <div className={styles.radioBtn}>
                {paymentTerms?.expense?.map((data, idx) => {
                  return (
                    <div key={idx} className={styles.radioBtn}>
                      <div className={styles.formCheck}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault2"
                          id="flexRadioDefault2"
                          checked={expensePaymentTermId === data?._id}
                          onChange={() => handleChnageExpensePayment(data?._id)}
                        />
                        <label className="form-check-label">
                          net{data?.paymentTerm}
                        </label>
                      </div>
                    </div>
                  );
                })}
              </div>
              <span className={styles.instruction}>
                You will receive payments 60 days after you create the invoice
              </span>
              <table>
                <tr>
                  <th>Expense Payment Terms</th>
                  <th>Daily Per Diem</th>
                  <th>Rental Car Allowance</th>
                  <th>Fuel</th>
                  <th>Airfare Allowance</th>
                  <th>Mileage Reimbursement </th>
                </tr>
                {paymentTerms?.expense?.map((data, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{data?.paymentTerm}</td>
                      <td>${data?.perDiem}</td>
                      <td>${data?.rentalCarAllowance}</td>
                      <td>${data?.fuel}</td>
                      <td>${data?.airfareAllowance}</td>
                      <td>${data?.mileage}</td>
                    </tr>
                  );
                })}
              </table>

              <div className={styles.sheetDiscription}>
                <p className="mt-4 mb-3">
                  By selecting any of the options below, you agree to a 20%
                  processing fee for each. The fee amounts will be taken
                  automatically from your payments each week.
                </p>
                <div className={styles.formCheck}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckHotel"
                    checked={hotelChecked}
                    onChange={handleHotelChange}
                  />
                  <label className="form-check-label" htmlFor="flexCheckHotel">
                    I Request that Automate America Pay for my Hotel.
                  </label>
                </div>
                <div className={styles.formCheck}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckRentalCar"
                    checked={rentalCarChecked}
                    onChange={handleRentalCarChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckRentalCar"
                  >
                    I Request that Automate America pay for my Rental car.
                  </label>
                </div>
                <div className={styles.formCheck}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckAirfare"
                    checked={airfareChecked}
                    onChange={handleAirfareChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckAirfare"
                  >
                    I Request that Automate America pay for my Airfare.
                  </label>
                </div>
                <button
                  onClick={() => handleApplyJob()}
                  className="cmn-tbn mt-4"
                >
                  Apply
                </button>
              </div>
            </div>
            {modalDetail.show && (
              <CongModal
                closeModal={handleOnCloseModal}
                showCongratsModal={showModal}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentTerms;
