import React, { useEffect, useState } from "react";

import Header from "../../header/routes";
import { useNavigate } from "react-router-dom";
import Img1 from "../../../assets/images/img_1.png";
import styles from "../styles/style.module.css";
import Slider from "react-slick";

function ChatListing() {
  const navigate = useNavigate();
  const handleGoToNext = () => {
    navigate("/feeds");
  };

  return (
    <>
      <div className={styles.ChatListing}>
        <ol className="list-group list-group-numbered">
          <li className="d-flex justify-content-between align-items-start mb-3 border p-2 rounded-3">
            <div className="ms-2 me-auto">
              <h4 className="text-16">Title of Conevrsations</h4>
              <p className="mb-0 text-14 opacity-75">Content for list item</p>
            </div>
            {/* <span className="badge text-bg-primary rounded-pill">14</span> */}
          </li>
          <li className=" d-flex justify-content-between align-items-start mb-3 border p-2 rounded-3">
            <div className="ms-2 me-auto">
            <h4 className="text-16">Title of Conevrsations</h4>
              <p className="mb-0 text-14 opacity-75">Content for list item</p>
            </div>
            {/* <span className="badge text-bg-primary rounded-pill">14</span> */}
          </li>
          <li className="d-flex justify-content-between align-items-start mb-3 border p-2 rounded-3" >
            <div className="ms-2 me-auto">
            <h4 className="text-16">Title of Conevrsations</h4>
              <p className="mb-0 text-14 opacity-75">Content for list item</p>
            </div>
            {/* <span className="badge text-bg-primary rounded-pill">14</span> */}
          </li>
          <li className="d-flex justify-content-between align-items-start mb-3 border p-2 rounded-3" >
            <div className="ms-2 me-auto">
            <h4 className="text-16">Title of Conevrsations</h4>
              <p className="mb-0 text-14 opacity-75">Content for list item</p>
            </div>
            {/* <span className="badge text-bg-primary rounded-pill">14</span> */}
          </li>
          <li className="d-flex justify-content-between align-items-start mb-3 border p-2 rounded-3" >
            <div className="ms-2 me-auto">
            <h4 className="text-16">Title of Conevrsations</h4>
              <p className="mb-0 text-14 opacity-75">Content for list item</p>
            </div>
            {/* <span className="badge text-bg-primary rounded-pill">14</span> */}
          </li>
        </ol>
      </div>
    </>
  );
}

export default ChatListing;
