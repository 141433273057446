import { Dispatch } from "react";
import {jsPDF} from "jspdf";
import * as htmlToImage from 'html-to-image';

import { axios } from "../../lib/axios";
import { API_ROUTES } from "../../utils/routes/apiRoutes";
import { UploadFileResponse } from "./interface";

export const uploadFile = async (file: FormData): Promise<UploadFileResponse> => {
    return axios.post(API_ROUTES.COMMON.UPLOAD_FILE, file);
};

export const addImagesToPdf=(refs:any[],setDownloading:Dispatch<Boolean>,name:string)=>{
    setDownloading(true)
    const contents=refs.map(ref=>ref.current)
    const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight(); // A4 page in mm
    const createCanvas=async(content:any)=>{
      const canvas= await htmlToImage.toCanvas(content)
      return canvas.toDataURL("image/png") 
    }

    const promises=contents.map((content)=>{
      return createCanvas(content)
    })
   
    Promise.all(promises).then((res)=>{
      
      res.forEach((imgData,index) => {
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight)
        if(index!=res.length-1)
          {pdf.addPage()}
      });
      pdf.save(`${name}${Date.now()}.pdf`)
      setDownloading(false)
    })

  }
