import React from "react";
import moment from "moment";
import styles from "../../css/styles/style.module.css"
import info from "../../../../assets/images/info_icon.svg";
import loc from "../../../../assets/images/loc.png";
import userImg from "../../../../assets/images/user.jpg"
import { ContractResponse } from "../interface";
import Toltip from "../../toltip/page";

interface Props {
 ongoingContractDetails: ContractResponse
 expenseDetails:any
}
const WhiteGloveContractDetails: React.FC<Props> = ({ ongoingContractDetails ,expenseDetails}) => {
    console.log(expenseDetails  ,"ongoingggggggggggggggggggg")


    return (
        <>
     
        <div className="Contracts OngoingContractDetails">
            <div className="container px-0">
         
                <div className={styles.OngoingContractDetails}>
                    <div className={styles.ContractTabs}>
                  
                        <div className={styles.OuterSlides}>
                            <div className="">
                            
                                <div className={`py-0 ${styles.ContarctHead}`}>
                                    <div className={styles.leftContract}>
                                        <div className="d-flex align-items-center">
                                            <h4 className="form-check-label text-20 fw-bold mb-0 font-inter">
                                            White Glove Hourly Contracts
                                            </h4>
                                            <i>
                                                <img className="customeTolImg" src={info} alt="" />
                                            </i>
                                            <Toltip
                                            />
                                        </div>
                                    </div>
                                    {/* <div className={styles.leftContract}>

                                        <button className="fw-semibold">Ongoing</button>
                                    </div> */}
                                </div>
                                <div className={`px-0 ${styles.outerNameDetail}`}>
                                    <div className="row g-3">
                                        <div className="col-lg-6">
                                            <div className={`py-3 ${styles.leftName}`} style={{minHeight: "170px"}}>

                                                <div className={styles.PrileImg}>
                                                    <span className="text-center">
                                                         <img src={expenseDetails?.user?.profilePicture} alt="" />
                                                        <h5 className="text-14 py-2 justify-content-center fw-medium">#{expenseDetails?.work?.workNumber }</h5>
                                                        <button className="text-12 fw-semibold btn-info text-blue rounded-pill px-2 py-1 border-0 bg-lightblue">{expenseDetails?.work?.workType === "hourly_contract" ? "Hourly Contract" : "" || "Hourly Contract"}</button>
                                                    </span>
                                                    <div className="ms-3">
                                                        {/* <h5>Bradely Innes</h5> */}
                                                        <h5>{expenseDetails?.user?.name }</h5>  
                                                        <h5 className="fw-medium text-14 font-poppins lh-base">Pia Automation . inc</h5>
                                                        <h5 className="fw-medium text-14 font-poppins lh-base">PLC Programer</h5>
                                                        <p className="fw-medium text-14 font-poppins lh-base ps-0 d-flex align-items-center justify-content-start">
                                                            <i className=''><img src={loc} alt="" className='position-relative'  /></i>{expenseDetails?.work?.street || "Chattanooga,Tennessee,37402"
                                                            }</p>
                                                     <h6 className="fw-bold text-blue text-14 font-poppins lh-base">Rate PerHour : ${expenseDetails?.work?.rates?.baseRate || "0"} (Net 90)</h6>
                                                      
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        {/* <div className="col-lg-6">
                                            <div className={`py-3 ${styles.leftName}`} style={{minHeight: "170px"}}>

                                                <div className={styles.PrileImg}>
                                                    <span className="text-center">
                                                        <img src={ongoingContractDetails?.contractor?.profilePicture ? ongoingContractDetails?.contractor?.profilePicture : ongoingContractDetails?.userInfo?.profilePicture} alt="" />
                                                        {ongoingContractDetails?.ownWork === true ?
                                                            <h5 className="text-14 py-2 justify-content-center fw-medium">Contractor</h5>
                                                            :
                                                            <h5 className="text-14 py-2 justify-content-center fw-medium">Professional</h5>
                                                        }
                                                    </span>

                                                    <div className="ms-3">
                                                        <h5 className="fw-medium text-14 font-poppins lh-base">{ongoingContractDetails?.contractor?.name?ongoingContractDetails?.contractor?.name:ongoingContractDetails?.userInfo?.name}</h5>

                                                        <h5 className="fw-medium text-14 font-poppins lh-base">{ongoingContractDetails?.contractor?.occupation?.name?ongoingContractDetails?.contractor?.occupation?.name:ongoingContractDetails?.userInfo?.occupation?.name}</h5>
                                                        <h5 className="fw-medium text-14 font-poppins lh-base">Date of Application: {moment(ongoingContractDetails?.times?.startDate).format("MM-DD-YYYY")}</h5>
                                                        <p className="fw-medium text-14 font-poppins lh-base mt-2">
                                                            <img src={loc} alt="" />{ongoingContractDetails?.userInfo?.address}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default WhiteGloveContractDetails

